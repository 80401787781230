import { FFmpeg } from '@ffmpeg/ffmpeg';
import Compressor from 'compressorjs';

import { BucketPaths } from 'config';

import { BinaryUploadProps, FileList, FileToUpload, UseUploadBinaryProps } from '../type';
import { AwsServices } from 'services/apis/AWS';
import { fetchFile, toBlobURL } from '@ffmpeg/util';

export const getSignedUrlQueryParams = (signedSrc?: string | null) => {
  if (!signedSrc) {
    return null;
  }
  const urlSearchParams = new URLSearchParams(signedSrc);
  const keys = Array.from(urlSearchParams.keys());
  const values = Array.from(urlSearchParams.values());
  const queryParams = keys.reduce(
    (acc, key, index) => ({ ...acc, [key]: values[index] }),
    {} as {
      ['X-Amz-Expires']: string;
      ['X-Amz-Algorithm']: string;
      ['X-Amz-Credential']: string;
      ['X-Amz-Date']: string;
      ['X-Amz-SignedHeaders']: string;
      ['X-Amz-Signature']: string;
    }
  );
  return queryParams;
};

export const getFileBlob = (file: File): Promise<Blob> => {
  return new Promise<Blob>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const blob = new Blob([reader.result as ArrayBuffer], { type: file?.type });
      resolve(blob);
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
};

export const uploadFilesToS3 = async ({ files, path }: { files: File[]; path: BucketPaths }) => {
  try {
    const awsFiles = await Promise.all(
      files.map(async file => {
        const blob = await getFileBlob(file);
        return {
          blob,
          path,
          type: file.type,
        };
      })
    );
    const binary = await AwsServices.uploadFilesToS3({
      files: awsFiles,
    });

    return binary;
  } catch (error) {
    throw error;
  }
};

export const resizeImage = async (
  file: File,
  quality: BinaryUploadProps['binaryData']['0']['quality'] = 1
) => {
  return new Promise<File>((resolve, reject) => {
    if (!file || !file.type.startsWith('image/')) {
      console.error('Invalid file type:', file.type);
      throw new Error('Invalid file type');
    }
    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = () => {
      const width = img.width * quality;
      const height = img.height * quality;

      new Compressor(file, {
        quality,
        width,
        height,
        maxWidth: width,
        maxHeight: height,
        success(result) {
          const formData = new FormData();
          formData.append('image', result);
          resolve(new File([result], 'image', { type: file.type }));
        },
        error(err) {
          console.error('Compression error:', err);
          reject(err);
        },
      });
    };
  });
};

export const compressVideo = async (
  inputPath: string,
  videoWidth: BinaryUploadProps['binaryData']['0']['videoWidth'] = 'iw'
) => {
  const ffmpeg = new FFmpeg();
  if (!ffmpeg.loaded) {
    const baseURL = 'https://unpkg.com/@ffmpeg/core-mt@0.12.6/dist/esm';

    await ffmpeg.load({
      coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript'),
      wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, 'application/wasm'),
      workerURL: await toBlobURL(`${baseURL}/ffmpeg-core.worker.js`, 'text/javascript'),
    });
  }

  await ffmpeg.writeFile('input.mp4', await fetchFile(inputPath));

  const output = await ffmpeg.exec([
    '-i',
    'input.mp4',
    '-vf',
    `scale=${videoWidth}:-2`,
    '-c:v',
    'libx264',
    '-crf',
    '23',
    '-preset',
    'veryslow',
    '-c:a',
    'aac',
    '-b:a',
    '128k',
    '-movflags',
    'faststart',
    '-y',
    'output.mp4',
  ]);

  if (output === 0) {
    const fileData = await ffmpeg.readFile('output.mp4');
    const blob = new Blob([fileData], { type: 'video/mp4' });
    return blob;
  }
  throw new Error('Failed to compress video');
};

export const resizeFiles = async ({ files }: { files: FileToUpload[] }) => {
  try {
    if (!files.length) {
      return null;
    }

    const resizedFiles = await Promise.all(
      files.map(async fileState => {
        const file = fileState.file as File;
        const isImage = file.type.includes('image');
        const isVideo = file.type.includes('video');
        const ifNotResizable = !isImage && !isVideo;
        let resizedFile: File | null = ifNotResizable ? file : null;

        if (isImage) {
          if (fileState?.quality) {
            resizedFile = await resizeImage(file, fileState?.quality);
          } else {
            resizedFile = new File([file], 'image', { type: file?.type });
          }
        } else if (isVideo) {
          if (fileState?.videoWidth) {
            const path = URL.createObjectURL(file);
            const compressedFile = await compressVideo(path, fileState.videoWidth);
            resizedFile = new File([compressedFile], 'video', { type: file?.type });
          } else {
            resizedFile = new File([file], 'video', { type: file?.type });
          }
        }

        if (!resizedFile) {
          throw new Error('File type not supported.');
        }

        return resizedFile;
      })
    );

    return resizedFiles;
  } catch (error) {
    throw error;
  }
  return null;
};

export const replaceAndUploadBinary = async ({
  binariesDataArray,
  filesList,
  bucketPath,
}: {
  binariesDataArray: UseUploadBinaryProps['binariesDataArray'];
  filesList: FileList[];
  bucketPath: BucketPaths;
}) => {
  try {
    const filesListPathsToDelete = filesList
      .flatMap(list => {
        const binariesData = (
          binariesDataArray.find(binaryData => binaryData.id === list.id)?.binariesData || []
        )?.map(binary => {
          return typeof binary === 'object' ? binary?.uri : binary;
        });

        const oldImagesToKeep = list?.files
          .filter(file => file.type === 'old' && file.src)
          .map(file => file.src) as string[];

        const pathsToDelete = binariesData?.filter(binaryData => {
          return !oldImagesToKeep.includes(binaryData || '');
        }) as string[];

        return pathsToDelete;
      })
      .filter(Boolean);

    const binaryDataWithoutId = binariesDataArray
      ?.filter(binaryData => !binaryData.id)
      ?.flatMap(binaryData =>
        binaryData.binariesData?.map(binary => {
          return typeof binary === 'object' ? binary?.uri : binaryData;
        })
      );

    const pathsToDelete = ([...filesListPathsToDelete, ...binaryDataWithoutId]?.filter(Boolean) ||
      []) as string[];

    if (pathsToDelete.length) {
      try {
        await AwsServices.deleteFromS3({
          fileKeys: pathsToDelete,
        });
      } catch (error) {
        console.log('Error deleting files:', error);
      }
    }

    const filesToResizeAndUpload = filesList.reduce<FileToUpload[]>((acc, list) => {
      const newFiles = list.files.filter(file => file.type === 'new');
      const newArr = [
        ...acc,
        ...newFiles.map((binary, index) => ({
          file: binary.file as File,
          quality: list.quality,
          videoWidth: list.videoWidth,
          id: list.id,
          index,
        })),
      ];
      return newArr;
    }, []);

    const resizedFiles = await resizeFiles({
      files: filesToResizeAndUpload,
    });

    let res: {
      signedUrl: string;
      fileKey: string;
      url: string;
    }[] = [];

    if (resizedFiles?.length) {
      res = await uploadFilesToS3({ files: resizedFiles, path: bucketPath });
    }

    const newPaths = filesList.map(list => {
      const oldFiles = list.files.filter(file => file.type === 'old');
      const newFiles = list.files.filter(file => file.type === 'new');
      const newUpdatedFiles = newFiles.map((file, index) => ({
        ...file,
        src: res[
          filesToResizeAndUpload.findIndex(item => item.id === list.id && item.index === index)
        ]?.url?.split('?')?.[0],
      }));
      return {
        id: list.id,
        paths: [...oldFiles, ...newUpdatedFiles].map(file => file.src as string),
      };
    });

    return newPaths;
  } catch (error) {
    throw error;
  }
};

export const downloadFile = async (filePath: string | undefined, fileName = 'download') => {
  try {
    if (filePath) {
      const response = await fetch(filePath);
      if (!response.ok) {
        throw new Error('Failed to fetch file');
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.download = `${fileName}.${blob.type.split('/')[1]}`;
      document.body.appendChild(link);
      link.click();
      if (link.parentNode) {
        link.parentNode.removeChild(link); // Check if parentNode is not null before removal
      }
    }
  } catch (error) {
    console.error('Error:', error);
  }
};

export const getFileType = (type: string) => {
  let contentType = '';
  switch (true) {
    case type.includes('video'):
      contentType = 'video';
      break;
    case type === 'application/pdf':
      contentType = 'pdf';
      break;
    case type.includes('image'):
      contentType = 'image';
      break;
    default:
      contentType = contentType;
  }
  if (contentType) {
    return contentType;
  }
  return null;
};

export const getFileTypeFromUrl = (url: string): string => {
  const fileExtension = url.split('.').pop()?.toLowerCase();

  const imageExtensions = ['png', 'jpg', 'jpeg', 'gif', 'bmp', 'tiff'];
  const videoExtensions = ['mp4', 'mov', 'avi', 'mkv', 'quicktime'];

  if (fileExtension && imageExtensions.includes(fileExtension)) {
    return 'image';
  } else if (fileExtension && videoExtensions.includes(fileExtension)) {
    return 'video';
  } else {
    return '';
  }
};
