import i18next from 'i18next';
import { CSSProperties } from 'react';
import Facebook from '../assets/socialIcon/facebook.png';
import Instagram from '../assets/socialIcon/instagram.png';
import Tiktok from '../assets/socialIcon/tiktok.png';
import Youtube from '../assets/socialIcon/youtube.png';
import Twitter from '../assets/socialIcon/twitter.png';
import Twitch from '../assets/socialIcon/twitch.png';
import Snapchat from '../assets/socialIcon/snapchat.png';
import Onlyfans from '../assets/socialIcon/onlyfans.png';
import Pinterest from '../assets/socialIcon/pinterest.png';
import Linkedin from '../assets/socialIcon/linkedin.png';
import { Option } from 'components/shared';
import { Insight } from 'services/apis/Handle/handle.types';
import { TOptionsBase } from 'i18next';
import { AWS_MEDIA_URL, CDN_MEDIA_URL } from 'config';
let debounse: number;
interface Crumb {
  name: string;
  screen: number;
}

export const sortArrays = (arr1: any, arr2: any) => {
  if (!arr1 || !arr2) {
    return [];
  }
  const sortedArr2 = arr1
    ?.map((item: any) => {
      const index = arr2?.findIndex((item2: any) => item2.title === item.title);
      if (index !== -1) {
        return arr2[index];
      }
      return null;
    })
    ?.filter((item: any) => item !== null);
  const addedKeys = arr2?.filter((item: any) => {
    const index = arr1?.findIndex((item2: any) => item2.title === item.title);
    return index === -1;
  });
  return [...sortedArr2, ...addedKeys];
};

export const deepEqualValues = (
  values1: {
    key: string;
    value: number | null;
  }[],
  values2: {
    key: string;
    value: number | null;
  }[]
) => {
  if (values1?.length !== values2?.length) {
    return false;
  }

  for (let i = 0; i < values1?.length; i++) {
    const value1 = values1[i];
    const value2 = values2[i];

    if (value1?.key !== value2?.key || value1?.value !== value2?.value) {
      return false;
    }
  }

  return true;
};

export const deepEqualStats = (stats1: Insight[], stats2: Insight[]) => {
  if (stats1?.length !== stats2?.length) {
    return false;
  }

  for (let i = 0; i < stats1?.length; i++) {
    const stat1 = stats1[i];
    const stat2 = stats2[i];

    if (stat1.type !== stat2.type || stat1.category !== stat2.category) {
      return false;
    }

    if (!deepEqualValues(stat1.values, stat2.values)) {
      return false;
    }
  }

  return true;
};

export const deepEqual = (obj1: any, obj2: any) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1?.length !== keys2?.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = obj1[key];
    const val2 = obj2[key];

    if (key === 'stats') {
      if (!deepEqualStats(val1, val2)) {
        return false;
      }
    } else if (typeof val1 === 'object' && typeof val2 === 'object') {
      if (!deepEqual(val1, val2)) {
        return false;
      }
    } else if (val1 !== val2) {
      return false;
    }
  }

  return true;
};

export const filterAndFormatNumeric = (inputValue: string) => {
  const numericValue = inputValue.replace(/\D/g, '');
  const parsedValue = parseInt(numericValue, 10);
  const formattedValue = isNaN(parsedValue) ? '' : parsedValue.toLocaleString();
  return formattedValue;
};

export type Without<T, K> = Pick<T, Exclude<keyof T, K>>;

export const calculateSum = (category: string, collection: any[]) => {
  const categoryStats = collection.find(stat => stat.category === category);
  if (!categoryStats) {
    return '-';
  }
  const sum = categoryStats.values.reduce((accumulator: number, currentValue: any) => {
    return parseFloat(accumulator + currentValue.value);
  }, 0);
  const roundedSum = (sum * 100).toFixed(0);
  return roundedSum;
};

export const debounce = (onChange: any) => {
  clearTimeout(debounse);
  debounse = setTimeout(() => {
    onChange();
  }, 300) as unknown as number;
};

export const combineReducers = (slices: any) => (state: any, action: any) =>
  Object.keys(slices).reduce(
    (acc, prop) => ({
      ...acc,
      [prop]: slices[prop](action, acc[prop]),
    }),
    state
  );

export const translate = (key: string, options: TOptionsBase | object = {}) => {
  try {
    return i18next.t(key, options);
  } catch (error: any) {
    return '';
  }
};

export const getInviteCode = (): string => {
  const inviteCode = localStorage.getItem('inviteCode') ?? '';
  return inviteCode;
};

export const clearInviteCode = () => {
  localStorage.removeItem('inviteCode');
};

export const classNames = (...classes: string[]): string => {
  return classes.filter(Boolean).join(' ');
};

export const checkThenReturn = (
  booleanValue: boolean,
  returnedValue: any,
  falseReturnValue: any = null
): any => {
  if (booleanValue) {
    return returnedValue;
  }
  return falseReturnValue;
};

export const checkIsImage = (url: string): boolean => {
  return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
};

export const calcPagination = (page = 1, per_page = 5) => {
  return {
    skip: (page - 1) * per_page,
    limit: per_page,
  };
};

export const getTheTime = (
  inputDate: any,
  moreOptions: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    // second: 'numeric',
    hour12: true,
  },
  UTC = false
) => {
  const date = new Date(inputDate);
  const dateInUTC = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  const options: any = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    // hour: '2-digit',
    // minute: '2-digit',
    minimumIntegerDigits: 2,
    ...moreOptions,
  };

  const formattedDate = (UTC ? dateInUTC : date)
    .toLocaleString('en-US', options as any)
    .replace(', ', ' ') // Remove the comma and space
    .replace(/(\d{2}:\d{2})\s(AM|PM)/, '$1 $2'); // Remove space only between minute and AM/PM

  return formattedDate;
};

export const getStatus = (status: string) => {
  switch (status) {
    case 'active':
      return 'Active';
    case 'inactive':
      return 'inactive';
    case 'new':
      return 'New';
    case 'pending':
      return 'pending';
    case 'inReview':
      return 'In Review';
    case 'inProgress':
      return 'In Progress';
    case 'approved':
      return 'approved';
    case 'accepted':
      return 'accepted';
    case 'rejected':
      return 'rejected';
    case 'suspended':
      return 'suspended';
    case 'notParticipating':
      return 'Not participating';
    default:
      return status ?? '-';
  }
};

export const getStatusClass = (status: string): string => {
  switch (status) {
    case 'active':
    case 'approved':
    case 'accepted':
      return 'text-primary-blue';
    case 'pending':
    case 'inactive':
    case 'new':
      return 'text-primary-light-grey';
    case 'rejected':
    case 'suspended':
      return 'text-primary-orange';
    case 'inreview':
    case 'notParticipating':
      return 'text-white';
  }
  return '';
};
export const getPercentClass = (percent: number): string => {
  if (percent < 33) {
    return 'text-white';
  }
  if (percent >= 33 && percent < 66) {
    return 'text-primary-blue';
  }
  if (percent >= 66 && percent <= 100) {
    return 'text-primary-orange';
  } else {
    return 'text-[#FF0000]';
  }
};

export function getCrumbClass(crumb: Crumb, screen: number) {
  if (screen === crumb.screen) {
    return 'bg-transparent';
  } else if (crumb.screen < screen) {
    return 'bg-primary-orange';
  } else {
    return 'bg-primary-light-grey';
  }
}

export const getMembership = (membership: string) => {
  switch (membership) {
    case 'influencer':
      return 'Investor-Creator';
    case 'ambassador':
      return 'Ambassador';
    case 'superAmbassador':
      return 'Super Ambassador';
    case 'agency':
      return 'Agency';
    case 'venturePartner':
      return 'Venture Partner';
    case 'company':
      return 'Company';
    case 'investmentOpportunity':
      return 'Investment Opportunity';
    case 'mediaCustomer':
      return 'Media Customer';
    default:
      return membership ?? '-';
  }
};

export const addIndefiniteArticle = (name: string) => {
  if (!name) return;
  const vowels = ['a', 'e', 'i', 'o', 'u'];
  const firstLetter = name?.charAt(0)?.toLowerCase();
  if (vowels.includes(firstLetter)) {
    return `an ${name}`;
  } else {
    return `a ${name}`;
  }
};

export const getSocilaIcon = (social: string): string => {
  switch (social) {
    case 'facebook':
      return Facebook;
    case 'instagram':
      return Instagram;
    case 'tiktok':
      return Tiktok;
    case 'youtube':
      return Youtube;
    case 'twitter':
      return Twitter;
    case 'twitch':
      return Twitch;
    case 'snapchat':
      return Snapchat;
    case 'onlyfans':
      return Onlyfans;
    case 'pinterest':
      return Pinterest;
    case 'linkedin':
      return Linkedin;
  }
  return '';
};

export const categoryList = [
  { label: 'Activist', value: 'Activist' },
  { label: 'Business', value: 'Business' },
  { label: 'Creative', value: 'Creative' },
  { label: 'Current Moment', value: 'Current Moment' },
  { label: 'Entertainment', value: 'Entertainment' },
  { label: 'Growth & Development', value: 'Growth & Development' },
  { label: 'Health & Wellness', value: 'Health & Wellness' },
  { label: 'Hobby', value: 'Hobby' },
  { label: 'Home', value: 'Home' },
  { label: 'Relationships', value: 'Relationships' },
  { label: 'Style', value: 'Style' },
  { label: 'Other', value: 'Other' },
];

export const getDeFaultSubCategory = (category: React.Key): string => {
  switch (category) {
    case 'Entertainment':
      return 'Commentary';
    case 'Health & Wellness':
      return 'Fitness';
    case 'Creative':
      return 'Art';
    case 'Hobby':
      return 'Automobile';
    case 'Business':
      return 'Crypto';
    case 'Current Moment':
      return 'Comedy';
    case 'Style':
      return 'Beauty (Cosmetics, Skin, Hair)';
    case 'Growth & Development':
      return 'Academic';
    case 'Activist':
      return 'Climate / Environment';
    case 'Relationships':
      return 'Family';
    case 'Home':
      return 'Cooking';
    case 'Other':
      return '';
  }
  return '';
};

export const getSubCategory = (category: string): Option[] => {
  switch (category) {
    case 'Entertainment':
      return [
        { label: 'Commentary', value: 'Commentary' },
        { label: 'Music', value: 'Music' },
        { label: 'Pop Culture (Interview, Trends)', value: 'Pop Culture (Interview, Trends)' },
        { label: 'Sports', value: 'Sports' },
        { label: 'Streaming (Twitch, OnlyFans)', value: 'Streaming (Twitch, OnlyFans)' },
        { label: 'T.V', value: 'T.V' },
      ];
    case 'Health & Wellness':
      return [
        { label: 'Fitness', value: 'Fitness' },
        { label: 'Medicine', value: 'Medicine' },
        { label: 'Nutrition', value: 'Nutrition' },
      ];
    case 'Creative':
      return [
        { label: 'Art', value: 'Art' },
        { label: 'Design', value: 'Design' },
        { label: 'Photography / Film', value: 'Photography / Film' },
      ];
    case 'Hobby':
      return [
        { label: 'Automobile', value: 'Automobile' },
        { label: 'Dance', value: 'Dance' },
        { label: 'DIY', value: 'DIY' },
        { label: 'Food & Drink', value: 'Food & Drink' },
        { label: 'Gaming', value: 'gaming' },
        { label: 'Travel', value: 'Travel' },
      ];
    case 'Business':
      return [
        { label: 'Crypto', value: 'Crypto' },
        { label: 'Entrepreneurship', value: 'Entrepreneurship' },
        { label: 'Finance', value: 'Finance' },
        { label: 'Real Estate', value: 'Real Estate' },
        { label: 'Technology', value: 'Technology' },
      ];
    case 'Current Moment':
      return [
        { label: 'Comedy', value: 'Comedy' },
        { label: 'Culture', value: 'Culture' },
        { label: 'Meme', value: 'Meme' },
        { label: 'News', value: 'News' },
        { label: 'Politics', value: 'Politics' },
        { label: 'True Crime', value: 'True Crime' },
      ];
    case 'Style':
      return [
        { label: 'Beauty (Cosmetics, Skin, Hair)', value: 'Beauty (Cosmetics, Skin, Hair)' },
        { label: 'Fashion', value: 'Fashion' },
        { label: 'Luxury', value: 'Luxury' },
        { label: 'Model', value: 'Model' },
        { label: 'Shopping', value: 'Shopping' },
        { label: 'Tattoos', value: 'Tattoos' },
      ];
    case 'Growth & Development':
      return [
        { label: 'Academic', value: 'Academic' },
        { label: 'Education', value: 'Education' },
        { label: 'Religion', value: 'Religion' },
        { label: 'Self-help', value: 'Self-help' },
        { label: 'Spirituality', value: 'Spirituality' },
      ];
    case 'Activist':
      return [
        { label: 'Climate / Environment', value: 'Climate / Environment' },
        { label: 'Philanthropy', value: 'Philanthropy' },
        { label: 'Social Causes', value: 'Social Causes' },
      ];
    case 'Relationships':
      return [
        { label: 'Family', value: 'Family' },
        { label: 'Friends', value: 'Friends' },
        { label: 'Parenting', value: 'Parenting' },
        { label: 'Pets', value: 'Pets' },
      ];
    case 'Home':
      return [
        { label: 'Cooking', value: 'Cooking' },
        { label: 'Gardening', value: 'Gardening' },
        { label: 'Home Improvement', value: 'Home Improvement' },
      ];
    case 'Other':
      return [
        { label: '', value: '' },
        { label: '', value: '' },
        { label: '', value: '' },
      ];
  }
  return [];
};

export const selectTransStyle: CSSProperties = {
  color: 'white',
  fontWeight: 500,
  fontSize: 14,
  letterSpacing: '3.5px',
  textTransform: 'uppercase',
};

export const SocialMediaList = [
  {
    value: 'facebook',
    label: 'Facebook',
  },
  {
    value: 'instagram',
    label: 'Instagram',
  },
  {
    value: 'linkedin',
    label: 'Linkedin',
  },
  {
    value: 'onlyfans',
    label: 'Onlyfans',
  },
  {
    value: 'pinterest',
    label: 'Pinterest',
  },
  {
    value: 'snapchat',
    label: 'Snapchat',
  },
  {
    value: 'tiktok',
    label: 'Tiktok',
  },
  {
    value: 'twitch',
    label: 'Twitch',
  },
  {
    value: 'twitter',
    label: 'Twitter',
  },
  {
    value: 'youtube',
    label: 'Youtube',
  },
];

export const whatLightsYourFireOptions = [
  {
    title: 'metadataScreen.food',
    detail: 'metadataScreen.coffeeEnergyDrinksJuicesWineAlcohol',
  },
  {
    title: 'metadataScreen.cannabis',
    detail: 'metadataScreen.CBDTopicalsTHCTrinksGummiesPsychedelics',
  },
  {
    title: 'metadataScreen.wellness',
    detail: 'metadataScreen.mentalWellbeingEcerciseEquipment',
  },
  {
    title: 'metadataScreen.fashion',
    detail: 'metadataScreen.skiingWatchesSwimwearUnderwearShoesJewelry',
  },
  {
    title: 'metadataScreen.cosmetics',
    detail: 'metadataScreen.perfumesCreamsMakeupMensGrooming',
  },
  {
    title: 'metadataScreen.gaming',
    detail: 'metadataScreen.gamblingPokerOnlineGamingEsports',
  },
  {
    title: 'metadataScreen.crypto',
    detail: 'metadataScreen.NFTCurrencyMusicArtSportsCollectibles',
  },
  {
    title: 'metadataScreen.entertainment',
    detail: 'metadataScreen.concertsArtMusic',
  },
  {
    title: 'metadataScreen.other',
    detail: 'metadataScreen.itsBigWorldOutThereWhatPartOfItDoYouOWN',
  },
];

export const whatTurnsYouOffOptions = [
  {
    title: 'metadataScreen.gambling',
    detail: 'metadataScreen.iOnlyPlayGamesForFun',
  },
  {
    title: 'metadataScreen.cannabisII',
    detail: 'metadataScreen.iCantSupportTHC',
  },
  {
    title: 'metadataScreen.sex',
    detail: 'metadataScreen.sexMaySellButIDontSellToysOrTools',
  },
  {
    title: 'metadataScreen.alcohol',
    detail: 'metadataScreen.iDontSupportDrinking',
  },
  {
    title: 'metadataScreen.meat',
    detail: 'metadataScreen.dontEatItDontWantOthersToEatIt',
  },
  {
    title: 'metadataScreen.gaming',
    detail: 'metadataScreen.gamblingPokerOnlineGamingEsports',
  },
  {
    title: 'metadataScreen.otherII',
    detail: 'metadataScreen.WhatTopicsOrCompaniesWontYouTouch',
  },
];

export const canadaProvinces = [
  { label: 'SK', value: 'SK' },
  { label: 'MB', value: 'MB' },
  { label: 'ON', value: 'QN' },
  { label: 'QC', value: 'QC' },
  { label: 'NB', value: 'NB' },
  { label: 'NS', value: 'NS' },
  { label: 'PE', value: 'PE' },
  { label: 'NL', value: 'NL' },
  { label: 'YT', value: 'YT' },
  { label: 'NT', value: 'NT' },
  { label: 'NU', value: 'NU' },
];

export const canadaProvincesII = [
  { label: 'Alberta', value: 'Alberta' },
  { label: 'British Columbia', value: 'British Columbia' },
  { label: 'Manitoba', value: 'Manitoba' },
  { label: 'New Brunswick', value: 'New Brunswick' },
  { label: 'Newfoundland and Labrador', value: 'Newfoundland and Labrador' },
  { label: 'Northwest Territories', value: 'Northwest Territories' },
  { label: 'Nova Scotia', value: 'Nova Scotia' },
  { label: 'Nunavut', value: 'Nunavut' },
  { label: 'Ontario', value: 'Ontario' },
  { label: 'Prince Edward Island', value: 'Prince Edward Island' },
  { label: 'Quebec', value: 'Quebec' },
  { label: 'Saskatchewan', value: 'Saskatchewan' },
  { label: 'Yukon', value: 'Yukon' },
];

export const getKeys = Object.keys as <T extends object>(obj: T) => Array<Extract<keyof T, string>>;

export type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>;
  }[Keys];

export const preferedContactMethod = (data?: string) => {
  if (data) {
    if (data.includes('@')) {
      return 'email';
    } else {
      return 'phone';
    }
  }
};

export const changeAWSToCDN = (value: string | { uri: string }) => {
  let updatedValue = value;

  if (typeof value === 'string' && value?.includes(AWS_MEDIA_URL)) {
    updatedValue = value?.replace(AWS_MEDIA_URL, CDN_MEDIA_URL);
  }
  if (
    typeof value === 'string' &&
    !value?.includes(AWS_MEDIA_URL) &&
    !value?.includes(CDN_MEDIA_URL) &&
    !value?.startsWith('blob')
  ) {
    updatedValue = `${CDN_MEDIA_URL}${updatedValue}`;
  }
  if (typeof value === 'object' && value?.uri && value?.uri?.includes(AWS_MEDIA_URL)) {
    updatedValue = value.uri.replace(AWS_MEDIA_URL, CDN_MEDIA_URL);
  }
  return updatedValue as string;
};
