import ProfileImage from 'assets/images/ProfileImage.svg';
import { CountdownCircle } from 'components/shared';
import { Modal } from 'components/shared/Modal';
import { Spiner } from 'components/shared/Spiner';
import { useCallback, useEffect, useState } from 'react';
import { FaRegEdit } from 'react-icons/fa';
import { SlReload } from 'react-icons/sl';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { toast } from 'react-toastify';
import { UracServices, UserAccountProperties } from 'services/apis/Urac';
import { useStore } from 'services/context';
import { updateUserAccount } from 'services/context/Auth/actions';
import { translate } from 'utils/helperFunctions';
import '../../components/componentStyle/Style.css';

import { BinaryUpload, Image } from 'components/shared/Binary/Binary';
import useUploadBinary from 'components/shared/Binary/hooks/useUploadBinary';
import { BinaryState } from 'components/shared/Binary/type';
import { BucketPaths } from 'config';
import { AiFillCaretLeft } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { OnboardingServices } from 'services/apis/Onboarding';
import { setStorageUserData } from 'utils/StorageServices';

type UserDetails = {
  firstName: string;
  lastName: string;
  profile: any;
  phone: string;
};

const ProfileLogo = ({
  userDetails,
  onUpdateProfileLogo,
}: {
  onUpdateProfileLogo: (value: UserDetails) => void;
  userDetails: UserDetails;
}) => {
  const [binaryData, { onUpload, filesList, setFilesList, clearImage }] = useUploadBinary({
    binariesDataArray: [
      {
        binariesData: userDetails?.profile?.logo ? [userDetails?.profile?.logo] : [],
        id: 'logo',
        accept: ['image/*'],
        maxNumberOfFiles: 2,
        maxSize: 100000,
        required: true,
        quality: 0.8,
      },
    ],
    bucketPath: BucketPaths.Handle,
    label: '',
    Preview: useCallback(
      (props: { file?: File | null; filePath?: string | null; click: () => void }) => (
        <div className='mx-auto relative w-36 h-36 sm:w-56 sm:h-56 sm:-mt-4 mb-10'>
          <Image
            src={props?.filePath || ProfileImage}
            className='inline-block rounded-full border-2 border-primary-orange w-36 h-36 sm:w-56 sm:h-56 overflow-hidden'
            alt='img'
          />
          <FaRegEdit
            className='absolute right-0 bottom-0 cursor-pointer w-6 h-6 text-primary-blue'
            onClick={props.click}
          />
        </div>
      ),
      []
    ),
  });

  const handleChangeImage = async (updatedFiles: BinaryState[]) => {
    clearImage('logo', 0);

    const newFiles = [...filesList];
    filesList[0].files[0] = updatedFiles.filter(item => item?.type === 'new')?.[0];

    requestAnimationFrame(() => {
      setFilesList(newFiles);
    });

    const paths = await onUpload();
    const foundImage = paths?.find(item => item.id === 'logo')?.paths?.[0];

    onUpdateProfileLogo({ ...userDetails, profile: { ...userDetails.profile, logo: foundImage } });
  };

  return (
    <div className='mx-auto relative w-36 h-36 sm:w-56 sm:h-56 sm:-mt-4 mb-10 rounded-full'>
      <BinaryUpload
        binaryData={binaryData}
        id='logo'
        onChange={handleChangeImage}
        showUploader={false}
      />
    </div>
  );
};

export const MyProfile = () => {
  const navigate = useNavigate();
  const [state, dispatch] = useStore();
  const { userData } = state.authStore;
  const [user, setUser] = useState<UserAccountProperties>({});
  const [userDetails, setUserDetails] = useState<UserDetails>({
    firstName: userData?.firstName,
    lastName: userData?.lastName,
    profile: userData?.profile,
    phone: userData?.phone,
  });
  const [emailDetails, setEmailDetails] = useState({
    email: '',
    confirmEmail: '',
  });
  const [phoneDetails, setPhoneDetails] = useState({
    phone: '',
    confirmPhone: '',
  });
  const [code, setCode] = useState<string>('');
  const [isPhoneVisible, setIsPhoneVisible] = useState<boolean>(false);
  const [isEmailVisible, setIsEmailVisible] = useState<boolean>(false);
  const [isEmailCodeVisible, setIsEmailCodeVisible] = useState<boolean>(false);
  const [isPhoneCodeVisible, setIsPhoneCodeVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEditableMode, setIsEditableMode] = useState<boolean>(false);
  const [toogleSmsTimer, setToogleSmsTimer] = useState<boolean>(false);
  const [phoneCode, setPhoneCode] = useState<string>('1');

  useEffect(() => {
    UracServices.fetchUser()
      .then(data => {
        setUser(data);
        return;
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    if (userData) {
      setUserDetails({
        firstName: userData.firstName || '',
        lastName: userData.lastName || '',
        phone: userData.phone || '',
        profile: userData.profile || {},
      });
    }
  }, [userData, user]);

  const onSaveAccountSettings = async (newUserDetails: UserDetails = userDetails) => {
    try {
      await UracServices.updateAccount(newUserDetails);

      setIsLoading(false);
      setIsEditableMode(false);
      toast.success(translate('profileScreen.accountUpdatedSuccessfully'));
      return;
    } catch (error: any) {
      toast.error(error?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveAccountSettings = () => {
    if (isEditableMode) {
      onSaveAccountSettings();
      setIsLoading(true);
    }
    setIsEditableMode(mode => !mode);
  };

  const onChangeEmail = () => {
    try {
      if (emailDetails.email && emailDetails.email !== emailDetails.confirmEmail) {
        toast.error('The confirmation email does not match');
      } else if (emailDetails.email === userData.email) {
        toast.error('No changes to update');
      } else {
        setIsLoading(true);
        UracServices.changeEmail(emailDetails.email)
          .then(() => {
            toast.success(translate('profileScreen.codeSentSuccessfully'));
            setToogleSmsTimer(false);
            setIsEmailVisible(false);
            setIsEmailCodeVisible(true);
            setIsLoading(false);
            setCode('');
            return;
          })
          .catch(error => {
            setIsLoading(false);
            toast.error(error?.message);
          });
      }
    } catch (error) {}
  };

  const onResendEmailCode = () => {
    setToogleSmsTimer(show => !show);
    try {
      UracServices.changeEmail(emailDetails.email)
        .then(() => {
          toast.success(translate('profileScreen.codeSentSuccessfully'));
          setCode('');
          return;
        })
        .catch(error => {
          toast.error(error?.message);
        });
    } catch (error) {}
  };

  const onValidateEmailCode = () => {
    try {
      setIsLoading(true);
      UracServices.validateChangeEmailCode(code)
        .then(() => {
          const newUserData = { ...userData, email: emailDetails.email };
          setStorageUserData(newUserData);
          dispatch(updateUserAccount(newUserData));
          toast.success(translate('profileScreen.emailUpdatedSuccessfully'));
          setIsLoading(false);
          setIsEmailCodeVisible(false);
          setEmailDetails({ email: '', confirmEmail: '' });
          return;
        })
        .catch(error => {
          setIsLoading(false);
          toast.error(error?.message);
        });
    } catch (error) {}
  };

  const onChangePhone = () => {
    try {
      if (phoneDetails.phone && phoneDetails.phone !== phoneDetails.confirmPhone) {
        toast.error('The confirmation phone does not match');
      } else {
        setIsLoading(true);
        UracServices.changePhone(`${phoneCode}${phoneDetails.phone}`)
          .then(() => {
            toast.success(translate('profileScreen.codeSentSuccessfully'));
            setToogleSmsTimer(false);
            setIsPhoneVisible(false);
            setIsPhoneCodeVisible(true);
            setIsLoading(false);
            setCode('');
            return;
          })
          .catch(error => {
            setIsLoading(false);
            toast.error(error.message);
          });
      }
    } catch (error) {}
  };

  const onResendPhoneCode = () => {
    setToogleSmsTimer(show => !show);
    try {
      UracServices.changePhone(`${phoneCode}${phoneDetails.phone}`)
        .then(() => {
          toast.success(translate('profileScreen.codeSentSuccessfully'));
          setCode('');
          return;
        })
        .catch(error => {
          toast.error(error.errors.details?.[0].message);
        });
    } catch (error: any) {
      toast.error(error?.message);
    }
  };

  const onValidatePhoneCode = () => {
    try {
      setIsLoading(true);
      OnboardingServices.validateChangePhoneCode(code)
        .then(() => {
          const newUserData = { ...userData, phone: `${phoneCode}${phoneDetails.phone}` };
          setStorageUserData(newUserData);
          dispatch(updateUserAccount(newUserData));
          toast.success(translate('profileScreen.phoneUpdatedSuccessfully'));
          setIsLoading(false);
          setIsPhoneCodeVisible(false);
          setPhoneDetails({ phone: '', confirmPhone: '' });
          return;
        })
        .catch(error => {
          setIsLoading(false);
          toast.error(error?.message);
        });
    } catch (error: any) {
      toast.error(error?.message);
    }
  };

  const _renderChangePhoneModal = () => {
    return (
      <Modal
        visible={isPhoneVisible}
        onClose={() => {
          setIsPhoneVisible(false);
          setPhoneDetails({ phone: '', confirmPhone: '' });
        }}
        className='!border-primary-blue'
      >
        <div className='flex flex-col space-y-4 px-2'>
          <h1 className='font-Condensed font-bold text-[32px] text-center capitalize'>
            {translate('profileScreen.changePhoneNumber')}
          </h1>
          <div>
            <label htmlFor='newPhone' className='block text-base text-primary-orange font-bold'>
              {translate('profileScreen.newPhoneNumber')}:
            </label>
            <div className='relative mt-1'>
              <input
                type={'text'}
                id={'newPhone'}
                maxLength={10}
                placeholder={translate('profileScreen.newPhoneNumber')}
                value={phoneDetails.phone}
                onChange={e => {
                  if (/^[0-9]+$/g.test(e.target.value) || !e.target.value) {
                    setPhoneDetails({ ...phoneDetails, phone: e.target.value });
                  }
                }}
                className='tw-input-secondary !pl-[110px]'
              />
              <span className='absolute transform -translate-y-1/2 top-[27px] left-2 z-10'>
                <PhoneInput
                  country={'us'}
                  buttonStyle={{
                    background: '#171717',
                    border: 'none',
                    bottom: '2px',
                    top: '2px',
                    left: '2px',
                  }}
                  containerStyle={{
                    width: `100px`,
                    margin: 'auto',
                  }}
                  dropdownStyle={{
                    color: 'white',
                    background: '#202020',
                    maxHeight: '150px',
                    maxWidth: '250px',
                  }}
                  value={phoneCode}
                  onChange={(phone: any) => {
                    setPhoneCode(phone);
                  }}
                  placeholder={''}
                  inputProps={{
                    className:
                      'block w-full border-0 border-white/50 bg-transparent focus:border-white focus:ring-0 text-right !pl-10 focus::!bg-black',
                  }}
                />
              </span>
            </div>
          </div>
          <div>
            <label
              htmlFor='confirmNewPhone'
              className='block text-base text-primary-orange font-bold'
            >
              {translate('profileScreen.confirmNewPhoneNumber')}:
            </label>
            <div className='relative mt-1'>
              <input
                type={'text'}
                id={'confirmNewPhone'}
                maxLength={10}
                placeholder={translate('profileScreen.confirmNewPhoneNumber')}
                value={phoneDetails.confirmPhone}
                onChange={e => {
                  if (/^[0-9]+$/g.test(e.target.value) || !e.target.value) {
                    setPhoneDetails({ ...phoneDetails, confirmPhone: e.target.value });
                  }
                }}
                className='tw-input-secondary !pl-[110px]'
              />
              <span className='absolute transform -translate-y-1/2 top-[27px] left-2 z-10'>
                <PhoneInput
                  country={'us'}
                  buttonStyle={{
                    background: '#171717',
                    border: 'none',
                    bottom: '2px',
                  }}
                  containerStyle={{
                    width: `100px`,
                    margin: 'auto',
                  }}
                  dropdownStyle={{
                    color: 'white',
                    background: '#202020',
                    maxHeight: '150px',
                    maxWidth: '250px',
                  }}
                  value={phoneCode}
                  onChange={(phone: any) => {
                    setPhoneCode(phone);
                  }}
                  placeholder={''}
                  inputProps={{
                    className:
                      'block w-full border-0 border-white/50 bg-transparent focus:border-white focus:ring-0 text-right !pl-10 focus::!bg-black',
                  }}
                />
              </span>
            </div>
          </div>
          <div className='w-full'>
            <button
              type='button'
              disabled={isLoading || !phoneDetails.phone || !phoneDetails.confirmPhone}
              className='tw-button-light-blue !w-full mt-5'
              onClick={onChangePhone}
            >
              {isLoading && <Spiner className='ml-32 md:ml-40' />}
              {translate('common.continue')}
            </button>
          </div>
        </div>
      </Modal>
    );
  };

  const _renderPhoneCodeModal = () => {
    return (
      <Modal
        visible={isPhoneCodeVisible}
        onClose={() => {
          setIsPhoneCodeVisible(false);
          setPhoneDetails({ phone: '', confirmPhone: '' });
          setToogleSmsTimer(false);
        }}
        className='!border-primary-blue'
      >
        <div className='flex flex-col space-y-4 px-2'>
          <h1 className='font-Condensed font-bold text-[32px] text-center capitalize'>
            {translate('profileScreen.changePhoneNumber')}
          </h1>
          <p className='mt-3 text-base font-normal text-primary-light-grey'>
            {translate('profileScreen.changePhoneCodeDescription')}
          </p>
          <div className=''>
            <label htmlFor='newEmail' className='block text-base text-primary-orange font-bold'>
              {translate('profileScreen.verificationCode')}:
            </label>
            <div className='flex items-center mt-1 w-full relative'>
              <input
                type={'text'}
                id={'securityCode'}
                maxLength={6}
                placeholder={translate('profileScreen.verificationCode')}
                value={code}
                onChange={e => setCode(e.target.value)}
                className='tw-input-secondary'
              />
              <span className='absolute right-0 mr-5 cursor-pointer'>
                {!toogleSmsTimer ? (
                  <SlReload
                    onClick={onResendPhoneCode}
                    className='text-3xl stroke-[70px] rotate-[150deg] text-primary-orange'
                  />
                ) : (
                  <CountdownCircle
                    className=''
                    initialTime={120}
                    onChange={e => setToogleSmsTimer(!e)}
                  />
                )}
              </span>
            </div>
          </div>
          <div className='w-full'>
            <button
              type='button'
              disabled={isLoading || !code}
              className='tw-button-light-blue !w-full mt-5'
              onClick={onValidatePhoneCode}
            >
              {isLoading && <Spiner className='ml-28' />}
              {translate('common.save')}
            </button>
          </div>
        </div>
      </Modal>
    );
  };

  const _renderChangeEmailModal = () => {
    return (
      <Modal
        visible={isEmailVisible}
        onClose={() => {
          setIsEmailVisible(false);
          setEmailDetails({ email: '', confirmEmail: '' });
        }}
        className='!border-primary-blue'
      >
        <div className='flex flex-col space-y-4 px-2'>
          <h1 className='font-Condensed font-bold text-[32px] text-center capitalize'>
            {translate('profileScreen.changeEmail')}
          </h1>
          <div>
            <label htmlFor='newEmail' className='block text-base text-primary-orange font-bold'>
              {translate('profileScreen.newEmail')}:
            </label>
            <div className='mt-1'>
              <input
                type={'email'}
                id={'newEmail'}
                placeholder={translate('profileScreen.enterNewEmail')}
                value={emailDetails.email}
                onChange={e => setEmailDetails({ ...emailDetails, email: e.target.value })}
                className='tw-input-secondary'
              />
            </div>
          </div>
          <div>
            <label
              htmlFor='confirmNewEmail'
              className='block text-base text-primary-orange font-bold'
            >
              {translate('profileScreen.confirmEmail')}
            </label>
            <div className='mt-1'>
              <input
                type={'email'}
                id={'confirmNewEmail'}
                placeholder={translate('profileScreen.enterEmailAgain')}
                value={emailDetails.confirmEmail}
                onChange={e => setEmailDetails({ ...emailDetails, confirmEmail: e.target.value })}
                className='tw-input-secondary'
              />
            </div>
          </div>
          <div className='w-full'>
            <button
              type='button'
              disabled={
                isLoading ||
                !emailDetails.email ||
                !emailDetails.confirmEmail ||
                emailDetails.email !== emailDetails.confirmEmail
              }
              className='tw-button-light-blue !w-full mt-5'
              onClick={onChangeEmail}
            >
              {isLoading && <Spiner className='ml-32 md:ml-40' />}
              {translate('common.continue')}
            </button>
          </div>
        </div>
      </Modal>
    );
  };

  const _renderEmailCodeModal = () => {
    return (
      <Modal
        visible={isEmailCodeVisible}
        onClose={() => {
          setIsEmailCodeVisible(false);
          setEmailDetails({ email: '', confirmEmail: '' });
        }}
        className='!border-primary-blue'
      >
        <div className='flex flex-col space-y-4 px-2'>
          <h1 className='font-Condensed font-bold text-[32px] text-center capitalize'>
            {translate('profileScreen.changeEmail')}
          </h1>
          <div className='flex justify-center'>
            <p className='mt-3 text-base font-normal text-primary-light-grey xw-72'>
              {translate('profileScreen.changeEmailCodeDescription')}
            </p>
          </div>
          <div className=''>
            <label htmlFor='newEmail' className='block text-base text-primary-orange font-bold'>
              {translate('profileScreen.verificationCode')}:
            </label>
            <div className='flex items-center mt-1 w-full relative'>
              <input
                type={'text'}
                id={'securityCode'}
                maxLength={6}
                placeholder={translate('profileScreen.enterVerificationCode')}
                value={code}
                onChange={e => setCode(e.target.value)}
                className='tw-input-secondary'
              />
              <span className='absolute right-0 mr-5 cursor-pointer'>
                {!toogleSmsTimer ? (
                  <SlReload
                    onClick={onResendEmailCode}
                    className='text-3xl stroke-[70px] rotate-[150deg] text-primary-orange'
                  />
                ) : (
                  <CountdownCircle
                    className=''
                    initialTime={120}
                    onChange={e => setToogleSmsTimer(!e)}
                  />
                )}
              </span>
            </div>
          </div>
          <div className='w-full'>
            <button
              type='button'
              disabled={isLoading || !code}
              className='tw-button-light-blue w-full mt-5'
              onClick={onValidateEmailCode}
            >
              {isLoading && <Spiner className='ml-28' />}
              {translate('common.save')}
            </button>
          </div>
        </div>
      </Modal>
    );
  };

  const _renderAccountTab = () => {
    return (
      <>
        <div className='font-Condensed flex justify-start items-center text-primary-light-grey text-3xl mb-2'>
          <div className=''>
            <AiFillCaretLeft
              className='text-5xl cursor-pointer hover:filter hover:brightness-125 hover:contrast-125 transition-all duration-300 hover:scale-[1.1]'
              onClick={() => {
                navigate('/home');
              }}
            />
          </div>
          <div>{translate('common.Profile')}</div>
        </div>
        {isEmailVisible && _renderChangeEmailModal()}
        {isEmailCodeVisible && _renderEmailCodeModal()}
        {isPhoneVisible && _renderChangePhoneModal()}
        {isPhoneCodeVisible && _renderPhoneCodeModal()}
        <div className='flex flex-col mx-auto gap-6 max-w-4xl'>
          <ProfileLogo
            key={userDetails?.profile?.logo}
            userDetails={userDetails}
            onUpdateProfileLogo={onSaveAccountSettings}
          />

          <div
            className='primaryBorder p-4 bg-primary-dark-grey'
            style={{ boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15266)' }}
          >
            <div className='flex items-start justify-end mb-4'>
              <button
                type='button'
                className={`tw-button-light-blue !w-24`}
                onClick={handleSaveAccountSettings}
              >
                {isLoading && <Spiner className='ml-20' />}
                {translate(isEditableMode ? 'common.save' : 'common.edit')}
              </button>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-5 items-center'>
              <div>
                <label htmlFor='fName' className='block text-base text-primary-orange font-normal'>
                  {translate('common.firstName')}
                </label>
                <div className='mt-1'>
                  <input
                    type='text'
                    id='fName'
                    value={userDetails.firstName}
                    disabled={!isEditableMode}
                    onChange={e => setUserDetails({ ...userDetails, firstName: e.target.value })}
                    className='tw-input-primary focus:border-primary-blue disabled:text-white disabled:bg-primary-light-grey disabled:border-primary-medium-grey/50 capitalize'
                    style={{ textAlign: 'left' }}
                    placeholder={translate('common.firstName')}
                  />
                </div>
              </div>
              <div>
                <label htmlFor='lName' className='block text-base text-primary-orange font-normal'>
                  {translate('common.lastName')}
                </label>
                <div className='mt-1'>
                  <input
                    type='text'
                    id='lName'
                    value={userDetails.lastName}
                    disabled={!isEditableMode}
                    onChange={e => setUserDetails({ ...userDetails, lastName: e.target.value })}
                    className='tw-input-primary focus:border-primary-blue disabled:text-white disabled:bg-primary-light-grey disabled:border-primary-medium-grey/50 capitalize'
                    style={{ textAlign: 'left' }}
                    placeholder={translate('common.lastName')}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className='primaryBorder p-4 bg-primary-dark-grey'
            style={{ boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15266)' }}
          >
            <div className='xmt-6'>
              <div className='grid grid-cols-1 md:grid-cols-2 gap-5 items-center'>
                <div>
                  <div className='flex justify-between items-center'>
                    <label
                      htmlFor='email'
                      className='block text-base text-primary-orange font-normal'
                    >
                      {translate('common.email')}
                    </label>
                    <FaRegEdit
                      className={`${
                        !isEditableMode
                          ? 'cursor-pointer text-primary-blue'
                          : 'cursor-not-allowed text-primary-light-grey'
                      } text-xl`}
                      onClick={() => !isEditableMode && setIsEmailVisible(true)}
                    />
                  </div>
                  <div className='mt-1'>
                    <input
                      type='email'
                      name='email'
                      id='email'
                      disabled
                      value={userData?.email}
                      className='tw-input-primary disabled:text-white disabled:bg-primary-medium-grey disabled:border-primary-light-grey'
                      style={{ textAlign: 'left' }}
                      placeholder={translate('common.email')}
                    />
                  </div>
                </div>

                <div>
                  <div className='flex justify-between items-center'>
                    <label
                      htmlFor='phone'
                      className='block text-base text-primary-orange font-normal'
                    >
                      {translate('common.phoneNumber')}
                    </label>
                    <FaRegEdit
                      className={`${
                        !isEditableMode
                          ? 'cursor-pointer text-primary-blue'
                          : 'cursor-not-allowed text-primary-light-grey'
                      } text-xl`}
                      onClick={() => !isEditableMode && setIsPhoneVisible(true)}
                    />
                  </div>
                  <div className='mt-1'>
                    <input
                      type='text'
                      name='phone'
                      id='phone'
                      disabled
                      value={`+${userData?.phone}`}
                      className='tw-input-primary disabled:text-white disabled:bg-primary-medium-grey disabled:border-primary-light-grey capitalize'
                      style={{ textAlign: 'left' }}
                      placeholder={translate('common.phone')}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return <>{_renderAccountTab()}</>;
};
