import React from 'react';
import { getMembership, translate } from 'utils/helperFunctions';

type NoAccessTypes = {
  membership: string;
  top?: string;
};

export const NoAccess = ({ membership, top }: NoAccessTypes) => {
  return (
    <div
      className={`absolute font-Condensed text-primary-light-grey text-4xl md:text-[40px] bottom-0 ${
        top ? top : 'top-44'
      } right-4 left-4`}
    >
      <div className='flex items-center justify-center text-center h-full'>
        <div className='max-w-xl px-4 sm:px-0'>
          {translate('handlesScreen.itLooksLikeYouDontHaveAccessToThe')}{' '}
          <span className='text-primary-orange'>
            {membership && (membership === 'influencer' ? 'IC' : getMembership(membership))}
          </span>{' '}
          <span className='lowercase'>{translate('common.missionControl')}.</span>
        </div>
      </div>
    </div>
  );
};
