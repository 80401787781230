import { Select } from 'components/shared';
import {
  categoryList,
  getDeFaultSubCategory,
  getSubCategory,
  translate,
} from 'utils/helperFunctions';
import { AiOutlinePlus } from 'react-icons/ai';
import { TiDeleteOutline } from 'react-icons/ti';
import { useParams } from 'react-router-dom';
import { HandleMetaDataInfo } from './HandleMetaDataInfo';
type Props = {
  onChange: (keys: any) => void;
  addHandle?: any;
};

export const AddHandleMetaData = (props: Props) => {
  const { onChange, addHandle } = props;
  const { handleId } = useParams();

  const onChangeInfo = (data = {}) => {
    onChange(data);
  };

  const addPartnership = () => {
    if (addHandle?.metadata?.partnerships) {
      onChangeInfo({
        ...addHandle,
        metadata: {
          ...addHandle.metadata,
          partnerships: [
            ...addHandle.metadata.partnerships,
            { name: '', relation: { exclusive: false } },
          ],
        },
      });
    } else {
      onChangeInfo({
        ...addHandle,
        metadata: {
          ...addHandle.metadata,
          partnerships: [{ name: '', relation: { exclusive: false } }],
        },
      });
    }
  };

  const removePartnership = (index: number) => {
    const partnershipsList = [...addHandle.metadata.partnerships];
    partnershipsList.splice(index, 1);
    onChangeInfo({
      ...addHandle,
      metadata: {
        ...addHandle.metadata,
        partnerships: partnershipsList,
      },
    });
    if (partnershipsList?.length === 0) {
      onChangeInfo({
        ...addHandle,
        metadata: {
          ...(addHandle?.metadata?.lightsYourFire?.length
            ? { lightsYourFire: addHandle?.metadata?.lightsYourFire }
            : {}),
          ...(addHandle?.metadata?.turnsYouOff?.length
            ? { turnsYouOff: addHandle?.metadata?.turnsYouOff }
            : {}),
          aboutYourPeople: [...(addHandle?.metadata?.aboutYourPeople ?? [])],
          stats: [...addHandle?.metadata?.stats],
        },
      });
    }
  };

  return (
    <div className='flex flex-col justify-center items-center gap-6 mt-4 mb-6 text-center w-full '>
      <div className='flex flex-col gap-6 w-full'>
        <h1 className='font-Condensed text-[36px] font-bold not-italic'>
          {translate('handlesScreen.whoAreSomeOfYourKeySponsorsAndPartnerships')}?
        </h1>
        {handleId === 'new' ? (
          <>
            <div className='input-wrapper relative text-left'>
              <label htmlFor='relation' className='tw-label !text-[24px] !font-Condensed'>
                *{translate('handlesScreen.category')}:
              </label>
              <Select
                options={categoryList}
                focusOnOpen={true}
                placeholderStyle='!text-[16px]'
                className='bg-primary-medium-grey  relative w-full !border-2 shadow-sm pl-3 text-left cursor-pointer focus:outline-none sm:text-sm h-12 rounded border-primary-light-grey'
                menuBg='bg-primary-medium-grey mt-[10px] border-2 !text-base rounded  border-primary-orange'
                placeholder={translate('handlesScreen.selectCategory')}
                value={addHandle.category || ''}
                onChange={e => {
                  onChangeInfo({
                    ...addHandle,
                    category: e?.value,
                    subCategory: getDeFaultSubCategory(e?.value || ''),
                  });
                }}
              />
            </div>
            <div className='input-wrapper relative text-left'>
              <label htmlFor='relation' className='tw-label !text-[24px] !font-Condensed'>
                *{translate('handlesScreen.subCategory')}:
              </label>
              {addHandle.category === 'Other' ? (
                <input
                  id='input'
                  type={'text'}
                  autoComplete='off'
                  maxLength={70}
                  placeholder={translate('handlesScreen.tellUsWhatCategoryDoesYourHandleFallUnder')}
                  className={`tw-input-primary`}
                  value={addHandle.subCategory || ''}
                  onChange={e => {
                    onChangeInfo({ ...addHandle, subCategory: e.target.value });
                  }}
                />
              ) : (
                <Select
                  options={getSubCategory(addHandle?.category || '') || []}
                  disabled={!addHandle?.category}
                  focusOnOpen={true}
                  placeholder={translate('handlesScreen.selectSubCategory')}
                  placeholderStyle='!text-[16px]'
                  className='bg-primary-medium-grey  relative w-full !border-2 shadow-sm pl-3 text-left cursor-pointer focus:outline-none sm:text-sm h-12 rounded border-primary-light-grey'
                  menuBg='bg-primary-medium-grey mt-[10px] border-2 !text-base rounded  border-primary-orange'
                  value={addHandle.subCategory || ''}
                  onChange={e => {
                    onChangeInfo({ ...addHandle, subCategory: e?.value });
                  }}
                />
              )}
            </div>
            <div>
              <textarea
                placeholder={translate('handlesScreen.tellEveryoneABitAboutHowYouDoWhatYouDo')}
                value={addHandle.description || ''}
                onChange={e => {
                  onChangeInfo({ ...addHandle, description: e.target.value });
                }}
                className='primaryTextArea sm:text-xl mt-2 focus:!border-primary-orange !text-sm !border-primary-light-grey'
              />
            </div>
          </>
        ) : (
          <>
            <HandleMetaDataInfo details={addHandle} />
          </>
        )}
      </div>
      <div className='flex flex-col gap-5 w-full px-2'>
        <p className='text-[19px] font-normal text-primary-light-grey text-left'>
          {translate('handlesScreen.provideListOfSomeTopPartnerships')}
        </p>
        <div className=' text-left text-primary-orange text-2xl font-Condensed'>
          {translate('handlesScreen.sponsors')}:
        </div>
        <div className='relative flex justify-left items-center '>
          <div
            className='flex justify-center items-center w-8 h-8 rounded-full bg-white cursor-pointer'
            onClick={addPartnership}
          >
            <AiOutlinePlus className='text-black text-2xl' />
          </div>
        </div>
        {addHandle?.metadata?.partnerships &&
          addHandle?.metadata?.partnerships?.map((partnership: any, index: number) => (
            <div key={index} className='flex flex-col gap-4 p-4 border-2 border-primary-orange'>
              {addHandle?.metadata?.partnerships?.length > 0 && (
                <div className='text-primary-orange flex justify-end '>
                  <button
                    type='button'
                    className='uppercase sm:text-xl'
                    onClick={() => removePartnership(index)}
                  >
                    <TiDeleteOutline size={40} />
                  </button>
                </div>
              )}
              <div className='input-wrapper relative text-left'>
                <label htmlFor='relation' className='tw-label !text-[24px] !font-Condensed'>
                  {translate('handlesScreen.sponsor')}:
                </label>
                <input
                  placeholder={translate('handlesScreen.enterSponsorName')}
                  className='tw-input-primary relative'
                  value={partnership?.name || ''}
                  type='text'
                  onChange={e => {
                    const updatedName = { ...addHandle };
                    updatedName.metadata.partnerships[index].name = e.target.value;
                    onChangeInfo(updatedName);
                  }}
                />
              </div>
              <div
                className='flex gap-4 mt-4 w-fit cursor-pointer items-center justify-center'
                onClick={() => {
                  const updatedExclusive = { ...addHandle };
                  updatedExclusive.metadata.partnerships[index].relation.exclusive =
                    !addHandle.metadata.partnerships[index].relation.exclusive;
                  updatedExclusive.metadata.partnerships[index].relation.description = '';
                  updatedExclusive.metadata.partnerships[index].relation.clause = '';
                  onChangeInfo(updatedExclusive);
                }}
              >
                <div className='flex justify-center items-center bg-transparent w-6 h-6 rounded-full border border-primary-light-grey'>
                  {addHandle?.metadata?.partnerships[index].relation.exclusive && (
                    <div className='w-4 h-4 rounded-full bg-primary-orange '></div>
                  )}
                </div>
                <div className='text-[21px] font-normal'>
                  {translate('handlesScreen.exclusive')}
                </div>
              </div>
              {addHandle?.metadata?.partnerships?.[index]?.relation?.exclusive && (
                <>
                  <div>
                    <textarea
                      className='primaryTextArea sm:text-xl mt-2 focus:!border-primary-orange !text-sm !border-primary-light-grey'
                      placeholder={translate('handlesScreen.describeTheDetailsOfThisRelationship')}
                      value={
                        addHandle?.metadata?.partnerships?.[index]?.relation?.description || ''
                      }
                      onChange={e => {
                        const description = { ...addHandle };
                        description.metadata.partnerships[index].relation.description =
                          e.target.value;
                        onChangeInfo(description);
                      }}
                    />
                  </div>
                  <div>
                    <textarea
                      placeholder={translate('handlesScreen.pasteYourLegalClauseHere')}
                      className='primaryTextArea sm:text-xl mt-2 focus:!border-primary-orange !text-sm !border-primary-light-grey'
                      value={addHandle?.metadata?.partnerships?.[index]?.relation?.clause || ''}
                      onChange={e => {
                        const clause = { ...addHandle };
                        clause.metadata.partnerships[index].relation.clause = e.target.value;
                        onChangeInfo(clause);
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};
