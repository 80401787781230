import { NoAccess, Tabs } from 'components/shared';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getMembership, translate } from 'utils/helperFunctions';
import { Content } from './Content';
import { Handles } from './Handles';
import { AiFillCaretLeft } from 'react-icons/ai';
import { Referrals } from './Referrals';
import { UseAbility, useStore } from 'services/context';
import OnboardingDetails from './OnboardingDetails';
export const MissionControl = () => {
  const navigate = useNavigate();
  const { membership } = useParams();
  const [state, _dispatch] = useStore();
  const {
    authStore: { isAclHitted },
  } = state;
  const canGetOnboardingMembership = UseAbility('membership', 'get');
  const canGetHandlesList = UseAbility('handles', 'get');
  const canGetNotInfluencerHandles = UseAbility('notInfluencer', 'get');
  const [searchParams, setSearchParams] = useSearchParams({
    tab: '',
  });
  const tabParam = searchParams.get('tab');
  const [activeKeyTab, setActiveKeyTab] = useState<string>('');

  useEffect(() => {
    switch (tabParam) {
      case 'content':
        return setActiveKeyTab('1');
      case 'handles':
        return setActiveKeyTab('2');
      case 'referrals':
        return setActiveKeyTab('3');
      default:
        return setActiveKeyTab('2');
    }
  }, [tabParam, searchParams]);

  const _renderTabs = () => {
    switch (activeKeyTab) {
      case '1':
        return <Content />;
      case '2':
        return <Handles />;
      case '3':
        return membership === 'influencer' ? <></> : <Referrals />;
      default:
        return null;
    }
  };

  const onChangeTab = (key: string) => {
    if (
      (membership === 'influencer' && !canGetHandlesList && key === '2') ||
      (membership !== 'influencer' && !canGetNotInfluencerHandles && key === '2') ||
      key === '1'
    ) {
      return;
    } else {
      setActiveKeyTab(key);
      let tab = 'handles';
      switch (key) {
        case '1':
          tab = 'content';
          break;
        case '2':
          tab = 'handles';
          break;
        case '3':
          tab = 'referrals';
          break;
        default:
          return;
      }
      setSearchParams({ tab });
    }
  };

  const tabs = [
    { name: translate('common.content'), key: '1', disabled: true },
    {
      name: translate('common.handles'),
      key: '2',
    },
    { name: translate('common.referrals'), key: '3' },
  ];
  const tabsToShow = tabs.filter(tab => (tab.key === '3' ? membership !== 'influencer' : true));

  return (
    <>
      {isAclHitted && (
        <div className=''>
          <div className='font-Condensed flex justify-start items-center text-primary-light-grey text-3xl mb-2'>
            <div className=''>
              <AiFillCaretLeft
                className='text-5xl cursor-pointer hover:filter hover:brightness-125 hover:contrast-125 transition-all duration-300 hover:scale-[1.1]'
                onClick={() => {
                  navigate('/home');
                }}
              />
            </div>
            <div>
              {membership && getMembership(membership)} {translate('common.missionControl')}
            </div>
          </div>
          {canGetOnboardingMembership && (
            <>
              <OnboardingDetails />
              {(membership === 'influencer' && canGetHandlesList) ||
              (membership !== 'influencer' &&
                membership !== 'venturePartner' &&
                canGetNotInfluencerHandles) ? (
                <>
                  <Tabs activeKey={activeKeyTab} onChange={onChangeTab} tabPanes={tabsToShow} />
                  {_renderTabs()}
                </>
              ) : membership === 'venturePartner' ? (
                <div className=''>
                  <Referrals />
                </div>
              ) : (
                <NoAccess membership={membership as string} />
              )}
            </>
          )}
        </div>
      )}
      {!canGetOnboardingMembership && <NoAccess membership={membership as string} />}
    </>
  );
};
