import { CustomRadio } from 'components/shared';
import React from 'react';
import { useStore } from 'services/context';
import { translate } from 'utils/helperFunctions';

type Props = {
  onChange: (keys: any) => void;
  metadata?: any;
};

export const WizardNewContact = (props: Props) => {
  const { onChange, metadata } = props;
  const [state] = useStore();
  const { userData } = state.authStore;

  const onChangeInfo = (data = {}) => {
    onChange(data);
  };

  return (
    <div className='flex flex-col justify-center items-center gap-4 mt-4 mb-[250px] md:my-14 mx-1 text-center px-1'>
      <div className='font-Condensed text-[36px] sm:text-[40px] font-bold'>
        {translate('metadataScreen.howCanWeReachYou')}?
      </div>
      <div className='text-[20px] sm:text-[24px] font-normal text-primary-light-grey text-left mx-1 sm:text-center pb-5'>
        {translate('metadataScreen.chooseYourPreferredCommunicationMethod')}
      </div>
      <CustomRadio
        RadioClassName='!h-5 !w-5'
        options={[
          {
            value: userData?.phone,
            label: `+${userData?.phone}`,
          },
          { value: userData.email, label: userData.email },
        ]}
        value={metadata.contact}
        onChange={e => onChangeInfo({ ...metadata, contact: e?.value })}
      />
    </div>
  );
};
