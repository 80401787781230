import format from 'date-fns/format';
import { getSocilaIcon, getStatus, getStatusClass, translate } from 'utils/helperFunctions';
import DetailsLessMore from 'components/shared/DetailsLessMore';
import { IHandleDetails } from 'services/apis/Handle/handle.types';
import { Image } from 'components/shared/Binary/Binary';

const HandleDetailsList = ({ handle }: { handle?: Partial<IHandleDetails> }) => {
  const exclusive = handle?.metadata?.partnerships?.filter(
    partnership => partnership.relation.exclusive === true
  );

  const nonExclusive = handle?.metadata?.partnerships?.filter(
    partnership => partnership.relation.exclusive === false
  );

  const _renderScreenshots = () => {
    return (
      <div className='w-full bg-primary-medium-grey p-4'>
        <h1 className='text-3xl text-primary-light-grey font-bold text-center pb-4'>
          {translate('handlesScreen.screenshots')}
        </h1>
        {!!handle?.metadata?.aboutYourPeople?.length && (
          <div className='overflow-hidden overflow-x-auto whitespace-nowrap pb-4 flex'>
            <div className='m-auto space-x-3'>
              {handle?.metadata?.aboutYourPeople?.map(image => {
                return (
                  <div key={image} className='inline-block w-[100px]'>
                    <Image
                      src={image}
                      containerClassName='max-w-[100px] h-[224px] rounded-lg inline-block'
                      alt='img'
                    />
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  };

  const _renderCategory = () => {
    return (
      (handle?.category || handle?.subCategory) && (
        <div className='flex flex-col items-start py-6 gap-y-6'>
          {handle?.category && (
            <div className='flex'>
              <div className='w-32 text-primary-orange'>{translate('handlesScreen.category')}:</div>
              <div>{handle?.category || '-'}</div>
            </div>
          )}
          {handle?.subCategory && (
            <div className='flex'>
              <div className='w-32 text-primary-orange'>
                {translate('handlesScreen.sub-Category')}:
              </div>
              <div>{handle?.subCategory || '-'}</div>
            </div>
          )}
        </div>
      )
    );
  };

  return (
    <>
      <div className='divide-y-2 divide-primary-light-grey text-2xl font-bold capitalize'>
        {handle?.status && (
          <div className='flex items-center py-6 '>
            <div className='w-32 text-primary-orange'>{translate('common.status')}:</div>
            <div>
              <span className={`${getStatusClass(handle?.status)}`}>
                {getStatus(handle?.status) || '-'}{' '}
              </span>
              {handle?.handle_metadata?._id &&
                handle.status !== 'suspended' &&
                `(${translate('handlesScreen.pendingChanges')})`}
            </div>
          </div>
        )}
        {handle?.socialMedia && (
          <div className='flex items-center py-6'>
            <div className='w-32 text-primary-orange'>{translate('common.type')}:</div>
            <div className='flex items-center gap-5'>
              <img src={getSocilaIcon(handle?.socialMedia)} alt='icon' /> {handle?.socialMedia}
            </div>
          </div>
        )}
        {handle?.followers && (
          <div className='flex items-center py-6'>
            <div className='w-32 text-primary-orange'>{translate('common.followers')}:</div>
            <div>
              <div>
                {handle?.followers?.toLocaleString() || '-'} {translate('common.followers')}
              </div>
              <div>
                <span className='lowercase'>{translate('handlesScreen.asOf')}</span>{' '}
                {(handle?.date && format(new Date(handle?.date), 'MMM dd, yyyy')) || '-'}
              </div>
            </div>
          </div>
        )}
        {_renderCategory()}
        {!!exclusive?.length && (
          <div className='flex items-start py-6 '>
            <div className='w-32 text-primary-orange self-start'>
              {translate('handlesScreen.exclusiveSponsors')}:
            </div>
            <div
              className='font-inter text-base font-semibold divide-y-2 divide-primary-light-grey'
              style={{ width: `calc(100% - 128px)` }}
            >
              {exclusive?.map((ele, index) => (
                <div
                  key={ele.name}
                  className={`flex flex-col gap-y-2 ${index !== 0 && 'pt-5'} pb-5`}
                >
                  <h1 className='underline'>{ele?.name}</h1>
                  <DetailsLessMore
                    description={ele?.relation?.description}
                    descriptionStyle={'font-normal text-primary-light-grey'}
                    buttonStyle={'!text-primary-light-grey'}
                    title={translate('common.details')}
                  />
                  <div>
                    <DetailsLessMore
                      description={ele?.relation?.clause}
                      descriptionStyle={'font-normal text-primary-light-grey'}
                      buttonStyle={'!text-primary-light-grey'}
                      title={translate('handlesScreen.legalClause')}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {nonExclusive?.[0]?.name !== '' && !!nonExclusive?.length && (
          <div className='flex items-start py-6 '>
            <div className='w-32 text-primary-orange self-start'>
              {' '}
              {translate('handlesScreen.regularSponsors')}:{' '}
            </div>
            <div
              className='font-inter text-base font-semibold divide-y-2 divide-primary-light-grey'
              style={{ width: `calc(100% - 128px)` }}
            >
              {nonExclusive?.map((ele, index) => (
                <div
                  key={ele.name}
                  className={`flex flex-col gap-y-2 ${index !== 0 && 'pt-5'} pb-5`}
                >
                  <h1 className='underline'>{ele?.name}</h1>
                </div>
              ))}
            </div>
          </div>
        )}
        {!!handle?.metadata?.lightsYourFire?.length && (
          <div className='flex flex-col items-start py-6 gap-y-6'>
            <div className='flex'>
              <div className='flex items-center w-32 min-w-[128px] text-primary-orange'>
                {' '}
                {translate('handlesScreen.likes')}:
              </div>
              <div>
                {handle?.metadata?.lightsYourFire
                  ?.map(ele => (ele.title === 'other' ? ele.detail : ele.title))
                  .join(' - ') || '-'}
              </div>
            </div>
            <div className='flex'>
              <div className='flex items-center w-32 min-w-[128px] text-primary-orange'>
                {' '}
                {translate('handlesScreen.dislikes')}:
              </div>
              <div>
                {handle?.metadata?.turnsYouOff
                  ?.map(ele => (ele.title === 'other' ? ele.detail : ele.title))
                  .join(' - ') || '-'}
              </div>
            </div>
          </div>
        )}
        <div className='flex flex-col items-start py-6 gap-y-6'>
          <div className='flex'>
            <div className='flex items-center w-32 min-w-[128px] text-primary-orange'>
              {' '}
              {translate('handlesScreen.anonymous')}?
            </div>
            <div>
              {!!handle?.metadata?.lightsYourFire?.length || !!handle?.metadata?.turnsYouOff?.length
                ? translate('common.yes')
                : translate('common.no')}
            </div>
          </div>
          {!!handle?.metadata?.aboutYourPeople?.length && _renderScreenshots()}
        </div>
      </div>
    </>
  );
};

export default HandleDetailsList;
