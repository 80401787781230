import { Spiner } from 'components/shared';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { OnboardingServices } from 'services/apis/Onboarding';
import { useStore } from 'services/context';
import { translate } from 'utils/helperFunctions';
import usePrompt from 'utils/hooks/usePrompt';

export const WizardInProgress = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [state] = useStore();
  const { userData } = state.authStore;
  const [loading, setLoading] = useState<boolean>(false);
  const [legalName, setLegalName] = useState<any>({
    legalName: `${userData.firstName} ${userData.lastName}` || '',
    companyName: '',
    nickName: '',
  });

  const { _renderModalDiscardChanges, setIsBlocking } = usePrompt({
    message: translate('discardModal.areYouSureYouWantToExitTheOnboardingProcess'),
    okText: translate('common.yes'),
    cancelText: translate('common.no'),
  });

  useEffect(() => {
    setIsBlocking(true);
  }, []);

  const onNext = async () => {
    if (id) {
      setIsBlocking(false);
      try {
        setLoading(true);
        const res = await OnboardingServices.addLegalName(id, {
          legalName: legalName.legalName,
          companyName: legalName.companyName,
          nickName: legalName.nickName,
        });
        const newWindow = window.open(res.url, '_self');
        await new Promise(resolve => {
          newWindow?.addEventListener('load', resolve);
        });
        navigate(`/home/onboarding-wizard/${id}`);
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        setIsBlocking(true);
        toast.error(error?.message);
      }
    }
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const _renderWizardInProgress = () => {
    return (
      <div className='flex flex-col justify-center items-center gap-8 m-4 text-center '>
        <div className='font-Condensed text-[48px] font-bold'>
          {translate('inProgressScreen.letsSign')}
        </div>
        <div className='text-[20px] font-normal text-left'>
          <span>{translate('inProgressScreen.approveOrEditLegalName')}</span>{' '}
          <span>{translate('inProgressScreen.NextYoullBeTakenToTheDocuSignWebsite')}</span>
        </div>
        <div className='text-left mt-0 flex flex-col gap-8 w-full'>
          <div className='w-full input-wrapper '>
            <label className='tw-label'>*{translate('inProgressScreen.legalName')}:</label>
            <input
              type='text'
              placeholder={translate('inProgressScreen.legalName')}
              value={legalName.legalName}
              onChange={e => setLegalName({ ...legalName, legalName: e.target.value })}
              className='tw-input-primary'
            />
          </div>
          <div className='w-full input-wrapper '>
            <label className='tw-label'>{translate('inProgressScreen.companyName')}:</label>
            <input
              type='text'
              placeholder={translate('inProgressScreen.YourCompanyNameIfApplicable')}
              value={legalName.companyName}
              onChange={e => setLegalName({ ...legalName, companyName: e.target.value })}
              className='tw-input-primary'
            />
          </div>
          <div className='w-full input-wrapper '>
            <label className='tw-label'>{translate('inProgressScreen.nickName')}:</label>
            <input
              type='text'
              placeholder={translate('inProgressScreen.whatShouldWeCallYou')}
              value={legalName.nickName}
              onChange={e => setLegalName({ ...legalName, nickName: e.target.value })}
              className='tw-input-primary'
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className='m-4 border xl:w-[70%] xl:mx-auto border-primary-orange bg-primary-dark-grey'>
        {_renderModalDiscardChanges()}
        <div className={`flex justify-center items-center`}>{_renderWizardInProgress()}</div>
        <div className='w-full sm:mt-20 md:mt-0'>
          <div className='flex justify-center items-center gap-7 h-20'>
            <button
              className='tw-button-light-blue !px-6 !text-base !w-[366px] !h-[48px] !mx-4'
              disabled={!legalName.legalName}
              onClick={onNext}
            >
              {loading && <Spiner className='ml-28' />}

              {translate('common.submit')}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
