import { Modal } from 'components/shared';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import history from './history';

type Props = {
  message: string;
  okText: string;
  cancelText: string;
  hideXIcon?: boolean;
};

const usePrompt = (props: Props) => {
  const {
    message: propsMessage = 'companiesScreen.discardChanges',
    okText = 'companiesScreen.exitAndDiscard',
    cancelText = 'common.cancel',
    hideXIcon = false,
  } = props;
  const navigate = useNavigate();

  const [isBlocking, setIsBlocking] = useState<boolean>(false);
  const [txpath, setTxPath] = useState<string>('');
  const [isVisibleDiscardChanges, setIsVisibleDiscardChanges] = useState<boolean>(false);

  function useBlocker(blocker: any, when: boolean) {
    useEffect(() => {
      if (!when) return;
      const unblock = history.block((tx: any) => {
        const autoUnblockingTx = {
          ...tx,
          retry() {
            unblock();
            tx.retry();
          },
        };
        blocker(autoUnblockingTx);
      });
      return unblock;
    }, [history, blocker, when]);
  }

  function Prompt(message: any, when: boolean) {
    const blocker = useCallback(
      (tx: any) => {
        if (tx.location.pathname.includes('/login')) {
          setIsBlocking(false);
          setIsVisibleDiscardChanges(false);
        } else if (tx.location.search.includes('tab')) {
          setTxPath(`${tx.location.pathname}${tx.location.search}`);
          setIsVisibleDiscardChanges(true);
          setIsBlocking(false);
        } else {
          setTxPath(tx.location.pathname);
          setIsVisibleDiscardChanges(true);
          setIsBlocking(false);
        }
      },
      [message]
    );
    useBlocker(blocker, when);
  }

  const _renderModalDiscardChanges = () => {
    return (
      <Modal
        visible={isVisibleDiscardChanges}
        onClose={() => {
          setIsVisibleDiscardChanges(false);
          setIsBlocking(true);
        }}
        hideXIcon={hideXIcon}
      >
        <div className=''>
          <h1 className='font-Condensed font-semibold text-2xl text-center my-8 mx-10'>
            {propsMessage}
          </h1>
          <div className='mt-9'>
            <div className='flex items-center justify-center gap-4 w-full'>
              <div className=''>
                <button
                  type='button'
                  className='tw-input-orange-dark !bg-transparent !pr-0 !pl-0 !w-[85px] md:!w-[150px] !text-center'
                  onClick={() => {
                    setIsBlocking(true);
                    setIsVisibleDiscardChanges(false);
                  }}
                >
                  <p>{cancelText}</p>
                </button>
              </div>
              <div>
                <button
                  type='button'
                  className='tw-button-light-blue !pr-0 !pl-0 !w-[85px] md:!w-[150px]'
                  onClick={() => {
                    setIsBlocking(false);
                    navigate(txpath);
                    setIsVisibleDiscardChanges(false);
                  }}
                >
                  {okText}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  };
  Prompt('', isBlocking);

  return { _renderModalDiscardChanges, setIsBlocking };
};

export default usePrompt;
