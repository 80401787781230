import React from 'react';
import { classNames } from 'utils/helperFunctions';

type TabsProps = {
  activeKey: string;
  className?: string;
  onChange: (activeKey: string) => void;
  tabPanes: {
    name: string;
    key: string;
    hasBadge?: boolean;
    disabled?: boolean;
  }[];
};
export const Tabs: React.FC<TabsProps> = React.memo(
  ({ activeKey, tabPanes, onChange, className = '' }) => {
    return (
      <div className={className}>
        <div className='block'>
          <div className='overflow-x-auto no-scrollbar'>
            <div className='border-2 border-b-0 border-primary-blue'>
              <nav className='flex justify-center items-stretch' aria-label='Tabs'>
                {tabPanes.map(tabPane => (
                  <div
                    key={tabPane.key}
                    onClick={() => onChange(tabPane.key)}
                    className={classNames(
                      tabPane.key === activeKey
                        ? 'text-white bg-primary-blue font-black'
                        : 'text-primary-light-grey bg-primary-dark-grey font-normal hover:font-bold hover:text-gray-500',
                      `whitespace-pre-wrap text-center py-2 xpx-3 xmd:px-8 w-full flex justify-center cursor-pointer text-sm capitalize ${
                        tabPane.disabled ? 'cursor-not-allowed' : ''
                      }`
                    )}
                    aria-current={tabPane.key === activeKey ? 'page' : undefined}
                  >
                    {tabPane.name}
                    {tabPane.hasBadge && (
                      <span className='bg-secondary-color w-[9px] h-[9px] -translate-y-3 rounded-full inline-block'>
                        {}
                      </span>
                    )}
                  </div>
                ))}
              </nav>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
