import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Logo from 'assets/images/Logo.svg';
import { toast } from 'react-toastify';
import { Spiner } from '../../components/shared';
import { translate } from 'utils/helperFunctions';
import { CiLock } from 'react-icons/ci';
import { OnboardingServices } from 'services/apis/Onboarding';
import { JoinDataType } from 'services/apis/Onboarding/Onboarding.types';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { BsFillExclamationCircleFill } from 'react-icons/bs';
import '../../components/componentStyle/Style.css';

export const Join = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [joinData, setJoinData] = useState<JoinDataType>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    code: null,
  });

  const [errors, setErrors] = useState<any>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    code: '',
  });
  const [phoneCode, setPhoneCode] = useState<string>('1');

  useEffect(() => {
    return () => {
      setJoinData({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        code: null,
      });
    };
  }, []);

  const onJoin = async () => {
    try {
      setLoading(true);
      const res = await OnboardingServices.onJoin({
        ...joinData,
        phone: `${phoneCode}${joinData.phone}`,
      });
      localStorage.setItem('inviteCode', joinData.code ?? '');
      setLoading(false);
      return navigate('/Validate', { state: res.code });
    } catch (error: any) {
      const errorCode = error?.cause?.errors?.codes?.[0];
      if (errorCode === 405) {
        localStorage.setItem('inviteCode', joinData.code ?? '');
        navigate('/already-user');
      } else if (errorCode === 406) {
        setErrors({ ...errors, code: `${translate('join.incorrectInvitationCode')}` });
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(error?.message);
      }
    }
  };

  const validateInputs = () => {
    const regexPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/g;

    const updatedErrors: any = {};
    Object.keys(joinData).forEach(key => {
      if (key === 'email') {
        if (joinData.email) {
          if (!regexPattern.test(joinData[key] as string)) {
            updatedErrors[key] = translate('common.isUnvalid');
          } else {
            updatedErrors[key] = '';
          }
        } else {
          updatedErrors[key] = translate('common.isRequired');
        }
      } else if (key === 'code') {
        if (joinData?.code !== null && joinData?.code !== undefined) {
          if (joinData?.code?.length < 6) {
            updatedErrors[key] = translate('common.isUnvalid');
          } else {
            updatedErrors[key] = '';
          }
        } else {
          updatedErrors[key] = translate('common.isRequired');
        }
      } else if (!joinData[key]) {
        updatedErrors[key] = translate('common.isRequired');
      } else {
        updatedErrors[key] = '';
      }
    });
    const isFormValid = Object.values(updatedErrors).every(error => error === '');

    if (isFormValid) {
      onJoin();
    }
    setErrors(updatedErrors);
  };

  return (
    <div className='p-8 lg:px-20'>
      <div className='flex flex-col items-start sm:ml-7 lg:ml-0 z-50'>
        <img src={Logo} alt='logo' />
      </div>
      <div className='grid grid-cols-1 lg:grid-cols-2 gap-y-6 gap-x-8 lg:mt-0'>
        <div className='text-left mt-6 lg:mt-[24vh] sm:ml-7 lg:ml-0'>
          <div className='sm:flex gap-3 lg:flex-col'>
            <h2 className='font-Condensed text-primary-orange text-[64px] lg:text-9xl font-bold uppercase h-[64px] sm:h-fit'>
              {translate('loginScreen.ifYouKnow')}
            </h2>
            <h2 className='font-Condensed text-primary-orange text-[64px]  lg:text-9xl font-bold uppercase h-[64px] sm:h-fit'>
              {translate('loginScreen.youKnow')}
            </h2>
          </div>
          <p className='mt-6 sm:mt-2 lg:mt-14 text-xl lg:text-3xl font-normal '>
            {translate('loginScreen.includeYourInviteCode')}
          </p>
        </div>
        <div className='flex flex-col gap-6 mx-0 sm:mx-7 lg:mx-0 bg-primary-dark-grey px-4 py-6 overflow-hidden primaryBorder '>
          <div>
            <div className='flex items-center'>
              <CiLock className='text-primary-orange text-4xl mr-4' />
              <h2 className='font-Condensed text-primary-orange text-4xl font-bold pt-2'>
                {translate('loginScreen.getStarted')}
              </h2>
            </div>
            <p className='mt-4 font-normal '>{translate('loginScreen.getStartedMessage')} </p>
          </div>
          <div className='input-wrapper relative'>
            <label htmlFor='firstName' className='tw-label'>
              *{translate('common.firstName')}
            </label>
            <input
              className={`tw-input-primary ${errors.firstName ? '!border-primary-dark-red' : ''}`}
              id='firstName'
              type='text'
              autoComplete={'off'}
              value={joinData?.firstName || ''}
              placeholder={translate('common.enterFirstName')}
              onChange={e => {
                if (e.target.value) {
                  setErrors({ ...errors, firstName: '' });
                }
                setJoinData({ ...joinData, firstName: e.target.value });
              }}
            />
            {errors.firstName && (
              <>
                <BsFillExclamationCircleFill className='absolute right-3 top-11 w-15 !text-xl text-primary-dark-red' />
                <p className='absolute left-1 -bottom-5 text-[12px] text-primary-dark-red'>
                  {errors.firstName}
                </p>
              </>
            )}
          </div>
          <div className='input-wrapper relative'>
            <label htmlFor='lastName' className='tw-label'>
              *{translate('common.lastName')}
            </label>
            <input
              className={`tw-input-primary ${errors.lastName ? '!border-primary-dark-red' : ''}`}
              id='lastName'
              type='text'
              autoComplete={'off'}
              value={joinData?.lastName || ''}
              placeholder={translate('common.enterLastName')}
              onChange={e => {
                if (e.target.value) {
                  setErrors({ ...errors, lastName: '' });
                }
                setJoinData({ ...joinData, lastName: e.target.value });
              }}
            />
            {errors.lastName && (
              <>
                <BsFillExclamationCircleFill className='absolute right-3 top-11 w-15 !text-xl text-primary-dark-red' />
                <p className='absolute left-1 -bottom-5 text-[12px] text-primary-dark-red'>
                  {errors.lastName}
                </p>
              </>
            )}
          </div>
          <div className='input-wrapper relative'>
            <label htmlFor='email' className='tw-label'>
              *{translate('common.email')}
            </label>
            <input
              className={`tw-input-primary ${errors.email ? '!border-primary-dark-red' : ''}`}
              id='email'
              type='text'
              autoComplete={'off'}
              value={joinData?.email || ''}
              placeholder={translate('common.enterEmail')}
              onChange={e => {
                if (e.target.value) {
                  setErrors({ ...errors, email: '' });
                }
                setJoinData({ ...joinData, email: e.target.value });
              }}
            />
            {errors.email ? (
              <>
                <BsFillExclamationCircleFill className='absolute right-3 top-11 w-15 !text-xl text-primary-dark-red' />
                <p className='absolute left-1 -bottom-5 text-[12px] text-primary-dark-red'>
                  {errors.email}
                </p>
              </>
            ) : joinData.email &&
              !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/g.test(joinData.email as string) ? (
              <>
                <BsFillExclamationCircleFill className='absolute right-3 top-11 w-15 !text-xl text-primary-dark-red' />
                <p className='absolute left-1 -bottom-5 text-[12px] text-primary-dark-red'>
                  {translate('common.isUnvalid')}
                </p>
              </>
            ) : (
              ''
            )}
          </div>
          <div className='input-wrapper relative'>
            <label htmlFor='phone' className='tw-label'>
              *{translate('common.phoneNumber')}
            </label>
            <div className='relative'>
              <input
                className={`tw-input-primary !pl-[110px] ${
                  errors.phone ? '!border-primary-dark-red placeholder:text-sm' : ''
                }`}
                id='phone'
                type='text'
                autoComplete={'off'}
                maxLength={10}
                value={joinData?.phone || ''}
                placeholder={translate('common.enterPhone')}
                onChange={e => {
                  if (/^[0-9]+$/g.test(e.target.value) || !e.target.value) {
                    setErrors({ ...errors, phone: '' });
                    setJoinData({ ...joinData, phone: e.target.value });
                  }
                }}
              />
              <span className='absolute transform -translate-y-1/2 top-[27px] left-2 z-10'>
                <PhoneInput
                  country={'us'}
                  buttonStyle={{ background: '#171717', border: 'none' }}
                  containerStyle={{
                    width: `${innerWidth < 640 ? '100px' : '100px'}`,
                  }}
                  dropdownStyle={{ color: 'white', background: '#6F7577' }}
                  value={phoneCode}
                  onChange={(phone: any) => {
                    setPhoneCode(phone);
                  }}
                  placeholder={''}
                  inputProps={{
                    className:
                      'block w-full border-0 border-white/50  bg-transparent focus:border-white focus:ring-0 text-right !pl-10 focus::!bg-black',
                  }}
                />
              </span>
            </div>
            {errors.phone && (
              <>
                <BsFillExclamationCircleFill className='absolute right-3 top-11 w-15 !text-xl text-primary-dark-red' />

                <p className='absolute left-1 -bottom-5 text-[12px] text-primary-dark-red'>
                  {errors.phone}
                </p>
              </>
            )}
          </div>
          <div className='input-wrapper relative'>
            <label htmlFor='code' className='tw-label'>
              *{translate('common.inviteCode')}
            </label>
            <input
              className={`tw-input-primary ${errors.code ? '!border-primary-dark-red' : ''}`}
              id='code'
              type='text'
              maxLength={6}
              autoComplete={'off'}
              value={joinData?.code || ''}
              placeholder={translate('common.enterInviteCode')}
              onChange={e => {
                if (/^[0-9]+$/g.test(e.target.value) || !e.target.value) {
                  setJoinData({ ...joinData, code: e.target.value });
                  setErrors({ ...errors, code: '' });
                }
              }}
            />
            {errors.code ? (
              <>
                <BsFillExclamationCircleFill className='absolute right-3 top-11 w-15 !text-xl text-primary-dark-red' />
                <p className='absolute left-1 -bottom-5 text-[12px] text-primary-dark-red'>
                  {errors.code}
                </p>
              </>
            ) : joinData.code && joinData.code.length < 6 ? (
              <>
                <BsFillExclamationCircleFill className='absolute right-3 top-11 w-15 !text-xl text-primary-dark-red' />
                <p className='absolute left-1 -bottom-5 text-[12px] text-primary-dark-red'>
                  {translate('common.isUnvalid')}
                </p>
              </>
            ) : (
              ''
            )}
          </div>
          <div className='text-center'>
            <div className='text-primary-orange text-sm text-center mx-auto'>
              <span className='block'>{translate('loginScreen.acceptTermsAndPrivacy')}</span>{' '}
              <a
                className='font-bold underline cursor-pointer'
                href='https://www.thelondonfund.com/terms'
                target='_blank'
                rel='noopener noreferrer'
              >
                {translate('loginScreen.termsOfService')}
              </a>{' '}
              {translate('common.and')}{' '}
              <a
                className='font-bold underline cursor-pointer'
                href='https://www.thelondonfund.com/privacy'
                target='_blank'
                rel='noopener noreferrer'
              >
                {translate('loginScreen.privacyPolicy')}
              </a>
            </div>
          </div>
          <div className='text-center'>
            <button
              type='button'
              onClick={() => {
                validateInputs();
              }}
              disabled={loading}
              className='tw-button-light-blue !w-full sm:!w-72'
            >
              {loading && <Spiner className='ml-28 sm:ml-40' />}
              {translate('common.letsGo')}
            </button>
          </div>
          <div className='text-center'>
            <p className='text-primary-orange text-sm text-center mx-auto'>
              <span className=''>{translate('loginScreen.alreadyHaveAccount')} </span>
              <Link to={'/login'} className='font-bold cursor-pointer underline'>
                {translate('loginScreen.signIn')}
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
