import { Modal } from 'components/shared';
import React from 'react';

type Props = {
  message: string;
  okText: string;
  cancelText: string;
  hideXIcon?: boolean;
  isVisible: boolean;
  setIsVisible: (value: boolean) => void;
  submitFunction: () => Promise<void>;
};

const SureToSubmit = (props: Props) => {
  const {
    message = 'companiesScreen.discardChanges',
    okText = 'companiesScreen.exitAndDiscard',
    cancelText = 'common.cancel',
    submitFunction,
    hideXIcon = false,
    isVisible = false,
    setIsVisible,
  } = props;

  return (
    <Modal
      visible={isVisible}
      onClose={() => {
        setIsVisible(false);
      }}
      hideXIcon={hideXIcon}
    >
      <div className=''>
        <h1 className='font-Condensed font-bold text-2xl md:text-3xl text-center mb-9 mx-7'>
          {message}
        </h1>
        <div className='mt-9'>
          <div className='flex items-center justify-center gap-5'>
            <div>
              <button
                type='button'
                className='tw-input-orange-dark !bg-transparent !pr-0 !pl-0 w-[85px] md:w-[150px] !text-center'
                onClick={() => {
                  setIsVisible(false);
                }}
              >
                <p>{cancelText}</p>
              </button>
            </div>
            <div>
              <button
                type='button'
                className='tw-button-blue-outline !bg-transparent !pr-0 !pl-0 w-[85px] md:w-[150px]'
                onClick={() => {
                  submitFunction();
                  setIsVisible(false);
                }}
              >
                {okText}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SureToSubmit;
