import { FC } from 'react';

import clsx from 'clsx';

import useBinary from './hooks/useBinary';
import { FileProps } from './type';
import { translate } from 'utils/helperFunctions';
import { downloadFile } from './hooks/utils';

const BufferedFile: FC<FileProps> = ({
  src,
  fallback,
  Fallback,
  enableDownload = '',
  style,
  className,
  containerClassName = '',
  alt,
}) => {
  const { signedSrc, isError, onError, onLoad } = useBinary({ src });

  return (
    <>
      {!!enableDownload && signedSrc && (
        <button className='tw-link block mb-2' onClick={() => downloadFile(signedSrc, 'download')}>
          {enableDownload}
        </button>
      )}
      {(signedSrc || fallback) && (!isError || !Fallback) && (
        <div className={clsx('relative overflow-hidden', containerClassName)}>
          <a
            className={clsx('h-full w-full object-cover', className)}
            onError={onError}
            onLoad={onLoad}
            style={style}
            target='_blank'
            href={isError ? fallback : signedSrc || fallback}
          >
            {translate('common.viewFile')}
          </a>
        </div>
      )}
      {!!Fallback && ((!signedSrc && !fallback) || isError) && (
        <Fallback
          className={`${className}`}
          style={style}
          src={signedSrc}
          alt={alt || 'fallBack'}
        />
      )}
    </>
  );
};

export default BufferedFile;
