import { RadioGroup } from '@headlessui/react';
import React, { useEffect, useState } from 'react';

type SelectProps = {
  options: any[] | [];
  onChange: (value: any) => void;
  value: string;
  wrapperClassName?: string;
  labelClassName?: string;
  RadioClassName?: string;
};

export const CustomRadio: React.FC<SelectProps> = React.memo(
  ({ options, value, onChange, wrapperClassName, labelClassName, RadioClassName = '' }) => {
    const [selectedOption, setSelectedOption] = useState<any>({
      value: '',
      label: '',
    });

    useEffect(() => {
      if (value != undefined) {
        setSelectedOption(options.find(option => option.value === value));
      }
    }, [value]);

    return (
      <RadioGroup
        value={selectedOption?.value}
        onChange={selected => {
          const valueAfterSelect = options?.find(option => option?.value === selected);
          setSelectedOption(valueAfterSelect);
          onChange(valueAfterSelect);
        }}
      >
        <RadioGroup.Label className='sr-only'>Accounts</RadioGroup.Label>
        <div
          className={`text-2xl flex flex-col gap-6 text-left w-full sm:w-fit ${wrapperClassName}`}
        >
          {options &&
            options.map((option: any, index: number) => {
              return (
                <RadioGroup.Option
                  key={index}
                  value={option.value}
                  className={`${
                    option.disabled ? 'cursor-not-allowed' : 'cursor-pointer'
                  } flex items-center  `}
                  disabled={option.disabled || false}
                >
                  {({ checked }) => (
                    <div className='flex items-center'>
                      <RadioGroup.Description as='div' className='text-sm'>
                        <div
                          className={`${
                            checked ? 'bg-primary-orange' : 'bg-transparent'
                          } h-3 w-3 rounded-full bg-transparent border-3 border-transparent outline-3 outline-none outline-primary-light-grey ${RadioClassName}`}
                        />
                      </RadioGroup.Description>
                      <div>
                        <div>
                          <RadioGroup.Label
                            as='p'
                            className={`font-medium hover:text-white/80 text-xl text-center ${
                              checked ? 'text-white' : 'text-primary-light-grey'
                            }  ml-5 break-all ${labelClassName}`}
                          >
                            {option.label}
                          </RadioGroup.Label>
                        </div>
                      </div>
                    </div>
                  )}
                </RadioGroup.Option>
              );
            })}
        </div>
      </RadioGroup>
    );
  }
);
