import { Modal } from 'components/shared';
import { useFetchLegalState } from 'queries/legalQueries/legalQueries';
import { useEffect, useState } from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { translate } from 'utils/helperFunctions';

export const LegalState = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { data: legalState, error } = useFetchLegalState();
  const [isLegalRequired, setIsLegalRequired] = useState(false);

  const isTAndCURequired = legalState?.details?.some(
    l => l?.type === 't&c' && l?.state === 'required'
  );
  const isPrivacyRequired = legalState?.details?.some(
    l => l?.type === 'privacyPolicy' && l?.state === 'required'
  );

  const ModalDescription =
    isTAndCURequired && isPrivacyRequired
      ? translate('legalScreen.termAndConditionsAndPrivacyPolicy')
      : isTAndCURequired
      ? translate('legalScreen.termAndConditions')
      : isPrivacyRequired
      ? translate('legalScreen.privacyPolicy')
      : '';

  const ModalTitle =
    isTAndCURequired && isPrivacyRequired
      ? translate('legalScreen.termAndConditionsUpdateAndPrivacyPolicyUpdate')
      : isTAndCURequired
      ? translate('legalScreen.termAndConditionsUpdate')
      : isPrivacyRequired
      ? translate('legalScreen.privacyPolicyUpdate')
      : '';

  useEffect(() => {
    if (legalState?.state === 'required') {
      setIsLegalRequired(true);
    }
    if (error) {
      navigate('/no-access');
    }
  }, [legalState, error]);

  if (!legalState || legalState?.state === 'ok' || pathname.includes('/home/legal') || error) {
    return null;
  }

  if (legalState?.state === 'required') {
    return (
      <Modal visible={isLegalRequired} onClose={() => {}} hideXIcon>
        <div className='flex flex-col gap-6 p-4'>
          <h1 className='font-Condensed text-[32px] text-center'>{ModalTitle}</h1>
          <p className='text-lg font-normal text-center'>
            {translate('legalScreen.legalRequiredUpdateDescription', { type: ModalDescription })}
          </p>
          <div className='flex justify-center'>
            <Link to={'/home/legal'}>
              <button className='tw-button-light-blue'>{translate('common.letsGo')}</button>
            </Link>
          </div>
        </div>
      </Modal>
    );
  }

  if (legalState?.state === 'optional' && pathname === '/home') {
    return (
      <div>
        <Link to={'/home/legal'} className='text-primary-orange font-bold flex-wrap'>
          We have updated our Terms & Condition. Please click here to view.{' '}
          <IoIosArrowForward className='inline' />
        </Link>
      </div>
    );
  }

  return null;
};
