import axios from 'axios';

export const NotificationServices = {
  help: async (payload: object): Promise<any> => {
    const endPoint = `notification/help`;
    try {
      const res = await axios.post(endPoint, payload);
      return res;
    } catch (error) {
      throw error;
    }
  },
};
