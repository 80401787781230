const publicEndPoints: string[] = [
  'oauth/token',
  'oauth/token/phone',
  'oauth/token/phone/code',
  'onboarding/validate/codes',
];

const badTokenMessages = [
  'The access token provided has expired.',
  'The access token provided is invalid.',
];

export { publicEndPoints, badTokenMessages };
