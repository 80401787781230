import React from 'react';
import { translate } from 'utils/helperFunctions';
import useMediaQuery from 'utils/hooks/useMediaQuery';

const HandleChangeDiff = ({
  sections,
  itemClass,
  options = {
    renderNewTitle: true,
    renderTitles: true,
    old: true,
    includeTitleWithBackground: true,
  },
}: {
  sections?: {
    title?: string;
    value: string;
    render?: () => JSX.Element;
  }[];
  itemClass?: string;
  options: {
    renderNewTitle?: boolean;
    renderTitles?: boolean;
    old?: boolean;
    includeTitleWithBackground?: boolean;
  };
}) => {
  const { mobile: isMobileLayout } = useMediaQuery({ mobile: true });
  const includeTitleMobile = isMobileLayout && options.includeTitleWithBackground;
  const backgroundColor = `${options.old ? 'bg-primary-light-brown' : 'bg-primary-medium-green'}`;
  const itemClassName = `flex ${backgroundColor} items-center p-4 capitalize w-[100%] min-h-[80px] max-w-[100%] ${itemClass} font-normal whitespace-pre-wrap text-ellipsis overflow-hidden`;
  const titleClassName = `font-bold text-[24px] text-primary-orange min-w-[105px] max-w-[105px] pt-2 ${
    options.renderTitles ? '' : 'opacity-0 !w-0 !min-w-0 !max-w-0'
  } ${includeTitleMobile ? `${backgroundColor} p-4` : ''}`;
  const sectionClassName = `flex ${isMobileLayout ? '' : 'gap-4'}`;

  return (
    <div className={`flex flex-col w-[100%] max-w-[100%]`}>
      <div className='flex flex-col'>
        {!isMobileLayout && options.renderNewTitle && (
          <div className={sectionClassName}>
            <div className={`${options.renderTitles ? 'min-w-[105px] max-w-[105px]' : ''}`}></div>
            <div>
              <div className='flex'>
                <div className='text-[32px] pb-4'>
                  {options.old ? translate('common.old') : translate('common.new')}
                </div>
              </div>
            </div>
          </div>
        )}
        {sections?.map((section, index) => {
          return (
            <div className={sectionClassName} key={index}>
              <div
                className={`${titleClassName} ${
                  index === 0 && `${isMobileLayout ? 'rounded-tl' : ''}`
                } ${index === sections.length - 1 && `${isMobileLayout ? 'rounded-bl' : ''}`}`}
              >
                {options.renderTitles && section.title ? `${section.title}:` : ''}
              </div>
              <div
                className={`${itemClassName} ${
                  index === 0 && `${includeTitleMobile ? '' : 'rounded-tl'} rounded-tr`
                } ${
                  index === sections.length - 1 &&
                  `${includeTitleMobile ? '' : 'rounded-bl'} rounded-br`
                } `}
              >
                <div className='flex'>
                  <div className='text-lg'>
                    {section.render ? section?.render() : section.value}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default HandleChangeDiff;
