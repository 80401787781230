import React from 'react';
import { HiOutlineCollection } from 'react-icons/hi';

export type MobileView = {
  dataIndex: string;
  title?: string | React.ReactNode;
  hideTitle?: boolean;
  className?: string;
  style?: React.CSSProperties;
  render?: (val: any, data: any) => number | string | JSX.Element | null | undefined;
};

type Props = {
  dataSource: Record<string, unknown>[];
  dataView: MobileView[];
  containerClassName?: string;
  onClickCard?: (item: any, index: number) => void;
};

const returnFinalData = (data: Record<string, unknown>, key: string): string | undefined => {
  const splitKeys = key?.split('.');
  if (!splitKeys) {
    return undefined;
  }
  const finalValue = splitKeys.reduce(
    (accumulator: Record<string, unknown> | unknown, currentKey: string) => {
      return (accumulator as Record<string, unknown>)?.[currentKey];
    },
    data
  );
  return finalValue as string;
};

const MobileCards = (props: Props) => {
  const { dataView, dataSource, containerClassName = '', onClickCard } = props;
  return (
    <div className='flex flex-col gap-4'>
      {!!dataSource.length &&
        dataSource.map((item: any, i: number) => {
          return (
            <div
              key={item._id}
              className={`text-sm font-normal flex flex-col gap-0 p-4 border rounded-lg border-secondary-light-grey bg-primary-medium-grey bg-primary-dark  hover:bg-primary-medium-grey/50 ${
                onClickCard ? 'cursor-pointer' : ''
              } ${containerClassName}`}
              onClick={() => {
                if (onClickCard) {
                  onClickCard(item, i);
                }
              }}
            >
              {dataView?.map((val, idx) => {
                return val ? (
                  <React.Fragment key={val?.dataIndex ?? idx}>
                    <div
                      style={val?.style ?? {}}
                      className={`pt-2 flex justify-between ${val?.className ?? ''}`}
                    >
                      {!val?.hideTitle && val?.title ? (
                        <label className='text-primary-gray'>{val?.title}</label>
                      ) : null}
                      {val?.render ? (
                        <div className={''}>
                          {val?.render(returnFinalData(item, val?.dataIndex), item)}
                        </div>
                      ) : (
                        <div className='break-words flex-1 whitespace-break-spaces w-[calc(100%-128px)]'>
                          {returnFinalData(item, val?.dataIndex) ?? '-'}
                        </div>
                      )}
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment key={idx}></React.Fragment>
                );
              })}
            </div>
          );
        })}
      {!dataSource.length && (
        <div className='text-primary-gray h-52 relative'>
          <div className='flex gap-2 absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 text-lg'>
            <HiOutlineCollection className='w-6 h-6 text-primary-gray' /> No Data
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileCards;
