import { translate } from '../utils/helperFunctions';

type ResponseError = {
  errors: {
    details: {
      code: number;
      message: string;
    }[];
  };
};

function resolveErrorResponse(error?: ResponseError, serviceName?: string) {
  const defaultErrorMsg = translate('errors.genaric.default');
  if (error && error.errors && error.errors.details) {
    const details = error.errors.details;
    return details
      .map(err => {
        const errorCode = String(err.code);
        if (serviceName) {
          return translate(`errors.${serviceName}.${errorCode}`, defaultErrorMsg);
        }
        return defaultErrorMsg;
      })
      .join(' - ');
  }
  return defaultErrorMsg;
}

const handleResponseError = (error?: ResponseError, serviceName?: string) => {
  let msg = resolveErrorResponse(error, serviceName);
  const nothingToUpdate = msg && msg.indexOf('not updated') !== -1;
  if (nothingToUpdate) {
    msg = translate('errors.genaric.nothingToUpdate');
  }
  throw new Error(msg, { cause: error });
};

export { handleResponseError };
