import { translate } from 'utils/helperFunctions';

export const HandleMetaDataInfo = ({ details }: any) => {
  const rows = [
    {
      value: details?.category,
      label: translate('handlesScreen.category'),
    },
    {
      value: details?.subCategory,
      label: translate('handlesScreen.sub-Category'),
      p: 'pb-6 pt-0',
      b: 'border-0',
    },
    {
      value: details?.description,
      label: translate('common.description'),
      class: 'w-full break-words',
      p: `py-6`,
    },
  ];

  return (
    <div className=''>
      {rows &&
        rows.map((row, index) => {
          return (
            <div
              key={index}
              className={`${row.p ? row.p : `py-6`} ${
                row.b ? row.b : 'border-t-2 border-primary-light-grey'
              } flex flex-row gap-4`}
            >
              <div className='flex items-start justify-start w-[128px] sm:w-auto break-words'>
                <div className='flex items-start justify-start font-Condensed text-[24px] text-primary-orange font-bold w-[128px] whitespace-normal break-words text-left'>
                  {row.label}:
                </div>
              </div>
              <div className='flex items-center justify-start '>
                <div
                  className={`${
                    row.class ? row.class : ''
                  } font-Condensed capitalize text-[24px] text-left font-bold sm:whitespace-normal break-words max-w-[180px] sm:max-w-none truncate`}
                >
                  {row?.value ? row.value : '-'}
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};
