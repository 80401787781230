export const ACCEPTED_FILE_TYPES: { [key: string]: string[] } = {
  'image/*': ['image/jpeg', 'image/png'],
  'application/pdf': ['application/pdf'],
  'video/*': [
    'video/mp4',
    'video/webm',
    'video/ogg',
    'video/x-msvideo',
    'video/x-matroska',
    'video/quicktime',
    'video/x-ms-wmv',
    'video/x-flv',
    'video/3gpp',
    'video/mpeg',
    'video/x-m4v',
  ],
};
