import { LoadingPage, Tabs } from 'components/shared';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { translate } from 'utils/helperFunctions';
import { AiFillCaretLeft } from 'react-icons/ai';
import { useStore } from 'services/context';
import { UserTermsListing } from './UserTerms/UserTermsListing';
import { PrivacyListing } from './Privacy/PrivacyListing';
import { DomainTermsListing } from './DomainTerms/DomainTermsListing';
import { useEffect } from 'react';
import Box from 'components/shared/Box';
import { useFetchLegalState } from 'queries/legalQueries/legalQueries';
export const LegalListing = () => {
  const navigate = useNavigate();
  const [state, _dispatch] = useStore();
  const {
    authStore: {
      isAclHitted,
      userData: { groups },
    },
  } = state;

  const isInfluencer = groups.includes('influencer_outdated') || groups.includes('influencer');

  const [searchParams, setSearchParams] = useSearchParams({
    tab: '',
  });

  const { data: legalState, isFetching: loading } = useFetchLegalState();

  const tabParam = searchParams.get('tab');

  const onChangeTab = (key: string) => {
    setSearchParams({ tab: key });
  };
  useEffect(() => {
    if (legalState?.state === 'ok') {
      navigate('/home', { replace: true });
      return;
    }

    const isTAndC = legalState?.details?.some((l: { type: string }) => l?.type === 't&c');
    if (isTAndC) return setSearchParams({ tab: 'user' });
    const isPrivacy = legalState?.details?.some(
      (l: { type: string }) => l?.type === 'privacyPolicy'
    );
    if (isPrivacy) return setSearchParams({ tab: 'privacy' });
    const isDomain = legalState?.details?.some((l: { type: string }) => l?.type === 'classSwitch');
    if (isDomain) return setSearchParams({ tab: 'domain' });
  }, []);

  return (
    <>
      {isAclHitted && (
        <div className=''>
          <div className='font-Condensed flex justify-start items-center text-primary-light-grey text-3xl mb-2'>
            <div className=''>
              <AiFillCaretLeft
                className='text-5xl cursor-pointer hover:filter hover:brightness-125 hover:contrast-125 transition-all duration-300 hover:scale-[1.1]'
                onClick={() => {
                  navigate('/home');
                }}
              />
            </div>
            <div>{translate('legalScreen.legal')}</div>
          </div>

          <Tabs
            activeKey={tabParam || ''}
            onChange={onChangeTab}
            tabPanes={[
              { name: translate('legalScreen.userTermsAndConditions'), key: 'user' },
              { name: translate('legalScreen.privacyPolicy'), key: 'privacy' },
              ...(isInfluencer
                ? [
                    {
                      name: translate('legalScreen.domainTermsAndConditions'),
                      key: 'domain',
                    },
                  ]
                : []),
            ]}
          />
          {tabParam === 'user' && <UserTermsListing />}
          {tabParam === 'privacy' && <PrivacyListing />}
          {tabParam === 'domain' && <DomainTermsListing />}
          {!tabParam && loading && (
            <Box className='border-t-0 h-[200px]'>
              <LoadingPage loading={loading} />
            </Box>
          )}
        </div>
      )}
    </>
  );
};
