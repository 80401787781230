/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useMemo } from 'react';
import { IHandleDetails } from 'services/apis/Handle/handle.types';
import { translate } from 'utils/helperFunctions';
import HandleChangeDiff from './HandleChangeDiff';
import useMediaQuery from 'utils/hooks/useMediaQuery';

type Likes = NonNullable<IHandleDetails['metadata']['lightsYourFire']>[0] | null;
type CombinedLikes = {
  new?: Likes;
  old?: Likes;
};
type Dislikes = NonNullable<IHandleDetails['metadata']['turnsYouOff']>[0] | null;
type CombinedDislikes = {
  new?: Dislikes;
  old?: Dislikes;
};

const combineLikesValues = ({ handle }: { handle?: IHandleDetails | null }): CombinedLikes[] => {
  const lightsYourFire = handle?.metadata?.lightsYourFire;
  const newLightsYourFire = handle?.handle_metadata?.holder?.metadata?.lightsYourFire;
  const filteredLightsYourFire = lightsYourFire?.filter(
    ele => ele.title !== newLightsYourFire?.find(ele2 => ele2.title === ele.title)?.title
  );
  const filteredNewLightsYourFire = newLightsYourFire?.filter(
    ele => ele.title !== lightsYourFire?.find(ele2 => ele2.title === ele.title)?.title
  );
  const allCombined: CombinedLikes[] = [];

  filteredLightsYourFire?.forEach(oldLightsYourFire => {
    const newLightsYourFireValue = filteredNewLightsYourFire?.find(
      ele => ele.title === oldLightsYourFire.title
    );
    allCombined.push({
      old: oldLightsYourFire,
      new: newLightsYourFireValue || null,
    });
  });

  filteredNewLightsYourFire?.forEach(newLightsYourFireValue => {
    const oldLightsYourFire = filteredLightsYourFire?.find(
      ele => ele.title === newLightsYourFireValue.title
    );
    if (!oldLightsYourFire) {
      allCombined.push({
        old: oldLightsYourFire || null,
        new: newLightsYourFireValue,
      });
    }
  });

  const allCombinedFiltered = allCombined.filter(ele => ele.new?.title !== ele.old?.title);

  return allCombinedFiltered;
};

const combineDislikesValues = ({
  handle,
}: {
  handle?: IHandleDetails | null;
}): CombinedDislikes[] => {
  const turnsYouOff = handle?.metadata?.turnsYouOff;
  const newTurnsYouOff = handle?.handle_metadata?.holder?.metadata?.turnsYouOff;
  const filteredTurnsYouOff = turnsYouOff?.filter(
    ele => ele.title !== newTurnsYouOff?.find(ele2 => ele2.title === ele.title)?.title
  );
  const filteredNewTurnsYouOff = newTurnsYouOff?.filter(
    ele => ele.title !== turnsYouOff?.find(ele2 => ele2.title === ele.title)?.title
  );
  const allCombined: CombinedDislikes[] = [];

  filteredTurnsYouOff?.forEach(oldTurnsYouOff => {
    const newTurnsYouOffValue = filteredNewTurnsYouOff?.find(
      ele => ele.title === oldTurnsYouOff.title
    );
    allCombined.push({
      old: oldTurnsYouOff,
      new: newTurnsYouOffValue || null,
    });
  });

  filteredNewTurnsYouOff?.forEach(newTurnsYouOffValue => {
    const oldTurnsYouOff = filteredTurnsYouOff?.find(
      ele => ele.title === newTurnsYouOffValue.title
    );
    if (!oldTurnsYouOff) {
      allCombined.push({
        old: oldTurnsYouOff || null,
        new: newTurnsYouOffValue,
      });
    }
  });

  const allCombinedFiltered = allCombined.filter(ele => ele.new?.title !== ele.old?.title);

  return allCombinedFiltered;
};

const HandleDiffLikesDislikes = ({ handle }: { handle: IHandleDetails | null }) => {
  const { mobile: isMobileLayout } = useMediaQuery({ mobile: true });

  const renderLikes = useCallback(
    (
      likesValue?: Likes,
      options: {
        renderTitles?: boolean;
        old?: boolean;
      } = {
        renderTitles: true,
        old: true,
      }
    ) => {
      return (
        <HandleChangeDiff
          sections={[
            { value: String(likesValue?.title), title: options.old ? '' : likesValue?.title },
          ]}
          itemClass='px-4 h-[50px]'
          options={{ ...options, includeTitleWithBackground: false, renderNewTitle: false }}
        />
      );
    },
    []
  );

  const newLikesCombined = useMemo(() => combineLikesValues({ handle }), [handle]);
  const newDislikesCombined = useMemo(() => combineDislikesValues({ handle }), [handle]);

  const changedLikes = newLikesCombined?.filter(ele => ele.new?.title !== ele.old?.title);

  const changedDislikes = newDislikesCombined?.filter(ele => ele.new?.title !== ele.old?.title);

  const noChange = !changedLikes?.length && !changedDislikes?.length;

  if (noChange) {
    return <></>;
  }

  const checkIsOption = (combined: CombinedDislikes[], option: string) => {
    if (option === 'old') {
      return combined?.some(d => d && d.old !== null) ?? false;
    } else {
      return combined?.some(d => d && d.new !== null) ?? false;
    }
  };

  return (
    <div className='flex flex-col gap-4'>
      <div
        className='bg-primary-dark-grey border-2 xborder-t-0 border-primary-blue p-4 capitalize'
        style={{ boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15266)' }}
      >
        <div className='flex flex-col gap-8'>
          {!!newLikesCombined?.length && (
            <div className='flex flex-col gap-8'>
              <div className='flex'>
                <label className='font-bold text-3xl'>{translate('handlesScreen.likes')}</label>
              </div>
              <div className={`flex ${isMobileLayout ? 'flex-col gap-4' : 'ml-[80px]'}`}>
                {checkIsOption(newLikesCombined, 'new') && (
                  <div className={`flex flex-col ${isMobileLayout ? '' : 'min-w-[50%]'}`}>
                    {checkIsOption(newLikesCombined, 'new') && !isMobileLayout && (
                      <span className='ml-4 text-[32px] mb-[15px]'>{translate('common.new')}</span>
                    )}
                    <div className='flex gap-4 flex-wrap'>
                      {newLikesCombined?.map((combinedLikes, index) => {
                        if (!combinedLikes.new) {
                          return null;
                        }
                        return (
                          <div key={index} className='flex gap-4'>
                            <div className={`flex gap-8`}>
                              <div className={`flex ${isMobileLayout ? 'flex-col' : ''} gap-4`}>
                                {renderLikes(combinedLikes.new, {
                                  old: false,
                                  renderTitles: false,
                                })}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                <div className={`flex flex-col ${isMobileLayout ? '' : 'min-w-[50%]'}`}>
                  {checkIsOption(newLikesCombined, 'old') && !isMobileLayout && (
                    <span className='text-[32px] mb-[15px]'>{translate('common.old')}</span>
                  )}
                  <div className='flex gap-4 flex-wrap'>
                    {newLikesCombined?.map((combinedLikes, index) => {
                      if (!combinedLikes.old) {
                        return null;
                      }
                      return (
                        <div key={index} className='flex gap-4'>
                          <div className={`flex gap-8`}>
                            <div className={`flex ${isMobileLayout ? 'flex-col' : ''} gap-4`}>
                              {renderLikes(combinedLikes.old, { old: true, renderTitles: false })}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          )}
          {!!newLikesCombined?.length && !!newDislikesCombined?.length && (
            <div className='border-t border-white' />
          )}
          {!!newDislikesCombined?.length && (
            <div className='flex flex-col gap-8'>
              <div className='flex'>
                <label className='font-bold text-3xl'>{translate('handlesScreen.dislikes')}</label>
              </div>
              <div className={`flex ${isMobileLayout ? 'flex-col gap-4' : 'ml-[80px]'}`}>
                {checkIsOption(newDislikesCombined, 'new') && (
                  <div className={`flex flex-col ${isMobileLayout ? '' : 'min-w-[50%]'}`}>
                    {checkIsOption(newDislikesCombined, 'new') && !isMobileLayout && (
                      <span className='ml-4 text-[32px] mb-[15px]'>{translate('common.new')}</span>
                    )}
                    <div className='flex gap-4 flex-wrap'>
                      {newDislikesCombined?.map((combinedDislikes, index) => {
                        if (!combinedDislikes.new) {
                          return null;
                        }
                        return (
                          <div key={index} className='flex gap-4'>
                            <div className={`flex gap-8`}>
                              <div className={`flex ${isMobileLayout ? 'flex-col' : ''} gap-4`}>
                                {renderLikes(combinedDislikes.new, {
                                  old: false,
                                  renderTitles: false,
                                })}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                <div className={`flex flex-col ${isMobileLayout ? '' : 'min-w-[50%]'}`}>
                  {checkIsOption(newDislikesCombined, 'old') && !isMobileLayout && (
                    <span className='text-[32px] mb-[15px]'>{translate('common.old')}</span>
                  )}
                  <div className='flex gap-4 flex-wrap'>
                    {newDislikesCombined?.map((combinedDislikes, index) => {
                      if (!combinedDislikes.old) {
                        return null;
                      }
                      return (
                        <div key={index} className='flex gap-4'>
                          <div className={`flex gap-8`}>
                            <div className={`flex ${isMobileLayout ? 'flex-col' : ''} gap-4`}>
                              {renderLikes(combinedDislikes.old, {
                                old: true,
                                renderTitles: false,
                              })}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HandleDiffLikesDislikes;
