import { AlignTypes, Column } from 'components/shared';
import { IHandleTerms } from 'services/apis/Handle';
import { getTheTime } from 'utils/helperFunctions';

const useLegalColumns = () => {
  const userTermsAndPrivacyColumns: Array<Column> = [
    {
      dataIndex: 'name',
      align: 'left' as AlignTypes,
      render: (name: string) => <div className='text-2xl whitespace-pre-wrap'>{name}</div>,
    },
    {
      dataIndex: 'time',
      align: 'right' as AlignTypes,
      render: (time: string) => (
        <div className='text-2xl whitespace-pre-wrap'>{time ? getTheTime(time) : '-'}</div>
      ),
    },
  ];

  const domainTermsColumns: Array<Column> = [
    {
      dataIndex: 'name',
      align: 'left' as AlignTypes,
      render: (_: any, item: IHandleTerms) => (
        <div>
          <div className='text-2xl whitespace-pre-wrap'>
            {item?.newClassInfo?.name ?? 'Legal Name'}
          </div>
          <div className='text-base text-primary-blue'>{item?.domainInfo?.name}</div>
        </div>
      ),
    },
    {
      dataIndex: 'time',
      align: 'right' as AlignTypes,
      render: (time: string) => (
        <div className='text-2xl whitespace-pre-wrap'>{time ? getTheTime(time) : '-'}</div>
      ),
    },
  ];

  return { userTermsAndPrivacyColumns, domainTermsColumns };
};

export default useLegalColumns;
