import { FC } from 'react';

import clsx from 'clsx';

import useBinary from './hooks/useBinary';
import { VideoProps } from './type';
import { downloadFile } from './hooks/utils';

const BufferedVideo: FC<VideoProps> = ({
  src,
  fallback,
  Fallback,
  style,
  className,
  containerClassName = '',
  enableDownload = '',
  alt,
  type = 'video/mp4',
  captions = [],
  muted = false,
  loop = false,
  autoPlay = false,
  controls = true,
  width = '100%',
  height = '100%',
}) => {
  const { signedSrc, isLoading, isError, onError } = useBinary({ src });

  return (
    <>
      {!!enableDownload && signedSrc && (
        <button className='tw-link block mb-2' onClick={() => downloadFile(signedSrc, 'download')}>
          {enableDownload}
        </button>
      )}
      {(signedSrc || fallback) && (!isError || !Fallback) && (
        <div className={clsx('overflow-hidden', containerClassName)}>
          <video
            width={width}
            height={height}
            controls={controls}
            autoPlay={autoPlay}
            loop={loop}
            muted={muted}
            onError={onError}
            style={style}
          >
            <source src={signedSrc ?? ''} type={type} />
            {captions.map((caption, index) => (
              <track
                key={index}
                src={caption.src}
                kind={caption.kind}
                srcLang={caption.srcLang}
                label={caption.label}
                default={caption.default}
              />
            ))}
            Your browser does not support the video tag.
          </video>
        </div>
      )}
      {!!Fallback && ((!signedSrc && !fallback) || isError || isLoading) && (
        <Fallback
          className={`${className}`}
          style={style}
          src={signedSrc}
          alt={alt || 'fallBack'}
        />
      )}
    </>
  );
};

export default BufferedVideo;
