import axios from 'axios';

export const ConvoServices = {
  convoTypes: async (params: any = {}): Promise<any> => {
    const endPoint = `convo/social/media/convo/types`;
    try {
      const list = await axios.get(endPoint, { params });
      return list;
    } catch (err) {
      throw err;
    }
  },
  handleConversations: async (handleId: string, params: any = {}): Promise<any> => {
    try {
      const endPoint = `convo/search/handle/${handleId}`;
      const handleslist = await axios.get(endPoint, { params });
      return handleslist;
    } catch (err) {
      throw err;
    }
  },
};
