import { ReAuthData } from 'services/apis/Auth';

export const getUserDataByKey = (key: string) => {
  try {
    const userData = JSON.parse(localStorage.getItem('userData') || '{}');
    return userData[key] || undefined;
  } catch (error) {
    return null;
  }
};

export const setUserKeyACL = (acl: any, index: any) => {
  let acl_cached: any = localStorage.getItem('userACL');
  if (!acl_cached) {
    acl_cached = {};
  } else {
    acl_cached = JSON.parse(acl_cached);
  }
  acl_cached[index] = acl;
  localStorage.setItem('userACL', JSON.stringify(acl_cached));
};

export const getUserKeyACL = (index: any) => {
  let acl_cached: any = localStorage.getItem('userACL');
  if (acl_cached) {
    acl_cached = JSON.parse(acl_cached);
    if (acl_cached[index]) {
      return acl_cached[index];
    }
  }
  return null;
};

export const clearUserKeyACL = () => {
  localStorage.removeItem('userACL');
};

export const getMembershipStatuses = () => {
  try {
    const statuses = JSON.parse(localStorage.getItem(`membershipStatuses`) || '{}');
    return statuses;
  } catch (error) {
    return null;
  }
};

export const getMembershipStatus = (id: string) => {
  try {
    const statuses = JSON.parse(localStorage.getItem(`membershipStatuses`) || '{}');
    return statuses[id] || undefined;
  } catch (error) {
    return null;
  }
};

export const clearMembershipStatus = () => {
  localStorage.removeItem('membershipStatuses');
};

export const setMembershipsStatus = (items: { id: string; status: string }[]) => {
  try {
    const statuses = JSON.parse(localStorage.getItem(`membershipStatuses`) || '{}');
    items.forEach(item => {
      statuses[item.id] = item.status;
    });
    localStorage.setItem('membershipStatuses', JSON.stringify(statuses));
  } catch (error) {
    return null;
  }
};

export const getStorageLoginData = (key?: string | null) => {
  try {
    const loginData = JSON.parse(localStorage.getItem('loginData') || '{}');
    if (key) {
      return loginData[key] || undefined;
    }
    return loginData;
  } catch (error) {
    return null;
  }
};

export const setStorageLoginData = (data: ReAuthData) => {
  localStorage.setItem('loginData', JSON.stringify({ ...data }));
};

export const getStorageUserData = () => {
  try {
    const userData = JSON.parse(localStorage.getItem('userData') || '{}');
    return userData;
  } catch (error) {
    return null;
  }
};

export const setStorageUserData = (user: any) => {
  localStorage.setItem('userData', JSON.stringify(user));
};
