import { Select } from 'components/shared';
import SureToSubmit from 'components/shared/SureToSubmit';
import { useState, useEffect } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { BsFillExclamationCircleFill } from 'react-icons/bs';
import { ImCancelCircle } from 'react-icons/im';
import PhoneInput from 'react-phone-input-2';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ReferralServices } from 'services/apis/Referral';
import { SocialMediaList, translate } from 'utils/helperFunctions';

type Props = {
  setIsSubmitted: (error: any) => void;
};

export const AddInfluencer = ({ setIsSubmitted }: Props) => {
  const navigate = useNavigate();
  const { membership, referralId } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [addRefferal, setAddRefferal] = useState<any>({
    referee: {
      contactInfo: {
        countryCode: '1',
        phone: '',
        concatenatedPhoneNumber: '1',
        firstName: '',
        lastName: '',
        email: '',
      },
      handles: [
        {
          socialMedia: '',
          handle: '',
          followers: '',
        },
      ],
    },
    membership: membership,
    relation: '',
    description: '',
  });

  const [errors, setErrors] = useState<any>({
    phone: '',
    firstName: '',
    lastName: '',
    email: '',
    handles: [{ socialMedia: '', handle: '', followers: '' }],
    relation: '',
    description: '',
  });

  const fetchRferraldetails = async () => {
    if (referralId) {
      try {
        const detail = await ReferralServices.referralDetails(referralId);
        setAddRefferal({
          ...addRefferal,
          referee: {
            ...addRefferal.referee,
            contactInfo: {
              ...addRefferal.referee.contactInfo,
              countryCode: detail?.referee?.contactInfo?.countryCode || '1',
              phone: detail?.referee?.contactInfo?.phone || '',
              concatenatedPhoneNumber: detail?.referee?.contactInfo?.concatenatedPhoneNumber || '1',
              firstName: detail?.referee?.contactInfo?.firstName || '',
              lastName: detail?.referee?.contactInfo?.lastName || '',
              email: detail?.referee?.contactInfo?.email || '',
            },
            handles: [...detail?.referee?.handles] || [
              {
                socialMedia: '',
                handle: '',
                followers: '',
              },
            ],
          },
          membership: membership,
          relation: detail?.relation || '',
          description: detail?.description || '',
        });
        setErrors((prev: any) => {
          const err = { ...prev };
          detail.referee.handles.map(() => {
            err.handles.push({
              socialMedia: '',
              handle: '',
              followers: '',
            });
          });
          return err;
        });
      } catch (error: any) {
        toast.error(error?.message);
      }
    }
  };

  useEffect(() => {
    if (referralId !== 'new') {
      fetchRferraldetails();
    }
  }, []);

  const saveInfluencerRefferal = async () => {
    const filteredHandles = addRefferal?.referee?.handles.filter((obj: any) => {
      return obj.socialmedia !== '' && obj.handle !== '';
    });
    setIsSubmitted(true);

    try {
      setLoading(true);
      if (referralId === 'new') {
        await ReferralServices.addReferral('influencer', {
          ...addRefferal,
          referee: {
            ...addRefferal.referee,
            handles: filteredHandles,
          },
        });
        setAddRefferal({
          referee: {
            contactInfo: {
              countryCode: '1',
              phone: '',
              concatenatedPhoneNumber: '1',
              firstName: '',
              lastName: '',
              email: '',
            },
            handles: [
              {
                socialMedia: '',
                handle: '',
                followers: '',
              },
            ],
          },
          membership: membership,
          relation: '',
          description: '',
        });
      } else if (referralId && referralId !== 'new') {
        await ReferralServices.EditReferralCompany(referralId, {
          ...addRefferal,
          referee: {
            ...addRefferal.referee,
            handles: filteredHandles,
          },
        });
      }

      toast.success(
        referralId === 'new'
          ? translate('handlesScreen.referralCreatedSuccessfully')
          : translate('handlesScreen.referralUpdatedSuccessfully')
      );
      setLoading(false);
      navigate(-1);
    } catch (error: any) {
      setIsSubmitted(false);
      setIsSubmit(false);
      setLoading(false);
      toast.error(error?.message);
    }
  };

  const submitInfluencerRefferal = async () => {
    const filteredHandles = addRefferal?.referee?.handles.filter((obj: any) => {
      return obj.socialmedia !== '' && obj.handle !== '';
    });
    setIsSubmitted(true);

    try {
      setLoading(true);
      if (referralId === 'new') {
        await ReferralServices.addReferral('influencer', {
          ...addRefferal,
          referee: {
            ...addRefferal.referee,

            handles: filteredHandles,
          },
          submit: true,
        });
        setAddRefferal({
          referee: {
            contactInfo: {
              countryCode: '1',
              phone: '',
              concatenatedPhoneNumber: '1',
              firstName: '',
              lastName: '',
              email: '',
            },
            handles: [
              {
                socialMedia: '',
                handle: '',
                followers: '',
              },
            ],
          },
          membership: membership,
          relation: '',
          description: '',
        });
      } else if (referralId && referralId !== 'new') {
        await ReferralServices.EditReferralCompany(referralId, {
          ...addRefferal,
          referee: {
            ...addRefferal.referee,

            handles: filteredHandles,
          },
          submit: true,
        });
      }

      toast.success(
        referralId === 'new'
          ? translate('handlesScreen.referralCreatedSubmitedSuccessfully')
          : translate('handlesScreen.referralUpdatedSubmitedSuccessfully')
      );
      setLoading(false);
      navigate(-1);
    } catch (error: any) {
      setIsSubmitted(false);
      setIsSubmit(false);
      setLoading(false);
      toast.error(error?.message);
    }
  };

  const validateInputs = () => {
    const regexPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/g;
    const updatedErrors: any = { handles: [] };
    addRefferal.referee.handles.map(() => {
      updatedErrors.handles.push({
        socialMedia: '',
        handle: '',
        followers: '',
      });
    });
    Object.keys(addRefferal).forEach(key => {
      if (key === 'referee') {
        Object.keys(addRefferal.referee).forEach(k => {
          if (k === 'contactInfo') {
            Object.keys(addRefferal?.referee?.contactInfo).forEach(ky => {
              if (addRefferal?.referee?.contactInfo) {
                if (ky === 'email') {
                  if (!addRefferal?.referee?.contactInfo[ky]) {
                    updatedErrors[ky] = translate('common.isRequired');
                  } else if (!regexPattern.test(addRefferal?.referee?.contactInfo[ky] as string)) {
                    updatedErrors[ky] = translate('common.isUnvalid');
                  }
                } else if (!addRefferal?.referee?.contactInfo[ky]) {
                  updatedErrors[ky] = translate('common.isRequired');
                } else {
                  updatedErrors[ky] = '';
                }
              } else {
                updatedErrors[ky] = translate('common.isRequired');
              }
            });
          } else if (k === 'handles') {
            addRefferal.referee.handles.forEach((_: any, i: number) => {
              if (!addRefferal?.referee?.handles[i]?.handle) {
                updatedErrors.handles[i].handle = translate('common.isRequired');
              }
              if (!addRefferal?.referee?.handles[i]?.socialMedia) {
                updatedErrors.handles[i].socialMedia = translate('common.isRequired');
              }
              if (!addRefferal?.referee?.handles[i]?.followers) {
                updatedErrors.handles[i].followers = translate('common.isRequired');
              }
            });
          } else {
            updatedErrors[key] = translate('common.isRequired');
          }
        });
      } else if (!addRefferal[key]) {
        updatedErrors[key] = translate('common.isRequired');
      } else {
        updatedErrors[key] = '';
      }
    });
    const isFormValid = Object.values(updatedErrors).every(error => {
      if (Array.isArray(error)) {
        return error.every(handle => {
          if (handle && typeof handle === 'object') {
            return Object.values(handle).every(value => value === '');
          }
          return true;
        });
      }
      return error === '';
    });

    if (isFormValid) {
      setIsVisible(true);
    }
    setErrors(updatedErrors);
  };

  const addSocialMedia = () => {
    setAddRefferal({
      ...addRefferal,
      referee: {
        ...addRefferal?.referee,
        handles: [
          ...addRefferal?.referee?.handles,
          {
            socialMedia: '',
            handle: '',
            followers: '',
          },
        ],
      },
    });
    setErrors({
      ...errors,
      handles: [...errors.handles, { socialMedia: '', handle: '', followers: '' }],
    });
  };

  const deleteSocialMedia = (index: number) => {
    const socialMediaList = [...addRefferal?.referee?.handles];
    socialMediaList.splice(index, 1);
    setAddRefferal({
      ...addRefferal,
      referee: {
        ...addRefferal?.referee,
        handles: socialMediaList,
      },
    });
    const socialMediaErrorList = [...errors?.handles];
    socialMediaErrorList.splice(index, 1);
    setErrors({ ...errors, handles: socialMediaErrorList });
  };

  return (
    <>
      <SureToSubmit
        message={
          isSubmit
            ? translate('handlesScreen.areYouSureYouWantToSubmitThisReferral')
            : translate('handlesScreen.areYouSureYouWantToSaveThisReferral')
        }
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        submitFunction={isSubmit ? submitInfluencerRefferal : saveInfluencerRefferal}
        okText={isSubmit ? translate('common.submit') : translate('common.save')}
        cancelText={`${translate('common.cancel')}`}
      />
      <div className='flex flex-col gap-6'>
        <div className='input-wrapper relative'>
          <label htmlFor='firstName' className='tw-label !text-[15px] sm:text-[16px]'>
            *{translate('handlesScreen.howDoYouKnowThisPerson/Company')}?
          </label>
          <Select
            options={[
              { label: 'friend', value: 'friend' },
              { label: 'from school', value: 'school' },
              { label: 'work', value: 'work' },
            ]}
            className={`bg-primary-medium-grey relative w-full shadow-sm pl-3 text-left cursor-pointer focus:outline-none sm:text-sm border-2 h-12 rounded  ${
              errors.relation ? '!border-primary-dark-red' : 'border-primary-light-grey'
            }`}
            placeholderStyle='!text-[16px] pl-2'
            value={addRefferal?.relation}
            preValue={addRefferal?.relation && ''}
            placeholder={`${translate('handlesScreen.selectWay')}`}
            menuBg='bg-primary-medium-grey mt-[10px] border-2 !text-base rounded border-primary-orange'
            focusOnOpen={true}
            onChange={e => {
              setAddRefferal({ ...addRefferal, relation: e?.value });
              if (e?.value) {
                setErrors({ ...errors, relation: '' });
              }
            }}
          />
          {errors.relation && (
            <>
              <p className='absolute left-1 -bottom-5 text-[12px] text-primary-dark-red'>
                {errors.relation}
              </p>
            </>
          )}
        </div>
        <div className='input-wrapper relative'>
          <textarea
            placeholder={translate('handlesScreen.tellUsMoreAboutThisReferral')}
            value={addRefferal?.description}
            onChange={e => {
              setAddRefferal({ ...addRefferal, description: e.target.value });
              if (e.target.value) {
                setErrors({ ...errors, description: '' });
              }
            }}
            className={`primaryTextArea sm:text-xl mt-2 focus:!border-primary-orange !text-sm ${
              errors.description ? '!border-primary-dark-red' : '!border-primary-light-grey'
            }`}
          />
          {errors.description && (
            <>
              <p className='absolute left-1 -bottom-5 text-[12px] text-primary-dark-red'>
                {errors.description}
              </p>
            </>
          )}
        </div>
        <hr
          className={`border-2 border-primary-light-grey w-full ${
            errors.description ? 'mt-2' : ''
          }`}
        />
        <p className='font-[600] text-[16px] text-primary-light-grey'>
          {translate('handlesScreen.contactDetails')}
        </p>
        <div className='input-wrapper relative'>
          <label htmlFor='firstName' className='tw-label'>
            *{translate('handlesScreen.firstName')}:
          </label>
          <input
            className={`tw-input-primary relative ${
              errors.firstName ? '!border-primary-dark-red' : ''
            }`}
            id='firstName'
            type='text'
            autoComplete={'off'}
            value={addRefferal?.referee?.contactInfo?.firstName || ''}
            placeholder={translate('handlesScreen.enterFirstName')}
            onChange={e => {
              const copy = { ...addRefferal };
              copy.referee.contactInfo.firstName = e.target.value;
              setAddRefferal(copy);
              if (e.target.value) {
                setErrors({ ...errors, firstName: '' });
              }
            }}
          />
          {errors.firstName && (
            <>
              <BsFillExclamationCircleFill className='absolute right-3 top-11 w-15 !text-xl text-primary-dark-red' />
              <p className='absolute left-1 -bottom-5 text-[12px] text-primary-dark-red'>
                {errors.firstName}
              </p>
            </>
          )}
        </div>
        <div className='input-wrapper relative'>
          <label htmlFor='lastName' className='tw-label'>
            *{translate('handlesScreen.lastName')}:
          </label>
          <input
            className={`tw-input-primary relative
          ${errors.lastName ? '!border-primary-dark-red' : ''}`}
            id='lastName'
            type='text'
            autoComplete={'off'}
            value={addRefferal?.referee?.contactInfo?.lastName}
            placeholder={translate('handlesScreen.enterLastName')}
            onChange={e => {
              const copy = { ...addRefferal };
              copy.referee.contactInfo.lastName = e.target.value;
              setAddRefferal(copy);
              if (e.target.value) {
                setErrors({ ...errors, lastName: '' });
              }
            }}
          />
          {errors.lastName && (
            <>
              <BsFillExclamationCircleFill className='absolute right-3 top-11 w-15 !text-xl text-primary-dark-red' />
              <p className='absolute left-1 -bottom-5 text-[12px] text-primary-dark-red'>
                {errors.lastName}
              </p>
            </>
          )}
        </div>
        <div className='input-wrapper'>
          <label htmlFor='phone' className='tw-label'>
            *{translate('handlesScreen.phoneNumber')}:
          </label>
          <div className='flex gap-2 relative'>
            <input
              className={`tw-input-primary relative !pl-[110px]
          ${errors.phone ? '!border-primary-dark-red' : ''}`}
              id='phone'
              type='text'
              autoComplete={'off'}
              maxLength={10}
              value={addRefferal?.referee?.contactInfo?.phone || ''}
              placeholder={translate('handlesScreen.enterPhone')}
              onChange={e => {
                if (/^[0-9]+$/g.test(e.target.value) || !e.target.value) {
                  const copy = { ...addRefferal };
                  copy.referee.contactInfo.phone = e.target.value;
                  copy.referee.contactInfo.concatenatedPhoneNumber =
                    copy.referee.contactInfo.countryCode + e.target.value;
                  setAddRefferal(copy);
                  if (e.target.value) {
                    setErrors({ ...errors, phone: '' });
                  }
                }
              }}
            />
            <span className='absolute transform -translate-y-1/2 top-[27px] left-2 z-10'>
              <PhoneInput
                country={'us'}
                buttonStyle={{ background: '#171717', border: 'none', bottom: '1px' }}
                containerStyle={{
                  width: `${innerWidth < 640 ? '100px' : '100px'}`,
                  margin: 'auto',
                }}
                dropdownStyle={{ color: 'white', background: '#6F7577' }}
                value={addRefferal?.referee?.contactInfo?.countryCode || '1'}
                onChange={(phone: any) => {
                  const copy = { ...addRefferal };
                  copy.referee.contactInfo.countryCode = phone;
                  copy.referee.contactInfo.concatenatedPhoneNumber =
                    phone + copy.referee.contactInfo.phone;
                  setAddRefferal(copy);
                }}
                placeholder={''}
                inputProps={{
                  className:
                    'block w-full border-0 border-white/50  bg-primary-medium-grey focus:border-white focus:ring-0 text-right !pl-10 focus::!bg-black',
                }}
              />
            </span>
            {errors.phone && (
              <>
                <BsFillExclamationCircleFill className='absolute right-3 top-[1rem] w-15 !text-xl text-primary-dark-red' />
                <p className='absolute left-1 -bottom-5 text-[12px] text-primary-dark-red'>
                  {errors.phone}
                </p>
              </>
            )}
          </div>
        </div>
        <div className='input-wrapper relative'>
          <label htmlFor='email' className='tw-label'>
            *{translate('handlesScreen.email')}:
          </label>
          <input
            className={`tw-input-primary relative  ${
              errors.email ? '!border-primary-dark-red' : ''
            }`}
            id='email'
            type='text'
            autoComplete={'off'}
            value={addRefferal?.referee?.contactInfo?.email}
            placeholder={translate('handlesScreen.enterEmail')}
            onChange={e => {
              const copy = { ...addRefferal };
              copy.referee.contactInfo.email = e.target.value;
              setAddRefferal(copy);
              if (e.target.value) {
                setErrors({ ...errors, email: '' });
              }
            }}
          />
          {errors.email ? (
            <>
              <BsFillExclamationCircleFill className='absolute right-3 top-11 w-15 !text-xl text-primary-dark-red' />
              <p className='absolute left-1 -bottom-5 text-[12px] text-primary-dark-red'>
                {errors.email}
              </p>
            </>
          ) : addRefferal?.referee?.contactInfo?.email &&
            !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/g.test(
              addRefferal?.referee?.contactInfo?.email as string
            ) ? (
            <>
              <BsFillExclamationCircleFill className='absolute right-3 top-11 w-15 !text-xl text-primary-dark-red' />
              <p className='absolute left-1 -bottom-5 text-[12px] text-primary-dark-red'>
                {translate('common.isUnvalid')}
              </p>
            </>
          ) : (
            ''
          )}
        </div>
        <hr className={`border-2 border-primary-light-grey w-full ${errors.email ? 'mt-2' : ''}`} />{' '}
        <p className='font-semibold mb-2 text-[16px] text-primary-light-grey'>
          {translate('handlesScreen.socialMedia')}
        </p>
        {addRefferal?.referee?.handles.map((item: any, index: number) => (
          <div className='flex gap-4 w-full' key={index}>
            <div className='flex flex-col gap-4 w-full'>
              <label htmlFor='email' className='tw-label -mb-2'>
                *{translate('common.handle')}:
              </label>
              <div className='flex gap-4 w-full'>
                <div className='flex flex-col gap-6 w-full'>
                  <div className='flex flex-col relative'>
                    <Select
                      options={SocialMediaList}
                      className={`bg-primary-medium-grey relative w-full shadow-sm pl-3 text-left cursor-pointer focus:outline-none sm:text-sm border-2 h-12 rounded  ${
                        errors?.handles?.[index]?.socialMedia
                          ? '!border-primary-dark-red'
                          : 'border-primary-light-grey'
                      }`}
                      placeholderStyle='!text-[16px] pl-2'
                      value={item?.socialMedia}
                      // preValue={item?.socialMedia && ''}
                      placeholder={`${translate('handlesScreen.selectSocialMedia')}`}
                      menuBg='bg-primary-medium-grey mt-[10px] border-2 !text-base rounded border-primary-orange'
                      focusOnOpen={true}
                      onChange={e => {
                        const select = { ...addRefferal };
                        select.referee.handles[index].socialMedia = e?.value;
                        setAddRefferal(select);
                        if (e?.value) {
                          setErrors((prev: any) => {
                            const err = { ...prev };
                            err.handles[index].socialMedia = '';
                            return err;
                          });
                        }
                      }}
                    />
                    {errors?.handles?.[index]?.socialMedia && (
                      <>
                        <p className='absolute left-1 -bottom-5 text-[12px] text-primary-dark-red'>
                          {errors?.handles?.[index]?.socialMedia}
                        </p>
                      </>
                    )}
                  </div>
                  <div className='flex flex-col relative'>
                    <input
                      placeholder={translate('handlesScreen.enterHandle')}
                      className={`tw-input-primary  ${
                        errors?.handles?.[index]?.handle ? '!border-primary-dark-red' : ''
                      }`}
                      value={item?.handle}
                      onChange={e => {
                        const select = { ...addRefferal };
                        select.referee.handles[index].handle = e.target?.value;
                        setAddRefferal(select);
                        if (e.target.value) {
                          setErrors((prev: any) => {
                            const err = { ...prev };
                            err.handles[index].handle = '';
                            return err;
                          });
                        }
                      }}
                    />
                    {errors?.handles?.[index]?.handle && (
                      <>
                        <BsFillExclamationCircleFill className='absolute right-3 top-4 w-15 !text-xl text-primary-dark-red' />
                        <p className='absolute left-1 -bottom-5 text-[12px] text-primary-dark-red'>
                          {errors?.handles[index].handle}
                        </p>
                      </>
                    )}
                  </div>
                  <div className='flex flex-col relative'>
                    <input
                      maxLength={15}
                      placeholder={translate('handlesScreen.enterNumberOfFollowers')}
                      className={`tw-input-primary  ${
                        errors?.handles?.[index]?.followers ? '!border-primary-dark-red' : ''
                      }`}
                      value={item?.followers ? item?.followers?.toString() : ''}
                      onChange={e => {
                        const { value } = e.target;
                        if (isNaN(+value)) return;
                        const select = { ...addRefferal };
                        select.referee.handles[index].followers = +value;
                        setAddRefferal(select);
                        if (e.target.value) {
                          setErrors((prev: any) => {
                            const err = { ...prev };
                            err.handles[index].followers = '';
                            return err;
                          });
                        }
                      }}
                    />
                    {errors?.handles?.[index]?.followers && (
                      <>
                        <BsFillExclamationCircleFill className='absolute right-3 top-4 w-15 !text-xl text-primary-dark-red' />
                        <p className='absolute left-1 -bottom-5 text-[12px] text-primary-dark-red'>
                          {errors?.handles?.[index]?.followers}
                        </p>
                      </>
                    )}
                  </div>
                </div>
                {addRefferal?.referee?.handles?.length !== 1 && (
                  <div className=' flex justify-end'>
                    <button
                      type='button'
                      className='uppercase sm:text-xl text-primary-orange'
                      onClick={() => deleteSocialMedia(index)}
                    >
                      <ImCancelCircle />
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
        {/* <div className='relative flex justify-start items-center '> */}
        <div
          className='flex justify-start items-center w-6 h-6 rounded-full bg-white cursor-pointer'
          onClick={addSocialMedia}
        >
          <AiOutlinePlus className='text-black text-3xl' />
          {/* </div> */}
        </div>
        <hr className='border-2 border-primary-light-grey w-full sm:hidden' />
        <div className='w-full flex flex-col-reverse sm:flex-row justify-center gap-5 mb-6'>
          <button
            type='button'
            onClick={() => {
              setIsSubmit(true);
              validateInputs();
            }}
            disabled={loading}
            className='tw-input-orange-dark-secondary !bg-transparent !text-center !w-full sm:!w-72'
          >
            {translate('handlesScreen.submitForApproval')}
          </button>
          <button
            type='button'
            onClick={() => {
              setIsSubmit(false);
              validateInputs();
            }}
            disabled={loading}
            className='tw-button-light-blue !w-full sm:!w-72'
          >
            {translate('handlesScreen.saveAndContinueLater')}
          </button>
        </div>
      </div>
    </>
  );
};
