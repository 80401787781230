import { useEffect, useState } from 'react';
import { changeAWSToCDN } from 'utils/helperFunctions';

const useBinary = ({ src }: { src?: string | { uri: string } | null }) => {
  const [signedSrc, setSignedSrc] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsError(false);
    setIsLoading(false);

    if (!src) {
      setSignedSrc(null);
      return;
    }

    setSignedSrc(changeAWSToCDN(src));

    setIsLoading(true);
  }, [src]);

  const onError = () => {
    setIsError(true);
    setIsLoading(false);
  };

  const onLoad = () => {
    setIsLoading(false);
  };

  return { signedSrc, isLoading, isError, onError, onLoad };
};

export default useBinary;
