type Props = {
  crumbsArray: any[];
  screen: number;
  state?: boolean;
};

export const AddHandleCrumbs = (props: Props) => {
  const { crumbsArray, screen, state } = props;
  return (
    <div className={`flex w-full items-center justify-center flex-wrap gap-2 sm:gap-3 mt-4 `}>
      {crumbsArray.map((crumb: any) => (
        <div className='relative' key={crumb?.screen}>
          <p className='absolute w-full text-[16px] font-bold text-center justify-center h-full flex items-center'>
            {crumb.name}
          </p>
          <svg
            width={!state ? '166' : '190'}
            height={!state ? '55' : '55'}
            viewBox={!state ? '0 0 166 51' : '0 0 190 51'}
            className=' hidden md:block '
            fill={crumb.screen < screen ? '#E66752' : crumb.screen > screen ? '#6F7577' : ''}
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d={
                !state
                  ? 'M0 0H159.011L166 25.5L159.011 51H0L6.98947 25.5L0 0Z'
                  : 'M0 0H182L190 25.5L182 51H0L8 25.5L0 0Z'
              }
              fill=''
              stroke={crumb.screen === screen ? '#E66752' : ''}
              strokeWidth='2'
            />
          </svg>
          <svg
            width={!state ? '100' : '162'}
            height={!state ? '40' : '40'}
            viewBox={!state ? '0 0 82 36' : '0 0 172 36'}
            className=' block md:hidden '
            fill={crumb.screen < screen ? '#E66752' : crumb.screen > screen ? '#6F7577' : ''}
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d={
                !state
                  ? 'M0 0H78.5474L82 18L78.5474 36H0L3.45263 18L0 0Z'
                  : 'M0 0H164.758L172 18L164.758 36H0L7.24211 18L0 0Z'
              }
              fill=''
              stroke={crumb.screen === screen ? '#E66752' : ''}
              strokeWidth='2'
            />
          </svg>
        </div>
      ))}
    </div>
  );
};
