import axios from 'axios';
import { GeneralParams } from '../types';

export const KbServices = {
  countriesList: async (params: GeneralParams = {}): Promise<any> => {
    const endPoint = `kb/countries`;
    try {
      const list = await axios.get(endPoint, { params });
      return list;
    } catch (err) {
      throw err;
    }
  },
  citiesList: async (params: GeneralParams = {}): Promise<any> => {
    const endPoint = `kb/cities`;
    try {
      const list = await axios.get(endPoint, { params });
      return list;
    } catch (err) {
      throw err;
    }
  },
};
