import React from 'react';

export type DetailsListingView = {
  key: string;
  title?: string | React.ReactNode;
  titleClassName?: string;
  required?: boolean;
  className?: string;
  style?: React.CSSProperties;
  render?: (val: any, data: any) => number | string | JSX.Element | null | undefined;
};

type Props = {
  data: any;
  viewArray: DetailsListingView[];
  containerClassName?: string;
};

const returnFinalData = (data: any, key: string) => {
  const splitKeys = key?.split('.');
  let finalValue: any = data;
  for (let i = 0; i < splitKeys?.length; i++) {
    finalValue = finalValue?.[splitKeys?.[i]];
  }
  return finalValue;
};

export const DetailsListing = (props: Props) => {
  const { viewArray, data, containerClassName = '' } = props;
  return (
    <div className={`flex flex-col gap-4 divide-y divide-primary-light-grey ${containerClassName}`}>
      {viewArray?.map((val, idx) => {
        return val ? (
          <React.Fragment key={val?.key ?? idx}>
            <div
              style={val?.style ?? {}}
              className={`pt-4 flex items-center ${val?.className ?? ''}`}
            >
              {val?.title ? (
                <label
                  className={`text-primary-orange text-2xl font-bold w-32 pr-2 ${
                    val?.titleClassName ?? ''
                  }`}
                >
                  {val?.required ? '*' : ''}
                  {val?.title}:
                </label>
              ) : null}
              {val?.render ? (
                <div
                  className={`${
                    !val?.title ? 'w-full' : 'w-[calc(100%-128px)]'
                  } break-words text-2xl`}
                >
                  {val?.render(returnFinalData(data, val?.key), data)}
                </div>
              ) : (
                <div className='text-2xl break-words flex-1 whitespace-break-spaces w-[calc(100%-128px)]'>
                  {returnFinalData(data, val?.key) ?? '-'}
                </div>
              )}
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment key={idx}></React.Fragment>
        );
      })}
    </div>
  );
};
