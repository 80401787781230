import { Fragment, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import { useStore } from 'services/context';
import { switchLanguage } from 'services/context/Auth/actions';
import { classNames, translate } from 'utils/helperFunctions';
import ProfileImage from 'assets/images/ProfileImage.svg';
import { useTranslation } from 'react-i18next';
import { Modal } from 'components/shared';
import { AuthSessionServices } from 'services/apis/AuthSession';
import { Image } from 'components/shared/Binary/Binary';

export const TopbarMenu = () => {
  const [state, dispatch] = useStore();
  const { userData } = state.authStore;
  const { pathname } = useLocation();
  const [visible, setVisible] = useState<boolean>(false);
  const { i18n } = useTranslation();

  const onLogout = async () => {
    await AuthSessionServices.clearSession();
  };

  const getSwitchedToLang = () => (i18n.language.startsWith('en') ? 'French' : 'English');

  const onChangeLanguage = () => {
    const lang = i18n.language.startsWith('en') ? 'fr' : 'en';
    i18n.changeLanguage(lang);
    dispatch(switchLanguage(lang));
    setVisible(false);
  };

  const logoutHandler = () => {
    if (pathname.includes('onboarding-wizard')) {
      if (
        pathname.includes('new') ||
        pathname.includes('inProgress') ||
        pathname.includes('waitingForSignature') ||
        pathname.includes('notSigned')
      ) {
        return '/login';
      } else {
        return '#';
      }
    } else if (pathname.includes('new-handle') || pathname.includes('new-referral')) {
      return '/login';
    } else {
      return '#';
    }
  };

  const _renderConfirmChangeLanguage = () => (
    <Modal visible={visible} onClose={() => setVisible(false)}>
      <p className='text-primary-leaf text-3xl font-black text-center mx-4 '>
        {translate('topBarMenu.confirmChangeLanguage')} {getSwitchedToLang()}?
      </p>
      <div className='grid grid-cols-1 sm:grid-cols-2 gap-y-3 items-center justify-center mt-6'>
        <div className='order-2 sm:-order-1 mx-auto'>
          <button className='tw-button-secondary' onClick={() => setVisible(false)}>
            {translate('common.cancel')}
          </button>
        </div>
        <div className='mx-auto'>
          <button className='tw-button-light-blue' onClick={() => onChangeLanguage()}>
            {translate('common.confirm')}
          </button>
        </div>
      </div>
    </Modal>
  );

  return (
    <>
      {_renderConfirmChangeLanguage()}
      <Menu as='div' className='relative z-20'>
        {({ open }) => (
          <div>
            <div>
              <Menu.Button
                className={classNames(
                  'border-primary-orange border inline-flex items-center rounded-[4px] text-base font-medium focus:outline-none w-[60px] h-[60px] md:w-auto md:px-5 md:py-2 capitalize text-white hover:bg-primary-light-grey/5'
                )}
              >
                <div className='flex items-center justify-center h-10 w-10 rounded-full font-black bg-primary-dark-grey m-auto text-white'>
                  <Image
                    src={userData?.profile?.logo || ProfileImage}
                    alt='profile'
                    containerClassName='!stroke-white h-8 w-8 rounded-full'
                  />
                </div>
                <p className='hidden md:block text-[18px] pl-4 font-meduim leading-6 text-primary-sand'>
                  {userData.firstName} {userData.lastName}
                </p>

                {open ? (
                  <ChevronUpIcon
                    className={classNames(
                      'hidden md:flex items-center text-primary-sand h-9 w-9 space-x-2 pl-4'
                    )}
                    aria-hidden='true'
                  />
                ) : (
                  <ChevronDownIcon
                    className={classNames(
                      'hidden md:flex items-center text-primary-sand h-9 w-9 space-x-2 pl-4  '
                    )}
                    aria-hidden='true'
                  />
                )}
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter='transition ease-out duration-100'
              enterFrom='transform opacity-0 scale-95'
              enterTo='transform opacity-100 scale-100'
              leave='transition ease-in duration-75'
              leaveFrom='transform opacity-100 scale-100'
              leaveTo='transform opacity-0 scale-95'
            >
              <Menu.Items className='border border-primary-orange bg-primary-medium-grey absolute right-0 md:left-1/2 z-10 mt-3 w-[252px] md:w-full md:-translate-x-1/2 transform px-0 py-3 md:px-0 overflow-hidden rounded-[4px]'>
                <Menu.Item>
                  <NavLink
                    to={'/home/profile'}
                    className='hover:font-bold hover:bg-primary-dark-grey/50 block px-4 py-2 text-lg text-white capitalize cursor-pointer'
                  >
                    {translate(`common.profile`)}
                  </NavLink>
                </Menu.Item>
                {/* <Menu.Item>
                  <div
                    className='flex items-center justify-between cursor-pointer px-4 py-2 6 text-primary-sand text-lg capitalize hover:stroke-primary-sand stroke-primary-sand stroke-[0.5px] hover:stroke-[1px] hover:font-bold hover:bg-primary-sand/50'
                    onClick={() => setVisible(true)}
                  >
                    <span>{getSwitchedToLang()}</span>
                    <GlobeIcon className='w-6' />
                  </div>
                </Menu.Item> */}
                <Menu.Item>
                  <Link
                    to={`${logoutHandler()}`}
                    className='hover:font-bold hover:bg-primary-dark-grey/50 block px-4 py-2 text-lg text-white capitalize cursor-pointer'
                    onClick={onLogout}
                  >
                    <div>{translate(`common.logout`)}</div>
                  </Link>
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </div>
        )}
      </Menu>
    </>
  );
};
