import { AclHitted, setIsAclLoading } from 'services/context/Auth/actions';
import { useStore } from 'services/context';
import { clearUserKeyACL } from 'utils/StorageServices';
import { createUserAbilities, updateAbilities } from 'services/context/abilities/actions';
import { PureAbility } from '@casl/ability';

export default function useRefetchAbilities() {
  const [, dispatch] = useStore();
  const ability = new PureAbility();

  const refetch = async () => {
    try {
      dispatch(setIsAclLoading(true));
      clearUserKeyACL();
      await createUserAbilities(ability);
      dispatch(updateAbilities(ability));
      dispatch(AclHitted(true));
      dispatch(setIsAclLoading(false));
    } catch (err) {
      dispatch(setIsAclLoading(false));
    }
  };

  return refetch;
}
