import { CustomRadio, Select } from 'components/shared';
import React, { useEffect, useState } from 'react';
import { AiFillCaretLeft } from 'react-icons/ai';
import { useNavigate, useParams } from 'react-router-dom';
import { translate } from 'utils/helperFunctions';
import { AddInfluencer } from './AddReferralsFields/addInfluencer';
import { AddCompany } from './AddReferralsFields/addCompany';
import { AddAmbassador } from './AddReferralsFields/addAmbassador';
import { AddAgency } from './AddReferralsFields/addAgency';
import usePrompt from 'utils/hooks/usePrompt';
import { ReferralServices } from 'services/apis/Referral';
import { toast } from 'react-toastify';
import { Splash } from 'Pages';
import { UseAbility } from 'services/context';

export const AddReferral = () => {
  const { membership, referralId } = useParams();

  const canAddInfluencerReferral = UseAbility('influencerReferral', 'add');
  const canAddAgencyReferral = UseAbility('agencyReferral', 'add');
  const canAddAmbassadorReferral = UseAbility('ambassadorReferral', 'add');

  const options = [
    { label: 'Investor-Creator', value: 'influencer', can: canAddInfluencerReferral },
    { label: 'Ambassador', value: 'ambassador', can: canAddAmbassadorReferral },
    { label: 'Agency', value: 'agency', can: canAddAgencyReferral },
  ];
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [memeberOrCompany, setMemberOrCompany] = useState<string>('');
  const [selectedMembership, setSelectedMembership] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const { _renderModalDiscardChanges, setIsBlocking } = usePrompt({
    message: translate('discardModal.areYouSureYouWantToExitTheOnboardingProcess'),
    okText: translate('common.exit'),
    cancelText: translate('common.cancel'),
  });

  const fetchRferraldetails = async () => {
    if (referralId) {
      try {
        const detail = await ReferralServices.referralDetails(referralId);
        setMemberOrCompany(detail.type);
        setSelectedMembership(detail.referee.membership);
      } catch (errors: any) {
        toast.error(errors?.message);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    if (referralId !== 'new') {
      fetchRferraldetails();
    } else {
      setMemberOrCompany('member');
      setLoading(false);
    }
    setIsBlocking(!isSubmitted);
  }, [isSubmitted]);

  function filterOptions() {
    if (membership === 'influencer') {
      return options.filter(option => option.value === 'brand');
    } else if (membership === 'ambassador') {
      return options.filter(option => option.value !== 'ambassador' && option.can);
    } else if (membership === 'agency') {
      return options.filter(
        option => option.value !== 'ambassador' && option.value !== 'agency' && option.can
      );
    } else {
      return options.filter(option => option.can);
    }
  }

  const _renderAddRefferal = () => {
    switch (selectedMembership) {
      case 'influencer':
        return <AddInfluencer setIsSubmitted={setIsSubmitted} />;
      case 'agency':
        return <AddAgency setIsSubmitted={setIsSubmitted} />;
      default:
        return (
          <AddAmbassador
            selectedMembership={selectedMembership}
            setMemberShipError={setError}
            setIsSubmitted={setIsSubmitted}
          />
        );
    }
  };

  return (
    <>
      {_renderModalDiscardChanges()}
      <div className='font-Condensed flex justify-start items-center text-primary-light-grey text-3xl mb-5'>
        <div className=''>
          <AiFillCaretLeft
            className='text-5xl cursor-pointer hover:filter hover:brightness-125 hover:contrast-125 transition-all duration-300 hover:scale-[1.1]'
            onClick={() => {
              navigate(-1);
            }}
          />
        </div>
        <div>
          {referralId !== 'new'
            ? translate('handlesScreen.editReferral')
            : translate('handlesScreen.addNewReferral')}
        </div>
      </div>

      <div className='border border-primary-blue px-4 bg-primary-dark-grey'>
        {!loading ? (
          <div className='flex flex-col mt-6 gap-6'>
            <p className='font-[600] text-[16px] text-primary-light-grey'>
              {translate('common.details')}
            </p>
            {membership !== 'venturePartner' && (
              <div className='flex flex-col gap-4'>
                <p className='font-[600] text-[16px] text-primary-light-grey w-4'>
                  {translate('handlesScreen.type')}
                </p>
                <div className=''>
                  <CustomRadio
                    wrapperClassName='!flex-row shrink-0'
                    labelClassName='!font-normal !text-[16px] !text-primary-light-grey !ml-2'
                    options={
                      membership === 'influencer'
                        ? [
                            {
                              value: 'company',
                              label: `${translate('handlesScreen.company')}`,
                              disabled: referralId !== 'new',
                            },
                          ]
                        : [
                            {
                              value: 'member',
                              label: `${translate('handlesScreen.member')}`,
                              disabled: referralId !== 'new',
                            },
                            {
                              value: 'company',
                              label: `${translate('handlesScreen.company')}`,
                              disabled: referralId !== 'new',
                            },
                          ]
                    }
                    value={membership === 'influencer' ? 'company' : memeberOrCompany}
                    onChange={e => {
                      setMemberOrCompany(e.value);
                      // setSelectedMembership('');
                      setError('');
                    }}
                  />
                </div>
              </div>
            )}
            {memeberOrCompany === 'company' ||
            membership === 'influencer' ||
            membership === 'venturePartner' ? (
              <AddCompany setIsSubmitted={setIsSubmitted} />
            ) : (
              <>
                <div className='input-wrapper relative'>
                  <label htmlFor='firstName' className='tw-label capitalize'>
                    *{translate('common.membership')}:
                  </label>
                  <Select
                    options={filterOptions()}
                    className={`bg-primary-medium-grey relative w-full shadow-sm pl-3 text-left cursor-pointer focus:outline-none sm:text-sm border-2 h-12 rounded  disabled:opacity-50 disabled:cursor-not-allowed  ${
                      error ? '!border-primary-dark-red' : 'border-primary-light-grey'
                    }`}
                    placeholderStyle='!text-[16px] pl-2'
                    value={selectedMembership || ''}
                    focusOnOpen={true}
                    preValue={selectedMembership && ''}
                    placeholder={translate('handlesScreen.selectMembership')}
                    menuBg='bg-primary-medium-grey mt-[10px] border-2 !text-base rounded border-primary-orange'
                    onChange={(e: any) => {
                      setSelectedMembership(e?.value as string);
                      if (e?.value) {
                        setError('');
                      }
                    }}
                    disabled={referralId !== 'new'}
                  />
                  {error && (
                    <p className='absolute left-1 -bottom-5 text-[12px] text-primary-dark-red'>
                      {error}
                    </p>
                  )}
                </div>
                {_renderAddRefferal()}
              </>
            )}
          </div>
        ) : (
          <div className='h-[400px] flex justify-center items-center'>
            <Splash />
          </div>
        )}
      </div>
    </>
  );
};
