export const initStateAbilities: any = {};

export type AbilitiesState = typeof initStateAbilities;

const AbilitiesReducer = (action: any, state: AbilitiesState = initStateAbilities) => {
  switch (action?.type) {
    case 'CHANGE_ABILITY':
      return action.payload;
    default:
      return state;
  }
};

export default AbilitiesReducer;
