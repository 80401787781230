import { useEffect, useState } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
type Props = {
  className?: string;
  onChange: (value: boolean) => void;
  initialTime: number;
};
export const CountdownCircle: React.FC<Props> = ({ className = '', initialTime, onChange }) => {
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  // const minuteSeconds = initialTime;
  const timerProps = {
    isPlaying: true,
    size: 20,
  };

  const getTimeSeconds = (time: any) => (initialTime - time) | 0;

  useEffect(() => {
    onChange(isCompleted);
  }, [isCompleted]);
  return (
    <div className={className}>
      <CountdownCircleTimer
        {...timerProps}
        colors='#6F7577'
        initialRemainingTime={initialTime}
        trailColor='#E66752'
        size={28}
        strokeWidth={4}
        duration={initialTime}
        onComplete={_totalElapsedTime => {
          setIsCompleted(true);
        }}
      >
        {({ elapsedTime, color }) => (
          <div style={{ color }}>
            <div className=''>
              <div className='text-primary-orange text-xs'>{getTimeSeconds(elapsedTime)}</div>
            </div>
          </div>
        )}
      </CountdownCircleTimer>
    </div>
  );
};
