import { useEffect, useState } from 'react';
import { Splash } from 'Pages';
import { AuthSessionServices } from 'services/apis/AuthSession';
import { getStorageLoginData } from 'utils/StorageServices';
import { setLoginData } from 'services/context/Auth/actions';
import store from 'services/context/store';
import AppRouter from 'AppRouter';
import useDisableInputScroll from 'utils/hooks/useDiableInputScroll';

const AuthLoading = () => {
  const [authLoading, setAuthLoading] = useState(true);

  useEffect(() => {
    const loginData = getStorageLoginData();
    store.dispatch(setLoginData(loginData));
    (async () => {
      await AuthSessionServices.createSession();
      setAuthLoading(false);
    })();
  }, []);

  useDisableInputScroll();

  return (
    <>
      {authLoading && <Splash />}
      {!authLoading && <AppRouter />}
    </>
  );
};

export default AuthLoading;
