import { filterAndFormatNumeric, translate } from 'utils/helperFunctions';
import { AddHandleState, Schema } from 'services/apis/Handle/handle.types';
import Select from 'react-select';
import { useCallback, useEffect, useState } from 'react';
import { HandleServices } from 'services/apis/Handle';
import { toast } from 'react-toastify';
import { DatePicker, Spiner } from 'components/shared';
import { useLocation, useParams } from 'react-router-dom';
import { KbServices } from 'services/apis/Kb';
import { debounce } from 'utils/helperFunctions';
import { CitiesType, CountriesType } from 'services/apis/Kb/Kb.types';

type Filter = {
  keywords: string;
  skip: number;
  limit: number;
};

type Option = {
  value: string;
  label: string;
};

type Props = {
  onChange: React.Dispatch<React.SetStateAction<AddHandleState>>;
  addHandle: AddHandleState;
  setSchemaVersion: (value: number) => void;
  setTotalError: React.Dispatch<React.SetStateAction<boolean>>;
};

const selectStyles = {
  control: () => ({
    boxShadow: 'none',
    display: 'flex',
    border: '2px solid #6F7577',
    borderRadius: '4px',
    minHeight: '48px',
    backgroundColor: '#202020',
    '&:focus-within': {
      borderColor: '#E66752',
      outline: 'none',
    },
    '&:hover': {
      borderColor: 'none',
    },
  }),
  input: (provided: any) => ({
    ...provided,
    width: '100%',
    color: 'white',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: 'white',
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    borderTop: 'none',
    borderRight: 'none',
    borderBottom: 'none',
    borderLeft: 'none',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    color: 'white',
    backgroundColor: state.isSelected ? 'rgba(111, 117, 119, 0.1)' : '#171717',
  }),
  menu: (provided: any) => ({
    ...provided,
    backgroundColor: '#171717',
    border: '2px solid #E66752',
  }),
};

type SchemaObject = {
  version: number;
  schema: Schema;
};

export const AddHandleInsights = (props: Props) => {
  const { state } = useLocation();
  const { onChange, addHandle, setTotalError, setSchemaVersion } = props;
  const { handleId } = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [countries, setCountries] = useState<CountriesType[]>([]);
  const [cities, setCities] = useState<CitiesType[]>([]);
  const [citiesLoadingStates, setCitiesLoadingStates] = useState<boolean[]>([]);
  const [countriesLoadingStates, setCountriesLoadingStates] = useState<boolean[]>([]);
  const [errors, setErrors] = useState<AddHandleState>({
    handle: '',
    category: '',
    subCategory: '',
    followers: '',
    socialMedia: '',
    date: new Date().getTime(),
    description: '',
    url: '',
    metadata: {
      lightsYourFire: [],
      turnsYouOff: [],
      partnerships: [],
      aboutYourPeople: [],
      stats: [
        {
          type: '',
          category: '',
          values: [],
        },
      ],
    },
  });

  const [schemaList, setSchemaList] = useState<SchemaObject>({
    version: 0,
    schema: [],
  });
  const [citiesFilter, setCitiesFilter] = useState<Filter>({
    keywords: '',
    skip: 0,
    limit: 20,
  });
  const [countriesFilter, setCountriesFilter] = useState<Filter>({
    keywords: '',
    skip: 0,
    limit: 20,
  });

  const Countryoptions: Option[] = countries?.map(({ name }) => ({
    value: name,
    label: name,
  }));

  const Cityoptions: Option[] = cities?.map(({ name }) => ({
    value: name,
    label: name,
  }));

  useEffect(() => {
    setCitiesLoadingStates(new Array(addHandle?.metadata?.stats?.[3]?.values?.length).fill(false));
    setCountriesLoadingStates(
      new Array(addHandle?.metadata?.stats?.[2]?.values?.length).fill(false)
    );
  }, []);

  const getTotal = (categoryIndex: number) => {
    if (addHandle?.metadata?.stats?.[categoryIndex]) {
      const total = addHandle?.metadata?.stats?.[categoryIndex].values.reduce((sum, val) => {
        return sum + (val.value || 0);
      }, 0);
      return total;
    }
    return null;
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    categoryIndex: number,
    propertyGroupIndex: number,
    propertyGroup: any,
    isSelectAndInput = false
  ) => {
    let value = e.target.value;
    const parts = value.split('.');

    if (parts.length > 1 && parts[1].length > 1) {
      return;
    }
    if (value === '' || isNaN(parseFloat(value))) {
      value = '0';
    }
    if (value === '' || isNaN(parseFloat(value))) {
      value = '';
    }

    if (
      value === '' ||
      (!isNaN(parseFloat(value)) &&
        parseFloat(value) >= propertyGroup.minimum &&
        parseFloat(value) <= propertyGroup.maximum)
    ) {
      const percentageToDecimal = (percentage: string) => parseFloat(percentage) / 100;
      const upd = { ...addHandle };
      if (isSelectAndInput) {
        if (upd?.metadata?.stats?.[categoryIndex]?.values?.[propertyGroupIndex]?.key) {
          upd.metadata.stats[categoryIndex].values[propertyGroupIndex].value =
            percentageToDecimal(value);
        } else {
          upd.metadata.stats[categoryIndex].values[propertyGroupIndex].key =
            upd.metadata.stats[categoryIndex].category === 'city'
              ? Cityoptions[0].label
              : Countryoptions[0].label;
          upd.metadata.stats[categoryIndex].values[propertyGroupIndex].value =
            percentageToDecimal(value);
        }
        const total = getTotal(categoryIndex);
        if (total && total > 1) {
          setErrors((err: any) => {
            const copy = { ...err };
            copy.metadata.stats[categoryIndex].values[propertyGroupIndex].value = `*${translate(
              'handlesScreen.theSumOfStatsMustNotExceed100'
            )}`;
            return copy;
          });
          setTotalError(true);
        } else {
          setErrors((err: any) => {
            const copy = { ...err };
            copy.metadata.stats?.[categoryIndex]?.values.forEach(
              (v: { value: string }) => (v.value = '')
            );
            return copy;
          });
          setTotalError(false);
        }
      } else {
        upd.metadata.stats[categoryIndex].values[propertyGroupIndex].key = propertyGroup.key;
        upd.metadata.stats[categoryIndex].values[propertyGroupIndex].value =
          percentageToDecimal(value);
        const total = getTotal(categoryIndex);
        if (total && total > 1) {
          setErrors((err: any) => {
            const copy = { ...err };
            copy.metadata.stats[categoryIndex].values[propertyGroupIndex].value = `*${translate(
              'handlesScreen.theSumOfStatsMustNotExceed100'
            )}`;
            return copy;
          });
          setTotalError(true);
        } else {
          setErrors((err: any) => {
            const copy = { ...err };
            copy.metadata.stats?.[categoryIndex]?.values.forEach(
              (v: { value: string }) => (v.value = '')
            );
            return copy;
          });
          setTotalError(false);
        }
      }
      onChange(upd);
    }
  };

  const onChangeInfo = (data: AddHandleState) => {
    onChange(data);
  };

  const handleState = (schema: Schema, data: AddHandleState = addHandle) => {
    const newError = JSON.parse(JSON.stringify(data));
    if (handleId === 'new') {
      if (schema) {
        const updatedSchema = schema.reduce(
          (acc, categoryItem, categoryIndex) => {
            const updatedHandleState = {
              ...acc.updatedHandleState,
              metadata: data?.metadata || { stats: [] },
            };
            updatedHandleState.metadata.stats[categoryIndex] = {
              type: categoryItem.type,
              category: categoryItem.category,
              values: [],
            };
            const updatedErrors = { ...acc.updatedErrors };
            updatedErrors.metadata.stats[categoryIndex] = {
              type: categoryItem.type,
              category: categoryItem.category,
              values: [],
            };

            categoryItem.properties?.forEach(prop => {
              if (categoryItem.category !== 'age' && categoryItem.category !== 'gender') {
                updatedHandleState.metadata.stats[categoryIndex].values.push({ key: '', value: 0 });
                updatedErrors.metadata.stats[categoryIndex].values.push({
                  key: '',
                  value: '',
                });
              } else {
                if ('key' in prop) {
                  updatedHandleState.metadata.stats[categoryIndex].values.push({
                    key: prop.key || '',
                    value: 0,
                  });

                  updatedErrors.metadata.stats[categoryIndex].values.push({
                    key: prop.key || '',
                    value: '',
                  });
                }
              }
            });

            return { ...acc, updatedHandleState, updatedErrors };
          },
          { updatedHandleState: data, updatedErrors: newError }
        );
        onChange(updatedSchema.updatedHandleState);
        setErrors(updatedSchema.updatedErrors);
      }
    } else {
      if (data?.handle && !data?.metadata?.hasOwnProperty('stats')) {
        onChange(() => ({
          ...data,
          metadata: {
            ...data.metadata,
            stats: [
              {
                type: '',
                category: '',
                values: [],
              },
            ],
          },
        }));
        setErrors(() => ({
          ...data,
          metadata: {
            ...data.metadata,
            stats: [
              {
                type: '',
                category: '',
                values: [],
              },
            ],
          },
        }));
        if (schema) {
          schema.forEach((categoryItem, categoryIndex) => {
            onChange(prev => {
              const copy = { ...prev };
              copy.metadata.stats[categoryIndex] = {
                type: categoryItem.type,
                category: categoryItem.category,
                values: [],
              };
              return copy;
            });
            setErrors(prev => {
              const copy = { ...prev };
              copy.metadata.stats[categoryIndex] = {
                type: categoryItem.type,
                category: categoryItem.category,
                values: [],
              };
              return copy;
            });
            categoryItem.properties?.forEach(prop => {
              if (categoryItem.category !== 'age' && categoryItem.category !== 'gender') {
                onChange(prev => {
                  const copy = { ...prev };
                  copy.metadata.stats[categoryIndex].values.push({ key: '', value: 0 });
                  return copy;
                });
              } else {
                if ('key' in prop) {
                  onChange(prev => {
                    const copy = { ...prev };
                    copy.metadata.stats[categoryIndex].values.push({
                      key: prop.key || '',
                      value: 0,
                    });
                    return copy;
                  });
                  setErrors((prev: any) => {
                    const upd = { ...prev };
                    upd.metadata.stats[categoryIndex].values.push({
                      key: prop.key || '',
                      value: '',
                    });
                    return upd;
                  });
                }
              }
            });
          });
        }
      } else {
        schema.forEach((categoryItem, categoryIndex) => {
          setErrors(() => {
            const copy = { ...newError };
            copy.metadata.stats[categoryIndex] = {
              type: categoryItem.type,
              category: categoryItem.category,
              values: [],
            };
            return copy;
          });
          categoryItem.properties?.forEach(prop => {
            if (categoryItem.category !== 'age' && categoryItem.category !== 'gender') {
              setErrors((prev: any) => {
                const upd = { ...prev };
                upd.metadata.stats[categoryIndex].values.push({
                  key: '',
                  value: '',
                });
                return upd;
              });
            } else {
              if ('key' in prop) {
                setErrors((prev: any) => {
                  const upd = { ...prev };
                  upd.metadata.stats[categoryIndex].values.push({
                    key: prop.key || '',
                    value: '',
                  });
                  return upd;
                });
              }
            }
          });
        });
      }
    }
  };

  const getSchema = async (data?: AddHandleState) => {
    try {
      if (data?.socialMedia) {
        const formSchema = await HandleServices.statsSchema(data.socialMedia);
        setSchemaList({ version: formSchema?.version, schema: formSchema?.schema });
        setSchemaVersion(formSchema?.version);
        if (formSchema.schema) {
          handleState(formSchema.schema, data);
        }
      }
    } catch (error: any) {
      toast.error(error?.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchHandledetails = async () => {
    if (handleId && handleId !== 'new' && state.isAddStats) {
      setLoading(true);
      try {
        const detail: any = await HandleServices.handleDetails(handleId);
        onChange(detail);
        if (detail.socialMedia) {
          getSchema(detail);
        }
      } catch (error: any) {
        toast.error(error?.message);
      }
    }
  };

  const fetchCountries = useCallback(
    async (params = {}, index = 0) => {
      const finalParams = { ...countriesFilter, ...params };
      try {
        setCountriesLoadingStates(prevStates => {
          return prevStates?.map((_, i: number) => {
            if (i === index) {
              prevStates[index] = true;
              return prevStates[index];
            }
            return false;
          });
        });
        const countriesList = await KbServices.countriesList(finalParams);
        setCountries(countriesList?.items);
      } catch (error: any) {
        toast.error(error?.message);
      } finally {
        setCountriesLoadingStates(prevStates => {
          return prevStates?.map((_, i: number) => {
            if (i === index) {
              prevStates[index] = false;
              return prevStates[index];
            }
            return false;
          });
        });
      }
    },
    [countriesFilter]
  );

  const fetchCities = useCallback(
    async (params = {}, index = 0) => {
      const finalParams = { ...citiesFilter, ...params };
      try {
        setCitiesLoadingStates(prevStates => {
          return prevStates?.map((_, i: number) => {
            if (i === index) {
              prevStates[index] = true;
              return prevStates[index];
            }
            return false;
          });
        });
        const citiesList = await KbServices.citiesList(finalParams);
        setCities(citiesList?.items);
      } catch (error: any) {
        toast.error(error?.message);
      } finally {
        setCitiesLoadingStates(prevStates => {
          return prevStates?.map((_, i: number) => {
            if (i === index) {
              prevStates[index] = false;
              return prevStates[index];
            }
            return false;
          });
        });
      }
    },
    [citiesFilter]
  );
  const getDefaultValue = (
    categoryIndex: number,
    categoryItem: Schema[0],
    propertyGroupIndex: number
  ) => {
    const initialValue =
      categoryItem?.category === 'city'
        ? { value: '', label: 'Select City' }
        : { value: '', label: 'Select Country' };
    if (handleId !== 'new') {
      if (addHandle?.metadata?.stats?.[0]?.values?.[0].key) {
        const copy = { ...addHandle };
        const result = copy?.metadata?.stats[categoryIndex]?.values.map(item =>
          item.key
            ? {
                value: item.key,
                label: item.key,
              }
            : initialValue
        );
        return result?.[propertyGroupIndex];
      } else {
        return initialValue;
      }
    } else {
      return initialValue;
    }
  };

  useEffect(() => {
    fetchCountries();
    fetchCities();
  }, []);

  const HandleInputSelectChange = (
    categoryItem: Schema[0],
    value: string,
    propertyGroupIndex: number
  ) => {
    if (categoryItem?.category === 'city') {
      setCitiesFilter({ ...citiesFilter, keywords: value });
      debounce(() => fetchCities({ keywords: value }, propertyGroupIndex));
    } else {
      setCountriesFilter({ ...countriesFilter, keywords: value });
      debounce(() => fetchCountries({ keywords: value }, propertyGroupIndex));
    }
  };

  useEffect(() => {
    if (state.isAddStats) {
      fetchHandledetails();
    } else {
      if (addHandle?.socialMedia) {
        getSchema(addHandle);
      }
    }
  }, [state.isAddStats]);

  const handleOptions = (category: string, idx: number) => {
    const values = addHandle?.metadata?.stats?.[idx]?.values;
    const valuesToFilter = new Set(values?.map(item => item.key)) || [];
    return [...(category === 'city' ? Cityoptions : Countryoptions)].filter(
      item => !valuesToFilter.has(item.value)
    );
  };
  const loadOptions = async (categoryItem: Schema[0], idx: number, propertyGroupIndex: number) => {
    try {
      if (categoryItem?.category === 'city') {
        setCitiesFilter(prevFilter => ({ ...prevFilter, limit: prevFilter.limit + 10 }));
        await fetchCities(
          { ...citiesFilter, skip: 0, limit: citiesFilter.limit + 10 },
          propertyGroupIndex
        );
        return handleOptions(categoryItem?.category, idx);
      } else {
        setCountriesFilter(prevFilter => ({ ...prevFilter, limit: prevFilter.limit + 10 }));
        await fetchCountries(
          { ...countriesFilter, skip: 0, limit: countriesFilter.limit + 10 },
          propertyGroupIndex
        );
        return handleOptions(categoryItem?.category, idx);
      }
    } catch (error) {
      console.error('Error loading options:', error);
      return [];
    }
  };
  const renderCategory = (categoryItem: Schema[0], categoryIndex: number) => {
    return (
      <div key={categoryIndex} className='flex flex-col gap-4'>
        <p className='font-Condensed text-2xl font-bold text-left w-full text-primary-orange'>
          {categoryItem.label}:
        </p>
        <div className='flex flex-col gap-6'>
          {categoryItem?.properties?.map((propertyGroup, propertyGroupIndex: number) => {
            return (
              <div key={propertyGroupIndex} className='flex flex-col gap-6'>
                {Array.isArray(propertyGroup) ? (
                  propertyGroup?.map((property, index) => {
                    return (
                      <div key={index} className='input-wrapper relative text-left '>
                        <label htmlFor={property?.key} className='text-sm font-sans'>
                          *{property?.label}:
                        </label>
                        {property?.element === 'input' ? (
                          <div className='relative'>
                            <input
                              id={property.key}
                              disabled={
                                !addHandle?.metadata?.stats?.[categoryIndex]?.values?.[
                                  propertyGroupIndex
                                ]?.key
                              }
                              placeholder={translate('handlesScreen.entervalue')}
                              className={`tw-input-primary relative ${
                                errors?.metadata?.stats?.[categoryIndex]?.values?.[
                                  propertyGroupIndex
                                ]?.value && '!border-primary-dark-red'
                              } `}
                              value={parseFloat(
                                (
                                  (addHandle?.metadata?.stats?.[categoryIndex]?.values?.[
                                    propertyGroupIndex
                                  ]?.value || 0) * 100
                                )?.toFixed(1)
                              )?.toString()}
                              type='number'
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const isSelectAndInput = true;
                                handleInputChange(
                                  e,
                                  categoryIndex,
                                  propertyGroupIndex,
                                  property,
                                  isSelectAndInput
                                );
                              }}
                            />
                            <span
                              className={`absolute bottom-3.5 right-[20px] text-primary-light-grey ${
                                errors?.metadata?.stats?.[categoryIndex]?.values?.[
                                  propertyGroupIndex
                                ]?.value && '!bottom-[30px]'
                              } `}
                            >
                              %
                            </span>
                            <div className='text-xs text-primary-dark-red'>
                              {
                                errors?.metadata?.stats?.[categoryIndex]?.values?.[
                                  propertyGroupIndex
                                ]?.value
                              }
                            </div>
                          </div>
                        ) : (
                          <Select
                            className='no-ring'
                            styles={selectStyles}
                            isSearchable={true}
                            isLoading={
                              categoryItem?.category === 'city'
                                ? citiesLoadingStates[propertyGroupIndex]
                                : countriesLoadingStates[propertyGroupIndex]
                            }
                            onMenuClose={() => {
                              if (categoryItem?.category === 'city') {
                                fetchCities({}, propertyGroupIndex);
                              } else {
                                fetchCountries({}, propertyGroupIndex);
                              }
                            }}
                            onMenuScrollToBottom={() =>
                              loadOptions(categoryItem, categoryIndex, propertyGroupIndex)
                            }
                            onInputChange={(value: string) => {
                              HandleInputSelectChange(categoryItem, value, propertyGroupIndex);
                            }}
                            options={handleOptions(categoryItem?.category, categoryIndex)}
                            defaultValue={getDefaultValue(
                              categoryIndex,
                              categoryItem,
                              propertyGroupIndex
                            )}
                            onChange={(selectedOption, actionMeta) => {
                              onChange((prev: AddHandleState) => {
                                const upd = { ...prev };
                                if (actionMeta.action === 'select-option') {
                                  upd.metadata.stats[categoryIndex].values[propertyGroupIndex].key =
                                    selectedOption?.label || '';
                                } else if (actionMeta.action === 'clear') {
                                  upd.metadata.stats[categoryIndex].values[propertyGroupIndex].key =
                                    '';
                                }
                                return upd;
                              });
                            }}
                          />
                        )}
                      </div>
                    );
                  })
                ) : (
                  <div className='input-wrapper relative text-left'>
                    <label htmlFor={propertyGroup.key} className='text-sm font-sans'>
                      {propertyGroup.label}:
                    </label>
                    {propertyGroup.element === 'input' && (
                      <div className='relative'>
                        <input
                          id={propertyGroup.key}
                          placeholder={translate('handlesScreen.entervalue')}
                          className={`tw-input-primary relative ${
                            errors?.metadata?.stats?.[categoryIndex]?.values?.[propertyGroupIndex]
                              ?.value && '!border-primary-dark-red'
                          } `}
                          value={
                            parseFloat(
                              (
                                (addHandle?.metadata?.stats?.[categoryIndex]?.values?.[
                                  propertyGroupIndex
                                ]?.value || 0) * 100
                              )?.toFixed(1)
                            )
                              ?.toString()
                              ?.replace(/\.0$|(\.[1-9]+)0+$/, '$1') || ''
                          }
                          type='number'
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            handleInputChange(e, categoryIndex, propertyGroupIndex, propertyGroup);
                          }}
                        />
                        <span
                          className={`absolute bottom-3.5 right-[20px] text-primary-light-grey ${
                            errors?.metadata?.stats?.[categoryIndex]?.values?.[propertyGroupIndex]
                              ?.value && '!bottom-[30px]'
                          } `}
                        >
                          %
                        </span>
                        <div className='text-xs text-primary-dark-red'>
                          {
                            errors?.metadata?.stats?.[categoryIndex]?.values?.[propertyGroupIndex]
                              ?.value
                          }
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div className='flex flex-col justify-center items-center gap-6 mt-4 mb-6 text-center w-full '>
      <div className='flex flex-col gap-6 w-full'>
        <h1 className='font-Condensed  text-[40px] font-bold not-italic'>
          {translate('handlesScreen.audienceInsights')}
        </h1>
        {loading ? (
          <div>
            <div className='h-[400px] flex justify-center items-center'>
              <Spiner />
            </div>
          </div>
        ) : (
          <div>
            <div className='flex flex-col gap-5 mb-6'>
              <p className='font-Condensed text-2xl font-bold text-left w-full text-primary-orange'>
                {translate('handlesScreen.handleBasics')}:
              </p>
              <div className='flex flex-col gap-6'>
                <div className='input-wrapper relative text-left'>
                  <label htmlFor='relation' className='text-sm font-sans'>
                    {translate('handlesScreen.followersNumber')}:
                  </label>
                  <input
                    placeholder={translate('handlesScreen.*Number of convos per month:')}
                    className='tw-input-primary relative'
                    value={addHandle.followers}
                    maxLength={15}
                    type='text'
                    onChange={e => {
                      const filtered = filterAndFormatNumeric(e.target.value);
                      onChangeInfo({ ...addHandle, followers: filtered });
                    }}
                  />
                </div>
                <div className='flex flex-col gap-6'>
                  <div className='input-wrapper relative text-left'>
                    <label htmlFor='relation' className='text-sm font-sans'>
                      {translate('handlesScreen.dateAsOf')}:
                    </label>
                    <DatePicker
                      placeholder={translate('handlesScreen.selectDate')}
                      calendarStyle='bg-primary-medium-grey mt-[20px] border-2 !text-base rounded  border-primary-orange'
                      inputClassName='placeholder:!text-primary-light-grey !bg-primary-medium-grey relative !w-full !border-2 !shadow-sm !pl-3 !text-left !cursor-pointer focus:!outline-none focus:!border-primary-orange !text-[16px] !h-12 !rounded !border-primary-light-grey'
                      value={new Date(addHandle?.date)?.getTime()}
                      onChange={date => {
                        onChangeInfo({ ...addHandle, date: date });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            {schemaList?.schema?.map((categoryItem, categoryIndex) => (
              <div key={categoryItem.category}>
                {renderCategory(categoryItem, categoryIndex)}{' '}
                {(categoryItem.category === 'age' ||
                  categoryItem.category === 'gender' ||
                  categoryItem.category === 'country' ||
                  categoryItem.category === 'city') && (
                  <div className='text-left text-primary-dark-red mt-2'>
                    *{translate('handlesScreen.theSumOfStatsMustNotExceed100')}
                  </div>
                )}
                <hr className='my-6 rounded border border-primary-light-grey' />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
