import axios from 'axios';
import { getStorageLoginData, setStorageLoginData } from 'utils/StorageServices';
import store from 'services/context/store';
import { setLoginData } from 'services/context/Auth/actions';

export interface ReAuthData {
  access_token: string;
  // Include other properties if they exist in the response data
  // For example:
  // refresh_token: string;
}

export const AuthServices = {
  loginByPhone: async (payload: { [key: string]: any }): Promise<any> => {
    try {
      const endPoint = `oauth/token/phone`;
      const response = await axios.post(endPoint, payload);
      return response;
    } catch (error) {
      throw error;
    }
  },
  verifyLoginByPhone: async (payload: string): Promise<any> => {
    try {
      const endPoint = `oauth/token/phone/code`;
      const login = await axios.post<object, ReAuthData>(endPoint, payload);
      setStorageLoginData(login);
      store.dispatch(setLoginData({ ...login }));
      return login;
    } catch (error: any) {
      throw error;
    }
  },
  logout: async (): Promise<any> => {
    const access_token = getStorageLoginData('access_token');
    const refresh_token = getStorageLoginData('refresh_token');

    if (!refresh_token && !access_token) {
      throw new Error('No refresh token or access token found');
    }

    const accessTokenEndPoint = `oauth/accessToken/${access_token}`;
    const refreshTokenEndPoint = `oauth/refreshToken/${refresh_token}`;

    try {
      if (refresh_token) {
        await axios.delete(refreshTokenEndPoint);
      }
      if (access_token) {
        await axios.delete(accessTokenEndPoint);
      }
      return;
    } catch (error: any) {
      console.log('error on logout', error.message);
    }
  },
  refreshTokens: async () => {
    try {
      const refreshToken = getStorageLoginData('refresh_token');
      const newTokens: ReAuthData = await axios.post('oauth/token', {
        refresh_token: refreshToken,
        grant_type: 'refresh_token',
      });
      if (newTokens?.access_token) {
        const prevLoginData = getStorageLoginData();
        const tokens = { ...prevLoginData, ...newTokens };
        setStorageLoginData(tokens);
        store.dispatch(setLoginData(tokens));
        return true;
      } else {
        throw new Error('Unable to refresh tokens');
      }
    } catch (error: any) {
      console.log('error on refreshTokens', error.message);
      throw error;
    }
  },
};
