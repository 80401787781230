import { useContext } from 'react';
import { StoreContext } from './Context';

export const useStore = () => {
  const [state, dispatch]: any = useContext(StoreContext);

  return [state, dispatch];
};

export const UseAbility = (A: any, I: any) => {
  const [state] = useStore();
  const { abilitiesStore } = state;
  if (Object.keys(abilitiesStore).length !== 0) {
    return abilitiesStore.can(A, I);
  }
};
