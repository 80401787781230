import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Logo from 'assets/images/Logo.svg';
import { useStore } from '../../services/context';
import { AuthServices } from '../../services/apis/Auth';
import { toast } from 'react-toastify';
import { CountdownCircle, Spiner } from '../../components/shared';
import { getInviteCode, translate } from 'utils/helperFunctions';
import { SlReload } from 'react-icons/sl';
import { createUserAbilities, updateAbilities } from 'services/context/abilities/actions';
import { PureAbility } from '@casl/ability';
import { OnboardingServices } from 'services/apis/Onboarding';
import { AuthSessionServices } from 'services/apis/AuthSession';

export const VerifyLogin = () => {
  const navigate = useNavigate();
  const route = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const [loginData, setLoginData] = useState<any>({
    code: '',
    phone: route.state,
  });
  const [toogleSmsTimer, setToogleSmsTimer] = useState<boolean>(false);
  const concatNumber = localStorage.getItem('concatNumber') || '';
  const [, dispatch] = useStore();

  const ability = new PureAbility();
  const code = getInviteCode();
  useEffect(() => {
    return () => {
      setLoginData({
        code: '',
        phone: route.state,
      });
    };
  }, []);

  const fetchMembershipRecord = async () => {
    try {
      const record = await OnboardingServices.fetchInviteRecord(code);
      if (record.status === 'accepted') {
        return navigate('/home', { replace: true, state: { record } });
      } else {
        navigate(`/home/onboarding-wizard/${record._id}`, { replace: true });
      }
    } catch (error: any) {
      toast.error(error?.message);
    }
  };

  const onLogin = async () => {
    try {
      if (loginData.code) {
        setLoading(true);
        await AuthServices.verifyLoginByPhone(loginData);
        await AuthSessionServices.createSession();
        await createUserAbilities(ability);
        dispatch(updateAbilities(ability));
        localStorage.removeItem('concatNumber');
        setLoading(false);
        if (code) {
          fetchMembershipRecord();
        } else {
          return navigate('/home', { replace: true });
        }
      }
    } catch (error: any) {
      setLoading(false);
      toast.error(error?.message);
    }
  };

  const onResendSmsCode = async () => {
    if (concatNumber) {
      try {
        await AuthServices.loginByPhone({ phone: concatNumber });
        setLoginData({
          ...loginData,
          phoneCode: '',
        });
        setToogleSmsTimer(show => !show);
        toast.success(translate('profileScreen.codeSentSuccessfully'));
      } catch (error: any) {
        toast.error(error?.message);
      }
    }
  };

  return (
    <div className='p-8 lg:px-20'>
      <div className='flex flex-col items-start z-50 sm:ml-7 lg:ml-0'>
        <img src={Logo} alt='logo' />
      </div>
      <div className='grid grid-cols-1 lg:grid-cols-2 gap-y-6 gap-x-8 lg:mt-[15vh]'>
        <div className='text-left mt-14 sm:ml-7 lg:ml-0'>
          <div className='flex lg:flex-col gap-3 flex-wrap'>
            <h2 className='font-Condensed text-primary-orange text-6xl lg:text-9xl font-bold uppercase sm:h-fit'>
              {translate('loginScreen.goodTo')}
            </h2>
            <h2 className='font-Condensed text-primary-orange text-6xl lg:text-9xl font-bold uppercase sm:h-fit'>
              {translate('loginScreen.seeYou')}
            </h2>
          </div>
          <p className='mt-6 sm:mt-2 lg:mt-14 text-xl lg:text-3xl font-normal '>
            {translate('loginScreen.welcomeBack')}
          </p>
        </div>
        <div className='flex flex-col gap-8 mx-0 sm:mx-7 lg:mx-0 bg-primary-dark-grey px-4 py-6 overflow-hidden primaryBorder'>
          <div>
            <h2 className='font-Condensed text-primary-orange text-4xl font-bold'>
              {translate('loginScreen.chechYourPhone')}
            </h2>
            <p className='mt-4 font-normal '>{translate('loginScreen.weSentYouCode')}</p>
          </div>
          <div className='flex items-center relative'>
            <div className='input-wrapper'>
              <label htmlFor='smsCode' className='tw-label'>
                {translate('common.smsCode')}
              </label>
              <input
                className='tw-input-primary'
                id='smsCode'
                type='text'
                value={loginData.code || ''}
                placeholder={translate('loginScreen.enterSmsCode')}
                onKeyUp={event => event.key === 'Enter' && loginData.code && onLogin()}
                onChange={e => {
                  const pattern = `^[0-9]{0,6}$`;
                  const regex = new RegExp(pattern, 'g');
                  if (regex.test(e.target.value) || !e.target.value) {
                    setLoginData({ ...loginData, code: e?.target.value.toString() as string });
                  }
                }}
              />
            </div>
            <span className='cursor-pointer absolute right-4 top-10'>
              {!toogleSmsTimer ? (
                <SlReload
                  onClick={onResendSmsCode}
                  className='text-3xl stroke-[70px] rotate-[150deg] text-primary-orange'
                />
              ) : (
                <CountdownCircle
                  className=''
                  initialTime={120}
                  onChange={e => setToogleSmsTimer(!e)}
                />
              )}
            </span>
          </div>
          <div className='text-center'>
            <button
              type='button'
              onClick={onLogin}
              disabled={loginData.code.length < 6}
              className='tw-button-light-blue !w-full sm:!w-72'
            >
              {loading && <Spiner className='ml-24 sm:ml-40' />}
              {translate('common.imMe')}
            </button>
          </div>
          <div className='text-center'>
            <p className='text-primary-orange text-xl uppercase text-center mx-auto'>
              <Link to={'/login'} className='font-Condensed text-2xl font-bold cursor-pointer'>
                {translate('common.goBack')}
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
