// BINARIES: Configurations for binary files

export enum BucketPaths {
  Card = 'card',
  Space = 'space',
  Profile = 'profile',
  Image = 'image',
  Domain = 'domain',
  Asset = 'asset',
  Sponsor = 'sponsor',
  Handle = 'handle',
  Elearning = 'elearning',
  CompanyNews = 'companyNews',
  News = 'news',
  Campaign = 'campaign',
  Products = 'products',
  Events = 'events',
}

export const AWS_MEDIA_URL = process.env.REACT_APP_AWS_MEDIA_URL as string;
export const CDN_MEDIA_URL = process.env.REACT_APP_CDN_MEDIA_URL as string;
