import React from 'react';

type Props = { children: React.ReactNode; className?: string };

const Box = (props: Props) => {
  const { children, className } = props;
  return (
    <div
      className={`bg-primary-dark-grey border-2 border-primary-blue p-4 relative ${
        className ?? ''
      }`}
      style={{ boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15266)' }}
    >
      {children}
    </div>
  );
};

export default Box;
