import { GeneralNoAccess } from 'components/shared/GeneralNoAccess';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { OnboardingServices } from 'services/apis/Onboarding';
import { Interest, OnboardingDetailsType } from 'services/apis/Onboarding/Onboarding.types';
import { UseAbility } from 'services/context';
import { translate } from 'utils/helperFunctions';

export const OnboardingDetails = () => {
  const canGetOnboardingMembership = UseAbility('membership', 'get');
  const [details, setDetails] = useState<Partial<OnboardingDetailsType> | null>(null);
  const { id, membership } = useParams();
  const lightsYourFire = details?.metadata?.lightsYourFire || [];
  const turnsYouOff = details?.metadata?.turnsYouOff || [];

  const getMembership = async () => {
    try {
      if (id) {
        const onboardingDetails = await OnboardingServices.fetchMembershipStatus(id);
        setDetails(onboardingDetails);
      }
    } catch (err) {
      return;
    }
  };

  const preferedContactMethod = (data: string) => {
    if (data) {
      if (data.includes('@')) {
        return 'Via email';
      } else {
        return 'Via phone';
      }
    }
  };

  const rows = [
    {
      value: details?.userInfo?.firstName,
      label: translate('common.firstName'),
      p: 'pt-0 pb-6',
      b: 'border-0',
    },
    {
      value: details?.userInfo?.lastName,
      label: translate('common.lastName'),
    },
    {
      value: (
        <div className='!break-all !whitespace-normal !w-full'>{details?.userInfo?.email}</div>
      ),
      label: translate('common.email'),
    },
    {
      value: details?.userInfo?.phone && '+' + details?.userInfo?.phone,
      label: translate('common.phone'),
      p: 'pb-6 pt-0',
      b: 'border-0',
    },
    {
      value: preferedContactMethod(details?.metadata?.contact || ''),
      label: translate('common.preferredContactMethod'),
      p: `${membership !== 'influencer' ? 'pb-0 pt-6' : 'py-6'}`,
    },
    {
      value: details?.metadata?.lightsYourFire?.map((interest: Interest, index: number) => {
        return (
          <div key={index} className='capitalize'>
            {interest?.title}
            {index < lightsYourFire?.length - 1 ? ' - ' : ''}
          </div>
        );
      }),
      label: translate('onBoardingScreen.likes'),
      class: 'w-full !flex !flex-wrap !max-w-none',
    },
    {
      value: details?.metadata?.turnsYouOff?.map((interest: Interest, index: number) => {
        return (
          <div key={index} className='capitalize'>
            {interest.title}
            {index < turnsYouOff?.length - 1 ? ' - ' : ''}
          </div>
        );
      }),
      label: translate('onBoardingScreen.disLikes'),
      p: 'pb-0 pt-6',
      b: 'border-0',
      class: `${membership !== 'influencer' ? 'hidden' : 'w-full !flex !flex-wrap !max-w-none'}`,
    },
  ];

  useEffect(() => {
    if (canGetOnboardingMembership) getMembership();
  }, []);

  return (
    <div className='border border-primary-blue  w-full bg-primary-dark-grey p-4 mb-5'>
      {canGetOnboardingMembership &&
        rows &&
        rows.map((row, index) => {
          if (membership !== 'influencer' && index >= rows.length - 2) {
            return null; // Don't show the last two rows if membership does not influencer
          }
          return (
            <div
              key={index}
              className={`${row.p ? row.p : `py-6`} ${
                row.b ? row.b : 'border-t-2 border-primary-light-grey'
              } flex flex-row gap-4`}
            >
              <div className='flex items-center  w-[128px] sm:w-auto break-words'>
                <div className='font-Condensed text-[24px] text-primary-orange font-bold w-[128px] whitespace-normal break-words'>
                  {row.label}:
                </div>
              </div>
              <div className='flex items-center justify-start '>
                <div
                  className={`${
                    row.class ? row.class : ''
                  } font-Condensed text-[24px] font-bold sm:whitespace-normal break-words sm:max-w-none truncate`}
                >
                  {row?.value ? row.value : '-'}
                </div>
              </div>
            </div>
          );
        })}
      {!canGetOnboardingMembership && <GeneralNoAccess border={false} />}
    </div>
  );
};

export default OnboardingDetails;
