import axios from 'axios';
import { AcceptLegalType, TermsAndConditionsType } from './Legal.types';

export const LegalServices = {
  fetchTermsAndConditions: async ({
    classId,
    handleId,
  }: {
    handleId: string;
    classId: string;
  }): Promise<any> => {
    const endPoint = `legal/user/domain/class/${classId}/terms/state`;
    try {
      const tAndC = await axios.get<object, TermsAndConditionsType>(endPoint, {
        params: { handleId },
      });
      return tAndC;
    } catch (error) {
      throw error;
    }
  },
  onAcceptTermsAndConditions: async ({
    classId,
    payload,
  }: {
    classId: string;
    payload: AcceptLegalType;
  }): Promise<any> => {
    const endPoint = `legal/user/domain/class/${classId}/terms`;
    try {
      const accept = await axios.post(endPoint, payload);
      return accept;
    } catch (error) {
      throw error;
    }
  },
  fetchOutdatedUserTerms: async (): Promise<any> => {
    const endPoint = `legal/user/outdated/user/terms`;
    try {
      const accept = await axios.get(endPoint);
      return accept;
    } catch (error) {
      throw error;
    }
  },
  fetchOutdatedPrivacyPolicy: async (): Promise<any> => {
    const endPoint = `legal/user/outdated/privacy/policies`;
    try {
      const accept = await axios.get(endPoint);
      return accept;
    } catch (error) {
      throw error;
    }
  },
  fetchUserTermsOrPrivacyPolicySummary: async (legalId: string): Promise<any> => {
    const endPoint = `legal/terms/${legalId}/summary`;
    try {
      const accept = await axios.get(endPoint);
      return accept;
    } catch (error) {
      throw error;
    }
  },
  onAcceptUserTerms: async (legalId: string): Promise<any> => {
    const endPoint = `legal/user/terms/user`;
    try {
      const accept = await axios.post(endPoint, { legalId });
      return accept;
    } catch (error) {
      throw error;
    }
  },
  onAcceptPrivacyPolicy: async (legalId: string): Promise<any> => {
    const endPoint = `legal/user/privacy/policy`;
    try {
      const accept = await axios.post(endPoint, { legalId });
      return accept;
    } catch (error) {
      throw error;
    }
  },
  fetchLegalUserState: async (): Promise<any> => {
    const endPoint = `legal/user/state`;
    try {
      const accept = await axios.get(endPoint);
      return accept;
    } catch (error) {
      throw error;
    }
  },
};
