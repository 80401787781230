import React from 'react';
import { AiFillCaretLeft } from 'react-icons/ai';
import { calcPagination } from 'utils/helperFunctions';

type Params = {
  skip: number;
  limit: number;
};
type Props = {
  onChange?: (params: Params) => void;
  count: number;
  limit: number;
  skip: number;
  className?: string;
};

export const Pagination: React.FC<Props> = React.memo(
  ({ onChange, count, limit, skip, className = '' }) => {
    const totalPages = limit === 0 ? 0 : Math.ceil(count / limit);
    const currentPage = limit === 0 ? 0 : skip / limit + 1;

    return (
      <div className={className}>
        <div className='flex items-center space-x-2 justify-end'>
          {currentPage > 1 && (
            <div>
              <button
                type='button'
                onClick={() => onChange && onChange({ ...calcPagination(currentPage - 1) })}
                className='inline-flex justify-center items-center rounded-[4.8px] border-[1.2px] border-primary-blue w-6 h-7 border-opacity-[0.28] '
              >
                <AiFillCaretLeft />
              </button>
            </div>
          )}
          <div>
            <div className={!totalPages ? 'hidden' : 'text-white text-[17px]'}>{`${
              currentPage || 0
            } of ${totalPages || 0}`}</div>
          </div>
          {currentPage < totalPages && (
            <div>
              <button
                type='button'
                onClick={() => onChange && onChange({ ...calcPagination(currentPage + 1) })}
                className='inline-flex justify-center items-center rounded-[4.8px] border-[1.2px] border-primary-blue w-6 h-7 border-opacity-[0.28] rotate-180'
              >
                <AiFillCaretLeft />
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
);
