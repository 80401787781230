import { Spiner } from 'components/shared';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { translate } from 'utils/helperFunctions';
import { FiCamera } from 'react-icons/fi';
import { AiFillCaretLeft } from 'react-icons/ai';
import { HandleServices } from 'services/apis/Handle';
import { BucketPaths } from 'config';
import useUploadBinary from 'components/shared/Binary/hooks/useUploadBinary';
import { BinaryUpload } from 'components/shared/Binary/Binary';

export const SuspendHandle = () => {
  const { membership, id, handleId } = useParams();
  const navigate = useNavigate();
  const [suspendRequest, setSuspendRequest] = useState<any>({
    note: '',
    images: [],
  });
  const [loading, setLoading] = useState<boolean>(false);

  const [binaryData, { onUpload, checkIfIdIsValid }] = useUploadBinary({
    binariesDataArray: [
      {
        binariesData: suspendRequest?.images ? suspendRequest?.images : [],
        id: 'images',
        accept: ['image/*'],
        maxNumberOfFiles: 10,
        maxSize: 100000,
        required: true,
        quality: 0.8,
      },
    ],
    bucketPath: BucketPaths.Handle,
    label: '',
  });

  const onSubmit = async () => {
    if (handleId) {
      try {
        setLoading(true);
        const paths = await onUpload();
        const UploadedImages = paths?.find((item: any) => item.id === 'images')?.paths;
        const payload = {
          note: suspendRequest?.note,
          images: UploadedImages,
        };
        await HandleServices.suspendHandle(handleId, { suspendRequest: { ...payload } });
        setLoading(false);
        navigate(`/home/mission-control/${membership}/${id}/${handleId}`);
      } catch (error: any) {
        setLoading(false);
        toast.error(error?.message);
      }
    }
  };

  return (
    <div>
      <div>
        <AiFillCaretLeft
          className=' text-5xl cursor-pointer text-primary-light-grey hover:text-primary-light-grey/60'
          onClick={() => {
            history.back();
          }}
        />
      </div>
      <div className='flex flex-col justify-center items-center gap-8 mx-2 md:mx-14 mb-10 text-center'>
        <h1 className='font-Condensed w-[350px] text-[40px] sm:text-[45px] md:w-[440px] md:text-[60px]  '>
          {translate('handlesScreen.tellUsWhyYouAreSuspendingThisHandle')}
        </h1>
        <div className='w-full max-w-md h-40'>
          <textarea
            placeholder={translate('helpScreen.PleaseExplainWhyYoureSuspendingThisHandle')}
            value={suspendRequest.message}
            onChange={e => setSuspendRequest({ ...suspendRequest, note: e.target.value })}
            className='bg-primary-medium-grey placeholder:bg-primary-medium-grey block text-left py-3 px-4 border-2 border-white/60 rounded-md placeholder-primary-light-grey placeholder:text-left text-white disabled:opacity-[.35] appearance-none outline-none focus:outline-none focus:ring-transparent focus:border-white  sm:text-lg font-medium w-full min-h-full'
          />
        </div>

        <div className='flex flex-col justify-center items-center gap-2 w-full max-w-md'>
          <div>
            <label htmlFor='pic' className='cursor-pointer'>
              <div className='flex gap-2 justify-center items-center text-base font-bold uppercase text-center hover:opacity-80'>
                <FiCamera className='text-2xl' />
                <span>
                  {translate('helpScreen.addImages')} ({suspendRequest?.images?.length})
                </span>
              </div>
            </label>
          </div>
          <BinaryUpload binaryData={binaryData} id='images' />
        </div>
        <div className='flex justify-center items-center'>
          <button
            className='tw-button-light-blue !w-[150px] !h-[50px] !capitalize !text-lg'
            disabled={suspendRequest.note === '' || !checkIfIdIsValid('images')}
            onClick={onSubmit}
          >
            {loading && <Spiner className='ml-28' />}
            {translate('common.submit')}
          </button>
        </div>
      </div>
    </div>
  );
};
