import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Insight } from 'services/apis/Handle/handle.types';
import { calculateSum, translate } from 'utils/helperFunctions';

type Props = {
  stats: Insight[];
  lightsYourFire: {
    title: string;
    detail: string;
  }[];
};

export const Stats = ({ stats, lightsYourFire }: Props) => {
  const navigate = useNavigate();
  const { membership, id, handleId } = useParams();
  const getGenderValues = useCallback((key: string) => {
    switch (key) {
      case 'male':
        return <div className='!normal-case'>{translate('handlesScreen.maleViewers')}:</div>;
      case 'female':
        return <div className='!normal-case'>{translate('handlesScreen.femaleViewers')}:</div>;
      case 'other':
        return <div className='!normal-case'>{translate('handlesScreen.otherViewers')}:</div>;
      default:
        return key;
    }
  }, []);

  const _renderValues = (index: number) => {
    return stats[index]?.values?.map((insight, indexI) => {
      return (
        <div key={indexI} className='flex justify-between capitalize'>
          <div className='flex flex-col'>
            {stats[index]?.category === 'gender'
              ? getGenderValues(insight?.key)
              : insight?.key + ':'}
          </div>
          <div className='flex flex-col'>
            {insight?.value && (insight?.value * 100).toFixed(0) + '%'}
          </div>
        </div>
      );
    });
  };

  return (
    <div className='border-2 border-primary-blue bg-primary-dark-grey w-full p-4 flex flex-col gap-6 '>
      <div className='font-Condensed text-[32px] leading-9 text-primary-light-grey w-full flex items-center justify-center'>
        {translate('handlesScreen.audienceInsights')}
      </div>
      {stats.length > 0 ? (
        <div className='grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4'>
          <div className='sm:order-1 md:order-1 md:row-span-2'>
            <div className='font-Condensed text-2xl text-primary-orange mb-3'>
              {translate('handlesScreen.audienceByAgeGroup')}:
            </div>
            <div className='flex flex-col gap-2'>{_renderValues(0)}</div>
          </div>
          <div className='sm:order-3 md:order-2 md:row-span-2'>
            <div className='font-Condensed text-2xl text-primary-orange mb-3'>
              {translate('handlesScreen.audienceByGender')}:
            </div>
            <div className='flex flex-col gap-2'>{_renderValues(1)}</div>
          </div>
          <div className='sm:order-2 md:order-3'>
            <div className='flex justify-between items-center mb-3'>
              <div className='font-Condensed text-2xl text-primary-orange'>
                {translate('handlesScreen.topAudienceByCountry')}:
              </div>
              <div className=''>{calculateSum('country', stats)}%</div>
            </div>
            <div className='flex flex-col gap-2'>{_renderValues(2)}</div>
          </div>
          <div className='sm:order-4 md:order-6 md:col-end-4 xl:col-end-auto'>
            <div className='flex justify-between items-center mb-3'>
              <div className='font-Condensed text-2xl text-primary-orange'>
                {translate('handlesScreen.topAudienceByCity')}:
              </div>
              <div className=''>{calculateSum('city', stats)}%</div>
            </div>
            <div className='flex flex-col gap-2'>{_renderValues(3)}</div>
          </div>
        </div>
      ) : (
        <div className='w-full flex flex-col items-center justify-center gap-2'>
          <div className='text-primary-light-grey text-[18px] font-normal'>
            {translate('handlesScreen.thisHandleDoesntHaveAnyStats')}
          </div>
          <button
            className='tw-button-light-blue !normal-case'
            onClick={() => {
              navigate(`/home/mission-control/${membership}/${id}/action/${handleId}`, {
                state: { state: lightsYourFire.length ? false : true, isAddStats: true },
              });
            }}
          >
            {translate('handlesScreen.addStats')}
          </button>
        </div>
      )}
    </div>
  );
};
