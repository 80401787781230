import React from 'react';
import { translate } from 'utils/helperFunctions';
import AppleStore from '../../assets/images/AppleStore.svg';
import GooglePlay from '../../assets/images/GooglePlay.svg';

export const WizardSigned = () => {
  const _renderWizardWaitingForSignature = () => {
    return (
      <div className='flex flex-col items-center gap-4 text-left border border-primary-orange bg-primary-dark-grey w-full px-2 sm:px-4 xl:w-[70%] xl:mx-auto min-h-[500px]'>
        <div className='font-Condensed text-primary-orange text-[96px] font-bold uppercase'>
          {translate('common.welcome')}
        </div>
        <div className='font-Condensed text-[40px] self-start font-bold text-left mx-2 sm:mx-0 uppercase'>
          {translate('signedScreen.activateYourNetworkToMakeItRain')}
        </div>
        <div className='flex flex-col gap-4 self-start'>
          <div className='text-[20px]'>
            {translate('signedScreen.yourapplicationispendingapproval')}
            {translate('signedScreen.weWillNotifyYouWithAnyFurtherUpdates')}
          </div>
          <div className='text-[20px]'>
            {translate('signedScreen.yourDocuSignContractIsCompleted')}
            <br /> <b>{translate('signedScreen.downloadTheAppToGetStarted')}</b>
          </div>
        </div>
        <div className='flex gap-3 sm:gap-6 mt-4 mb-36 sm:mb-4'>
          <a
            href='https://play.google.com/store/apps/details?id=com.fersancapital.storyteller'
            target='_blank'
          >
            <img src={GooglePlay} alt='appleStore' className='w-40 h-12' />
          </a>
          <a href='https://apps.apple.com/us/app/storyteller/id1604667075' target='_blank'>
            <img src={AppleStore} alt='googlePlay' className='w-40 h-12' />
          </a>
        </div>
      </div>
    );
  };

  return (
    <div className='xoverflow-y-auto xoverflow-x-hidden'>
      <div
        className={`flex justify-start items-center px-4`}
        style={{ minHeight: 'calc(100vh - 200px)' }}
      >
        {_renderWizardWaitingForSignature()}
      </div>
    </div>
  );
};
