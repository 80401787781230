import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Logo from 'assets/images/Logo.svg';
import { AuthServices } from '../../services/apis/Auth';
import { toast } from 'react-toastify';
import { Spiner } from '../../components/shared';
import { translate } from 'utils/helperFunctions';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import '../../components/componentStyle/Style.css';
export const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [loginPhone, setLoginPhone] = useState<any>({
    phone: '',
  });
  const [phoneCode, setPhoneCode] = useState<string>('1');

  useEffect(() => {
    return () => {
      setLoginPhone('');
    };
  }, []);

  const onLogin = async () => {
    try {
      if (loginPhone) {
        setLoading(true);
        await AuthServices.loginByPhone({
          ...loginPhone,
          phone: `${phoneCode}${loginPhone.phone}`,
        });
        localStorage.setItem('concatNumber', `${phoneCode}${loginPhone.phone}`);
        setLoading(false);
        return navigate('/verify', { state: `${phoneCode}${loginPhone.phone}` });
      }
    } catch (error: any) {
      setLoading(false);
      toast.error(error?.message);
    }
  };

  return (
    <div className='p-8 lg:px-20'>
      <div className='flex flex-col items-start sm:ml-7 lg:ml-0 z-50'>
        <img src={Logo} alt='logo' />
      </div>
      <div className='grid grid-cols-1 lg:grid-cols-2 gap-y-6 gap-x-8 lg:mt-[20vh] lg:items-center'>
        <div className='text-left mt-6  sm:ml-7 lg:ml-0'>
          <div className='sm:flex gap-3 lg:flex-col'>
            <h2 className='font-Condensed text-primary-orange text-[64px] lg:text-9xl font-bold uppercase h-[64px] sm:h-fit'>
              {translate('loginScreen.goodTo')}
            </h2>
            <h2 className='font-Condensed text-primary-orange text-[64px]  lg:text-9xl font-bold uppercase h-[64px] sm:h-fit'>
              {translate('loginScreen.seeYou')}
            </h2>
          </div>
          <p className='mt-6 sm:mt-2 lg:mt-14 text-xl lg:text-3xl font-normal'>
            {translate('loginScreen.welcomeBack')}
          </p>
        </div>
        <div className='flex flex-col gap-8 mx-0 sm:mx-7 lg:mx-0 bg-primary-dark-grey px-4 py-6 overflow-hidden primaryBorder h-fit'>
          <div>
            <h2 className='font-Condensed text-primary-orange text-4xl font-bold tracking-tight'>
              {translate('loginScreen.enterYourPhone')}
            </h2>
            <div>
              <p className='mt-4 font-normal sm:flex sm:flex-col text-[15px] sm:text-[16px]'>
                {translate('loginScreen.enterYourPhoneToSignIn')}{' '}
                <Link to={'/join'} className=' font-bold cursor-pointer'>
                  {translate('loginScreen.createOneNow')}
                </Link>
              </p>
            </div>
          </div>
          <div className='input-wrapper relative'>
            <label htmlFor={'phone'} className='tw-label pb-1'>
              {translate('common.phoneNumber')}
            </label>
            <div className='relative'>
              <input
                className='tw-input-primary !pl-[110px]'
                id={'phone'}
                type='text'
                autoComplete={'off'}
                maxLength={10}
                value={loginPhone.phone || ''}
                placeholder={translate('common.enterPhone')}
                onChange={e => {
                  if (/^[0-9]+$/g.test(e.target.value) || !e.target.value) {
                    setLoginPhone({ phone: e.target.value });
                  }
                }}
                onKeyUp={event => event.key === 'Enter' && loginPhone.phone && onLogin()}
              />
              <span className='absolute transform -translate-y-1/2 top-[27px] left-2 z-10'>
                <PhoneInput
                  country={'us'}
                  buttonStyle={{ background: '#171717', border: 'none' }}
                  containerStyle={{
                    width: `${innerWidth < 640 ? '100px' : '100px'}`,
                  }}
                  dropdownStyle={{ color: 'white', background: '#202020', maxHeight: '150px' }}
                  value={phoneCode}
                  onChange={(phone: any) => {
                    setPhoneCode(phone);
                  }}
                  placeholder={''}
                  inputProps={{
                    className:
                      'block w-full border-0 border-white/50 bg-transparent focus:border-white focus:ring-0 text-right !pl-10 focus::!bg-black',
                  }}
                />
              </span>
            </div>
          </div>

          <div className='text-center'>
            <button
              type='button'
              onClick={onLogin}
              disabled={loading || !loginPhone.phone}
              className='tw-button-light-blue !w-full sm:!w-72'
            >
              {loading && <Spiner className='ml-24 sm:ml-40' />}
              {translate('common.login')}
            </button>
          </div>
          <div className='text-center'>
            <p className='text-primary-orange text-sm text-center mx-auto'>
              <span className=''>{translate('loginScreen.don’tHaveAccount')} </span>
              <Link to={'/join'} className=' font-bold cursor-pointer underline'>
                {translate('loginScreen.createOneNow')}
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
