import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { translate } from 'utils/helperFunctions';

export const WizardRejected = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const route = useLocation();

  const _renderWizardRejected = () => {
    return (
      <div className='flex flex-col justify- items-start gap-8 text-left border border-primary-orange bg-primary-dark-grey m-4 w-full xl:w-[70%] py-12 px-4 xl:mx-auto min-h-[600px]'>
        <div className='font-Condensed text-primary-light-grey text-4xl font-bold text-left'>
          {translate('rejectedScreen.itLooksLikeYourMembershipHasBeenRejected')}
          <br />
          <div>
            {translate('rejectedScreen.ifYouFeelThatThisWasAMistake')}{' '}
            <span
              className='text-white cursor-pointer hover:text-white/80 underline mt-2'
              onClick={() => {
                navigate(`/home/onboarding-wizard/${id}/wizardSendNote`, {
                  state: { logo: route?.state?.logo, membership: route?.state?.membership },
                });
              }}
            >
              {translate('common.contactUs')}
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div
        className={`flex justify-start items-center`}
        style={{ minHeight: 'calc(100vh - 200px)' }}
      >
        {_renderWizardRejected()}
      </div>
    </div>
  );
};
