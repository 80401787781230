import React from 'react';
import { FC, useEffect, useRef, useState } from 'react';
import { Calendar } from 'react-date-range';
import { IoIosArrowDown } from 'react-icons/io';
import format from 'date-fns/format';
import 'react-date-range/dist/theme/default.css';
import 'react-date-range/dist/styles.css';
import '../componentStyle/Style.css';
import { classNames } from 'utils/helperFunctions';

interface DatePickerProps {
  onChange: (value: any) => void;
  value?: any;
  label?: JSX.Element;
  floatingLabel?: string;
  labelClassName?: string;
  inputClassName?: string;
  calendarStyle?: string;
  placeholder?: string;
}

export const DatePicker: FC<DatePickerProps> = ({
  value,
  onChange,
  label,
  floatingLabel,
  labelClassName = '',
  inputClassName = '',
  calendarStyle = '',
  placeholder = '',
}) => {
  const [open, setOpen] = useState(false);
  const refOne = useRef<any>(null);

  const hideOnEscape = (e: any) => {
    if (e.key === 'Escape') {
      setOpen(false);
    }
  };

  const hideOnClickOutside = (e: any) => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', hideOnEscape, true);
    document.addEventListener('click', hideOnClickOutside, true);
  }, [value]);

  const handleSelect = (date: any) => {
    onChange?.(new Date(date).getTime());
    setOpen(false);
  };

  return (
    <>
      {label && (
        <label
          htmlFor='date'
          className='text-xs text-primary-light-grey uppercase font-bold text-left'
        >
          {label}
        </label>
      )}
      <div ref={refOne} className='relative !text-left inline-block xtw-input cursor-pointer'>
        {floatingLabel && (
          <h1
            className={classNames(
              `absolute pointer-events-none text-sm text-primary-light-grey left-4 transform translate-x-[0px] translate-y-[8px] scale-100 origin-top-left transition duration-200 ease-out z-10 ${
                value &&
                'transform translate-x-[-15px] translate-y-[-20px] scale-[0.8] sm:scale-[0.8] text-primary-light-grey uppercase'
              }`,
              labelClassName
            )}
          >
            {floatingLabel}
          </h1>
        )}
        <input
          id='date'
          placeholder={placeholder}
          value={value ? format(new Date(value), 'dd/MM/yyyy') : ''}
          readOnly
          className={classNames(
            `tw-input !bg-primary-medium-grey placholder:!text-primary-blue  pl-3 !text-left h-10 focus:border focus-visible:outline-none !text-sm cursor-pointer ${
              open ? '!rounded-b-[0px] !text-center' : ''
            }`,
            inputClassName
          )}
          onClick={() => setOpen(!open)}
        />
        <IoIosArrowDown
          className={`absolute right-3 top-2 xtext-xl translate-y-1/2  ${open ? 'rotate-180' : ''}`}
          onClick={() => setOpen(!open)}
        />
        <div className={`!text-white `}>
          {open && (
            <Calendar
              date={value ? new Date(value) : new Date()}
              onChange={handleSelect}
              className={`${calendarStyle} calendarElement absolute top-10 right-0 w-full flex items-center !bg-primary-dark-grey rounded-b !text-white z-10 focus-visible:`}
              color={'#E66752'}
              background-color={'#041a1c'}
              maxDate={new Date()}
            />
          )}
        </div>
      </div>
    </>
  );
};
