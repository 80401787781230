import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { HandleServices } from 'services/apis/Handle';
import { IHandleDetails } from 'services/apis/Handle/handle.types';
import { useNavigate, useParams } from 'react-router-dom';
import { UseAbility } from 'services/context';
import { translate } from 'utils/helperFunctions';
import { NoAccess } from 'components/shared';
import { Splash } from 'Pages';
import { AiFillCaretLeft } from 'react-icons/ai';
import HandleDiffPartnerships from './HandleDiffPartnerships';
import HandleDiffScreenshots from './HandleDiffScreenshots';
import HandleDiffInsights from './HandleDiffInsights';
import HandleDiffLikesDislikes from './HandleDiffLikesDislikes';
import useMediaQuery from 'utils/hooks/useMediaQuery';

const ViewHandleChanges = () => {
  const navigate = useNavigate();
  const { mobile: isMobileLayout } = useMediaQuery({ mobile: true });
  const { membership, id, handleId } = useParams();
  const canGetHandleDetails = UseAbility('handleDetails', 'get');
  const [handle, setHandle] = useState<IHandleDetails | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const fetchHandleDetails = async () => {
    if (handleId) {
      setLoading(true);
      try {
        const details = await HandleServices.handleDetails(handleId);
        setHandle(details);
      } catch (error: any) {
        toast.error(error?.message);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchHandleDetails();
  }, []);

  const _renderHandleDiffDetails = () => {
    return (
      <div className='flex flex-col gap-4'>
        <div className='flex flex-col gap-4'>
          {isMobileLayout && (
            <div className='flex italic gap-2 align-center'>
              <span className='pt-2'>{translate('common.legend')}:</span>
              <div className='bg-primary-medium-green p-2 pr-3 rounded-md'>
                {translate('common.new')}
              </div>
              <div className='bg-primary-light-brown p-2 pr-3 rounded-md'>
                {translate('common.old')}
              </div>
            </div>
          )}
        </div>

        <HandleDiffPartnerships handle={handle} />
        <HandleDiffScreenshots handle={handle} />
        <HandleDiffInsights handle={handle} />
        <HandleDiffLikesDislikes handle={handle} />
      </div>
    );
  };

  return (
    <div className='relative font-Condensed'>
      {loading && (
        <div className='inset-0 z-50 absolute w-full bg-primary-medium-grey'>
          <Splash />
        </div>
      )}
      {!loading && (
        <>
          <div className='font-Condensed capitalize flex justify-start items-center text-primary-light-grey text-3xl mb-5'>
            <div className=''>
              <AiFillCaretLeft
                className='text-5xl cursor-pointer hover:filter hover:brightness-125 hover:contrast-125 transition-all duration-300 hover:scale-[1.1]'
                onClick={() => {
                  navigate(`/home/mission-control/${membership}/${id}/${handleId}`);
                }}
              />
            </div>
            <div className='normal-case'>
              {handle?.handle} {translate('common.details')}
            </div>
          </div>
          {canGetHandleDetails && handle?.handle && _renderHandleDiffDetails()}

          {!handle?.handle && (
            <div className='text-3xl text-primary-light-grey font-bold text-center'>
              {translate('handlesScreen.noHandleFound')}
            </div>
          )}
          {!canGetHandleDetails && <NoAccess membership={membership as string} />}
        </>
      )}
    </div>
  );
};

export default ViewHandleChanges;
