import { useEffect, useState } from 'react';
import {
  DefaultNoAccess,
  DetailsListing,
  DetailsListingView,
  LoadingPage,
  Spiner,
} from 'components/shared';
import { AiFillCaretLeft } from 'react-icons/ai';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { LegalServices } from 'services/apis/Legal';
import { UseAbility } from 'services/context';
import { getSocilaIcon, getTheTime, translate } from 'utils/helperFunctions';
import { HandleServices, IHandleTerms } from 'services/apis/Handle';
import Box from 'components/shared/Box';
import ViewWYSIWYG from 'components/shared/ViewWYSIWYG';
import useRefetchAbilities from 'utils/hooks/useRefetchAbilities';
import { useFetchLegalState } from 'queries/legalQueries/legalQueries';

const renderValues = ({
  label,
  values,
  titles,
  indexKey,
}: {
  values: Record<string, number | string>;
  label: string;
  titles: Record<string, string>;
  indexKey: 'new' | 'old';
}) => (
  <div className='flex flex-col gap-3 grow'>
    <div className='font-bold'>{label}</div>
    <div
      className={`flex flex-col gap-3 grow  p-4 rounded ${
        indexKey === 'new' ? 'bg-primary-medium-green' : 'bg-primary-light-brown'
      }`}
    >
      {Object.keys(titles).map((key, i) => (
        <div className='text-base' key={i}>
          {values?.[key as keyof typeof titles] ? values?.[key as keyof typeof titles] : 'N/A'}
        </div>
      ))}
    </div>
  </div>
);

const renderTitles = ({
  titles,
  // header = translate('common.membership'),
  headerClassName = 'text-primary-orange font-bold',
  itemClassName = 'text-primary-orange font-bold',
}: {
  titles: Record<string, string>;
  header?: string;
  headerClassName?: string;
  itemClassName?: string;
}) => (
  <div className='flex flex-col gap-3 py-4 w-fit'>
    <div className={`${headerClassName} text-transparent opacity-0`}>/</div>
    {Object.keys(titles).map(key => (
      <div key={key} className={`whitespace-nowrap text-base ${itemClassName}`}>
        {titles[key as keyof typeof titles]}:
      </div>
    ))}
  </div>
);

const NewOldClass = ({ data }: { data: IHandleTerms }) => {
  const titles: Record<keyof typeof data.newClassInfo, string> = {
    name: translate('common.name'),
    valueOfFollower: translate('legalScreen.numberOfFollowers'),
    numberOfConvos: translate('legalScreen.numberOfConvos'),
    potentialValue: translate('legalScreen.value'),
  };

  return (
    <div key={data?._id}>
      <div className='flex gap-5 mt-2 grow'>
        {renderTitles({ titles })}
        <div className='flex flex-col gap-3 grow'>
          {renderValues({
            indexKey: 'new',
            label: 'New Class',
            values: data?.newClassInfo,
            titles,
          })}
        </div>
        <div className='hidden sm:flex shrink flex-col gap-3 grow'>
          {renderValues({
            indexKey: 'old',
            label: 'Old Class',
            values: data?.oldClassInfo,
            titles,
          })}
        </div>
      </div>
      <div className='sm:hidden flex gap-5 mt-2 w-full'>
        {renderTitles({ titles })}
        <div className='flex flex-col gap-3 grow'>
          {renderValues({
            indexKey: 'old',
            label: 'Old Class',
            values: data?.oldClassInfo,
            titles,
          })}
        </div>
      </div>
    </div>
  );
};

export const LegalSummaryView = () => {
  const { type, legalId, participationId } = useParams();
  const [summary, setSummary] = useState<
    IHandleTerms & { name: string; metadata: { url: string } }
  >();

  const { refetch } = useFetchLegalState();
  const refetchAbilities = useRefetchAbilities();

  const [loading, setLoading] = useState<{ [key in 'page' | 'accept']: boolean }>({
    page: false,
    accept: false,
  });
  const navigate = useNavigate();

  const canGetUserTermsOrPrivacyPolicyDetails = UseAbility(
    'userTermsOrPrivacyPolicySummary',
    'get'
  );
  const canGetClassSwitchRequestDetails = UseAbility('classSwitchRequestDetails', 'get');

  const canAcceptUserTerms = UseAbility('userTermsAndCondition', 'accept');

  const canAcceptPrivacyPolicy = UseAbility('userPrivacyPolicy', 'accept');

  const canAcceptClassSwitchRequest = UseAbility('classSwitchRequest', 'accept');

  const canGetContent = {
    user: canGetUserTermsOrPrivacyPolicyDetails,
    privacy: canGetUserTermsOrPrivacyPolicyDetails,
    domain: canGetClassSwitchRequestDetails,
  };

  const canAccept = {
    user: canAcceptUserTerms,
    privacy: canAcceptPrivacyPolicy,
    domain: canAcceptClassSwitchRequest,
  };

  const headerTitle = {
    user: translate('legalScreen.termAndConditionsUpdate'),
    privacy: translate('legalScreen.privacyPolicyUpdate'),
    domain: translate('legalScreen.domainTermsAndConditionsUpdate'),
  };

  const getSummary = async () => {
    try {
      setLoading(prev => ({ ...prev, page: true }));
      if (!legalId || !type) {
        return;
      }
      if (type === 'user' || type === 'privacy') {
        const res = await LegalServices.fetchUserTermsOrPrivacyPolicySummary(legalId);
        setSummary(res);
      }
      if (type === 'domain' && participationId) {
        const res = await HandleServices.getClassSwitchRequestDetails({
          handleId: legalId,
          participationId,
        });
        setSummary(res);
      }
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error?.message);
      }
    }
    setLoading(prev => ({ ...prev, page: false }));
  };

  useEffect(() => {
    getSummary();
  }, [legalId]);

  const handleAcceptTerms = async () => {
    if (!legalId || (type === 'domain' && (!participationId || !summary?.legalInfo?._id))) return;
    try {
      setLoading(prev => ({ ...prev, accept: true }));
      if (type === 'user') {
        await LegalServices.onAcceptUserTerms(legalId);
      }
      if (type === 'privacy') {
        await LegalServices.onAcceptPrivacyPolicy(legalId);
      }
      if (type === 'domain' && participationId && summary?.legalInfo?._id) {
        await HandleServices.onAcceptClassSwitchRequest({
          legalId: summary?.legalInfo?._id,
          handleId: legalId,
          participationId,
          action: 'accept',
        });
      }
      await refetchAbilities();
      await refetch();
      navigate(`/home/legal`, { replace: true });
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error?.message);
      }
    }
    setLoading(prev => ({ ...prev, accept: false }));
  };

  const userOrPrivacyView: DetailsListingView[] = [
    {
      key: 'name',
      title: translate('common.name'),
      render: name => <span>{name}</span>,
    },
    {
      key: 'metadata.url',
      title:
        type === 'user'
          ? translate('legalScreen.termsAndConditions')
          : translate('legalScreen.privacyPolicy'),
      className: 'flex-col !items-start gap-2',
      titleClassName: 'w-auto',
      render: url => (
        <a target='_blank' href={url} className='text-primary-blue underline lowercase'>
          {url}
        </a>
      ),
    },
  ];

  const domainView: DetailsListingView[] = [
    {
      key: 'domainInfo.name',
      title: translate('common.domainName'),
      render: name => <span>{name}</span>,
    },
    {
      key: 'switchedAt',
      title: translate('legalScreen.switchedAt'),
      render: time => (time ? getTheTime(time) : '-'),
    },
    {
      key: 'handleInfo',
      title: translate('common.handle'),
      render: handleInfo => (
        <div className='flex items-center gap-5'>
          <img src={getSocilaIcon(handleInfo?.socialMedia)} alt='icon' />
          {handleInfo?.handle}
        </div>
      ),
    },
    {
      key: 'newOldClass',
      className: 'flex-col !items-start gap-2',
      render: () => <NewOldClass data={summary as IHandleTerms} />,
    },
  ];

  const domainTermsView: DetailsListingView[] = [
    {
      key: 'legalInfo.name',
      title: translate('common.name'),
      render: name => <span>{name}</span>,
    },
    {
      key: 'legalInfo.metadata.content',
      render: terms => (
        <div className='flex flex-col gap-4'>
          <label className='text-primary-orange text-2xl font-bold w-32 pr-2'>
            {translate('legalScreen.termsAndConditions')}:
          </label>
          <Box className='w-full !p-0'>
            <ViewWYSIWYG className=' bg-primary-medium-grey p-4' value={terms || ''} />
          </Box>
        </div>
      ),
    },
  ];

  return (
    <div className='relative font-Condensed capitalize'>
      <div className='font-Condensed flex justify-start items-center text-primary-light-grey text-3xl mb-5'>
        <div className=''>
          <AiFillCaretLeft
            className='text-5xl cursor-pointer hover:filter hover:brightness-125 hover:contrast-125 transition-all duration-300 hover:scale-[1.1]'
            onClick={() => {
              navigate(`/home/legal?tab=${type}`);
            }}
          />
        </div>
        <div>{headerTitle[type as keyof typeof canGetContent]}</div>
      </div>
      {type && canGetContent[type as keyof typeof canGetContent] ? (
        <div className='flex flex-col gap-4'>
          <Box>
            <LoadingPage loading={loading.page} />
            <DetailsListing
              data={summary}
              viewArray={type === 'domain' ? domainView : userOrPrivacyView}
            />
          </Box>
          {type === 'domain' && (
            <Box>
              <DetailsListing data={summary} viewArray={type === 'domain' ? domainTermsView : []} />
            </Box>
          )}
          <div className='flex justify-center'>
            <button
              disabled={!canAccept[type as keyof typeof canGetContent] || loading.accept}
              onClick={handleAcceptTerms}
              className='relative tw-button-light-blue'
            >
              {translate('common.accept')}
              {loading.accept && <Spiner className='right-0' />}
            </button>
          </div>
        </div>
      ) : (
        <DefaultNoAccess />
      )}
    </div>
  );
};
