import { Spiner } from 'components/shared';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { HandleServices } from 'services/apis/Handle';
import { deepEqual, sortArrays, translate } from 'utils/helperFunctions';
import usePrompt from 'utils/hooks/usePrompt';
import { AiFillCaretLeft } from 'react-icons/ai';
import { AddHandleState, IHandleDetails } from 'services/apis/Handle/handle.types';
import {
  AddHandleCrumbs,
  AddHandleInfo,
  AddHandleMetaData,
  AddHandleInsights,
  AddWhatLightsYourFire,
  AddWhatTurnsYouOff,
} from './AddHandleScreens';
import useUploadBinary from 'components/shared/Binary/hooks/useUploadBinary';
import { BucketPaths } from 'config';

export const HandleForm = ({
  screen,
  setScreen,
  loading,
  setLoading,
  addHandle,
  setAddHandle,
  originalData,
}: {
  screen: number;
  setScreen: React.Dispatch<React.SetStateAction<number>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  addHandle: AddHandleState;
  setAddHandle: React.Dispatch<React.SetStateAction<AddHandleState>>;
  originalData: IHandleDetails | null;
}) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { membership, id, handleId } = useParams();
  const [schemaVersion, setSchemaVersion] = useState<number>(0);
  const [totalError, setTotalError] = useState<boolean>(false);
  const metaDataToCheck = originalData?.handle_metadata?.holder?.metadata ?? originalData?.metadata;

  const checkPartnerships = () => {
    const partnerships: any[] = addHandle?.metadata?.partnerships || [];
    if (Array.isArray(partnerships) && partnerships?.length > 0) {
      return partnerships.some(partner => {
        return (
          partner?.relation?.exclusive &&
          (!partner?.name || !partner?.relation?.description || !partner?.relation?.clause)
        );
      });
    }
    return false;
  };

  const filteredPartnerships: string[] = addHandle?.metadata?.partnerships
    ? addHandle?.metadata?.partnerships?.filter((partnership: any) => partnership?.name !== '')
    : [];

  const sortedArrays = {
    lightsYourFire: sortArrays(
      metaDataToCheck?.lightsYourFire,
      addHandle?.metadata?.lightsYourFire
    ),
    turnsYouOff: sortArrays(metaDataToCheck?.turnsYouOff, addHandle?.metadata?.turnsYouOff),
  };

  const isFollowersMatch = (): boolean => {
    if (addHandle && originalData) {
      return (
        addHandle.followers === originalData.followers && +addHandle.date === +originalData.date
      );
    }
    return false;
  };

  const isMetadataEqual = !state.state
    ? deepEqual({ ...addHandle.metadata, ...sortedArrays }, metaDataToCheck || {})
    : deepEqual({ ...addHandle.metadata }, metaDataToCheck || {});

  const disableButtonIfNoChanges = () => {
    if (
      (handleId !== 'new' && screen === 3 && state.state) ||
      (handleId !== 'new' && screen === 5 && !state.state)
    ) {
      const matched = isMetadataEqual && isFollowersMatch();
      return matched;
    }
  };

  const [binaryData, { onUpload, checkIfIdIsValid }] = useUploadBinary({
    binariesDataArray: [
      {
        id: 'aboutYourPeople',
        binariesData: addHandle.metadata?.aboutYourPeople?.length
          ? addHandle.metadata?.aboutYourPeople
          : [],
        accept: ['image/*'],
        maxSize: 1000000,
        maxNumberOfFiles: 10,
        quality: 0.8,
        required: true,
      },
    ],
    bucketPath: BucketPaths.Handle,
    label: '',
  });

  const handleValidation = () => {
    if (
      addHandle.handle &&
      addHandle.socialMedia &&
      addHandle.url &&
      checkIfIdIsValid('aboutYourPeople')
    )
      return true;
  };

  const handleValidation2 = () => {
    if (!addHandle?.category || !addHandle?.subCategory) return false;
    return true;
  };

  const { _renderModalDiscardChanges, setIsBlocking } = usePrompt({
    message: translate('discardModal.exitingWillDiscardAllChangesMade'),
    okText: translate('common.exit'),
    cancelText: translate('common.cancel'),
  });

  useEffect(() => {
    setIsBlocking(true);
  }, []);

  const handleStatsValidation = () => {
    if (!addHandle.followers || !addHandle.date) return true;
    if (totalError) return true;
    if (addHandle?.metadata?.stats?.[0]?.values[0]) {
      return addHandle?.metadata?.stats?.some(value => {
        const total = value?.values?.reduce((acc, val) => acc + (val?.value || 0), 0);
        return (
          value?.values?.some(innerValue => {
            if (value.category === 'age' || value.category === 'gender') {
              return false;
            }
            return innerValue?.key === '' || innerValue?.value === 0;
          }) ||
          value.values?.every(
            val => !val?.key || val?.value === undefined || val?.value === null
          ) ||
          total === 0 ||
          total > 1
        );
      });
    } else if (!addHandle?.metadata?.stats) {
      return true;
    }
  };

  const _renderAddHandle = () => {
    if (state.isAddStats) {
      switch (screen) {
        case 3:
          return (
            <AddHandleInsights
              setTotalError={setTotalError}
              onChange={setAddHandle}
              addHandle={addHandle}
              setSchemaVersion={setSchemaVersion}
            />
          );
      }
    } else {
      switch (screen) {
        case 1:
          return (
            <AddHandleInfo
              onChange={setAddHandle}
              addHandle={addHandle}
              binaryData={{ binaryData, id: 'aboutYourPeople' }}
            />
          );
        case 2:
          return <AddHandleMetaData onChange={setAddHandle} addHandle={addHandle} />;
        case 3:
          return (
            <AddHandleInsights
              setTotalError={setTotalError}
              onChange={setAddHandle}
              addHandle={addHandle}
              setSchemaVersion={setSchemaVersion}
            />
          );
        case 4:
          return <AddWhatLightsYourFire onChange={setAddHandle} addHandle={addHandle} />;
        case 5:
          return <AddWhatTurnsYouOff onChange={setAddHandle} addHandle={addHandle} />;
        default:
          return null;
      }
    }
  };

  const unknownCrumbsArray =
    membership === 'influencer'
      ? [
          {
            name: 'Details',
            screen: 1,
          },
          {
            name: 'Meta Data',
            screen: 2,
          },
          {
            name: 'Stats',
            screen: 3,
          },
          {
            name: 'Likes',
            screen: 4,
          },
          {
            name: 'Dislikes',
            screen: 5,
          },
        ]
      : [];

  const knownCrumbsArray =
    membership === 'influencer'
      ? [
          {
            name: 'Details',
            screen: 1,
          },
          {
            name: 'Meta Data',
            screen: 2,
          },
          {
            name: 'Stats',
            screen: 3,
          },
        ]
      : [];

  const onBack = () => {
    if (screen === 1) {
      history.back();
    } else {
      setScreen(prev => prev - 1);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const onNext = async () => {
    if (
      (screen === 3 && state.state) ||
      (screen === 5 && !state.state) ||
      (screen === 3 && state.isAddStats)
    ) {
      try {
        setIsBlocking(false);
        setLoading(true);
        if (handleId) {
          if (
            (!state.state && screen === 5) ||
            (state.state && screen === 3) ||
            (screen === 3 && state.isAddStats)
          ) {
            const newHandle = { ...addHandle };
            const paths = await onUpload();
            const newAboutYourPeople =
              paths?.find(path => path.id === 'aboutYourPeople')?.paths || [];

            const metadata = {
              ...(addHandle?.metadata?.lightsYourFire?.length
                ? { lightsYourFire: addHandle.metadata.lightsYourFire }
                : {}),
              ...(addHandle?.metadata?.turnsYouOff?.length
                ? { turnsYouOff: addHandle.metadata.turnsYouOff }
                : {}),
              aboutYourPeople: newAboutYourPeople,
              stats: {
                schemaVersion: schemaVersion,
                values: newHandle.metadata.stats,
              },
              ...(filteredPartnerships?.length ? { partnerships: filteredPartnerships } : {}),
            };
            if (handleId === 'new') {
              await HandleServices.addHandle({
                ...newHandle,
                metadata: metadata,
                onboardingId: id,
                followers: Number(newHandle.followers.toString().replace(/,/g, '')),
              });
            } else {
              await HandleServices.editMetadata(handleId, { metadata: metadata });
            }
          }
        }
        setLoading(false);
        if (state.isAddStats) {
          toast.success(translate('handlesScreen.statsAddedSuccessfully'));
        } else {
          toast.success(
            translate(
              `handlesScreen.${
                handleId === 'new' ? 'handleCreatedSuccessfully' : 'handleUpdatedSuccessfully'
              }`
            )
          );
        }
        navigate(
          `/home/mission-control/${membership}/${id}${
            handleId == 'new' ? '?tab=handles' : `/${handleId}`
          }`
        );
      } catch (error: any) {
        setLoading(false);
        setIsBlocking(true);
        toast.error(error?.message);
      }
    } else {
      setScreen(prev => prev + 1);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className=''>
      <div className='border-2 border-primary-orange p-4 bg-primary-dark-grey'>
        {_renderModalDiscardChanges()}
        <AddHandleCrumbs
          crumbsArray={state.state ? knownCrumbsArray : unknownCrumbsArray}
          screen={screen}
          state={state.state}
        />
        <div className='flex justify-center items-center'>{_renderAddHandle()}</div>
        <div className='w-full'>
          <div className='flex justify-center items-center gap-[12px]'>
            {!state.isAddStats && (
              <button
                className='tw-button-secondary !px-2 !text-base !capitalize !w-44 sm:!w-72'
                onClick={onBack}
              >
                {translate(screen === 1 ? 'common.cancel' : 'common.back')}
              </button>
            )}
            <button
              className='tw-button-light-blue !px-2 !text-base !w-44 sm:!w-72'
              disabled={
                disableButtonIfNoChanges() ||
                (screen === 5 &&
                  !addHandle.metadata?.turnsYouOff &&
                  addHandle.metadata?.turnsYouOff?.length === 0) ||
                (screen === 5 && !addHandle.metadata?.turnsYouOff) ||
                (screen === 4 && !addHandle.metadata?.lightsYourFire) ||
                (screen === 1 && !handleValidation()) ||
                (screen === 2 && !handleValidation2()) ||
                (screen === 3 && handleStatsValidation()) ||
                (screen === 1 && checkPartnerships())
              }
              onClick={onNext}
            >
              {loading && <Spiner className='ml-28' />}
              {translate(
                (screen === 3 && state.state) ||
                  (screen === 5 && !state.state) ||
                  (screen === 3 && state.isAddStats)
                  ? 'common.submit'
                  : 'common.next'
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export const AddEditHandle = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { membership, id, handleId } = useParams();
  const [screen, setScreen] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [originalData, setOriginalData] = useState<IHandleDetails | null>(null);
  const metaDataToCheck = originalData?.handle_metadata?.holder?.metadata ?? originalData?.metadata;
  const [addHandle, setAddHandle] = useState<AddHandleState>({
    handle: '',
    category: '',
    subCategory: '',
    followers: '',
    socialMedia: '',
    date: new Date().getTime(),
    description: '',
    url: '',
    metadata: {
      ...(metaDataToCheck?.lightsYourFire && metaDataToCheck?.lightsYourFire?.length
        ? { lightsYourFire: [] }
        : {}),
      ...(metaDataToCheck?.turnsYouOff && metaDataToCheck?.turnsYouOff?.length
        ? { turnsYouOff: [] }
        : {}),
      ...(metaDataToCheck?.partnerships && metaDataToCheck?.partnerships?.length
        ? { partnerships: [] }
        : {}),
      aboutYourPeople: [],
      stats: [
        {
          type: '',
          category: '',
          values: [],
        },
      ],
    },
  });

  const fetchHandleDetails = async () => {
    if (handleId && handleId !== 'new') {
      setLoading(true);
      try {
        const detail: any = await HandleServices.handleDetails(handleId);
        setOriginalData(JSON.parse(JSON.stringify(detail)));
        if (detail.handle_metadata) {
          setAddHandle({
            ...detail,
            metadata: detail.handle_metadata.holder.metadata,
          });
        } else {
          setAddHandle(detail);
        }
        setLoading(false);
      } catch (error: any) {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (state.isAddStats) {
      setScreen(3);
    } else {
      fetchHandleDetails();
    }
  }, []);

  return (
    <div className=''>
      <div className='font-Condensed flex justify-start items-center text-primary-light-grey text-3xl mb-5'>
        <div className=''>
          <AiFillCaretLeft
            className='text-5xl cursor-pointer hover:filter hover:brightness-125 hover:contrast-125 transition-all duration-300 hover:scale-[1.1]'
            onClick={() => {
              navigate(
                state.isAddStats || handleId !== 'new'
                  ? `/home/mission-control/${membership}/${id}/${handleId}`
                  : `/home/mission-control/${membership}/${id}`
              );
            }}
          />
        </div>
        <div className='font-Condensed text-[40px] text-primary-light-grey'>
          {state?.isAddStats
            ? translate('handlesScreen.addStatsBackArrow')
            : handleId !== 'new'
            ? translate('handlesScreen.editHandle')
            : translate('handlesScreen.addHandle')}
        </div>
      </div>
      {(handleId === 'new' || addHandle?._id) && (
        <HandleForm
          screen={screen}
          setScreen={setScreen}
          loading={loading}
          setLoading={setLoading}
          addHandle={addHandle}
          setAddHandle={setAddHandle}
          originalData={originalData}
        />
      )}
    </div>
  );
};
