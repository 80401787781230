import React, { useState } from 'react';
import { getStatusClass, translate } from 'utils/helperFunctions';
import { AlignTypes, Column, ComingSoon, Table, VeticalAlignTypes } from 'components/shared';
import { SearchIcon } from '@heroicons/react/outline';

export const Content = () => {
  const [content, _setContent] = useState([
    { content: 'Cheetos', status: 'active', budget: 'Budget', conversation: 12, handles: 35 },
    {
      content: 'Campblles Soup',
      status: 'inactive',
      budget: 'TimeFfame',
      conversation: 324,
      handles: 34,
    },
    {
      content: 'JBL Speakers',
      status: 'inactive',
      budget: 'Budget',
      conversation: 324,
      handles: 4,
    },
    { content: 'LG TVs', status: 'active', budget: 'Budget', conversation: 12, handles: 35 },
    {
      content: 'Louis Vuittin Bags',
      status: 'inactive',
      budget: 'Timeframe',
      conversation: 7,
      handles: 567,
    },
  ]);
  const [isContentVisible, _setIsContentVisible] = useState<boolean>(false);

  const _renderContenTable = () => {
    const columns: Array<Column> = [
      {
        dataIndex: '',
        align: 'left' as AlignTypes,
        render: (_, item: any) => {
          return (
            <>
              <div className='text-2xl'>
                <div>
                  {item.content} <span className='text-primary-blue'>({item.budget})</span>
                </div>
              </div>
              <div className='text-xl'>
                <div>
                  <span className='text-primary-orange'>{item.conversation}</span> Conversation -{' '}
                  <span className='text-primary-orange'> {item.handles}</span> Handles
                </div>
              </div>
            </>
          );
        },
      },
      {
        dataIndex: 'status',
        align: 'right' as AlignTypes,
        veticalAlign: 'top' as VeticalAlignTypes,
        render: (status: string) => {
          return (
            <div
              className={`${getStatusClass(
                status
              )} text-2xl flex justify-end items-start align-top capitalize`}
            >
              {status}
            </div>
          );
        },
      },
    ];

    return (
      <>
        <div className='' style={{ boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15266)' }}>
          <div className='pt-6'>
            <div className='relative shadow-sm w-full'>
              <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
                <SearchIcon className='h-5 w-5 text-primary-light-grey' aria-hidden='true' />
              </div>
              <input
                type='search'
                name='search'
                className='tw-input-blue-dark !pl-10 h-8 w-full sm:w-52'
                placeholder={translate('common.search')}
              />
            </div>
          </div>
          <Table columns={columns} dataSource={content} hover />
        </div>
      </>
    );
  };

  return (
    <div className='bg-primary-dark-grey border-2 border-t-0 border-primary-blue px-4 relative overflow-hidden'>
      {isContentVisible ? (
        _renderContenTable()
      ) : (
        <div className='h-[370px] flex justify-center'>
          <ComingSoon />
        </div>
      )}
    </div>
  );
};
