import { CustomCheckbox, OptionType } from 'components/shared';
import { useTranslation } from 'react-i18next';
import { whatTurnsYouOffOptions } from 'utils/helperFunctions';

type Props = {
  onChange: (keys: any) => void;
  metadata?: any;
};

export const WizardNewTurnsYouOff = (props: Props) => {
  const { onChange, metadata } = props;
  const { t } = useTranslation();
  const onChangeInfo = (data = {}) => {
    onChange(data);
  };

  const options: OptionType[] = whatTurnsYouOffOptions.map(({ detail, title }) => ({
    title: t(title),
    detail: t(detail) || '',
  }));

  return (
    <div className='flex flex-col justify-center items-center gap-8 mt-4 mb-6 mx-4 sm:mx-8 md:mx-16 lg:mx-40 text-center'>
      <div className='font-Condensed text-white text-[36px] font-bold'>
        {t('metadataScreen.whatTurnsYouOff')}?
      </div>
      <div className='text-[20px] sm:text-[24px] -mb-4 sm:mb-0 w-[100%] text-primary-light-grey font-normal text-left sm:text-center'>
        {t('metadataScreen.WhatDoYouNotWantToTalkAbout')}
      </div>
      <CustomCheckbox
        className={'grid grid-cols-2 md:grid-cols-3 gap-4'}
        borderColor={'border-primary-blue'}
        onSelectColor='bg-[#074257]'
        options={options}
        selectedPackages={metadata.turnsYouOff || []}
        onChange={e => {
          onChangeInfo({ ...metadata, turnsYouOff: e });
        }}
      />
    </div>
  );
};
