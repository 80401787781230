import { createContext } from 'react';
import { AuthState } from './Auth/reducer';

export const StoreContext = createContext<
  | {
      state: AuthState;
      dispatch: any;
    }
  | undefined
>(undefined);
