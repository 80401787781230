import store from 'services/context/store';
import { AuthServices } from '../Auth';
import { UracServices } from '../Urac';
import { setIsLoggedIn, setLoginData, updateUserAccount } from 'services/context/Auth/actions';
import { getStorageLoginData } from 'utils/StorageServices';

export const AuthSessionServices = {
  createSession: async () => {
    try {
      const loginData = getStorageLoginData();
      if (!loginData?.access_token || !loginData?.refresh_token) {
        throw new Error('No login data found');
      }
      const user = await UracServices.fetchUser();
      if (!user) {
        // in case API returns null but not an error
        throw new Error('User not found');
      }
      store.dispatch(setIsLoggedIn(true));
    } catch (error: any) {
      console.log('error', error?.message);
      AuthSessionServices.clearSession();
    }
  },
  clearSession: async (options: { shouldLogout?: boolean } = { shouldLogout: true }) => {
    const { shouldLogout } = options;
    if (shouldLogout) {
      try {
        await AuthServices.logout();
      } catch (error: any) {
        console.log('error', error?.message);
      }
    }

    try {
      localStorage.clear();
    } catch (error: any) {
      console.log('error', error?.message);
    }

    store.dispatch(updateUserAccount(null));
    store.dispatch(setIsLoggedIn(false));
    store.dispatch(setLoginData(null));
  },
};
