import { CustomRadio, Select } from 'components/shared';
import SureToSubmit from 'components/shared/SureToSubmit';
import { useEffect, useState } from 'react';
import { BsFillExclamationCircleFill } from 'react-icons/bs';
import PhoneInput from 'react-phone-input-2';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ReferralServices } from 'services/apis/Referral';
import { translate } from 'utils/helperFunctions';

type Props = {
  setIsSubmitted: (error: any) => void;
};

export const AddAgency = ({ setIsSubmitted }: Props) => {
  const navigate = useNavigate();
  const { membership, referralId } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [addAgency, setAddAgency] = useState<any>({
    referee: {
      contactInfo: {
        countryCode: '1',
        phone: '',
        concatenatedPhoneNumber: '1',
        firstName: '',
        lastName: '',
        email: '',
        linkedIn: '',
        decisionMaker: null,
      },
      agencyName: '',
      linkedIn: '',
      website: '',
    },
    membership: membership,
    relation: '',
    description: '',
  });

  const [errors, setErrors] = useState<any>({
    phone: '',
    firstName: '',
    lastName: '',
    email: '',
    linkedIn: '',
    decisionMaker: '',
    relation: '',
    description: '',
    agencyName: '',
    agencyLinkedIn: '',
    website: '',
  });

  const fetchRferraldetails = async () => {
    if (referralId) {
      try {
        const detail = await ReferralServices.referralDetails(referralId);
        setAddAgency({
          ...addAgency,
          referee: {
            ...addAgency.referee,
            contactInfo: {
              ...addAgency.referee.contactInfo,
              countryCode: detail?.referee?.contactInfo?.countryCode || '1',
              phone: detail?.referee?.contactInfo?.phone || '',
              concatenatedPhoneNumber: detail?.referee?.contactInfo?.concatenatedPhoneNumber || '1',
              firstName: detail?.referee?.contactInfo?.firstName || '',
              lastName: detail?.referee?.contactInfo?.lastName || '',
              email: detail?.referee?.contactInfo?.email || '',
              linkedIn: detail?.referee?.contactInfo?.linkedIn || '',
              decisionMaker: detail?.referee?.contactInfo?.decisionMaker || true,
            },
            agencyName: detail?.referee?.agencyName || '',
            linkedIn: detail?.referee?.linkedIn || '',
            website: detail?.referee?.website || '',
          },
          membership: membership,
          relation: detail?.relation || '',
          description: detail?.description || '',
        });
      } catch (error: any) {
        toast.error(error?.message);
      }
    }
  };

  useEffect(() => {
    if (referralId !== 'new') {
      fetchRferraldetails();
    }
  }, []);

  const saveAgencyRefferal = async () => {
    setIsSubmitted(true);
    try {
      setLoading(true);
      if (referralId === 'new') {
        await ReferralServices.addReferral('agency', {
          ...addAgency,
          referee: {
            ...addAgency.referee,
            contactInfo: {
              ...addAgency.referee.contactInfo,
              linkedIn:
                addAgency?.referee?.contactInfo?.linkedIn.startsWith('http://') ||
                addAgency?.referee?.contactInfo?.linkedIn.startsWith('https://')
                  ? addAgency?.referee?.contactInfo?.linkedIn
                  : `https://${addAgency?.referee?.contactInfo?.linkedIn}`,
            },
            linkedIn:
              addAgency?.referee?.linkedIn.startsWith('http://') ||
              addAgency?.referee?.linkedIn.startsWith('https://')
                ? addAgency?.referee?.linkedIn
                : `https://${addAgency?.referee?.linkedIn}`,
          },
        });
      } else if (referralId && referralId !== 'new') {
        await ReferralServices.EditReferralCompany(referralId, {
          ...addAgency,
          referee: {
            ...addAgency.referee,
            contactInfo: {
              ...addAgency.referee.contactInfo,
              linkedIn:
                addAgency?.referee?.contactInfo?.linkedIn.startsWith('http://') ||
                addAgency?.referee?.contactInfo?.linkedIn.startsWith('https://')
                  ? addAgency?.referee?.contactInfo?.linkedIn
                  : `https://${addAgency?.referee?.contactInfo?.linkedIn}`,
            },
            linkedIn:
              addAgency?.referee?.linkedIn.startsWith('http://') ||
              addAgency?.referee?.linkedIn.startsWith('https://')
                ? addAgency?.referee?.linkedIn
                : `https://${addAgency?.referee?.linkedIn}`,
          },
        });
      }
      setAddAgency({
        referee: {
          contactInfo: {
            countryCode: '1',
            phone: '',
            concatenatedPhoneNumber: '1',
            firstName: '',
            lastName: '',
            email: '',
            linkedIn: '',
            decisionMaker: null,
          },
          agencyName: '',
          linkedIn: '',
          website: '',
        },
        membership: membership,
        relation: '',
        description: '',
      });
      toast.success(
        referralId === 'new'
          ? translate('handlesScreen.referralCreatedSuccessfully')
          : translate('handlesScreen.referralUpdatedSuccessfully')
      );
      setLoading(false);
      navigate(-1);
    } catch (error: any) {
      setIsSubmitted(false);
      setIsSubmit(false);
      setLoading(false);
      toast.error(error?.message);
    }
  };

  const submitAgencyRefferal = async () => {
    setIsSubmitted(true);
    try {
      setLoading(true);
      if (referralId === 'new') {
        await ReferralServices.addReferral('agency', {
          ...addAgency,
          referee: {
            ...addAgency.referee,
            contactInfo: {
              ...addAgency.referee.contactInfo,
              linkedIn:
                addAgency?.referee?.contactInfo?.linkedIn.startsWith('http://') ||
                addAgency?.referee?.contactInfo?.linkedIn.startsWith('https://')
                  ? addAgency?.referee?.contactInfo?.linkedIn
                  : `https://${addAgency?.referee?.contactInfo?.linkedIn}`,
            },
            linkedIn:
              addAgency?.referee?.linkedIn.startsWith('http://') ||
              addAgency?.referee?.linkedIn.startsWith('https://')
                ? addAgency?.referee?.linkedIn
                : `https://${addAgency?.referee?.linkedIn}`,
          },
          submit: true,
        });
      } else if (referralId && referralId !== 'new') {
        await ReferralServices.EditReferralCompany(referralId, {
          ...addAgency,
          referee: {
            ...addAgency.referee,
            contactInfo: {
              ...addAgency.referee.contactInfo,
              linkedIn:
                addAgency?.referee?.contactInfo?.linkedIn.startsWith('http://') ||
                addAgency?.referee?.contactInfo?.linkedIn.startsWith('https://')
                  ? addAgency?.referee?.contactInfo?.linkedIn
                  : `https://${addAgency?.referee?.contactInfo?.linkedIn}`,
            },
            linkedIn:
              addAgency?.referee?.linkedIn.startsWith('http://') ||
              addAgency?.referee?.linkedIn.startsWith('https://')
                ? addAgency?.referee?.linkedIn
                : `https://${addAgency?.referee?.linkedIn}`,
          },
          submit: true,
        });
      }
      setAddAgency({
        referee: {
          contactInfo: {
            countryCode: '1',
            phone: '',
            concatenatedPhoneNumber: '1',
            firstName: '',
            lastName: '',
            email: '',
            linkedIn: '',
            decisionMaker: null,
          },
          agencyName: '',
          linkedIn: '',
          website: '',
        },
        membership: membership,
        relation: '',
        description: '',
      });
      toast.success(
        referralId === 'new'
          ? translate('handlesScreen.referralCreatedSubmitedSuccessfully')
          : translate('handlesScreen.referralUpdatedSubmitedSuccessfully')
      );
      setLoading(false);
      navigate(-1);
    } catch (error: any) {
      setIsSubmitted(false);
      setIsSubmit(false);
      setLoading(false);
      toast.error(error?.message);
    }
  };

  const validateInputs = () => {
    const regexPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/g;
    const updatedErrors: any = {};
    Object.keys(addAgency).forEach(key => {
      if (key === 'referee') {
        Object.keys(addAgency.referee).forEach(k => {
          if (k === 'contactInfo') {
            Object.keys(addAgency?.referee?.contactInfo).forEach(ky => {
              if (addAgency?.referee?.contactInfo) {
                if (ky === 'email') {
                  if (!addAgency?.referee?.contactInfo[ky]) {
                    updatedErrors[ky] = translate('common.isRequired');
                  } else if (!regexPattern.test(addAgency?.referee?.contactInfo[ky] as string)) {
                    updatedErrors[ky] = translate('common.isUnvalid');
                  }
                } else if (ky === 'decisionMaker') {
                  if (addAgency?.referee?.contactInfo[ky] === null) {
                    updatedErrors[ky] = translate('common.isRequired');
                  } else {
                    updatedErrors[ky] = '';
                  }
                } else if (!addAgency?.referee?.contactInfo[ky]) {
                  updatedErrors[ky] = translate('common.isRequired');
                } else {
                  updatedErrors[ky] = '';
                }
              } else {
                updatedErrors[ky] = translate('common.isRequired');
              }
            });
          } else if (k === 'linkedIn') {
            if (!addAgency.referee.linkedIn) {
              updatedErrors.agencyLinkedIn = translate('common.isRequired');
            } else {
              updatedErrors.agencyLinkedIn = '';
            }
          } else if (!addAgency.referee[k]) {
            updatedErrors[k] = translate('common.isRequired');
          } else {
            updatedErrors[k] = '';
          }
        });
      } else if (!addAgency[key]) {
        updatedErrors[key] = translate('common.isRequired');
      } else {
        updatedErrors[key] = '';
      }
    });
    const isFormValid = Object.values(updatedErrors).every(error => error === '');

    if (isFormValid) {
      setIsVisible(true);
    }
    setErrors(updatedErrors);
  };

  return (
    <>
      <SureToSubmit
        message={
          isSubmit
            ? translate('handlesScreen.areYouSureYouWantToSubmitThisReferral')
            : translate('handlesScreen.areYouSureYouWantToSaveThisReferral')
        }
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        submitFunction={isSubmit ? submitAgencyRefferal : saveAgencyRefferal}
        okText={isSubmit ? translate('common.submit') : translate('common.save')}
        cancelText={`${translate('common.cancel')}`}
      />
      <div className='flex flex-col gap-6'>
        <div className='input-wrapper relative'>
          <label htmlFor='agnecyName' className='tw-label'>
            *{translate('handlesScreen.agencyName')}:
          </label>
          <input
            className={`tw-input-primary relative ${
              errors.agencyName ? '!border-primary-dark-red' : ''
            }`}
            id='agencyName'
            type='text'
            autoComplete={'off'}
            value={addAgency?.referee?.agencyName}
            placeholder={translate('handlesScreen.enterAgencyName')}
            onChange={e => {
              const copy = { ...addAgency };
              copy.referee.agencyName = e.target.value;
              setAddAgency(copy);
              if (e.target.value) {
                setErrors({ ...errors, agencyName: '' });
              }
            }}
          />
          {errors.agencyName && (
            <>
              <BsFillExclamationCircleFill className='absolute right-3 top-11 w-15 !text-xl text-primary-dark-red' />
              <p className='absolute left-1 -bottom-5 text-[12px] text-primary-dark-red'>
                {errors.agencyName}
              </p>
            </>
          )}
        </div>
        <div className='input-wrapper relative'>
          <label htmlFor='firstName' className='tw-label'>
            *{translate('handlesScreen.agencyLinkedin')}:
          </label>
          <input
            className={`tw-input-primary relative ${
              errors.agencyLinkedIn ? '!border-primary-dark-red' : ''
            }`}
            id='firstName'
            type='text'
            autoComplete={'off'}
            value={addAgency?.referee?.linkedIn}
            placeholder={translate('handlesScreen.enterAgencyProfile')}
            onChange={e => {
              const copy = { ...addAgency };
              copy.referee.linkedIn = e.target.value;
              setAddAgency(copy);
              if (e.target.value) {
                setErrors({ ...errors, agencyLinkedIn: '' });
              }
            }}
          />
          {errors.agencyLinkedIn && (
            <>
              <BsFillExclamationCircleFill className='absolute right-3 top-11 w-15 !text-xl text-primary-dark-red' />
              <p className='absolute left-1 -bottom-5 text-[12px] text-primary-dark-red'>
                {errors.agencyLinkedIn}
              </p>
            </>
          )}
        </div>
        <div className='input-wrapper relative'>
          <label htmlFor='website' className='tw-label'>
            *{translate('handlesScreen.agencyWebsite')}:
          </label>
          <input
            className={`tw-input-primary relative ${
              errors.website ? '!border-primary-dark-red' : ''
            }`}
            id='website'
            type='text'
            autoComplete={'off'}
            value={addAgency?.referee?.website}
            placeholder={translate('handlesScreen.enterURL')}
            onChange={e => {
              const copy = { ...addAgency };
              copy.referee.website = e.target.value;
              setAddAgency(copy);
              if (e.target.value) {
                setErrors({ ...errors, website: '' });
              }
            }}
          />
          {errors.website && (
            <>
              <BsFillExclamationCircleFill className='absolute right-3 top-11 w-15 !text-xl text-primary-dark-red' />
              <p className='absolute left-1 -bottom-5 text-[12px] text-primary-dark-red'>
                {errors.website}
              </p>
            </>
          )}
        </div>
        <div className='input-wrapper relative'>
          <label htmlFor='firstName' className='tw-label !text-[15px] sm:text-[16px]'>
            *{translate('handlesScreen.howDoYouKnowThisPerson/Company')}?
          </label>
          <Select
            options={[
              { label: 'friend', value: 'friend' },
              { label: 'from school', value: 'school' },
              { label: 'work', value: 'work' },
            ]}
            className={`bg-primary-medium-grey relative w-full shadow-sm pl-3 text-left cursor-pointer focus:outline-none sm:text-sm border-2 h-12 rounded  ${
              errors.relation ? '!border-primary-dark-red' : 'border-primary-light-grey'
            }`}
            placeholderStyle='!text-[16px] pl-2'
            value={addAgency?.relation}
            preValue={addAgency?.relation && ''}
            placeholder={`${translate('handlesScreen.selectWay')}`}
            menuBg='bg-primary-medium-grey mt-[10px] border-2 !text-base rounded border-primary-orange'
            focusOnOpen={true}
            onChange={e => {
              setAddAgency({ ...addAgency, relation: e?.value });
              if (e?.value) {
                setErrors({ ...errors, relation: '' });
              }
            }}
          />
          {errors.relation && (
            <>
              <p className='absolute left-1 -bottom-5 text-[12px] text-primary-dark-red'>
                {errors.relation}
              </p>
            </>
          )}
        </div>
        <div className='input-wrapper relative'>
          <textarea
            placeholder={translate('handlesScreen.tellUsMoreAboutThisReferral')}
            value={addAgency?.description}
            onChange={e => {
              setAddAgency({ ...addAgency, description: e.target.value });
              if (e.target.value) {
                setErrors({ ...errors, description: '' });
              }
            }}
            className={`primaryTextArea sm:text-xl mt-2 focus:!border-primary-orange !text-sm ${
              errors.description ? '!border-primary-dark-red' : '!border-primary-light-grey'
            }`}
          />
          {errors.description && (
            <>
              <p className='absolute left-1 -bottom-5 text-[12px] text-primary-dark-red'>
                {errors.description}
              </p>
            </>
          )}
        </div>
        <hr
          className={`border-2 border-primary-light-grey w-full ${
            errors.description ? 'mt-2' : ''
          }`}
        />
        <p className='font-[600] text-[16px] text-primary-light-grey'>
          {translate('handlesScreen.contactDetails')}
        </p>
        <div className='relative'>
          <p className='font-normal mb-2 text-[16px] text-primary-light-grey'>
            {translate('handlesScreen.isTheContactDesicionMaker')}?
          </p>
          <CustomRadio
            wrapperClassName='!flex-row'
            RadioClassName={`${errors.decisionMaker ? '!outline-primary-dark-red' : ''}`}
            labelClassName='!font-normal !text-[16px] !text-primary-light-grey !ml-2'
            options={[
              {
                value: true,
                label: `${translate('common.yes')}`,
              },
              { value: false, label: `${translate('common.no')}` },
            ]}
            value={addAgency?.referee?.contactInfo?.decisionMaker}
            onChange={e => {
              const copy = { ...addAgency };
              copy.referee.contactInfo.decisionMaker = e?.value;
              setAddAgency(copy);
              if (e?.value !== null) {
                setErrors({ ...errors, decisionMaker: '' });
              }
            }}
          />
          {errors.decisionMaker && (
            <>
              <p className='absolute left-1 -bottom-5 text-[12px] text-primary-dark-red'>
                {errors.decisionMaker}
              </p>
            </>
          )}
        </div>
        <div className='input-wrapper relative'>
          <label htmlFor='firstName' className='tw-label'>
            *{translate('handlesScreen.firstName')}:
          </label>
          <input
            className={`tw-input-primary relative
          ${errors.firstName ? '!border-primary-dark-red' : ''}`}
            id='firstName'
            type='text'
            autoComplete={'off'}
            value={addAgency?.referee?.contactInfo?.firstName}
            placeholder={translate('handlesScreen.enterFirstName')}
            onChange={e => {
              const copy = { ...addAgency };
              copy.referee.contactInfo.firstName = e.target.value;
              setAddAgency(copy);
              if (e.target.value) {
                setErrors({ ...errors, firstName: '' });
              }
            }}
          />
          {errors.firstName && (
            <>
              <BsFillExclamationCircleFill className='absolute right-3 top-11 w-15 !text-xl text-primary-dark-red' />
              <p className='absolute left-1 -bottom-5 text-[12px] text-primary-dark-red'>
                {errors.firstName}
              </p>
            </>
          )}
        </div>
        <div className='input-wrapper relative'>
          <label htmlFor='lastName' className='tw-label'>
            *{translate('handlesScreen.lastName')}:
          </label>
          <input
            className={`tw-input-primary relative
          ${errors.lastName ? '!border-primary-dark-red' : ''}`}
            id='lastName'
            type='text'
            autoComplete={'off'}
            value={addAgency?.referee?.contactInfo?.lastName}
            placeholder={translate('handlesScreen.enterLastName')}
            onChange={e => {
              const copy = { ...addAgency };
              copy.referee.contactInfo.lastName = e.target.value;
              setAddAgency(copy);
              if (e.target.value) {
                setErrors({ ...errors, lastName: '' });
              }
            }}
          />
          {errors.lastName && (
            <>
              <BsFillExclamationCircleFill className='absolute right-3 top-11 w-15 !text-xl text-primary-dark-red' />
              <p className='absolute left-1 -bottom-5 text-[12px] text-primary-dark-red'>
                {errors.lastName}
              </p>
            </>
          )}
        </div>
        <div className='input-wrapper'>
          <label htmlFor='phone' className='tw-label'>
            *{translate('handlesScreen.phoneNumber')}:
          </label>
          <div className='flex gap-2 relative'>
            <input
              className={`tw-input-primary relative !pl-[110px]
          ${errors.phone ? '!border-primary-dark-red' : ''}`}
              id='phone'
              type='text'
              autoComplete={'off'}
              maxLength={10}
              value={addAgency?.referee?.contactInfo?.phone}
              placeholder={translate('handlesScreen.enterPhone')}
              onChange={e => {
                if (/^[0-9]+$/g.test(e.target.value) || !e.target.value) {
                  const copy = { ...addAgency };
                  copy.referee.contactInfo.phone = e.target.value;
                  copy.referee.contactInfo.concatenatedPhoneNumber =
                    copy.referee.contactInfo.countryCode + e.target.value;
                  setAddAgency(copy);
                  if (e.target.value) {
                    setErrors({ ...errors, phone: '' });
                  }
                }
              }}
            />
            <span className='absolute transform -translate-y-1/2 top-[27px] left-2 z-10'>
              <PhoneInput
                country={'us'}
                buttonStyle={{ background: '#171717', border: 'none', bottom: '1px' }}
                containerStyle={{
                  width: `${innerWidth < 640 ? '100px' : '100px'}`,
                  margin: 'auto',
                }}
                dropdownStyle={{ color: 'white', background: '#6F7577' }}
                value={addAgency?.referee?.contactInfo?.countryCode || '1'}
                onChange={(phone: any) => {
                  const copy = { ...addAgency };
                  copy.referee.contactInfo.countryCode = phone;
                  copy.referee.contactInfo.concatenatedPhoneNumber =
                    phone + copy.referee.contactInfo.phone;
                  setAddAgency(copy);
                }}
                placeholder={''}
                inputProps={{
                  className:
                    'block w-full border-0 border-white/50  bg-primary-medium-grey focus:border-white focus:ring-0 text-right !pl-10 focus::!bg-black',
                }}
              />
            </span>
            {errors.phone && (
              <>
                <BsFillExclamationCircleFill className='absolute right-3 top-[1rem] w-15 !text-xl text-primary-dark-red' />
                <p className='absolute left-1 -bottom-5 text-[12px] text-primary-dark-red'>
                  {errors.phone}
                </p>
              </>
            )}
          </div>
        </div>
        <div className='input-wrapper relative'>
          <label htmlFor='email' className='tw-label'>
            *{translate('handlesScreen.email')}:
          </label>
          <input
            className={`tw-input-primary relative  ${
              errors.email ? '!border-primary-dark-red' : ''
            }`}
            id='email'
            type='text'
            autoComplete={'off'}
            value={addAgency?.referee?.contactInfo?.email}
            placeholder={translate('handlesScreen.enterEmail')}
            onChange={e => {
              const copy = { ...addAgency };
              copy.referee.contactInfo.email = e.target.value;
              setAddAgency(copy);
              if (e.target.value) {
                setErrors({ ...errors, email: '' });
              }
            }}
          />
          {errors.email ? (
            <>
              <BsFillExclamationCircleFill className='absolute right-3 top-11 w-15 !text-xl text-primary-dark-red' />
              <p className='absolute left-1 -bottom-5 text-[12px] text-primary-dark-red'>
                {errors.email}
              </p>
            </>
          ) : addAgency?.referee?.contactInfo?.email &&
            !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/g.test(
              addAgency?.referee?.contactInfo?.email as string
            ) ? (
            <>
              <BsFillExclamationCircleFill className='absolute right-3 top-11 w-15 !text-xl text-primary-dark-red' />
              <p className='absolute left-1 -bottom-5 text-[12px] text-primary-dark-red'>
                {translate('common.isUnvalid')}
              </p>
            </>
          ) : (
            ''
          )}
        </div>
        <div className='input-wrapper relative'>
          <label htmlFor='linkedin' className='tw-label'>
            *{translate('handlesScreen.linkedin')}:
          </label>
          <input
            className={`tw-input-primary relative
          ${errors.linkedIn ? '!border-primary-dark-red' : ''}`}
            id='linkedin'
            type='text'
            autoComplete={'off'}
            value={addAgency?.referee?.contactInfo?.linkedIn}
            placeholder={translate('handlesScreen.enterContactsProfile')}
            onChange={e => {
              const copy = { ...addAgency };
              copy.referee.contactInfo.linkedIn = e.target.value;
              setAddAgency(copy);
              if (e.target.value) {
                setErrors({ ...errors, linkedIn: '' });
              }
            }}
          />
          {errors.linkedIn && (
            <>
              <BsFillExclamationCircleFill className='absolute right-3 top-11 w-15 !text-xl text-primary-dark-red' />
              <p className='absolute left-1 -bottom-5 text-[12px] text-primary-dark-red'>
                {errors.linkedIn}
              </p>
            </>
          )}
        </div>
        <hr className='border-2 border-primary-light-grey w-full sm:hidden' />
        <div className='w-full flex flex-col-reverse sm:flex-row justify-center gap-5 mb-6'>
          <button
            type='button'
            onClick={() => {
              setIsSubmit(true);
              validateInputs();
            }}
            disabled={loading}
            className='tw-input-orange-dark-secondary !bg-transparent !text-center !w-full sm:!w-72'
          >
            {translate('handlesScreen.submitForApproval')}
          </button>
          <button
            type='button'
            onClick={() => {
              setIsSubmit(false);
              validateInputs();
            }}
            disabled={loading}
            className='tw-button-light-blue !w-full sm:!w-72'
          >
            {translate('handlesScreen.saveAndContinueLater')}
          </button>
        </div>
      </div>
    </>
  );
};
