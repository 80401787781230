import React, { useEffect, useState } from 'react';
import {
  calcPagination,
  getMembership,
  getStatus,
  getStatusClass,
  translate,
} from 'utils/helperFunctions';
import {
  AlignTypes,
  Column,
  Pagination,
  Select,
  Spiner,
  Table,
  VeticalAlignTypes,
} from 'components/shared';
import { SearchIcon } from '@heroicons/react/outline';
import { ReferralServices } from 'services/apis/Referral';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { UseAbility } from 'services/context';
import MobileCards, { MobileView } from 'components/shared/MobileCards';

const options = [
  { label: 'Investor-Creator', value: 'influencer' },
  { label: 'Ambassador', value: 'ambassador' },
  { label: 'Agency', value: 'agency' },
];

let debounce: any = null;
export const Referrals = () => {
  const { membership, id } = useParams();
  const canAddCompanyReferral = UseAbility('companyReferral', 'add');
  const canAddInfluencerReferral = UseAbility('influencerReferral', 'add');
  const canAddAgencyReferral = UseAbility('agencyReferral', 'add');
  const canAddAmbassadorReferral = UseAbility('ambassadorReferral', 'add');
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [referrals, setReferrals] = useState<any[]>([]);
  const [countUsersPages, setCountUsersPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [filter, setFilter] = useState<{ [key: string]: any }>({
    keywords: '',
    referrerMembership: membership,
  });

  const [_isContentVisible, _setIsContentVisible] = useState<boolean>(false);

  const fetchRefferals = async (params: any = {}) => {
    try {
      setLoading(true);
      const finalParams = { ...calcPagination(), ...filter, ...params };
      const list = await ReferralServices.referralList(finalParams);
      setReferrals(list.items);
      setCountUsersPages(list.count);
      if (list.items.length > 0) setCurrentPage(finalParams.skip / finalParams.limit + 1);
      setLoading(false);
    } catch (error: any) {
      toast.error(error?.message);
      setLoading(false);
    }
  };

  function filterOptions() {
    if (membership === 'ambassador') {
      return options.filter(option => option.value !== 'ambassador');
    } else if (membership === 'agency') {
      return options.filter(option => option.value !== 'ambassador' && option.value !== 'agency');
    } else {
      return options;
    }
  }

  useEffect(() => {
    fetchRefferals();
  }, [filter?.refereeMembership, filter?.status]);

  const _renderContenTable = () => {
    const columns: Array<Column> = [
      {
        dataIndex: '',
        className: '!pr-0 bg-red',
        align: 'left' as AlignTypes,
        render: (_, item: any) => {
          return (
            <>
              <div className='pb-3 sm:pb-2 w-fit'>
                <div className='text-xl sm:text-2xl max-w-[170px]'>
                  <span className='capitalize'>
                    {item?.referee?.contactInfo?.firstName} {item?.referee?.contactInfo?.lastName}{' '}
                  </span>

                  <span className='text-primary-orange text-xl pl-1 capitalize'>
                    {item?.referee?.companyName
                      ? translate('common.brand')
                      : getMembership(item?.referee?.membership)}
                  </span>
                </div>
              </div>
              <div className='w-fit sm:pb-2'>
                <div className='flex flex-col w-fit'>
                  <span className='text-xl sm:text-2xl pb-3 sm:pb-1 w-fit'>
                    +{item?.referee?.contactInfo?.concatenatedPhoneNumber}
                  </span>
                  <span className='w-fit text-xl sm:text-2xl emailStyle'>
                    {item?.referee?.contactInfo?.email}
                  </span>{' '}
                </div>
              </div>
            </>
          );
        },
      },
      {
        dataIndex: 'status',
        align: 'right' as AlignTypes,
        veticalAlign: 'bottom' as VeticalAlignTypes,
        render: (status: string) => {
          return (
            <div
              className={`${getStatusClass(
                status
              )} text-2xl flex justify-end items-start align-top capitalize`}
            >
              {getStatus(status)}
            </div>
          );
        },
      },
    ];

    const mobileColumns: Array<MobileView> = [
      {
        dataIndex: 'name',
        render: (_: string, item) => (
          <div className='text-base font-bold capitalize'>
            {item?.referee?.contactInfo?.firstName} {item?.referee?.contactInfo?.lastName}
          </div>
        ),
      },
      {
        dataIndex: 'membership',
        title: translate('common.membership'),
        render: (_, item) => {
          return (
            <>
              {item?.referee?.companyName
                ? translate('common.brand')
                : getMembership(item?.referee?.membership)}
            </>
          );
        },
      },
      {
        dataIndex: 'contactNumber',
        title: translate('common.contactNumber'),
        render: (_, item) =>
          (item?.referee?.contactInfo?.concatenatedPhoneNumber &&
            `+${item?.referee?.contactInfo?.concatenatedPhoneNumber}`) ||
          '-',
      },
      {
        dataIndex: 'emailAddress',
        title: translate('common.emailAddress'),
        render: (_, item) => item?.referee?.contactInfo?.email || '-',
      },
      {
        dataIndex: 'status',
        title: translate('common.status'),
        render: (status: string) => {
          return (
            <div
              className={`${getStatusClass(
                status
              )} flex justify-end items-start align-top capitalize`}
            >
              {getStatus(status)}
            </div>
          );
        },
      },
    ];

    const { limit, skip } = calcPagination(currentPage);

    return (
      <>
        <div
          className={`bg-primary-dark-grey border-2 ${
            membership === 'venturePartner' ? 'border-t-2' : 'border-t-0'
          } border-primary-blue px-4 relative`}
          style={{ boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15266)' }}
        >
          {membership === 'venturePartner' && (
            <h1 className='text-[32px] font-bold pt-4'>{translate('common.referrals')}</h1>
          )}
          <div className='pt-6 flex gap-5 items-center justify-between'>
            <div className='relative shadow-sm grow w-full'>
              <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
                <SearchIcon className='h-5 w-5 text-primary-light-grey' aria-hidden='true' />
              </div>
              <input
                type='search'
                name='search'
                className='tw-input-blue-dark !border-[3px] !bg-primary-medium-grey placeholder:!bg-primary-medium-grey placeholder:!text-lg font-bold !pl-10 h-12 w-full '
                placeholder={translate('common.search')}
                value={filter?.keywords || ''}
                onChange={e => {
                  setFilter({ ...filter, keywords: e.target.value });
                  clearTimeout(debounce);
                  debounce = setTimeout(() => {
                    fetchRefferals({ keywords: e?.target?.value });
                  }, 300);
                }}
              />
            </div>
            {(canAddCompanyReferral ||
              canAddInfluencerReferral ||
              canAddAgencyReferral ||
              canAddAmbassadorReferral) && (
              <div className='flex justify-end'>
                <button
                  type='button'
                  className='tw-button-light-blue !h-12 w-[85px] md:w-[175px] !p-4'
                  onClick={() => {
                    navigate(`/home/mission-control/${membership}/${id}/referral/new`, {
                      state: false,
                    });
                  }}
                >
                  {translate('handlesScreen.addReferral')}
                </button>
              </div>
            )}
          </div>
          <div className='flex gap-5 mt-4 flex-wrap'>
            {(membership === 'agency' ||
              membership === 'superAmbassador' ||
              membership === 'ambassador') && (
              <Select
                options={filterOptions()}
                value={filter?.refereeMembership || 'all'}
                className='bg-primary-medium-grey relative w-40 shadow-sm pl-3 pr-10 text-left cursor-default focus:outline-none sm:text-sm border-2  !border-primary-blue h-8'
                preValue={filter?.refereeMembership ? '' : translate('common.type')}
                menuBg='bg-primary-medium-grey'
                onChange={e => {
                  if (e?.value !== 'company') {
                    setFilter({
                      ...filter,
                      refereeMembership: e?.value === 'all' ? undefined : e?.value,
                    });
                  } else {
                    setFilter({ ...filter, refereeMembership: e?.value });
                  }
                }}
              />
            )}
            <Select
              options={[
                { label: 'All', value: 'all' },
                { label: 'New', value: 'new' },
                { label: 'Pending', value: 'pending' },
                { label: 'Accepted', value: 'accepted' },
                { label: 'Rejected', value: 'rejected' },
              ]}
              className='bg-primary-medium-grey relative sm:w-36 shadow-sm pl-3 pr-10 text-left cursor-default focus:outline-none sm:text-sm !border-2  !border-primary-blue h-8'
              value={filter?.status || 'all'}
              preValue={filter?.status ? '' : translate('common.status')}
              menuBg='bg-primary-medium-grey'
              onChange={e => {
                setFilter({ ...filter, status: e?.value === 'all' ? undefined : e?.value });
              }}
            />
          </div>
          {loading ? (
            <div className='flex items-center justify-center' style={{ minHeight: '208px' }}>
              <Spiner />
            </div>
          ) : (
            <>
              <div className='hidden sm:block'>
                <Table
                  columns={columns}
                  dataSource={referrals}
                  hover
                  onClickRow={(_index, item) =>
                    navigate(`/home/mission-control/${membership}/${id}/ref/${item._id}`)
                  }
                />
              </div>
              <div className='sm:hidden mt-4'>
                <MobileCards
                  dataSource={referrals}
                  dataView={mobileColumns}
                  onClickCard={(item, _index) =>
                    navigate(`/home/mission-control/${membership}/${id}/ref/${item._id}`)
                  }
                />
              </div>
            </>
          )}
          <Pagination
            count={countUsersPages}
            limit={limit}
            skip={skip}
            onChange={pageParams => {
              fetchRefferals(pageParams);
            }}
            className='mt-5 pr-4 pb-3'
          />
        </div>
      </>
    );
  };

  return <div className=''>{_renderContenTable()}</div>;
};
