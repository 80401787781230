import { ComingSoon } from 'components/shared';
import React from 'react';

export const HandlesScreen = () => {
  return (
    <div className='translate-y-48'>
      <ComingSoon />
    </div>
  );
};
