import DOMPurify from 'dompurify';

type Props = { value: string; applyStyle?: boolean; className?: string };

const ViewWYSIWYG = (props: Props) => {
  const { className, applyStyle = true } = props;
  function createMarkup(html: any) {
    return {
      __html: DOMPurify.sanitize(html),
    };
  }
  return (
    <div
      className={`${applyStyle ? 'wyswyg' : ''} ${className || ''}`}
      dangerouslySetInnerHTML={createMarkup(props?.value)}
    ></div>
  );
};

export default ViewWYSIWYG;
