import { translate } from '../../utils/helperFunctions';
import { ReactComponent as HomeIcon } from 'assets/images/HomeIcon.svg';
import { ReactComponent as HandlesIcon } from 'assets/images/HandlesIcon.svg';
import { ReactComponent as CompaniesIcon } from 'assets/images/CompaniesIcon.svg';
import { ReactComponent as HelpIcon } from 'assets/images/HelpIcon.svg';

export type ISidebarRoute = {
  name: string;
  path: string;
  icon: (isActive: boolean) => JSX.Element;
};

export const sideBarRoutes = (): ISidebarRoute[] => [
  {
    name: translate('common.home'),
    path: '/home',
    icon: isActive => (
      <HomeIcon
        fill={isActive ? '#FFFFFF' : '#FFFFFF'}
        className={`mr-3 ml-3 flex-shrink-0 h-6 w-6`}
      />
    ),
  },
  {
    name: translate('common.handles'),
    path: '/home/handles',
    icon: isActive => (
      <HandlesIcon
        fill={isActive ? '#FFFFFF' : '#ffffff'}
        className={`mr-3 ml-3 flex-shrink-0 h-6 w-6`}
      />
    ),
  },
  {
    name: translate('common.companies'),
    path: '/home/companies',
    icon: isActive => (
      <CompaniesIcon
        fill={isActive ? '#FFFFFF' : '#FFFFFF'}
        className={`mr-3 ml-3 flex-shrink-0 h-6 w-6`}
      />
    ),
  },
  {
    name: translate('common.help'),
    path: '/home/help',
    icon: isActive => (
      <HelpIcon
        fill={isActive ? '#FFFFFF' : '#FFFFFF'}
        className={`mr-3 ml-3 flex-shrink-0 h-6 w-6`}
      />
    ),
  },
];

export const getPathTitle = (pathname: string): string | JSX.Element => {
  try {
    const title: string = pathname.split('/')[2] || 'home';
    return translate(`common.${title}`);
  } catch (error) {
    return translate('common.home');
  }
};
