import React, { useCallback } from 'react';
import { IHandleDetails } from 'services/apis/Handle/handle.types';
import { translate } from 'utils/helperFunctions';
import HandleChangeDiff from './HandleChangeDiff';
import useMediaQuery from 'utils/hooks/useMediaQuery';
import { Image } from 'components/shared/Binary/Binary';

const HandleDiffScreenshots = ({ handle }: { handle: IHandleDetails | null }) => {
  const { mobile: isMobileLayout } = useMediaQuery({ mobile: true });

  const renderScreenshots = useCallback(
    (
      screenshots?: string[],
      options: {
        renderTitles?: boolean;
        old?: boolean;
      } = {
        renderTitles: true,
        old: true,
      }
    ) => {
      const sections = [
        {
          title: '',
          value: '',
          render: () => {
            return (
              <div className='w-full p-4'>
                {!!screenshots?.length && (
                  <div className='pb-4 flex'>
                    <div className='gap-4 flex flex-wrap'>
                      {screenshots.map(image => {
                        return (
                          <div key={image} className='inline-block w-[100px]'>
                            <Image
                              src={image}
                              containerClassName='max-w-[100px] h-[224px] rounded-lg inline-block object-cover'
                              alt='img'
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            );
          },
        },
      ];
      return <HandleChangeDiff sections={sections} itemClass='p-4' options={options} />;
    },
    []
  );

  const newScreenshots = handle?.handle_metadata?.holder?.metadata?.aboutYourPeople;
  const oldScreenshots = handle?.metadata?.aboutYourPeople;

  const changedNewScreenshots = newScreenshots?.filter(s => !oldScreenshots?.includes(s));
  const changedOldScreenshots = oldScreenshots?.filter(s => !newScreenshots?.includes(s));

  const noChange =
    !changedNewScreenshots?.length &&
    !changedOldScreenshots?.length &&
    newScreenshots?.length === oldScreenshots?.length;

  if (noChange) {
    return <></>;
  }

  return (
    <div
      className='bg-primary-dark-grey  xborder-t-0 p-4 capitalize flex flex-col gap-8  border-2 border-primary-blue'
      style={{ boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15266)' }}
    >
      <div className='flex'>
        <label className='font-bold text-3xl'>{translate('handlesScreen.screenshots')}</label>
      </div>

      <div className={`flex flex-col gap-4 ${isMobileLayout ? '' : 'ml-[80px]'}`}>
        <div className={`flex ${isMobileLayout ? 'flex-col-reverse gap-5' : 'flex-row'}`}>
          {!!changedNewScreenshots?.length &&
            renderScreenshots(changedNewScreenshots, { renderTitles: false, old: false })}
          {!!changedOldScreenshots?.length &&
            renderScreenshots(changedOldScreenshots, {
              old: true,
              renderTitles: false,
            })}
        </div>
      </div>
    </div>
  );
};

export default HandleDiffScreenshots;
