import React, { useEffect, useState } from 'react';
import { calcPagination, getStatusClass, debounce, translate } from 'utils/helperFunctions';
import {
  AlignTypes,
  Column,
  Pagination,
  Select,
  Spiner,
  Table,
  VeticalAlignTypes,
} from 'components/shared';
import { SearchIcon } from '@heroicons/react/outline';
import format from 'date-fns/format';
import { ConvoServices } from 'services/apis/Convo';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { Conversation } from 'services/apis/Convo/Convo.types';
import { UseAbility } from 'services/context';
import { GeneralNoAccess } from 'components/shared/GeneralNoAccess';

type Filter = {
  keywords: string;
  'sortBy[0][field]'?: string;
  'sortBy[0][type]'?: string;
  status?: string;
};

export const Conversations = () => {
  const canGetConversationsList = UseAbility('conversations', 'get');
  const { handleId } = useParams();
  const [countConversationsPages, setCountConversationsPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [isemptyStateVisible, setIsEmptyStateVisible] = useState<boolean>(false);
  const [content, setContent] = useState<Conversation[]>([]);
  const [filter, setFilter] = useState<Filter>({
    keywords: '',
  });
  const [loading, setLoading] = useState<boolean>(true);

  const fetchHandleConversationsList = async (params = calcPagination()) => {
    setLoading(true);
    try {
      if (handleId) {
        const list = await ConvoServices.handleConversations(handleId, params);
        setContent(list?.items);
        setCountConversationsPages(list.count);
        setCurrentPage(params.skip / params.limit + 1);
        if (list?.items?.length === 0) {
          setIsEmptyStateVisible(true);
        } else {
          setIsEmptyStateVisible(true);
        }
      }
    } catch (error: any) {
      toast.error(error?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchHandleConversationsList();
  }, []);

  const onChangeFilter = (newFilters = {}) => {
    setFilter({ ...filter, ...newFilters });
    fetchHandleConversationsList({ ...calcPagination(), ...filter, ...newFilters });
  };

  const handleSortChange = (value: string) => {
    let newFilters = {};

    if (value === 'newest') {
      newFilters = {
        skip: 0,
        'sortBy[0][field]': 'date',
        'sortBy[0][type]': 'desc',
      };
    } else if (value === 'oldest') {
      newFilters = {
        skip: 0,
        'sortBy[0][field]': 'date',
        'sortBy[0][type]': 'asc',
      };
    } else {
      newFilters = {
        skip: 0,
        'sortBy[0][field]': undefined,
        'sortBy[0][type]': undefined,
      };
    }

    onChangeFilter(newFilters);
  };

  const handleStatusChange = (value: string) => {
    if (value === 'all') {
      onChangeFilter({ skip: 0, status: undefined });
    } else {
      onChangeFilter({ skip: 0, status: value });
    }
  };

  const _renderContenTable = () => {
    const columns: Array<Column> = [
      {
        dataIndex: 'time',
        align: 'left' as AlignTypes,
        render: (_, item: Conversation) => {
          return (
            <div className='max-w-[170px] sm:max-w-none'>
              <div className='text-2xl'>
                <div>{item?.name || '-'}</div>
              </div>
              <div className='text-xl'>
                <div className='w-[270px] whitespace-normal'>
                  {translate('handlesScreen.postedOn')}{' '}
                  <span className='text-primary-orange'>
                    {format(new Date(item?.time?.createdAt), 'MMM dd, yyyy') || '-'}
                  </span>{' '}
                  <span className='lowercase'>{translate('handlesScreen.for')}</span>
                  <span className='text-primary-orange'> {item?.campaignName || '-'}</span>
                </div>
              </div>
            </div>
          );
        },
      },
      {
        dataIndex: 'status',
        align: 'right' as AlignTypes,
        veticalAlign: 'top' as VeticalAlignTypes,
        render: (status: string) => {
          return (
            <div
              className={`${getStatusClass(
                status
              )} text-2xl flex justify-end items-start align-top capitalize`}
            >
              {status}
            </div>
          );
        },
      },
    ];

    const { limit, skip } = calcPagination(currentPage);

    const handleChange = (e: any) => {
      const { value } = e.target;
      setFilter({ ...filter, keywords: value });
      debounce(() => onChangeFilter({ ...filter, keywords: value }));
    };

    return (
      <>
        {!filter.status &&
        !filter.keywords &&
        filter['sortBy[0][field]'] &&
        !isemptyStateVisible ? (
          <div className='flex justify-center items-center font-Condensed font-bold text-4xl h-32 md:h-full px-6 py-3 text-center'>
            {translate('handlesScreen.noConversationsHaveBeenCreatedYet')}
          </div>
        ) : (
          <div className='' style={{ boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15266)' }}>
            <div className='pt-6'>
              <div className='shadow-sm flex justify-start gap-5 xwhitespace-normal flex-wrap'>
                <div className='relative shrink-2'>
                  <div className='absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none'>
                    <SearchIcon className='h-5 w-5 text-primary-light-grey' aria-hidden='true' />
                  </div>
                  <input
                    type='search'
                    name='search'
                    className='tw-input-blue-dark !pl-8 h-8 w-[180px] placeholder:!text-sm placeholder:!font-bold placholder:!self-center !py-0 !pb-0.5'
                    placeholder={translate('common.search')}
                    value={filter?.keywords || ''}
                    onChange={handleChange}
                  />
                </div>
                <Select
                  options={[
                    { label: 'All', value: 'all' },
                    { label: 'Newest', value: 'newest' },
                    { label: 'Oldest', value: 'oldest' },
                  ]}
                  className='bg-primary-medium-grey relative w-[110px] shadow-sm pl-3 pr-10 text-left cursor-default focus:outline-none sm:text-sm border border-primary-blue h-8 !border-b-primary-blue'
                  value={filter['sortBy[0][field]'] || 'all'}
                  preValue={filter['sortBy[0][field]'] ? '' : translate('common.date')}
                  menuBg='bg-primary-medium-grey'
                  onChange={(e: any) => {
                    handleSortChange(e?.value);
                  }}
                />
                <Select
                  options={[
                    { label: 'All', value: 'all' },
                    { label: 'Active', value: 'active' },
                    { label: 'Approved', value: 'approved' },
                    { label: 'Accepted', value: 'accepted' },
                    { label: 'Pending', value: 'pending' },
                    { label: 'Inactive', value: 'inactive' },
                    { label: 'Rejected', value: 'rejected' },
                    { label: 'Inreview', value: 'inreview' },
                  ]}
                  className='bg-primary-medium-grey relative w-[130px] shadow-sm pl-3 pr-10 text-left cursor-default focus:outline-none sm:text-sm border border-primary-blue h-8 !border-b-primary-blue'
                  value={filter?.status || 'all'}
                  preValue={filter?.status ? '' : translate('common.status')}
                  menuBg='bg-primary-medium-grey'
                  onChange={(e: any) => {
                    handleStatusChange(e?.value);
                  }}
                />
              </div>
            </div>
            <>
              {!loading ? (
                <div>
                  <Table columns={columns} dataSource={content} hover />
                </div>
              ) : (
                <div className='flex items-center justify-center' style={{ minHeight: '208px' }}>
                  <Spiner />
                </div>
              )}
            </>

            <Pagination
              count={countConversationsPages}
              limit={limit}
              skip={skip}
              onChange={pageParams => {
                fetchHandleConversationsList(pageParams);
              }}
              className='mt-5 pr-4 pb-3'
            />
          </div>
        )}
      </>
    );
  };

  return (
    <div className='bg-primary-dark-grey border-2 border-t-0 border-primary-blue px-4 relative overflow-hidden'>
      <>
        <div>
          <div>{canGetConversationsList && _renderContenTable()}</div>
          <div>{!canGetConversationsList && <GeneralNoAccess border={false} />}</div>
        </div>
      </>
    </div>
  );
};
