import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { OnboardingServices } from 'services/apis/Onboarding';
import { Splash } from 'Pages';
import { toggleSplash } from 'services/context/Auth/actions';
import { useStore } from 'services/context';
import { clearInviteCode } from 'utils/helperFunctions';
import { setMembershipsStatus } from 'utils/StorageServices';

export const OnboardingWizard = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState<boolean>(false);
  const [, dispatch] = useStore();

  const fetchMembershipStatusbyId = async () => {
    if (id) {
      try {
        setRefresh(true);
        setTimeout(async () => {
          await OnboardingServices.fetchMembershipStatus(id)
            .then(res => {
              setRefresh(false);
              setMembershipsStatus([{ id, status: res.status }]);
              switch (res.status) {
                case 'accepted':
                  return navigate('home/mission-control');
                case 'new':
                case 'inProgress':
                case 'signed':
                case 'notSigned':
                case 'rejected':
                case 'waitingForSignature':
                  return navigate(`/home/onboarding-wizard/${id}/${res.status}`, {
                    replace: true,
                    state: { logo: res?.sponsorInfo?.logo, membership: res.membership },
                  });
              }
              return;
            })
            .catch(error => {
              setRefresh(false);
              toast.error(error?.message);
            });
        }, 2000);
      } catch (error: any) {
        setRefresh(false);
        toast.error(error?.message);
      }
    }
  };

  useEffect(() => {
    fetchMembershipStatusbyId();
    dispatch(toggleSplash(false));
    clearInviteCode();
  }, []);

  return (
    <div className='xrelative xmin-h-screen xoverflow-y-auto'>
      {refresh && (
        <div className='top-0 left-0 z-50 absolute w-full bg-primary-medium-grey'>
          <Splash />
        </div>
      )}
    </div>
  );
};
