import React from 'react';
import { translate } from 'utils/helperFunctions';

type Props = {
  className?: string;
  border?: boolean;
};

export const GeneralNoAccess: React.FC<Props> = ({ className = '', border = true }) => {
  return (
    <div
      className={`font-Condensed text-primary-light-grey text-4xl md:text-[40px] rounded border-2 border-primary-blue px-3 ${
        className ?? ''
      } ${border ? '' : '!border-none'}`}
    >
      <span className={`flex justify-center items-center text-center h-[300px]`}>
        {translate('common.itLooksLikeYouDontHaveAccess')}
      </span>
    </div>
  );
};
