import { Splash } from 'Pages';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { OnboardingServices } from 'services/apis/Onboarding';
import { translate } from 'utils/helperFunctions';
import usePrompt from 'utils/hooks/usePrompt';
import { flushSync } from 'react-dom';

export const WizardNotSigned = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const route = useLocation();

  const [refresh, setRefresh] = useState<boolean>(false);

  const { _renderModalDiscardChanges, setIsBlocking } = usePrompt({
    message: translate('discardModal.areYouSureYouWantToExitTheOnboardingProcess'),
    okText: translate('common.yes'),
    cancelText: translate('common.no'),
  });

  useEffect(() => {
    setIsBlocking(true);
  }, []);

  const onResign = async () => {
    if (id) {
      setIsBlocking(false);
      try {
        setRefresh(true);
        setTimeout(async () => {
          await OnboardingServices.onResign(id).then(res => {
            if (res.wait) {
              setRefresh(false);
              setIsBlocking(true);
              toast.error(res?.message);
            } else {
              window.open(res.url, '_self');
              navigate(`/home/onboarding-wizard/${id}`);
              setRefresh(false);
            }
            return res;
          });
        }, 2000);
      } catch (error: any) {
        setRefresh(false);
        toast.error(error?.message);
      } finally {
        setRefresh(false);
      }
    }
  };

  const _renderWizardNotSigned = () => {
    return (
      <div className='flex flex-col items-center gap-8 text-left border border-primary-orange bg-black m-4 w-full xl:w-[70%] xl:mx-auto min-h-[500px]'>
        <div className='font-Condensed text-[48px] mt-4 font-bold '>
          {translate('notSignedScreen.didntMakeIt')}
        </div>
        <div
          className='self-start mx-6 text-[20px]  
         font-normal text-left mb-[500px] md:mb-80'
        >
          {translate('notSignedScreen.looksLikeYouDidntSignYourDocuSignContract')}
          <br />
          <span
            className='text-primary-blue cursor-pointer hover:text-primary-blue/80'
            onClick={onResign}
          >
            {translate('notSignedScreen.clickHereToSignAgain')}
          </span>
          <br />
          <div className='mt-5'>
            {translate('notSignedScreen.ifYouNeedSomeHelpGetInTouchWithYourContactOr')}{' '}
            <br className='hidden break-Line ' />
            <span
              className='text-primary-blue cursor-pointer hover:text-primary-blue/80'
              onClick={() => {
                flushSync(() => {
                  setIsBlocking(false);
                });
                navigate(`/home/onboarding-wizard/${id}/wizardSendNote`, {
                  state: { logo: route?.state?.logo, membership: route?.state?.membersip },
                });
              }}
            >
              {translate('common.sendUsANote')}
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className='xoverflow-y-auto xoverflow-x-hidden'>
      {_renderModalDiscardChanges()}
      {refresh ? (
        <div className='top-0 left-0 z-50 absolute w-full bg-primary-medium-grey'>
          <Splash />
        </div>
      ) : (
        <div>
          <div
            className={`flex justify-start items-center`}
            style={{ minHeight: 'calc(100vh - 200px)' }}
          >
            {_renderWizardNotSigned()}
          </div>
        </div>
      )}
    </div>
  );
};
