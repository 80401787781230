import axios from 'axios';
import { GeneralParams } from '../types';
import { HandlePost, IHandleDetails, Schema } from './handle.types';

type SchemaObject = {
  version: number;
  schema: Schema;
};

export const HandleServices = {
  handleList: async (params: GeneralParams = {}): Promise<any> => {
    try {
      const endPoint = `handle/user/search`;
      const handleslist = await axios.get(endPoint, { params });
      return handleslist;
    } catch (err) {
      throw err;
    }
  },
  statsSchema: async (socialMedia: string) => {
    try {
      const endPoint = `handle/schema/${socialMedia}`;
      const statsSchema = await axios.get<object, SchemaObject>(endPoint);
      return statsSchema;
    } catch (err) {
      throw err;
    }
  },
  notInfluencerlhandle: async (type: string, params: GeneralParams = {}): Promise<any> => {
    const endPoint = `handle/search/connected/membership/type/${type}`;
    try {
      const list = await axios.get(endPoint, { params });
      return list;
    } catch (err) {
      throw err;
    }
  },
  handleDetails: async (handleId: string) => {
    const endPoint = `handle/user/${handleId}`;
    try {
      const details = await axios.get<object, IHandleDetails>(endPoint);
      return details;
    } catch (err) {
      throw err;
    }
  },
  suspendHandle: async (handleId: string, payload: object): Promise<any> => {
    const endPoint = `handle/user/${handleId}/suspend/request`;
    try {
      const suspend = await axios.put(endPoint, payload);
      return suspend;
    } catch (err) {
      throw err;
    }
  },
  handleDomains: async (handleId: string, params: GeneralParams = {}): Promise<any> => {
    const endPoint = `handle/user/${handleId}/domains`;
    try {
      const domainsList = await axios.get(endPoint, { params });
      return domainsList;
    } catch (err) {
      throw err;
    }
  },
  similarDomainParticipation: async (handleId: string, domainId: string): Promise<any> => {
    const endPoint = `handle/user/${handleId}/domain/participation/${domainId}/similar`;
    try {
      const similar = await axios.get(endPoint);
      return similar;
    } catch (err) {
      throw err;
    }
  },
  participationDetail: async (handleId: string, participationId: string): Promise<any> => {
    const endPoint = `handle/user/${handleId}/domain/participation/${participationId}`;
    try {
      const detail = await axios.get(endPoint);
      return detail;
    } catch (err) {
      throw err;
    }
  },
  addHandle: async (payload: HandlePost): Promise<any> => {
    const endPoint = `handle/user`;
    try {
      const addHandle = await axios.post(endPoint, payload);
      return addHandle;
    } catch (error) {
      throw error;
    }
  },
  addDomainParticipation: async (handleId: string, payload: object): Promise<any> => {
    const endPoint = `handle/user/${handleId}/domain/participation`;
    try {
      const addParticipation = await axios.post(endPoint, payload);
      return addParticipation;
    } catch (error) {
      throw error;
    }
  },
  editDomainParticipation: async (
    handleId: string,
    participationId: string,
    payload: object
  ): Promise<any> => {
    const endPoint = `handle/user/${handleId}/domain/participation/${participationId}`;
    try {
      const edit = await axios.put(endPoint, payload);
      return edit;
    } catch (err) {
      throw err;
    }
  },
  submitDomainParticipation: async (handleId: string, participationId: string): Promise<any> => {
    const endPoint = `handle/user/${handleId}/domain/participation/${participationId}/status`;
    try {
      const status = await axios.put(endPoint);
      return status;
    } catch (err) {
      throw err;
    }
  },
  deleteDomainParticipation: async (handleId: string, participationId: string): Promise<any> => {
    const endPoint = `handle/user/${handleId}/domain/participation/${participationId}`;
    try {
      const remove = await axios.delete(endPoint);
      return remove;
    } catch (error) {
      throw error;
    }
  },
  editMetadata: async (handleId: string, payload: object): Promise<any> => {
    const endPoint = `handle/${handleId}/metadata`;
    try {
      const metadata = await axios.put(endPoint, payload);
      return metadata;
    } catch (err) {
      throw err;
    }
  },
  getClassSwitchParticipations: async (): Promise<any> => {
    const endPoint = `handle/user/participations/switched/class`;
    try {
      const res = await axios.get(endPoint);
      return res;
    } catch (err) {
      throw err;
    }
  },
  getClassSwitchRequestDetails: async ({
    handleId,
    participationId,
  }: {
    handleId: string;
    participationId: string;
  }): Promise<any> => {
    const endPoint = `handle/user/handle/${handleId}/participation/${participationId}/switched/class`;
    try {
      const res = await axios.get(endPoint);
      return res;
    } catch (err) {
      throw err;
    }
  },
  onAcceptClassSwitchRequest: async ({
    handleId,
    participationId,
    action,
    legalId,
  }: {
    handleId: string;
    participationId: string;
    action: 'accept' | 'reject';
    legalId: string;
  }): Promise<any> => {
    const endPoint = `handle/user/handle/${handleId}/participation/${participationId}/switch/class/action/${action}`;
    try {
      const res = await axios.put(endPoint, { legalId });
      return res;
    } catch (err) {
      throw err;
    }
  },
};
