import React, { FC, Fragment, useCallback, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { Trans } from 'react-i18next';
import { HiOutlineXCircle } from 'react-icons/hi2';

import { CloudArrowUp20Filled } from '@fluentui/react-icons';

import { File, FilePreview } from './Binary';
import Image from './BufferedImage';
import Video from './BufferedVideo';
import { BinaryUploadProps } from './type';
import { ACCEPTED_FILE_TYPES } from 'constants/acceptedFileType';
import { toast } from 'react-toastify';
import { getFileTypeFromUrl } from './hooks/utils';
import { debounce, translate } from 'utils/helperFunctions';

const BinaryUpload: FC<BinaryUploadProps> = ({ binaryData, id, onChange, showUploader = true }) => {
  const {
    label,
    required,
    disabled,
    accept,
    maxSize,
    maxNumberOfFiles = 3,
    clearImage,
    Preview,
    files,
    setFiles,
  } = binaryData.find(item => item.id === id) || {};
  const inputRef = useRef<HTMLInputElement | null>(null);

  const filesPreviews = files?.map(file => file.preview);

  const accepted = [accept]?.flat().filter(Boolean) as (keyof typeof ACCEPTED_FILE_TYPES)[];
  const acceptedTypes = accepted?.map(item => ACCEPTED_FILE_TYPES[item]).flat();
  const acceptString = acceptedTypes?.join(',');

  const maxFilesReached = !!maxNumberOfFiles && (files?.length || 0) >= maxNumberOfFiles;

  const validateAndSetFiles = (selectedFiles: File[]) => {
    const newFilesLength = selectedFiles.length;
    if (newFilesLength + (files?.length || 0) > maxNumberOfFiles) {
      toast.error(`${translate('common.maxFilesReached', { maxNumberOfFiles })}`);
      return;
    }
    const acceptedFiles = selectedFiles.filter(selectedFile => {
      const fileSize = selectedFile.size / 1024 / 1024;
      return (
        acceptedTypes?.some(item => selectedFile.type.includes(item)) &&
        fileSize <= (maxSize || 1000)
      );
    });

    if (!acceptedFiles.length) {
      toast.error(`${translate('common.invalidFileType')}`);
      return;
    }

    const newFiles = acceptedFiles.map(file => ({
      type: 'new' as const,
      file,
      preview: URL.createObjectURL(file),
    }));

    setFiles?.(prev => [...prev, ...newFiles]);
    onChange?.([...(files ?? []), ...newFiles]);
  };

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const selectedFiles = [...(e.target.files || [])] as File[];

    if (selectedFiles.length) {
      validateAndSetFiles(selectedFiles);
    }
  };

  const onClick = useCallback(() => {
    debounce(() => {
      inputRef?.current?.click();
    });
  }, []);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length) {
      validateAndSetFiles(acceptedFiles);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: false,
    disabled,
    noKeyboard: true,
  });

  return (
    <div
      className={`flex flex-col ${label ? 'gap-5' : ''}`}
      {...(showUploader ? getRootProps() : {})}
      onClick={e => e.stopPropagation()}
    >
      {label ? (
        <label
          htmlFor='binaryUpload'
          className={`${
            disabled ? 'cursor-not-allowed' : 'cursor-pointer'
          } relative w-fit flex flex-col gap-5 text-primary-orange font-bold`}
        >
          {required ? '*' : ''}
          {label}:
        </label>
      ) : null}

      <input
        {...(showUploader ? getInputProps() : {})}
        id='binaryUpload'
        className='hidden'
        defaultValue={''}
        placeholder='Text input'
        type='file'
        multiple
        required={required}
        disabled={disabled}
        accept={acceptString}
        name='image'
        onChange={onFileChange}
        ref={inputRef}
      />
      <div className='flex flex-wrap gap-5'>
        {!maxFilesReached && showUploader && (
          <div
            className={`flex flex-col items-center justify-center w-32 h-32 border-2 border-dashed border-primary-gray rounded-lg bg-primary-dark p-4 ${
              isDragActive ? 'bg-primary-1-light' : ''
            } ${disabled ? 'cursor-not-allowed' : 'cursor-pointer hover:bg-primary-1-light'}`}
            onClick={onClick}
          >
            <CloudArrowUp20Filled className='w-8 h-8 text-primary-white' />
            <p className='text-primary-white text-center font-thin text-sm'>
              <Trans
                i18nKey='common.dropFile'
                components={{
                  browse: (
                    <span
                      className='text-primary-blue cursor-pointer underline'
                      onClick={onClick}
                    />
                  ),
                }}
              />
            </p>
          </div>
        )}

        {filesPreviews?.map((filePath, index) => {
          const file = files?.[index]?.file;
          const filePathType = getFileTypeFromUrl(filePath);

          const type = filePathType || file?.type || acceptString;
          const isImage = type.includes('image') && !type?.includes('application/pdf');
          const isVideo = type?.includes('video') && !type?.includes('application/pdf');

          return (
            <Fragment key={filePath}>
              {Preview && <Preview file={file} filePath={filePath} click={onClick} />}
              {!Preview && (
                <div className='w-32 relative'>
                  <HiOutlineXCircle
                    className={`absolute top-0.5 right-0.5 w-5 h-5 text-primary-orange ${
                      disabled ? 'cursor-not-allowed' : 'cursor-pointer'
                    } !bg-primary-dark rounded-full z-50`}
                    onClick={() => {
                      if (disabled) return;
                      if (inputRef.current) {
                        inputRef.current.value = '';
                      }
                      clearImage?.(index);
                    }}
                  />
                  {!file?.type ? (
                    <FilePreview src={filePath} />
                  ) : (
                    <>
                      {isImage && <Image className='w-full h-32 object-contain' src={filePath} />}
                      {isVideo && <Video src={filePath} />}
                      {!isImage && !isVideo && (
                        <File className='text-primary-light' src={filePath} />
                      )}
                    </>
                  )}
                </div>
              )}
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default BinaryUpload;
