import React from 'react';
import { Link } from 'react-router-dom';
import { AuthSessionServices } from 'services/apis/AuthSession';
import { translate } from 'utils/helperFunctions';

export const SafetyScreen = () => {
  const onLogout = async () => {
    await AuthSessionServices.clearSession();
  };

  return (
    <div className={`font-Condensed text-primary-light-grey text-4xl md:text-[40px] h-screen`}>
      <div className='flex flex-col gap-5 items-center justify-center text-center h-full'>
        <div className='max-w-xl px-4 sm:px-0'>
          {translate('handlesScreen.itLooksLikeYouDontHaveAccess')}
        </div>
        <div className='flex gap-5'>
          <Link to={'/home'}>
            <button className='tw-button-light-blue !px-2 !text-base !w-44 sm:!w-72'>
              {translate('common.goToDashboard')}
            </button>
          </Link>
          <button
            className='tw-button-secondary font-inter !px-2 !text-base !capitalize !w-44 sm:!w-72'
            onClick={onLogout}
          >
            {translate('common.logout')}
          </button>
        </div>
      </div>
    </div>
  );
};
