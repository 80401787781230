import { translate } from 'utils/helperFunctions';

type NoAccessTypes = {
  msg?: string;
};

export const DefaultNoAccess = ({ msg }: NoAccessTypes) => {
  return (
    <div className={`font-Condensed text-primary-light-grey text-4xl md:text-[40px]`}>
      <div className='flex items-center justify-center text-center h-full  min-h-[230px]'>
        <div className='max-w-xl px-4 sm:px-0'>
          {msg ? msg : translate('handlesScreen.itLooksLikeYouDontHaveAccess')}{' '}
        </div>
      </div>
    </div>
  );
};
