import { UserAccountProperties } from '.';
import {
  getStorageUserData,
  getUserDataByKey,
  getUserKeyACL,
  setStorageUserData,
  setUserKeyACL,
} from 'utils/StorageServices';
import store from 'services/context/store';
import { updateUserAccount } from 'services/context/Auth/actions';
import axios from 'axios';

export const UracServices = {
  //used APIs
  fetchUser: async (): Promise<any> => {
    const endPoint = `urac/user/me`;
    try {
      const uracUser = await axios.get(endPoint);
      setStorageUserData(uracUser);
      store.dispatch(updateUserAccount(uracUser));
      return uracUser;
    } catch (error) {
      throw error;
    }
  },
  updateAccount: async (payload: Partial<UserAccountProperties>): Promise<any> => {
    const endPoint = `urac/account`;
    try {
      const id: string = getUserDataByKey('_id');
      const userData = await axios.put(endPoint, {
        id,
        ...payload,
      });
      const prevUserData = getStorageUserData();

      const newData = { ...userData, ...prevUserData, ...payload };

      setStorageUserData(newData);
      store.dispatch(updateUserAccount(newData));

      return newData;
    } catch (error) {
      throw error;
    }
  },
  changeEmail: async (email: string): Promise<any> => {
    const endPoint = `urac/account/email/code`;
    const id: string = getUserDataByKey('_id');
    try {
      const response = await axios.put(endPoint, { email, id });
      return response;
    } catch (error) {
      throw error;
    }
  },
  validateChangeEmailCode: async (code: string): Promise<any> => {
    const endPoint = `urac/validate/changeEmail?token=${code}`;
    try {
      const response = await axios.get(endPoint);
      return response;
    } catch (error) {
      throw error;
    }
  },
  changePhone: async (phone: string): Promise<any> => {
    const endPoint = `urac/account/phone`;
    const id: string = getUserDataByKey('_id');
    try {
      const response = await axios.put(endPoint, { phone, id });
      return response;
    } catch (error) {
      throw error;
    }
  },
  getUserACL: async (extKey?: any) => {
    //TODO: check cache
    let cacheIndex = '0000000000000000';
    if (extKey) {
      cacheIndex = extKey.substr(extKey.length - 16);
    }

    const acl_cached = getUserKeyACL(cacheIndex);
    if (acl_cached) {
      return acl_cached;
    }
    let opt: any = null;
    if (extKey) {
      opt = {
        headers: { key: extKey },
      };
    }
    const aclKey = await axios.get('/soajs/acl', opt);
    if (aclKey) {
      setUserKeyACL(aclKey, cacheIndex);
    }
    return aclKey;
  },
};
