import { useEffect, useState } from 'react';
import { DefaultNoAccess, LoadingPage, Table } from 'components/shared';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { UseAbility } from 'services/context';
import Box from 'components/shared/Box';
import useLegalColumns from '../hooks/useLegalColumns';
import { HandleServices, IHandleTerms } from 'services/apis/Handle';

export const DomainTermsListing = () => {
  const { domainTermsColumns } = useLegalColumns();
  const navigate = useNavigate();
  const canGetDomainTermsList = UseAbility('classSwitchParticipations', 'check');
  const canGetDomainTermsDetails = UseAbility('classSwitchRequestDetails', 'get');

  const [loading, setLoading] = useState<boolean>(false);
  const [domainTerms, setDomainTerms] = useState<IHandleTerms[]>([]);

  const fetchUserTermsList = async () => {
    setLoading(true);
    try {
      const list = await HandleServices.getClassSwitchParticipations();
      setDomainTerms(list.items);
    } catch (error: any) {
      toast.error(error?.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (canGetDomainTermsList) {
      fetchUserTermsList();
    }
  }, [canGetDomainTermsList]);

  return (
    <Box className='relative border-t-0'>
      <LoadingPage loading={loading} />
      {canGetDomainTermsList && (
        <Table
          columns={domainTermsColumns}
          dataSource={domainTerms}
          hover
          onClickRow={(_index, item) =>
            canGetDomainTermsDetails &&
            navigate(`/home/legal/domain/${item?.handleInfo?._id}/${item._id}`)
          }
          loading={loading}
        />
      )}
      {!canGetDomainTermsList && <DefaultNoAccess />}
    </Box>
  );
};
