import { AbilityBuilder } from '@casl/ability';
import APIs from 'availableAbilities';
import { UracServices } from 'services/apis/Urac';

export const updateAbility = async (ability: any, extKey?: string) => {
  const { can, rules } = new AbilityBuilder(ability);
  let userACL = null;
  try {
    userACL = await UracServices.getUserACL(extKey);
  } catch (e) {
    // NotificationManager.error(e.message);
  }
  if (userACL && userACL.finalACL) {
    let abilityAdded = 0;
    const abilityLogMsg = [];
    if (APIs && APIs.abilities) {
      const abilities: any = APIs.abilities;
      const services = Object.keys(abilities);
      for (let i = 0; i < services.length; i++) {
        if (userACL.finalACL[services[i]]) {
          let allAPiAvailable = false;
          if (
            userACL.acl[services[i]] &&
            userACL.acl[services[i]][APIs.versions[services[i]]] &&
            (!userACL.acl[services[i]][APIs.versions[services[i]]].apisPermission ||
              userACL.acl[services[i]][APIs.versions[services[i]]].apisPermission !== 'restricted')
          ) {
            allAPiAvailable = true;
          }
          const methods = Object.keys(abilities[services[i]]);
          for (let j = 0; j < methods.length; j++) {
            const apis = Object.keys(abilities[services[i]][methods[j]]);
            for (let k = 0; k < apis.length; k++) {
              let enableThisAbility = false;
              if (!allAPiAvailable) {
                if (
                  userACL.finalACL[services[i]][methods[j]] &&
                  userACL.finalACL[services[i]][methods[j]].includes(apis[k])
                ) {
                  enableThisAbility = true;
                }
              } else {
                enableThisAbility = true;
              }
              if (enableThisAbility) {
                if (Array.isArray(abilities[services[i]][methods[j]][apis[k]])) {
                  for (let l = 0; l < abilities[services[i]][methods[j]][apis[k]].length; l++) {
                    if (
                      abilities[services[i]][methods[j]][apis[k]][l].I &&
                      abilities[services[i]][methods[j]][apis[k]][l].A
                    ) {
                      can(
                        abilities[services[i]][methods[j]][apis[k]][l].I,
                        abilities[services[i]][methods[j]][apis[k]][l].A
                      );
                      abilityAdded++;
                    } else {
                      abilityLogMsg.push(
                        'Skipping: ' + abilities[services[i]][methods[j]][apis[k]][l].M
                      );
                    }
                  }
                } else {
                  if (
                    abilities[services[i]][methods[j]][apis[k]].I &&
                    abilities[services[i]][methods[j]][apis[k]].A
                  ) {
                    can(
                      abilities[services[i]][methods[j]][apis[k]].I,
                      abilities[services[i]][methods[j]][apis[k]].A
                    );
                    abilityAdded++;
                  } else {
                    abilityLogMsg.push(
                      'Skipping: ' + abilities[services[i]][methods[j]][apis[k]].M
                    );
                  }
                }
              } else {
                if (Array.isArray(abilities[services[i]][methods[j]][apis[k]])) {
                  for (let l = 0; l < abilities[services[i]][methods[j]][apis[k]].length; l++) {
                    abilityLogMsg.push(
                      'Disabled: ' + abilities[services[i]][methods[j]][apis[k]][l].M
                    );
                  }
                } else {
                  abilityLogMsg.push('Disabled: ' + abilities[services[i]][methods[j]][apis[k]].M);
                }
              }
            }
          }
        }
      }
      //}
    }
    console.log(abilityLogMsg);
    console.log('Abilities Added: ' + abilityAdded);
  }
  ability.update(rules);
};

export const createUserAbilities = async (ability: any) => {
  await updateAbility(ability);
  return {
    type: 'changeAbility',
    payload: ability,
  };
};

export const updateUserAbilities = async (ability: any, extKey: any) => {
  return new Promise(resolve => {
    ability.on('updated', resolve);
    updateAbility(ability, extKey);
  });
};

export const updateAbilities = (ability: any) => ({
  type: 'CHANGE_ABILITY',
  payload: ability,
});
