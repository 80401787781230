import { Link } from 'react-router-dom';
import { translate } from 'utils/helperFunctions';
import Logo from 'assets/images/Logo.svg';

export const AlreadyUser = () => {
  return (
    <div className='p-8 lg:px-20'>
      <div className='flex flex-col items-start z-50'>
        <img src={Logo} alt='logo' />
      </div>
      <div className='grid grid-cols-1 lg:grid-cols-2 gap-y-6 mt-20 md:mt-40 '>
        <div className='lg:text-left w-[450px] lg:w-full justify-center mx-auto '>
          <div className='flex lg:flex-col gap-3 '>
            <h2 className='font-Condensed text-primary-orange text-6xl  lg:text-9xl font-bold uppercase'>
              {translate('loginScreen.goodTo')}
            </h2>{' '}
            <h2 className='font-Condensed text-primary-orange text-6xl  lg:text-9xl font-bold uppercase lg:mt-5'>
              {translate('loginScreen.seeYou')}
            </h2>
          </div>
          <div>
            <p className='mt-2 lg:mt-14 text-xl lg:text-3xl font-normal '>
              {translate('loginScreen.welcomeBack')}
            </p>
          </div>
        </div>
        <div className='flex flex-col gap-8 mx-auto lg:mx-0 lg:mt-11 bg-primary-dark-grey px-4 py-6 overflow-hidden primaryBorder h-fit sm:w-[450px]'>
          <h2 className='font-Condensed  text-left text-primary-orange sm:text-center text-4xl font-bold'>
            {translate('loginScreen.alreadyMember')}
          </h2>
          <div className='text-center'>
            <Link to='/login' className='tw-button-light-blue !w-full sm:!w-72'>
              {translate('common.login')}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
