import { CustomRadio, Select } from 'components/shared';
import { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { useNavigate, useParams } from 'react-router-dom';
import { translate } from 'utils/helperFunctions';
import { toast } from 'react-toastify';
import { ReferralServices } from 'services/apis/Referral';
import { BsFillExclamationCircleFill } from 'react-icons/bs';
import SureToSubmit from 'components/shared/SureToSubmit';

type Props = {
  setIsSubmitted: (error: any) => void;
};

export const AddCompany = ({ setIsSubmitted }: Props) => {
  const navigate = useNavigate();
  const { membership, referralId } = useParams();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [addCompany, setAddCompany] = useState<any>({
    referee: {
      contactInfo: {
        countryCode: '1',
        phone: '',
        concatenatedPhoneNumber: '1',
        firstName: '',
        lastName: '',
        email: '',
        linkedIn: '',
        decisionMaker: true,
      },
      companyName: '',
      linkedIn: '',
      website: '',
    },
    membership: membership,
    relation: '',
    description: '',
  });

  const [errors, setErrors] = useState<any>({
    phone: '',
    firstName: '',
    lastName: '',
    email: '',
    linkedIn: '',
    decisionMaker: '',
    relation: '',
    description: '',
    companyName: '',
    companyLinkedIn: '',
    website: '',
  });

  const fetchRferraldetails = async () => {
    if (referralId) {
      try {
        const detail = await ReferralServices.referralDetails(referralId);
        setAddCompany({
          ...addCompany,
          referee: {
            ...addCompany.referee,
            contactInfo: {
              ...addCompany.referee.contactInfo,
              countryCode: detail?.referee?.contactInfo?.countryCode || '1',
              phone: detail?.referee?.contactInfo?.phone || '',
              concatenatedPhoneNumber: detail?.referee?.contactInfo?.concatenatedPhoneNumber || '1',
              firstName: detail?.referee?.contactInfo?.firstName || '',
              lastName: detail?.referee?.contactInfo?.lastName || '',
              email: detail?.referee?.contactInfo?.email || '',
              linkedIn: detail?.referee?.contactInfo?.linkedIn || '',
              decisionMaker: detail?.referee?.contactInfo?.decisionMaker || true,
            },
            companyName: detail?.referee?.companyName || '',
            linkedIn: detail?.referee?.linkedIn || '',
            website: detail?.referee?.website || '',
          },
          membership: membership,
          relation: detail?.relation || '',
          description: detail?.description || '',
        });
      } catch (error: any) {
        toast.error(error?.message);
      }
    }
  };

  useEffect(() => {
    if (referralId !== 'new') {
      fetchRferraldetails();
    }
  }, []);

  const SaveCompanyRefferal = async () => {
    setIsSubmitted(true);
    try {
      const payload = {
        ...addCompany,
        referee: {
          ...addCompany.referee,
          contactInfo: {
            ...addCompany.referee.contactInfo,
            linkedIn:
              addCompany?.referee?.contactInfo?.linkedIn.startsWith('http://') ||
              addCompany?.referee?.contactInfo?.linkedIn.startsWith('https://')
                ? addCompany?.referee?.contactInfo?.linkedIn
                : `https://${addCompany?.referee?.contactInfo?.linkedIn}`,
          },
          linkedIn:
            addCompany?.referee?.linkedIn.startsWith('http://') ||
            addCompany?.referee?.linkedIn.startsWith('https://')
              ? addCompany?.referee?.linkedIn
              : `https://${addCompany?.referee?.linkedIn}`,
        },
      };
      setLoading(true);
      if (referralId === 'new') {
        if (membership === 'venturePartner') {
          await ReferralServices.addInvestmentOpportunity(payload);
        } else {
          await ReferralServices.addMediaCustomer(payload);
        }
      } else if (referralId && referralId !== 'new') {
        await ReferralServices.EditReferralCompany(referralId, payload);
      }
      setAddCompany({
        referee: {
          contactInfo: {
            countryCode: '1',
            phone: '',
            concatenatedPhoneNumber: '1',
            firstName: '',
            lastName: '',
            email: '',
            linkedIn: '',
            decisionMaker: false,
          },
          companyName: '',
          linkedIn: '',
          website: '',
        },
        membership: membership,
        relation: '',
        description: '',
      });
      toast.success(
        referralId === 'new'
          ? translate('handlesScreen.referralCreatedSuccessfully')
          : translate('handlesScreen.referralUpdatedSuccessfully')
      );
      setLoading(false);
      navigate(-1);
    } catch (error: any) {
      setIsSubmitted(false);
      setIsSubmit(false);
      setLoading(false);
      toast.error(error?.message);
    }
  };

  const SubmitCompanyRefferal = async () => {
    setIsSubmitted(true);
    try {
      const payload = {
        ...addCompany,
        referee: {
          ...addCompany.referee,
          contactInfo: {
            ...addCompany.referee.contactInfo,
            linkedIn:
              addCompany?.referee?.contactInfo?.linkedIn.startsWith('http://') ||
              addCompany?.referee?.contactInfo?.linkedIn.startsWith('https://')
                ? addCompany?.referee?.contactInfo?.linkedIn
                : `https://${addCompany?.referee?.contactInfo?.linkedIn}`,
          },
          linkedIn:
            addCompany?.referee?.linkedIn.startsWith('http://') ||
            addCompany?.referee?.linkedIn.startsWith('https://')
              ? addCompany?.referee?.linkedIn
              : `https://${addCompany?.referee?.linkedIn}`,
        },
        submit: true,
      };
      if (referralId === 'new') {
        if (membership === 'venturePartner') {
          await ReferralServices.addInvestmentOpportunity(payload);
        } else {
          await ReferralServices.addMediaCustomer(payload);
        }
      } else if (referralId && referralId !== 'new') {
        await ReferralServices.EditReferralCompany(referralId, payload);
      }
      setAddCompany({
        referee: {
          contactInfo: {
            countryCode: '1',
            phone: '',
            concatenatedPhoneNumber: '1',
            firstName: '',
            lastName: '',
            email: '',
            linkedIn: '',
            decisionMaker: false,
          },
          companyName: '',
          linkedIn: '',
          website: '',
        },
        membership: membership,
        relation: '',
        description: '',
      });
      toast.success(
        referralId === 'new'
          ? translate('handlesScreen.referralCreatedSubmitedSuccessfully')
          : translate('handlesScreen.referralUpdatedSubmitedSuccessfully')
      );
      setLoading(false);
      navigate(-1);
    } catch (error: any) {
      setIsSubmitted(false);
      setLoading(false);
      toast.error(error?.message);
    }
  };

  const validateInputs = () => {
    const regexPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/g;

    const updatedErrors: any = {};
    Object.keys(addCompany).forEach(key => {
      if (key === 'referee') {
        Object.keys(addCompany.referee).forEach(k => {
          if (k === 'contactInfo') {
            Object.keys(addCompany?.referee?.contactInfo).forEach(ky => {
              if (addCompany?.referee?.contactInfo) {
                if (ky === 'email') {
                  if (!addCompany?.referee?.contactInfo[ky]) {
                    updatedErrors[ky] = translate('common.isRequired');
                  } else if (!regexPattern.test(addCompany?.referee?.contactInfo[ky] as string)) {
                    updatedErrors[ky] = translate('common.isUnvalid');
                  }
                } else if (ky === 'decisionMaker') {
                  if (addCompany?.referee?.contactInfo[ky] === null) {
                    updatedErrors[ky] = translate('common.isRequired');
                  } else {
                    updatedErrors[ky] = '';
                  }
                } else if (!addCompany?.referee?.contactInfo[ky]) {
                  updatedErrors[ky] = translate('common.isRequired');
                } else {
                  updatedErrors[ky] = '';
                }
              } else {
                updatedErrors[ky] = translate('common.isRequired');
              }
            });
          } else if (k === 'linkedIn') {
            if (!addCompany.referee.linkedIn) {
              updatedErrors.companyLinkedIn = translate('common.isRequired');
            } else {
              updatedErrors.companyLinkedIn = '';
            }
          } else if (!addCompany.referee[k]) {
            updatedErrors[k] = translate('common.isRequired');
          } else {
            updatedErrors[k] = '';
          }
        });
      } else if (!addCompany[key]) {
        updatedErrors[key] = translate('common.isRequired');
      } else {
        updatedErrors[key] = '';
      }
    });
    const isFormValid = Object.values(updatedErrors).every(error => error === '');

    if (isFormValid) {
      setIsVisible(true);
    }
    setErrors(updatedErrors);
  };

  return (
    <>
      <SureToSubmit
        message={
          isSubmit
            ? translate('handlesScreen.areYouSureYouWantToSubmitThisReferral')
            : translate('handlesScreen.areYouSureYouWantToSaveThisReferral')
        }
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        submitFunction={isSubmit ? SubmitCompanyRefferal : SaveCompanyRefferal}
        okText={isSubmit ? translate('common.submit') : translate('common.save')}
        cancelText={`${translate('common.cancel')}`}
      />

      <div className='flex flex-col gap-6'>
        <div className='input-wrapper relative'>
          <label htmlFor='companyName' className='tw-label'>
            *{translate('handlesScreen.companysName')}:
          </label>
          <input
            className={`tw-input-primary relative ${
              errors.companyName ? '!border-primary-dark-red' : ''
            }`}
            id='companyName'
            type='text'
            autoComplete={'off'}
            value={addCompany?.referee?.companyName}
            placeholder={translate('handlesScreen.enterCompanyName')}
            onChange={e => {
              const copy = { ...addCompany };
              copy.referee.companyName = e.target.value;
              setAddCompany(copy);
              if (e.target.value) {
                setErrors({ ...errors, companyName: '' });
              }
            }}
          />
          {errors.companyName && (
            <>
              <BsFillExclamationCircleFill className='absolute right-3 top-11 w-15 !text-xl text-primary-dark-red' />
              <p className='absolute left-1 -bottom-5 text-[12px] text-primary-dark-red'>
                {errors.companyName}
              </p>
            </>
          )}
        </div>
        <div className='input-wrapper relative'>
          <label htmlFor='companyLinkedIn' className='tw-label'>
            *{translate('handlesScreen.companysLinkedin')}:
          </label>
          <input
            className={`tw-input-primary relative ${
              errors.companyLinkedIn ? '!border-primary-dark-red' : ''
            }`}
            id='companyLinkedIn'
            type='text'
            autoComplete={'off'}
            value={addCompany?.referee?.linkedIn}
            placeholder={translate('handlesScreen.enterCompanyLinkedin')}
            onChange={e => {
              const copy = { ...addCompany };
              copy.referee.linkedIn = e.target.value;
              setAddCompany(copy);
              if (e.target.value) {
                setErrors({ ...errors, companyLinkedIn: '' });
              }
            }}
          />
          {errors.companyLinkedIn && (
            <>
              <BsFillExclamationCircleFill className='absolute right-3 top-11 w-15 !text-xl text-primary-dark-red' />
              <p className='absolute left-1 -bottom-5 text-[12px] text-primary-dark-red'>
                {errors.companyLinkedIn}
              </p>
            </>
          )}
        </div>
        <div className='input-wrapper relative'>
          <label htmlFor='website' className='tw-label'>
            *{translate('handlesScreen.companyWebsite')}:
          </label>
          <input
            className={`tw-input-primary relative ${
              errors.website ? '!border-primary-dark-red' : ''
            }`}
            id='website'
            type='text'
            autoComplete={'off'}
            value={addCompany?.referee?.website}
            placeholder={translate('handlesScreen.enterURL')}
            onChange={e => {
              const copy = { ...addCompany };
              copy.referee.website = e.target.value;
              setAddCompany(copy);
              if (e.target.value) {
                setErrors({ ...errors, website: '' });
              }
            }}
          />
          {errors.website && (
            <>
              <BsFillExclamationCircleFill className='absolute right-3 top-11 w-15 !text-xl text-primary-dark-red' />
              <p className='absolute left-1 -bottom-5 text-[12px] text-primary-dark-red'>
                {errors.website}
              </p>
            </>
          )}
        </div>
        <div className='input-wrapper relative'>
          <label htmlFor='relation' className='tw-label !text-[15px] sm:text-[16px]'>
            *{translate('handlesScreen.howDoYouKnowThisPerson/Company')}?
          </label>
          <Select
            options={[
              { label: 'friend', value: 'friend' },
              { label: 'from school', value: 'school' },
              { label: 'work', value: 'work' },
            ]}
            className={`bg-primary-medium-grey relative w-full shadow-sm pl-3 text-left cursor-pointer focus:outline-none sm:text-sm border-2 h-12 rounded border-primary-light-grey  ${
              errors.relation ? '!border-primary-dark-red' : 'border-primary-light-grey'
            }`}
            placeholderStyle='!text-[16px] pl-2'
            value={addCompany?.relation ?? addCompany?.relation}
            preValue={addCompany?.relation && ''}
            placeholder={`${translate('handlesScreen.selectWay')}`}
            menuBg='bg-primary-medium-grey mt-[10px] border-2 !text-base rounded border-primary-orange'
            focusOnOpen={true}
            onChange={e => {
              setAddCompany({ ...addCompany, relation: e?.value });
              if (e?.value) {
                setErrors({ ...errors, relation: '' });
              }
            }}
          />
          {errors.relation && (
            <>
              <p className='absolute left-1 -bottom-5 text-[12px] text-primary-dark-red'>
                {errors.relation}
              </p>
            </>
          )}
        </div>
        <div className='input-wrapper relative'>
          <textarea
            placeholder={translate('handlesScreen.tellUsMoreAboutThisReferral')}
            value={addCompany?.description}
            onChange={e => {
              setAddCompany({ ...addCompany, description: e?.target.value });
              if (e.target.value) {
                setErrors({ ...errors, description: '' });
              }
            }}
            className={`primaryTextArea sm:text-xl mt-2 focus:!border-primary-orange !text-sm ${
              errors.description ? '!border-primary-dark-red' : '!border-primary-light-grey'
            }`}
          />
          {errors.description && (
            <>
              <p className='absolute left-1 -bottom-5 text-[12px] text-primary-dark-red'>
                {errors.description}
              </p>
            </>
          )}
        </div>
        <hr
          className={`border-2 border-primary-light-grey w-full ${
            errors.description ? 'mt-2' : ''
          }`}
        />{' '}
        <p className='font-[600] text-[16px] text-primary-light-grey'>
          {translate('handlesScreen.contactDetails')}
        </p>
        <div className='relative'>
          <p className='font-normal mb-2 text-[16px] text-primary-light-grey'>
            {translate('handlesScreen.isTheContactDesicionMaker')}?
          </p>
          <CustomRadio
            wrapperClassName='!flex-row'
            RadioClassName={`${errors.decisionMaker ? '!outline-primary-dark-red' : ''}`}
            labelClassName='!font-normal !text-[16px] !text-primary-light-grey !ml-2'
            options={[
              {
                value: true,
                label: `${translate('common.yes')}`,
              },
              { value: false, label: `${translate('common.no')}` },
            ]}
            value={addCompany?.referee?.contactInfo?.decisionMaker}
            onChange={e => {
              const copy = { ...addCompany };
              copy.referee.contactInfo.decisionMaker = e.value;
              setAddCompany(copy);
              if (e?.value !== null) {
                setErrors({ ...errors, decisionMaker: '' });
              }
            }}
          />
          {errors.decisionMaker && (
            <>
              <p className='absolute left-1 -bottom-5 text-[12px] text-primary-dark-red'>
                {errors.decisionMaker}
              </p>
            </>
          )}
        </div>
        <div className='input-wrapper relative'>
          <label htmlFor='firstName' className='tw-label'>
            *{translate('handlesScreen.firstName')}:
          </label>
          <input
            className={`tw-input-primary relative
          ${errors.firstName ? '!border-primary-dark-red' : ''}`}
            id='firstName'
            type='text'
            autoComplete={'off'}
            value={addCompany?.referee?.contactInfo?.firstName}
            placeholder={translate('handlesScreen.enterFirstName')}
            onChange={e => {
              const copy = { ...addCompany };
              copy.referee.contactInfo.firstName = e.target.value;
              setAddCompany(copy);
              if (e.target.value) {
                setErrors({ ...errors, firstName: '' });
              }
            }}
          />
          {errors.firstName && (
            <>
              <BsFillExclamationCircleFill className='absolute right-3 top-11 w-15 !text-xl text-primary-dark-red' />
              <p className='absolute left-1 -bottom-5 text-[12px] text-primary-dark-red'>
                {errors.firstName}
              </p>
            </>
          )}
        </div>
        <div className='input-wrapper relative'>
          <label htmlFor='lastName' className='tw-label'>
            *{translate('handlesScreen.lastName')}:
          </label>
          <input
            className={`tw-input-primary relative ${
              errors.lastName ? '!border-primary-dark-red' : ''
            }`}
            id='lastName'
            type='text'
            autoComplete={'off'}
            value={addCompany?.referee?.contactInfo?.lastName}
            placeholder={translate('handlesScreen.enterLastName')}
            onChange={e => {
              const copy = { ...addCompany };
              copy.referee.contactInfo.lastName = e.target.value;
              setAddCompany(copy);
              if (e.target.value) {
                setErrors({ ...errors, lastName: '' });
              }
            }}
          />
          {errors.lastName && (
            <>
              <BsFillExclamationCircleFill className='absolute right-3 top-11 w-15 !text-xl text-primary-dark-red' />
              <p className='absolute left-1 -bottom-5 text-[12px] text-primary-dark-red'>
                {errors.lastName}
              </p>
            </>
          )}
        </div>
        <div className='input-wrapper'>
          <label htmlFor='phone' className='tw-label'>
            *{translate('handlesScreen.phoneNumber')}:
          </label>
          <div className='flex gap-2 relative'>
            <input
              className={`tw-input-primary relative !pl-[110px]
          ${errors.phone ? '!border-primary-dark-red' : ''}`}
              id='phone'
              type='text'
              autoComplete={'off'}
              maxLength={10}
              value={addCompany?.referee?.contactInfo?.phone}
              placeholder={translate('handlesScreen.enterPhone')}
              onChange={e => {
                if (/^[0-9]+$/g.test(e.target.value) || !e.target.value) {
                  const copy = { ...addCompany };
                  copy.referee.contactInfo.phone = e.target.value;
                  copy.referee.contactInfo.concatenatedPhoneNumber =
                    copy.referee.contactInfo.countryCode + e.target.value;
                  setAddCompany(copy);
                  if (e.target.value) {
                    setErrors({ ...errors, phone: '' });
                  }
                }
              }}
            />
            <span className='absolute transform -translate-y-1/2 top-[27px] left-2 z-10'>
              <PhoneInput
                country={'us'}
                buttonStyle={{ background: '#171717', border: 'none', bottom: '1px' }}
                containerStyle={{
                  width: `${innerWidth < 640 ? '100px' : '100px'}`,
                  margin: 'auto',
                }}
                dropdownStyle={{ color: 'white', background: '#6F7577' }}
                value={addCompany?.referee?.contactInfo?.countryCode || '1'}
                onChange={(phone: any) => {
                  const copy = { ...addCompany };
                  copy.referee.contactInfo.countryCode = phone;
                  copy.referee.contactInfo.concatenatedPhoneNumber =
                    phone + copy.referee.contactInfo.phone;
                  setAddCompany(copy);
                }}
                placeholder={''}
                inputProps={{
                  className:
                    'block w-full border-0 border-white/50  bg-primary-medium-grey focus:border-white focus:ring-0 text-right !pl-10 focus::!bg-black',
                }}
              />
            </span>
            {errors.phone && (
              <>
                <BsFillExclamationCircleFill className='absolute right-3 top-[1rem] w-15 !text-xl text-primary-dark-red' />
                <p className='absolute left-1 -bottom-5 text-[12px] text-primary-dark-red'>
                  {errors.phone}
                </p>
              </>
            )}
          </div>
        </div>
        <div className='input-wrapper relative'>
          <label htmlFor='email' className='tw-label'>
            *{translate('handlesScreen.email')}:
          </label>
          <input
            className={`tw-input-primary relative  ${
              errors.email ? '!border-primary-dark-red' : ''
            }`}
            id='email'
            type='text'
            autoComplete={'off'}
            value={addCompany?.referee?.contactInfo?.email}
            placeholder={translate('handlesScreen.enterEmail')}
            onChange={e => {
              const copy = { ...addCompany };
              copy.referee.contactInfo.email = e.target.value;
              setAddCompany(copy);
              if (e.target.value) {
                setErrors({ ...errors, email: '' });
              }
            }}
          />
          {errors.email ? (
            <>
              <BsFillExclamationCircleFill className='absolute right-3 top-11 w-15 !text-xl text-primary-dark-red' />
              <p className='absolute left-1 -bottom-5 text-[12px] text-primary-dark-red'>
                {errors.email}
              </p>
            </>
          ) : addCompany?.referee?.contactInfo?.email &&
            !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/g.test(
              addCompany?.referee?.contactInfo?.email as string
            ) ? (
            <>
              <BsFillExclamationCircleFill className='absolute right-3 top-11 w-15 !text-xl text-primary-dark-red' />
              <p className='absolute left-1 -bottom-5 text-[12px] text-primary-dark-red'>
                {translate('common.isUnvalid')}
              </p>
            </>
          ) : (
            ''
          )}
        </div>
        <div className='input-wrapper relative'>
          <label htmlFor='linkedIn' className='tw-label'>
            *{translate('handlesScreen.linkedin')}:
          </label>
          <input
            className={`tw-input-primary relative  ${
              errors.linkedIn ? '!border-primary-dark-red' : ''
            }`}
            id='linkedIn'
            type='text'
            autoComplete={'off'}
            value={addCompany?.referee?.contactInfo?.linkedIn}
            placeholder={translate('handlesScreen.enterContactsProfile')}
            onChange={e => {
              const copy = { ...addCompany };
              copy.referee.contactInfo.linkedIn = e.target.value;
              setAddCompany(copy);
              if (e.target.value) {
                setErrors({ ...errors, linkedIn: '' });
              }
            }}
          />
          {errors.linkedIn && (
            <>
              <BsFillExclamationCircleFill className='absolute right-3 top-11 w-15 !text-xl text-primary-dark-red' />
              <p className='absolute left-1 -bottom-5 text-[12px] text-primary-dark-red'>
                {errors.linkedIn}
              </p>
            </>
          )}
        </div>
        <hr className='border-2 border-primary-light-grey w-full sm:hidden' />
        <div className='w-full flex flex-col-reverse sm:flex-row justify-center gap-5 mb-6'>
          <button
            type='button'
            onClick={() => {
              setIsSubmit(true);
              validateInputs();
            }}
            disabled={loading}
            className='tw-input-orange-dark-secondary !bg-transparent !text-center !w-full sm:!w-72'
          >
            {translate('handlesScreen.submitForApproval')}
          </button>
          <button
            type='button'
            onClick={() => {
              setIsSubmit(false);
              validateInputs();
            }}
            disabled={loading}
            className='tw-button-light-blue !w-full sm:!w-72'
          >
            {translate('handlesScreen.saveAndContinueLater')}
          </button>
        </div>
      </div>
    </>
  );
};
