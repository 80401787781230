import axios from 'axios';
import { GeneralParams } from '../types';
export const ReferralServices = {
  referralList: async (params: GeneralParams = {}): Promise<any> => {
    const endPoint = `referral/user/search`;
    try {
      const list = await axios.get(endPoint, { params });
      return list;
    } catch (err) {
      throw err;
    }
  },
  referralDetails: async (referralId: string): Promise<any> => {
    const endPoint = `referral/user/${referralId}`;
    try {
      const detail = await axios.get(endPoint);
      return detail;
    } catch (err) {
      throw err;
    }
  },
  addReferral: async (type: string, payload: object): Promise<any> => {
    const endPoint = `referral/user/${type}`;
    try {
      await axios.post(endPoint, payload);
    } catch (error) {
      throw error;
    }
  },
  addMediaCustomer: async (payload: object): Promise<any> => {
    const endPoint = `referral/user/media/customer`;
    try {
      await axios.post(endPoint, payload);
    } catch (error) {
      throw error;
    }
  },
  addInvestmentOpportunity: async (payload: object): Promise<any> => {
    const endPoint = `referral/user/investment/opportunity`;
    try {
      await axios.post(endPoint, payload);
    } catch (error) {
      throw error;
    }
  },
  submitReferral: async (referralId: string): Promise<any> => {
    const endPoint = `referral/user/${referralId}/status`;
    try {
      await axios.put(endPoint);
    } catch (error) {
      throw error;
    }
  },
  EditReferralCompany: async (referralId: string, payLoad: object): Promise<any> => {
    const endPoint = `referral/user/${referralId}`;
    try {
      await axios.put(endPoint, payLoad);
    } catch (error) {
      throw error;
    }
  },
};
