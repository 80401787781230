import { useEffect } from 'react';

const useDisableInputScroll = () => {
  const handleWheel = (event: any) => {
    if (event?.target?.type === 'number') {
      event?.target?.blur();
    }
  };

  useEffect(() => {
    document.addEventListener('wheel', handleWheel, { passive: false });

    return () => {
      document.removeEventListener('wheel', handleWheel);
    };
  }, []);

  return null;
};

export default useDisableInputScroll;
