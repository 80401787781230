import { useQuery } from '@tanstack/react-query';
import { LEGAL_QUERIES_KEYS } from './lagalQueriesKeys';
import { ILegalState, LegalServices } from 'services/apis/Legal';

export const useFetchLegalState = () => {
  const queryResult = useQuery<ILegalState>({
    queryKey: [LEGAL_QUERIES_KEYS.FETCH_LEGAL_STATE],
    queryFn: () => LegalServices.fetchLegalUserState(),
    refetchOnWindowFocus: false,
    retry: 0,
    networkMode: 'online',
    refetchOnMount: false,
  });

  return queryResult;
};
