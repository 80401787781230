import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AiFillCaretLeft } from 'react-icons/ai';
import {
  getMembership,
  getSocilaIcon,
  getStatus,
  getStatusClass,
  translate,
} from 'utils/helperFunctions';
import { NoAccess, Spiner } from 'components/shared';
import { UseAbility } from 'services/context';
import { Splash } from 'Pages';
import { ReferralServices } from 'services/apis/Referral';
import DetailsLessMore from 'components/shared/DetailsLessMore';
import { toast } from 'react-toastify';
import SureToSubmit from 'components/shared/SureToSubmit';

export const ReferralDetails = () => {
  const navigate = useNavigate();
  const { membership, id, referralId } = useParams();
  const canGetreferralDetails = UseAbility('referralDetails', 'get');
  const canEditReferral = UseAbility('referral', 'edit');
  const canSubmitReferral = UseAbility('referralStatus', 'update');
  const [referral, setReferral] = useState<{ [key: string]: any }>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const companyId = referral?.referee?.companyId;
  const onboardingId = referral?.referee?.onboardingId;

  const shouldShowOnboardingSummary = referral?.status === 'accepted' && !!onboardingId;

  const shouldShowCompanySummary = referral?.status === 'accepted';

  const referralOnboardingSummaryKey = {
    keys: ['referee'],
    title: translate('referralScreen.onboardingSummary'),
    showDots: true,
    render: () => (
      <Link
        to={`/home/mission-control/${membership}/${id}/ref/${referralId}/onboarding-summary/${onboardingId}`}
      >
        <div className='normal-case underline text-primary-blue whitespace-normal break-all'>
          {translate('referralScreen.view')}
        </div>
      </Link>
    ),
  };

  const referralCompanySummaryKey = {
    keys: ['referee'],
    title: translate('referralScreen.companySummary'),
    showDots: true,
    render: () => (
      <Link
        to={`/home/mission-control/${membership}/${id}/ref/${referralId}/company-summary/${companyId}`}
      >
        <div className='normal-case underline text-primary-blue whitespace-normal break-all'>
          {translate('referralScreen.view')}
        </div>
      </Link>
    ),
  };

  const companyView = [
    {
      keys: ['status'],
      showDots: true,
      title: translate('common.status'),
      render: (status: string) => (
        <span className={`${getStatusClass(status)} capitalize`}>{getStatus(status)}</span>
      ),
    },
    { keys: ['type'], showDots: true, title: translate('handlesScreen.type') },
    {
      keys: ['referee', 'membership'],
      title: translate('common.membership'),
      render: (refereeMembership: string) => <>{getMembership(refereeMembership)}</>,
    },
    { keys: ['referee', 'companyName'], title: translate('handlesScreen.companys_Name') },
    {
      keys: ['referee', 'linkedIn'],
      title: translate('handlesScreen.companysLinkedin'),
      showDots: true,
      render: (linkedIn: string) => (
        <a
          href={linkedIn}
          target='_blank'
          className='normal-case underline text-primary-blue whitespace-normal break-all'
        >
          {linkedIn}
        </a>
      ),
    },
    { keys: ['relation'], showDots: true, title: translate('common.relation') },
    {
      keys: ['description'],
      title: translate('referralScreen.moreInfo'),
      showDots: true,

      render: (description: string) => (
        <DetailsLessMore description={description} buttonStyle='text-2xl' />
      ),
    },
    {
      keys: ['referee', 'contactInfo', 'firstName'],
      showDots: true,
      title: translate('common.firstName'),
    },
    {
      keys: ['referee', 'contactInfo', 'lastName'],
      showDots: true,
      title: translate('common.lastName'),
    },
    {
      keys: ['referee', 'contactInfo', 'decisionMaker'],
      title: translate('referralScreen.decisionMaker'),
      showDots: true,
      render: (decisionMaker: any) => <>{decisionMaker ? 'Yes' : 'No'}</>,
    },
    {
      keys: ['referee', 'contactInfo', 'concatenatedPhoneNumber'],
      title: translate('common.phoneNumber'),
      showDots: true,
      render: (concatenatedPhoneNumber: string) => <>+{concatenatedPhoneNumber}</>,
    },
    {
      keys: ['referee', 'contactInfo', 'email'],
      title: translate('common.email'),
      showDots: true,
      render: (email: string) => (
        <span className='normal-case whitespace-normal break-all'>{email}</span>
      ),
    },
    {
      keys: ['referee', 'contactInfo', 'linkedIn'],
      showDots: true,
      title: translate('handlesScreen.contactsLinkedin'),
      render: (linkedIn: string) => (
        <a
          href={linkedIn}
          target='_blank'
          className='normal-case underline text-primary-blue whitespace-normal break-all'
        >
          {linkedIn}
        </a>
      ),
    },
    ...(shouldShowCompanySummary ? [referralCompanySummaryKey] : []),
  ];

  const influencerView = [
    {
      keys: ['status'],
      showDots: true,
      title: translate('common.status'),
      render: (status: string) => (
        <span className={`${getStatusClass(status)} capitalize`}>{getStatus(status)}</span>
      ),
    },
    { keys: ['type'], showDots: true, title: translate('handlesScreen.type') },
    {
      keys: ['referee', 'membership'],
      showDots: true,
      title: translate('common.membership'),
      render: (member: string) => getMembership(member),
    },
    { keys: ['relation'], showDots: true, title: translate('common.relation') },
    {
      keys: ['description'],
      showDots: true,

      title: translate('referralScreen.moreInfo'),
      render: (description: string) => (
        <DetailsLessMore description={description} buttonStyle='text-2xl' />
      ),
    },
    {
      keys: ['referee', 'contactInfo', 'firstName'],
      showDots: true,
      title: translate('common.firstName'),
    },
    {
      keys: ['referee', 'contactInfo', 'lastName'],
      showDots: true,
      title: translate('common.lastName'),
    },
    {
      keys: ['referee', 'contactInfo', 'concatenatedPhoneNumber'],
      title: translate('common.phoneNumber'),
      showDots: true,
      render: (concatenatedPhoneNumber: string) => <>+{concatenatedPhoneNumber}</>,
    },
    {
      keys: ['referee', 'contactInfo', 'email'],
      title: translate('common.email'),
      showDots: true,
      render: (email: string) => (
        <span className='normal-case whitespace-normal break-all'>{email}</span>
      ),
    },
    {
      keys: ['referee', 'handles'],
      title: (
        <div className='flex flex-row w-[128px]'>
          {translate('referralScreen.socialMediaHandles')}:
        </div>
      ),
      showDots: false,
      render: (handles: any[]) => (
        <div className='flex flex-col gap-5 sm:flex-row sm:flex-wrap w-full'>
          {[...(handles || [])].map(handle => {
            return (
              <div className='flex items-center gap-5' key={handle?.handle}>
                <img src={getSocilaIcon(handle?.socialMedia)} alt='icon' className='w-8 h-8' />{' '}
                <span className='whitespace-normal break-all'>
                  {handle?.handle} ({handle?.followers?.toLocaleString()})
                </span>
              </div>
            );
          })}
        </div>
      ),
    },
    ...(shouldShowOnboardingSummary ? [referralOnboardingSummaryKey] : []),
  ];

  const agencyView = [
    {
      keys: ['status'],
      showDots: true,
      title: translate('common.status'),
      render: (status: string) => (
        <span className={`${getStatusClass(status)} capitalize`}>{getStatus(status)}</span>
      ),
    },
    { keys: ['type'], showDots: true, title: translate('handlesScreen.type') },
    {
      keys: ['referee', 'membership'],
      showDots: true,
      title: translate('common.membership'),
      render: (member: string) => getMembership(member),
    },
    {
      keys: ['referee', 'linkedIn'],
      showDots: true,

      title: translate('handlesScreen.agencyLinkedin'),
      render: (linkedIn: string) => (
        <a
          href={linkedIn}
          target='_blank'
          className='normal-case underline text-primary-blue whitespace-normal break-all'
        >
          {linkedIn}
        </a>
      ),
    },
    {
      keys: ['referee', 'website'],
      showDots: true,

      title: translate('handlesScreen.agencyWebsite'),
      render: (website: string) => (
        <a
          href={website}
          target='_blank'
          className='normal-case underline text-primary-blue whitespace-normal break-all'
        >
          {website}
        </a>
      ),
    },
    { keys: ['relation'], showDots: true, title: translate('common.relation') },
    {
      keys: ['description'],
      showDots: true,

      title: translate('referralScreen.moreInfo'),
      render: (description: string) => (
        <DetailsLessMore description={description} buttonStyle='text-2xl' />
      ),
    },
    {
      keys: ['referee', 'contactInfo', 'firstName'],
      showDots: true,
      title: translate('common.firstName'),
    },
    {
      keys: ['referee', 'contactInfo', 'lastName'],
      showDots: true,
      title: translate('common.lastName'),
    },
    {
      keys: ['referee', 'contactInfo', 'decisionMaker'],
      showDots: true,

      title: translate('referralScreen.decisionMaker'),
      render: (decisionMaker: any) => <>{decisionMaker ? 'Yes' : 'No'}</>,
    },
    {
      keys: ['referee', 'contactInfo', 'concatenatedPhoneNumber'],
      showDots: true,

      title: translate('common.phoneNumber'),
      render: (concatenatedPhoneNumber: string) => <>+{concatenatedPhoneNumber}</>,
    },
    {
      keys: ['referee', 'contactInfo', 'email'],
      showDots: true,

      title: translate('common.email'),
      render: (email: string) => (
        <span className='normal-case whitespace-normal break-all'>{email}</span>
      ),
    },
    {
      keys: ['referee', 'contactInfo', 'linkedIn'],
      showDots: true,

      title: translate('handlesScreen.contactsLinkedin'),
      render: (linkedIn: string) => (
        <a
          href={'linkedIn'}
          target='_blank'
          className='normal-case underline text-primary-blue whitespace-normal break-all'
        >
          {linkedIn}
        </a>
      ),
    },
    ...(shouldShowOnboardingSummary ? [referralOnboardingSummaryKey] : []),
  ];

  const ambassadorView = [
    {
      keys: ['status'],
      showDots: true,
      title: translate('common.status'),
      render: (status: string) => (
        <span className={`${getStatusClass(status)} capitalize`}>{getStatus(status)}</span>
      ),
    },
    { keys: ['type'], showDots: true, title: translate('handlesScreen.type') },
    {
      keys: ['referee', 'membership'],
      showDots: true,
      title: translate('common.membership'),
      render: (member: string) => getMembership(member),
    },
    { keys: ['relation'], showDots: true, title: translate('common.relation') },
    {
      keys: ['description'],
      title: translate('referralScreen.moreInfo'),
      showDots: true,

      render: (description: string) => (
        <DetailsLessMore description={description} buttonStyle='text-2xl' />
      ),
    },
    {
      keys: ['referee', 'contactInfo', 'firstName'],
      showDots: true,
      title: translate('common.firstName'),
    },
    {
      keys: ['referee', 'contactInfo', 'lastName'],
      showDots: true,
      title: translate('common.lastName'),
    },
    {
      keys: ['referee', 'contactInfo', 'concatenatedPhoneNumber'],
      title: translate('common.phoneNumber'),
      showDots: true,
      render: (concatenatedPhoneNumber: string) => <>+{concatenatedPhoneNumber}</>,
    },
    {
      keys: ['referee', 'contactInfo', 'email'],
      title: translate('common.email'),
      showDots: true,
      render: (email: string) => (
        <span className='normal-case whitespace-normal break-all'>{email}</span>
      ),
    },
    ...(shouldShowOnboardingSummary ? [referralOnboardingSummaryKey] : []),
  ];

  const getView = (type: string) => {
    switch (type) {
      case 'company':
        return companyView;
      case 'influencer':
        return influencerView;
      case 'agency':
        return agencyView;
      case 'ambassador':
        return ambassadorView;
      default:
        return [];
    }
  };

  const renderValue = (que: any, keys: any) => {
    let value = que;
    for (const key of keys) {
      if (value.hasOwnProperty(key)) {
        value = value[key];
      } else {
        value = '-'; // default value if key is not found
        break;
      }
    }
    return value;
  };

  const fetchRferraldetails = async () => {
    if (referralId) {
      setLoading(true);
      try {
        const detail = await ReferralServices.referralDetails(referralId);
        setReferral(detail);
      } catch (error: any) {
        toast.error(error?.message);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchRferraldetails();
  }, []);

  const onSubmitReferral = async () => {
    if (referralId) {
      try {
        setLoading(true);
        await ReferralServices.submitReferral(referralId);
        fetchRferraldetails();
        toast.success(translate('referralScreen.statusUpdatedSuccessfully'));
      } catch (error: any) {
        toast.error(error?.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const _renderReferralDetails = () => {
    return (
      <>
        <SureToSubmit
          message={translate('handlesScreen.areYouSureYouWantToSubmitThisReferral')}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          submitFunction={onSubmitReferral}
          okText={translate('common.submit')}
          cancelText={`${translate('common.cancel')}`}
        />
        <div
          className='bg-primary-dark-grey border-2 xborder-t-0 border-primary-blue p-4'
          style={{ boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15266)' }}
        >
          {referral?.status === 'new' && (
            <div className='w-full flex gap-5 justify-end'>
              {canSubmitReferral && (
                <button
                  className='tw-input-orange-dark !bg-transparent !px-0 w-[110px] md:w-[150px] !text-center font-inter'
                  onClick={() => setIsVisible(true)}
                >
                  {loading && <Spiner className='ml-[85px] md:ml-28' />}
                  {translate('common.submit')}
                </button>
              )}
              {canEditReferral && (
                <button
                  className='tw-button-light-blue !px-0 w-[110px] md:w-[150px] !text-center font-inter'
                  onClick={() => {
                    navigate(`/home/mission-control/${membership}/${id}/referral/${referralId}`, {
                      state: false,
                    });
                  }}
                >
                  {translate('common.edit')}
                </button>
              )}
            </div>
          )}
          <div className='divide-y-2 divide-primary-light-grey text-2xl font-bold capitalize'>
            {referral &&
              getView(
                referral?.type === 'company' ? referral?.type : referral?.referee?.membership
              ).map((item: any, index: number) => (
                <div key={index}>
                  {item?.keys && (
                    <div className='flex items-start py-6 '>
                      <div className='shrink-0 w-[128px] text-primary-orange'>
                        {item.title}
                        {item.showDots && `${`:`}`}{' '}
                      </div>
                      <div style={{ wordBreak: 'break-word' }}>
                        {item?.render ? (
                          item?.render(renderValue(referral, item.keys))
                        ) : (
                          <div>{renderValue(referral, item.keys)}</div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            {!referral && (
              <div className='flex items-center justify-center text-3xl h-52 text-primary-light-grey font-bold'>
                {translate('referralScreen.noReferralFound')}
              </div>
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <div className='relative font-Condensed capitalize'>
      {loading ? (
        <div className='inset-0 z-50 absolute w-full bg-primary-medium-grey'>
          <Splash />
        </div>
      ) : (
        <>
          <div className='font-Condensed flex justify-start items-center text-primary-light-grey text-3xl mb-5'>
            <div className=''>
              <AiFillCaretLeft
                className='text-5xl cursor-pointer hover:filter hover:brightness-125 hover:contrast-125 transition-all duration-300 hover:scale-[1.1]'
                onClick={() => {
                  navigate(`/home/mission-control/${membership}/${id}?tab=referrals`);
                }}
              />
            </div>
            <div className='xnormal-case'>
              {getMembership(
                referral?.type === 'company' ? referral?.type : referral?.referee?.membership
              )}{' '}
              {translate('common.referral')} {translate('common.details')}
            </div>
          </div>
          {canGetreferralDetails ? (
            <div className='flex flex-col gap-4'>{_renderReferralDetails()}</div>
          ) : (
            <NoAccess membership={membership as string} />
          )}
        </>
      )}
    </div>
  );
};
