import { useEffect, useState } from 'react';
import { DefaultNoAccess, LoadingPage, Table } from 'components/shared';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { UseAbility } from 'services/context';
import { ILegalItem, LegalServices } from 'services/apis/Legal';
import Box from 'components/shared/Box';
import useLegalColumns from '../hooks/useLegalColumns';

export const PrivacyListing = () => {
  const { userTermsAndPrivacyColumns } = useLegalColumns();
  const navigate = useNavigate();
  const canGetOutdatedPrivacyPolicies = UseAbility('OutdatedPrivacyPolicies', 'check');
  const canGetUserTermsOrPrivacyPolicyDetails = UseAbility(
    'userTermsOrPrivacyPolicySummary',
    'get'
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [privacyPolicy, setPrivacyPolicy] = useState<ILegalItem[]>([]);

  const fetchUserTermsList = async () => {
    setLoading(true);
    try {
      const list = await LegalServices.fetchOutdatedPrivacyPolicy();
      setPrivacyPolicy(list.items);
    } catch (error: any) {
      toast.error(error?.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (canGetOutdatedPrivacyPolicies) {
      fetchUserTermsList();
    }
  }, [canGetOutdatedPrivacyPolicies]);

  return (
    <Box className='relative border-t-0'>
      <LoadingPage loading={loading} />
      {canGetOutdatedPrivacyPolicies && (
        <Table
          columns={userTermsAndPrivacyColumns}
          dataSource={privacyPolicy}
          hover
          onClickRow={(_index, item) =>
            canGetUserTermsOrPrivacyPolicyDetails &&
            navigate(`/home/legal/privacy/${item._id}/privacy`)
          }
          loading={loading}
        />
      )}
      {!canGetOutdatedPrivacyPolicies && <DefaultNoAccess />}
    </Box>
  );
};
