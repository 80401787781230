import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Logo from 'assets/images/Logo.svg';
import { toast } from 'react-toastify';
import { CountdownCircle, Spiner } from '../../components/shared';
import { getInviteCode, translate } from 'utils/helperFunctions';
import { SlReload } from 'react-icons/sl';
import { OnboardingServices } from 'services/apis/Onboarding';
import { ValidateDataType } from 'services/apis/Onboarding/Onboarding.types';
import { AuthSessionServices } from 'services/apis/AuthSession';

export const ValidateJoin = () => {
  const navigate = useNavigate();
  const route = useLocation();

  const [loading, setLoading] = useState<boolean>(false);
  const [validateData, setValidateData] = useState<ValidateDataType>({
    code: route.state,
    phoneCode: '',
    emailCode: '',
  });
  const [toogleSmsTimer, setToogleSmsTimer] = useState<boolean>(false);
  const [toogleEmailTimer, setToogleEmailTimer] = useState<boolean>(false);
  const code = getInviteCode();
  useEffect(() => {
    return () => {
      setValidateData({
        code: route.state,
        phoneCode: '',
        emailCode: '',
      });
    };
  }, []);

  const fetchMembershipRecord = async () => {
    if (code) {
      try {
        const record = await OnboardingServices.fetchInviteRecord(code);
        navigate(`/home/onboarding-wizard/${record._id}`, { replace: true });
      } catch (error: any) {
        toast.error(error?.message);
      }
    }
  };

  const onValidateCode = async () => {
    try {
      if (validateData.phoneCode && validateData.emailCode) {
        setLoading(true);
        await OnboardingServices.onValidateCodes({ ...validateData });
        await AuthSessionServices.createSession();
        setLoading(false);
        fetchMembershipRecord();
      }
    } catch (error: any) {
      setLoading(false);
      if (error.cause.errors.details[0].code === 404) {
        return toast.error(translate('join.IncorrectSmsOrEmailCode'));
      }
      toast.error(error?.message);
    }
  };

  const onResendSmsCode = async () => {
    try {
      await OnboardingServices.onResendValidateCode(route.state, 'phone');
      setValidateData({
        ...validateData,
        phoneCode: '',
      });
      setToogleSmsTimer(show => !show);
      toast.success(translate('profileScreen.codeSentSuccessfully'));
    } catch (error: any) {
      toast.error(error?.message);
    }
  };
  const onResendEmailCode = async () => {
    try {
      await OnboardingServices.onResendValidateCode(route.state, 'email');
      setValidateData({
        ...validateData,
        emailCode: '',
      });
      setToogleEmailTimer(show => !show);
      toast.success(translate('profileScreen.codeSentSuccessfully'));
    } catch (error: any) {
      toast.error(error?.message);
    }
  };

  return (
    <div className='p-8 '>
      <div className='flex flex-col items-start z-50 sm:ml-7 lg:ml-0'>
        <img src={Logo} alt='logo' />
      </div>
      <div className='grid grid-cols-1 lg:grid lg:grid-cols-2 gap-y-6 gap-x-28 lg:mt-[15vh]'>
        <div className='text-left mt-14 sm:ml-7 lg:ml-0'>
          <div className='sm:flex gap-3 lg:flex-col items-start'>
            <h2 className='font-Condensed text-primary-orange text-[64px] lg:text-9xl font-bold uppercase h-[64px] sm:h-fit'>
              {translate('loginScreen.prove')}
            </h2>
            <h2 className='font-Condensed text-primary-orange text-[64px]  lg:text-9xl font-bold uppercase h-[64px] sm:h-fit'>
              {translate('loginScreen.youreYou')}
            </h2>
          </div>
        </div>
        <div className='flex flex-col gap-8 mx-0 sm:mx-7 lg:mx-0 bg-primary-dark-grey px-4 py-6 overflow-hidden primaryBorder'>
          <div>
            <h2 className='font-Condensed text-primary-orange text-4xl font-bold'>
              {translate('loginScreen.chechYourPhoneandEmail')}
            </h2>
            <p className='mt-4 xtext-base font-normal '>
              {translate('loginScreen.weSentYouCodeandEmail')}
            </p>
          </div>
          <div className='flex flex-col gap-6'>
            <div className='relative'>
              <div className='input-wrapper'>
                <label htmlFor='smsCode' className='tw-label'>
                  {translate('common.smsCode')}
                </label>
                <input
                  className='tw-input-primary'
                  id='smsCode'
                  type='text'
                  value={validateData.phoneCode || ''}
                  placeholder={translate('loginScreen.enterSmsCode')}
                  onKeyUp={event =>
                    event.key === 'Enter' &&
                    validateData.emailCode &&
                    validateData.phoneCode &&
                    onValidateCode()
                  }
                  onChange={e => {
                    const pattern = `^[0-9]{0,6}$`;
                    const regex = new RegExp(pattern, 'g');
                    if (regex.test(e.target.value) || !e.target.value) {
                      setValidateData({
                        ...validateData,
                        phoneCode: e?.target.value.toString() as string,
                      });
                    }
                  }}
                />
              </div>

              <span className='cursor-pointer absolute right-4 top-10'>
                {!toogleSmsTimer ? (
                  <SlReload
                    onClick={onResendSmsCode}
                    className='text-3xl stroke-[70px] rotate-[150deg] text-primary-orange'
                  />
                ) : (
                  <CountdownCircle
                    className=''
                    initialTime={120}
                    onChange={e => setToogleSmsTimer(!e)}
                  />
                )}
              </span>
            </div>
            <div className='relative'>
              <div className='input-wrapper'>
                <label className='tw-label'>{translate('common.emailCode')}</label>
                <input
                  className='tw-input-primary'
                  placeholder={translate('loginScreen.enterEmailCode')}
                  value={validateData.emailCode || ''}
                  onKeyUp={event =>
                    event.key === 'Enter' &&
                    !!validateData.emailCode &&
                    !!validateData.phoneCode &&
                    onValidateCode()
                  }
                  onChange={e => {
                    const pattern = `^[0-9]{0,6}$`;
                    const regex = new RegExp(pattern, 'g');
                    if (regex.test(e.target.value) || !e.target.value) {
                      setValidateData({
                        ...validateData,
                        emailCode: e?.target.value.toString() as string,
                      });
                    }
                  }}
                />
              </div>
              <span className='cursor-pointer absolute right-4 top-10'>
                {!toogleEmailTimer ? (
                  <SlReload
                    onClick={onResendEmailCode}
                    className='text-3xl stroke-[70px] rotate-[150deg] text-primary-orange'
                  />
                ) : (
                  <CountdownCircle
                    className=''
                    initialTime={120}
                    onChange={e => setToogleEmailTimer(!e)}
                  />
                )}
              </span>
            </div>
          </div>
          <div className='text-center'>
            <button
              type='button'
              disabled={loading || !validateData.phoneCode || !validateData.emailCode}
              onClick={onValidateCode}
              className='tw-button-light-blue !w-full sm:!w-72'
            >
              {loading && <Spiner className='ml-24 sm:ml-40' />}
              {translate('common.imMe')}
            </button>
          </div>
          <div className='text-center -mt-3'>
            <p className='text-primary-orange text-xl uppercase text-center mx-auto'>
              <Link to={'/join'} className='font-Condensed text-2xl font-bold cursor-pointer'>
                {translate('common.goBack')}
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
