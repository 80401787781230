import { CustomCheckbox, OptionType } from 'components/shared';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { whatLightsYourFireOptions } from 'utils/helperFunctions';

type Props = {
  onChange: (keys: any) => void;
  metadata?: any;
};

export const WizardNewLightsYourFire = (props: Props) => {
  const { onChange, metadata } = props;
  const { t } = useTranslation();
  const onChangeInfo = (data = {}) => {
    onChange(data);
  };

  const options: OptionType[] = whatLightsYourFireOptions.map(({ detail, title }) => ({
    title: t(title),
    detail: t(detail) || '',
  }));

  return (
    <div className='flex flex-col justify-center items-center gap-8 mt-4 mb-6 mx-4 sm:mx-8 md:mx-16 lg:mx-40 text-center'>
      <div className='font-Condensed text-white text-[36px] font-bold'>
        {t('metadataScreen.whatLightsyourFire')}?
      </div>
      <div className='text-[20px] text-primary-light-grey font-normal text-left -ml-2 sm:ml-0 sm:text-center -mb-4 sm:mb-0'>
        {t('metadataScreen.yourFollowersAreLiftedByWhatInspiresYou')}
      </div>
      <CustomCheckbox
        className={'grid grid-cols-2 md:grid-cols-3 gap-4'}
        borderColor={'border-primary-orange'}
        onSelectColor='bg-[#3D1E19]'
        options={options}
        selectedPackages={metadata.lightsYourFire || []}
        onChange={e => {
          onChangeInfo({ ...metadata, lightsYourFire: e });
        }}
      />
    </div>
  );
};
