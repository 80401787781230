import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AiFillCaretLeft } from 'react-icons/ai';
import { toast } from 'react-toastify';
// import { translate } from 'utils/helperFunctions';
import { translate } from 'utils/helperFunctions';
import { DomainServices } from 'services/apis/Domain';
import { UseAbility } from 'services/context';
import { NoAccess } from 'components/shared';
import { Splash } from 'Pages';
import { Image } from 'components/shared/Binary/Binary';

export const DomainDetails = () => {
  const navigate = useNavigate();
  const { membership, id, handleId, domainId } = useParams();
  const canGetDomainSummary = UseAbility('domainSummary', 'get');
  const [domain, setDomain] = useState<{ [key: string]: any }>({});
  const [loading, setLoading] = useState<boolean>(false);

  const fetchHandledetails = async () => {
    if (domainId) {
      try {
        setLoading(true);
        const detail = await DomainServices.domainDetails(domainId);
        setDomain(detail);
        setLoading(false);
      } catch (error: any) {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    fetchHandledetails();
  }, []);

  const _renderHandleDetails = () => {
    return (
      <div
        className='bg-primary-dark-grey border-2 xborder-t-0 border-primary-blue py-2 px-4'
        style={{ boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15266)' }}
      >
        <div className='divide-y-2 divide-primary-light-grey text-2xl font-bold capitalize'>
          <div className='flex items-center py-2 sm:py-4 gap-x-5 '>
            <div className='min-w-[100px] text-primary-orange'>{translate('common.name')}:</div>
            <div>{domain?.name || '-'}</div>
          </div>
          <div className='flex items-center py-2 sm:py-4 gap-x-5'>
            <div className='min-w-[100px] text-primary-orange'>
              {translate('common.description')}:
            </div>
            <div>{domain?.description || '-'}</div>
          </div>
          <div className='flex items-center py-2 sm:py-4 gap-x-5'>
            <div className='min-w-[100px] text-primary-orange'>{translate('common.image')}:</div>
            {domain?.image ? (
              <div className='overflow-hidden overflow-x-auto whitespace-nowrap flex'>
                <div className='m-auto space-x-3'>
                  {[domain?.image]?.flat()?.map((image: string, index: number) => {
                    return (
                      <div key={index} className='inline-block w-[100px]'>
                        <Image
                          src={image}
                          containerClassName='max-w-[100px] h-[224px] rounded-lg inline-block object-cover'
                          alt='img'
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              '-'
            )}
          </div>
          <div className='flex items-center py-2 sm:py-4 gap-x-5'>
            <div className='min-w-[100px] text-primary-orange'>{translate('common.type')}:</div>
            <div>{domain?.type || '-'}</div>
          </div>
          <div className='flex items-center py-2 sm:py-4 gap-x-5'>
            <div className='min-w-[100px] text-primary-orange'>{translate('common.unit')}:</div>
            <div>{domain?.unit || '-'}</div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className='font-Condensed capitalize'>
      {loading && (
        <div className='inset-0 z-50 absolute w-full bg-primary-medium-grey'>
          <Splash />
        </div>
      )}
      <div className='font-Condensed flex justify-start items-center text-primary-light-grey text-3xl mb-5'>
        <div className=''>
          <AiFillCaretLeft
            className='text-5xl cursor-pointer hover:filter hover:brightness-125 hover:contrast-125 transition-all duration-300 hover:scale-[1.1]'
            onClick={() => {
              navigate(`/home/mission-control/${membership}/${id}/${handleId}`);
            }}
          />
        </div>
        <div className='normal-case'>
          {domain?.name} {translate('common.details')}
        </div>
      </div>
      {canGetDomainSummary ? (
        <div className='flex flex-col gap-4'>{_renderHandleDetails()}</div>
      ) : (
        <NoAccess membership={membership as string} />
      )}
    </div>
  );
};
