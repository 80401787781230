import React, { useState, useRef, useEffect } from 'react';

type Props = {
  description: string;
  title?: string;
  buttonStyle?: string;
  descriptionStyle?: string;
  containerClassNames?: string;
};

const DetailsLessMore = ({
  description,
  title,
  buttonStyle = '',
  descriptionStyle = '',
  containerClassNames = '',
}: Props) => {
  const contentRefs: any = useRef(null);
  const [expanded, setExpanded] = useState({
    detailNeedexpand: false,
    isDetailExpanded: false,
  });

  useEffect(() => {
    const ref = contentRefs.current;
    if (ref) {
      const divHeight = ref.clientHeight;
      const lineHeight = parseInt(window.getComputedStyle(ref).lineHeight);
      const numberOfLines = Math.ceil(divHeight / lineHeight);
      setExpanded({
        ...expanded,
        detailNeedexpand: numberOfLines > 4,
      });
    }
  }, [description]);

  return (
    <div className={`relative pb-5 ${containerClassNames ? containerClassNames : ''}`}>
      <div
        ref={contentRefs}
        className={`font-normal ${
          expanded.isDetailExpanded || !expanded.detailNeedexpand ? '' : 'ellipsis'
        }`}
      >
        {title ? <span>{title}: </span> : null}{' '}
        <span className={`${descriptionStyle ? descriptionStyle : 'font-normal text-white'}`}>
          {description || '-'}
        </span>
        {expanded.detailNeedexpand && (
          <button
            className={`absolute right-2 bottom-0 z-10 leading-6 text-white bg-primary-dark-grey ${
              buttonStyle ? buttonStyle : 'text-sm'
            }`}
            onClick={() => {
              setExpanded({
                ...expanded,
                isDetailExpanded: !expanded.isDetailExpanded,
              });
            }}
          >
            {expanded.isDetailExpanded ? 'See Less' : 'See More'}
          </button>
        )}
      </div>
    </div>
  );
};

export default DetailsLessMore;
