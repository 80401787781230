import { NoAccess } from 'components/shared';
import React, { useCallback, useEffect, useState } from 'react';
import { AiFillCaretLeft } from 'react-icons/ai';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { OrganizationServices } from 'services/apis/Organization';
import { CompanySummary as CompanySummaryType } from 'services/apis/Organization/Organization.types';
import { UseAbility } from 'services/context';
import { translate } from 'utils/helperFunctions';
import format from 'date-fns/format';

const CompanySummary = () => {
  const { referralId, membership, id, companyId } = useParams();
  const [companySummary, setCompanySummary] = useState<Partial<CompanySummaryType> | null>({});
  const navigate = useNavigate();
  const canGetreferralDetails = UseAbility('referralDetails', 'get');

  const getCompanySummary = useCallback(async () => {
    try {
      if (!companyId) {
        return;
      }
      const summary = await OrganizationServices.fetchCompanySummary(companyId);
      setCompanySummary(summary);
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error?.message);
      }
    }
  }, [companyId]);

  useEffect(() => {
    getCompanySummary();
  }, [companyId, getCompanySummary]);

  const views = [
    {
      title: translate('common.name'),
      render: () => <span>{companySummary?.name}</span>,
    },
    {
      title: translate('common.description'),
      render: () => (
        <span dangerouslySetInnerHTML={{ __html: companySummary?.description || '' }} />
      ),
    },
    {
      title: translate('common.address1'),
      render: () => <span>{companySummary?.address?.address1 || '-'}</span>,
    },
    {
      title: translate('common.address2'),
      render: () => <span>{companySummary?.address?.address2 || '-'}</span>,
    },
    {
      title: translate('common.city'),
      render: () => <span>{companySummary?.address?.city || '-'}</span>,
    },
    {
      title: translate('common.state'),
      render: () => <span>{companySummary?.address?.state || '-'}</span>,
    },
    {
      title: translate('common.zip'),
      render: () => <span>{companySummary?.address?.zip || '-'}</span>,
    },
    {
      title: translate('common.country'),
      render: () => <span>{companySummary?.address?.country || '-'}</span>,
    },
    {
      title: translate('common.knownAs'),
      render: () => (
        <span>{companySummary?.knownAs?.length ? companySummary?.knownAs?.join(', ') : '-'}</span>
      ),
    },
    {
      title: translate('common.numberOfEmployees'),
      render: () => (
        <span>
          {companySummary?.profile?.nbrOfEmployee?.from} -{' '}
          {companySummary?.profile?.nbrOfEmployee?.to}
        </span>
      ),
    },
    {
      title: translate('common.addedOn'),
      render: () => (
        <span>
          {companySummary?.time ? format(new Date(companySummary?.time), 'MMM dd, yyyy') : ''}
        </span>
      ),
    },
  ];

  const _renderReferralDetails = () => {
    return (
      <>
        <div
          className='bg-primary-dark-grey border-2 xborder-t-0 border-primary-blue p-4'
          style={{ boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15266)' }}
        >
          <div className='divide-y-2 divide-primary-light-grey text-2xl font-bold capitalize'>
            {views.map((item, index) => (
              <div key={index} className='flex items-start py-6 '>
                <div className='shrink-1 w-[128px] text-primary-orange' style={{ minWidth: 128 }}>
                  {item.title}:
                </div>
                <div className=''>
                  <div style={{ wordBreak: 'break-word' }}>{item.render()}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
    );
  };

  const validCompanyId = companyId && companySummary;

  return (
    <div className='relative font-Condensed capitalize'>
      <div className='font-Condensed flex justify-start items-center text-primary-light-grey text-3xl mb-5'>
        <div className=''>
          <AiFillCaretLeft
            className='text-5xl cursor-pointer hover:filter hover:brightness-125 hover:contrast-125 transition-all duration-300 hover:scale-[1.1]'
            onClick={() => {
              navigate(`/home/mission-control/${membership}/${id}/ref/${referralId}`);
            }}
          />
        </div>
        <div className='xnormal-case'>{translate('referralScreen.companySummary')}</div>
      </div>
      {canGetreferralDetails && validCompanyId ? (
        <div className='flex flex-col gap-4'>{_renderReferralDetails()}</div>
      ) : (
        <NoAccess membership={membership as string} />
      )}
      {!validCompanyId && (
        <div className='text-3xl text-primary-light-grey font-bold text-center'>
          {translate('referralScreen.companySummary')}
        </div>
      )}
    </div>
  );
};

export default CompanySummary;
