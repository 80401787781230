import { NoAccess } from 'components/shared';
import React, { useCallback, useEffect, useState } from 'react';
import { AiFillCaretLeft } from 'react-icons/ai';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { OnboardingServices } from 'services/apis/Onboarding';
import { OnBoardingSummary } from 'services/apis/Onboarding/Onboarding.types';
import { UseAbility } from 'services/context';
import { translate } from 'utils/helperFunctions';

const OnboardingSummary = () => {
  const { membership, id, referralId, onboardingId } = useParams();
  const [onboardingSummary, setOnboardingSummary] = useState<Partial<OnBoardingSummary> | null>({});
  const navigate = useNavigate();
  const canGetreferralDetails = UseAbility('referralDetails', 'get');

  const getOnboardingSummary = useCallback(async () => {
    try {
      if (!onboardingId) {
        return;
      }
      const summary = await OnboardingServices.fetchOnboardingSummary(onboardingId);
      setOnboardingSummary(summary);
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error?.message);
      }
    }
  }, [onboardingId]);

  useEffect(() => {
    getOnboardingSummary();
  }, [onboardingId, getOnboardingSummary]);

  const views = [
    {
      title: translate('common.membership'),
      render: () => <span>{onboardingSummary?.membership}</span>,
    },
    {
      title: translate('common.status'),
      render: () => <span>{onboardingSummary?.status}</span>,
    },
    {
      title: translate('common.firstName'),
      render: () => <span>{onboardingSummary?.firstName}</span>,
    },
    {
      title: translate('common.lastName'),
      render: () => <span>{onboardingSummary?.lastName}</span>,
    },
  ];

  const _renderSumamry = () => {
    return (
      <>
        <div
          className='bg-primary-dark-grey border-2 xborder-t-0 border-primary-blue p-4'
          style={{ boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15266)' }}
        >
          <div className='divide-y-2 divide-primary-light-grey text-2xl font-bold capitalize'>
            {views.map((item, index) => (
              <div key={index} className='flex items-start py-6 '>
                <div className='shrink-1 min-w[128px] w-[256px] text-primary-orange'>
                  {item.title}:
                </div>
                <div className=''>
                  <div className='xwhitespace-normal xbreak-all'>{item.render()}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
    );
  };

  const validOnboardingId = onboardingId && onboardingSummary;

  return (
    <div className='relative font-Condensed capitalize'>
      <div className='font-Condensed flex justify-start items-center text-primary-light-grey text-3xl mb-5'>
        <div className=''>
          <AiFillCaretLeft
            className='text-5xl cursor-pointer hover:filter hover:brightness-125 hover:contrast-125 transition-all duration-300 hover:scale-[1.1]'
            onClick={() => {
              navigate(`/home/mission-control/${membership}/${id}/ref/${referralId}`);
            }}
          />
        </div>
        <div className='xnormal-case'>{translate('referralScreen.onboardingSummary')}</div>
      </div>
      {canGetreferralDetails && validOnboardingId ? (
        <div className='flex flex-col gap-4'>{_renderSumamry()}</div>
      ) : (
        <NoAccess membership={membership as string} />
      )}
      {!validOnboardingId && (
        <div className='text-3xl text-primary-light-grey font-bold text-center'>
          {translate('referralScreen.onboardingSummary')}
        </div>
      )}
    </div>
  );
};

export default OnboardingSummary;
