import { Suspense } from 'react';
import {
  unstable_HistoryRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from 'react-router-dom';
import { useStore } from 'services/context';
import { Join, Login, ValidateJoin, VerifyLogin } from 'screens/Login';
import { DashboardLayout, Home } from 'screens/Dashboard';
import { MyProfile } from 'screens/MyProfile';
import { Page404 } from 'Pages';
import ScrollToTop from 'utils/ScrollToTop';
import { AlreadyUser } from 'screens/Login/AlreadyUser';
import {
  OnboardingWizard,
  WizardInProgress,
  WizardNew,
  WizardNotSigned,
  WizardRejected,
  WizardSendNote,
  WizardSigned,
  WizardWaitingForSignature,
} from 'screens/onboardingWizard';
import {
  DomainDetails,
  HandleDetails,
  MissionControl,
  SuspendHandle,
  AddReferral,
  ReferralDetails,
  AddEditHandle,
  SuspensionDetails,
} from 'screens/missionControl';
import { CompaniesScreen } from 'screens/companiesScreen';
import { HandlesScreen } from 'screens/handlesScreen';
import history from 'utils/hooks/history';
import ViewHandleChanges from 'screens/missionControl/ViewHandleChanges';
import OnBoardingSummary from 'screens/missionControl/OnboardingSummary';
import CompanySummary from 'screens/missionControl/CompanySummary';
import { LegalListing } from 'screens/Legal';
import { LegalSummaryView } from 'screens/Legal/LegalSummaryView';
import { SafetyScreen } from 'components/shared';

const publicRoutes = [
  {
    path: '/login',
    component: <Login />,
  },
  {
    path: '/verify',
    component: <VerifyLogin />,
  },
  {
    path: '/join',
    component: <Join />,
  },
  {
    path: '/Validate',
    component: <ValidateJoin />,
  },
  {
    path: '/already-user',
    component: <AlreadyUser />,
  },
];

const privateRoutes = [
  {
    path: '/no-access',
    element: <SafetyScreen />,
    children: [],
  },
  {
    path: '/home',
    element: <DashboardLayout />,
    children: [
      {
        path: '/home',
        element: <Home />,
      },
      {
        path: '/home/legal',
        element: <LegalListing />,
      },
      {
        path: '/home/legal/:type/:legalId/:participationId',
        element: <LegalSummaryView />,
      },
      {
        path: '/home/mission-control/:membership/:id',
        element: <MissionControl />,
      },
      {
        path: '/home/mission-control/:membership/:id/:handleId',
        element: <HandleDetails />,
      },
      {
        path: '/home/mission-control/:membership/:id/:handleId/view-changes',
        element: <ViewHandleChanges />,
      },
      {
        path: '/home/mission-control/:membership/:id/:handleId/suspend',
        element: <SuspendHandle />,
      },
      {
        path: '/home/mission-control/:membership/:id/:handleId/suspension',
        element: <SuspensionDetails />,
      },
      {
        path: '/home/mission-control/:membership/:id/:handleId/:domainId',
        element: <DomainDetails />,
      },
      {
        path: '/home/mission-control/:membership/:id/action/:handleId',
        element: <AddEditHandle />,
      },
      {
        path: '/home/mission-control/:membership/:id/ref/:referralId',
        element: <ReferralDetails />,
      },
      {
        path: '/home/mission-control/:membership/:id/ref/:referralId/onboarding-summary/:onboardingId',
        element: <OnBoardingSummary />,
      },
      {
        path: '/home/mission-control/:membership/:id/ref/:referralId/company-summary/:companyId',
        element: <CompanySummary />,
      },
      {
        path: '/home/mission-control/:membership/:id/referral/:referralId',
        element: <AddReferral />,
      },
      {
        path: '/home/handles',
        element: <HandlesScreen />,
      },
      {
        path: '/home/profile',
        element: <MyProfile />,
      },
      {
        path: '/home/companies',
        element: <CompaniesScreen />,
      },
      {
        path: '/home/help',
        element: <WizardSendNote />,
      },
      {
        path: '/home/onboarding-wizard/:id',
        element: <OnboardingWizard />,
      },
      {
        path: '/home/onboarding-wizard/:id/new',
        element: <WizardNew />,
      },
      {
        path: '/home/onboarding-wizard/:id/inProgress',
        element: <WizardInProgress />,
      },
      {
        path: '/home/onboarding-wizard/:id/waitingForSignature',
        element: <WizardWaitingForSignature />,
      },
      {
        path: '/home/onboarding-wizard/:id/notSigned',
        element: <WizardNotSigned />,
      },
      {
        path: '/home/onboarding-wizard/:id/signed',
        element: <WizardSigned />,
      },
      {
        path: '/home/onboarding-wizard/:id/rejected',
        element: <WizardRejected />,
      },
      {
        path: '/home/onboarding-wizard/:id/wizardSendNote',
        element: <WizardSendNote />,
      },
    ],
  },
];

const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  const [state] = useStore();
  const isAuthenticated: boolean = state.authStore.isLoggedIn || false;

  if (!isAuthenticated) {
    return <Navigate to='/join' state={{ from: location }} />;
  }

  return children;
};

const PublicRoute = ({ children }: { children: JSX.Element }) => {
  const [state] = useStore();
  const isAuthenticated: boolean = state.authStore.isLoggedIn || false;

  if (isAuthenticated) {
    return <Navigate to='/home' />;
  }
  return children;
};

const AppRouter = () => {
  return (
    <Suspense>
      <Router
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        history={history}
      >
        <ScrollToTop />
        <Routes>
          {/* Start Public Routes */}
          {publicRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={<PublicRoute key={index}>{route.component}</PublicRoute>}
            />
          ))}
          {/* End Public Routes */}

          {/* Start Private Routes */}
          {privateRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={<PrivateRoute>{route.element}</PrivateRoute>}
            >
              {route.children.map((childRoute, cIndex) => (
                <Route
                  key={`${index}-${cIndex}`}
                  path={childRoute.path}
                  element={<PrivateRoute>{childRoute.element}</PrivateRoute>}
                />
              ))}
            </Route>
          ))}
          {/* End Private Routes */}
          <Route path='*' element={<Page404 />} />
        </Routes>
      </Router>
    </Suspense>
  );
};

export default AppRouter;
