import axios from 'axios';

export const DomainServices = {
  domainDetails: async (domainId: string): Promise<any> => {
    const endPoint = `domain/${domainId}/user/summary`;
    try {
      const list = await axios.get(endPoint);
      return list;
    } catch (err) {
      throw err;
    }
  },
};
