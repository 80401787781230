import React, { useEffect, useState } from 'react';
import { calcPagination, classNames, getStatusClass, translate } from 'utils/helperFunctions';
import { AlignTypes, Column, Modal, Pagination, Table, VeticalAlignTypes } from 'components/shared';
import { SearchIcon } from '@heroicons/react/outline';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { IoLogoUsd } from 'react-icons/io';
import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { Link, useParams } from 'react-router-dom';
import { HandleServices } from 'services/apis/Handle';
import { ConvoServices } from 'services/apis/Convo';
import { toast } from 'react-toastify';
import { UseAbility } from 'services/context';
import { GeneralNoAccess } from 'components/shared/GeneralNoAccess';
import { HiOutlineInformationCircle } from 'react-icons/hi';
import { LegalServices } from 'services/apis/Legal';
import { useInView } from 'react-intersection-observer';

export const Domains = (props: any) => {
  const { handle, updateHandle } = props;
  const { membership, id, handleId } = useParams();
  const canGetDomainSummary = UseAbility('domainSummary', 'get');
  const canGetHandleDomains = UseAbility('handleDomains', 'get');
  const canGetHandleDomainParticipation = UseAbility('handleDomainParticipation', 'get');
  const canDeleteDomainParticipation = UseAbility('domainParticipation', 'delete');
  const canAddDomainParticipation = UseAbility('domainParticipation', 'add');
  const canUpdateDomainParticipation = UseAbility('participation', 'update');
  const canCheckIsClassLastTermsUpdated = UseAbility('isClassLastTermsUpdated', 'check');
  const canAddNewClassTerms = UseAbility('newClassTerms', 'add');

  const canGetSimilar = UseAbility('similar', 'get');
  const [filter, setFilter] = useState<{ [key: string]: any }>({
    keywords: '',
  });
  const [selectedDomain, setSelectedDomain] = useState<any>({});
  const [termsAndConditions, setTermsAndConditions] = useState<{
    isUpToDate: boolean;
    data?: { _id: string; content: string };
  } | null>(null);
  const [isAddParticipationVisible, setIsAddParticipationVisible] = useState<boolean>(false);
  const [haveSimilarParticipation, setHaveSimilarParticipation] = useState<boolean>(false);
  const [isTerminatedDomainVisible, setIsTerminatedDomainVisible] = useState<boolean>(false);
  const [convoTypes, setConvoTypes] = useState<any[]>([]);
  const [domainsList, setDomainsList] = useState<any[]>([]);
  const [participationDetails, setParticipationDetails] = useState<any>({});
  const [newDomainParticipation, setNewDomainParticipation] = useState<any>({
    domainId: '',
    configuration: { rateCard: {} },
    note: '',
  });
  const [similarParticipation, setSimilarParticipation] = useState<any>({
    domainId: '',
    configuration: { rateCard: {} },
    note: '',
  });
  const [countUsersPages, setCountUsersPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [firstLoad, setFirstLoad] = useState<number>(0);
  const { ref: termsRef, inView: inReadTerms } = useInView({ threshold: 0 });

  const fetchHandlesDomains = async (params = calcPagination()) => {
    if (handleId) {
      try {
        setLoading(true);
        const list = await HandleServices.handleDomains(handleId, params);
        setDomainsList(list.items);
        setCountUsersPages(list.count);
        setCurrentPage(params.skip / params.limit + 1);
        setLoading(false);
        setFirstLoad(prev => prev + 1);
      } catch (error: any) {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (canGetHandleDomains) fetchHandlesDomains({ ...filter, ...calcPagination() });
  }, [filter]);

  const resetData = () => {
    setIsAddParticipationVisible(false);
    setTimeout(() => {
      setNewDomainParticipation({
        domainId: '',
        configuration: {},
        note: '',
      });
      setSimilarParticipation({
        domainId: '',
        configuration: {},
        note: '',
      });
      setHaveSimilarParticipation(false);
      setTermsAndConditions(null);
    }, 1000);
  };

  const handleLastitem = () => {
    const x = domainsList?.filter((ele: any, index: number) => {
      return ele._id === selectedDomain._id && index === domainsList?.length - 1 ? true : null;
    });
    if (x.length > 0) {
      return true;
    }
  };

  const getparticipationId = (): string => {
    const participationId = handle?.domainParticipations?.find(
      (ele: any) => ele.domainId === selectedDomain._id
    );
    return participationId?._id;
  };

  const hasParticipation = (): boolean => {
    const checkParticipation = handle?.domainParticipations?.some(
      (ele: any) => ele.domainId === selectedDomain._id
    );
    return checkParticipation;
  };

  const getParticipation = (itemId: string): string => {
    const checkParticipation = handle?.domainParticipations?.filter(
      (ele: any) => ele.domainId === itemId
    );
    return checkParticipation?.[0]?.status;
  };

  const onAcceptTermsAndConditions = async () => {
    if (!canAddNewClassTerms || !selectedDomain?._id || !termsAndConditions?.data?._id) return;
    try {
      const res = await LegalServices.onAcceptTermsAndConditions({
        classId: selectedDomain?.classInfo?._id,
        payload: { legalId: termsAndConditions?.data?._id, handleId: selectedDomain?._id },
      });
      if (res) setTermsAndConditions({ isUpToDate: true });
    } catch (err: any) {
      const isMissingTAndC = err?.cause?.errors?.details?.[0]?.code === 401;
      if (isMissingTAndC) {
        setIsAddParticipationVisible(false);
        setTermsAndConditions(null);
      }
      toast.error(err.message);
    }
  };

  const ParticipationHandler = async () => {
    if (!canCheckIsClassLastTermsUpdated || !handleId) return;
    try {
      const terms = await LegalServices.fetchTermsAndConditions({
        classId: selectedDomain.classInfo?._id,
        handleId,
      });
      setTermsAndConditions(terms);

      if (getParticipation(selectedDomain._id) === 'suspended') {
        setIsTerminatedDomainVisible(true);
        return;
      }
      if (handleId && selectedDomain.type === 'equity' && hasParticipation()) {
        const detail = await HandleServices.participationDetail(handleId, getparticipationId());
        setParticipationDetails(detail);
      }

      if (handleId && selectedDomain.type !== 'equity') {
        const types = await ConvoServices.convoTypes({ socialMedia: handle.socialMedia });
        setConvoTypes(types.items);
        if (hasParticipation()) {
          const detail = await HandleServices.participationDetail(handleId, getparticipationId());
          setNewDomainParticipation(detail);
          setParticipationDetails(detail);
        }
        const similar = await HandleServices.similarDomainParticipation(
          handleId,
          selectedDomain._id
        );
        if (similar !== null) {
          setHaveSimilarParticipation(true);
          setSimilarParticipation({
            domainId: selectedDomain._id,
            configuration: { rateCard: { ...similar } },
            note: '',
          });
        }
      }
      setIsAddParticipationVisible(true);
    } catch (error: any) {
      toast.error(error?.message);
    }
  };

  const getSimilar = () => {
    setNewDomainParticipation({
      domainId: similarParticipation.domainId,
      configuration: similarParticipation.configuration,
      note: '',
    });
    setHaveSimilarParticipation(false);
  };

  const onAddDomainParticipation = async () => {
    if (handleId) {
      try {
        await HandleServices.addDomainParticipation(handleId, {
          ...newDomainParticipation,
          domainId: selectedDomain._id,
          submit: true,
        });
        updateHandle();
        resetData();
      } catch (error: any) {
        toast.error(error?.message);
      }
    }
  };

  const onAddNotequityParticipation = async () => {
    if (handleId) {
      try {
        if (
          hasParticipation() &&
          (getParticipation(selectedDomain._id) === 'new' ||
            getParticipation(selectedDomain._id) === 'rejected')
        ) {
          await HandleServices.editDomainParticipation(handleId, getparticipationId(), {
            ...newDomainParticipation,
            domainId: selectedDomain._id,
          });
        } else {
          await HandleServices.addDomainParticipation(handleId, {
            ...newDomainParticipation,
            domainId: selectedDomain._id,
          });
        }
        const toastText = translate(
          'handlesScreen.yourParticipationIsSavedYouCanSubmitItNowOrLater'
        )
          .split('  ')
          .map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ));
        toast.success(<div>{toastText}</div>, {
          position: innerWidth < 630 ? toast.POSITION.BOTTOM_CENTER : toast.POSITION.TOP_RIGHT,
        });
        updateHandle();
        resetData();
      } catch (error: any) {
        toast.error(error?.message);
      }
    }
  };
  const onSubmitParticipation = async () => {
    if (handleId) {
      try {
        if (
          hasParticipation() &&
          (getParticipation(selectedDomain._id) === 'new' ||
            getParticipation(selectedDomain._id) === 'rejected')
        ) {
          await HandleServices.editDomainParticipation(handleId, getparticipationId(), {
            ...newDomainParticipation,
            domainId: selectedDomain._id,
            submit: true,
          });
        } else {
          await HandleServices.addDomainParticipation(handleId, {
            ...newDomainParticipation,
            domainId: selectedDomain._id,
            submit: true,
          });
        }
        toast.success(translate('handlesScreen.yourSubmissionIsNowPendingTheAdminApproval'), {
          position: innerWidth < 630 ? toast.POSITION.BOTTOM_CENTER : toast.POSITION.TOP_RIGHT,
        });
        updateHandle();
        resetData();
      } catch (error: any) {
        toast.error(error?.message);
      }
    }
  };

  const onDeleteParticipation = async () => {
    if (handleId && getparticipationId() && getParticipation(selectedDomain._id) === 'new') {
      try {
        await HandleServices.deleteDomainParticipation(handleId, getparticipationId());
        toast.warn(translate('handlesScreen.yourParticipationDraftHasBeenDeleted'), {
          position: innerWidth < 630 ? toast.POSITION.BOTTOM_CENTER : toast.POSITION.TOP_RIGHT,
        });
        updateHandle();
        resetData();
      } catch (error: any) {
        toast.error(error?.message);
      }
    }
  };

  const checkWithParticipation = () => {
    return (
      hasParticipation() &&
      getParticipation(selectedDomain._id) !== 'new' &&
      getParticipation(selectedDomain._id) !== 'rejected'
    );
  };

  const checkWithoutParticipation = () => {
    return (
      !hasParticipation() ||
      getParticipation(selectedDomain._id) === 'new' ||
      getParticipation(selectedDomain._id) === 'rejected'
    );
  };

  const _renderTerminatedDomainModal = () => {
    return (
      <Modal
        visible={isTerminatedDomainVisible}
        onClose={() => {
          setIsTerminatedDomainVisible(false);
        }}
        hideXIcon
      >
        <div className='flex flex-col justify-center space-y-4 my-2'>
          <h1 className='font-Condensed font-bold text-2xl text-center capitalize mb-4'>
            {translate('handlesScreen.yourParticipationForThisDomainTerminated')}
          </h1>

          <div className='self-center'>
            <button
              type='button'
              className='tw-button-blue-outline w-[85px] md:w-[150px]'
              onClick={() => {
                setIsTerminatedDomainVisible(false);
                setSelectedDomain({});
              }}
            >
              {translate('common.dismiss')}
            </button>
          </div>
        </div>
      </Modal>
    );
  };

  const participationModalHeader = (): JSX.Element | null => {
    if (checkWithParticipation()) {
      if (selectedDomain.type === 'equity') {
        return (
          <>
            <h1 className='font-Condensed font-bold text-2xl text-center capitalize'>
              Your participation for this equity domain
            </h1>
            <p className='text-xl font-Condensed text-center capitalize'>
              Status: <span className='text-primary-blue'>{participationDetails?.status}</span>
            </p>
          </>
        );
      } else {
        return (
          <>
            <h1 className='font-Condensed font-bold text-2xl text-center capitalize'>
              Your participation for these conversations:
            </h1>
            <p className='text-xl font-Condensed text-center capitalize'>
              Status: <span className='text-primary-blue'>{participationDetails?.status}</span>
            </p>
          </>
        );
      }
    }
    if (checkWithoutParticipation()) {
      //add Participation
      if (selectedDomain.type === 'equity') {
        return (
          <h1 className='font-Condensed font-bold text-2xl text-center capitalize'>
            Are you sure you want to participate in this domain?
          </h1>
        );
      } else {
        return (
          <h1 className='font-Condensed font-bold text-2xl text-center capitalize'>
            Please specify your participation for these conversations:
          </h1>
        );
      }
    }
    return null;
  };

  const participationModalBody = (): JSX.Element | null => {
    if (checkWithParticipation()) {
      //viewDetail
      if (selectedDomain.type === 'equity') {
        return (
          <>
            <div className='flex items-center xpy-6 '>
              <div className='w-32 shrink-0 self-start text-primary-orange'>Note:</div>
              <div className='font-inter text-sm font-normal text-primary-light-grey break-words w-[50%] md:w-[70%]'>
                {participationDetails?.note || '-'}
              </div>
            </div>
          </>
        );
      } else {
        return (
          <>
            <div>
              {convoTypes?.map(type => (
                <div key={type.key} className='flex items-center py-1 '>
                  <div className='w-32 shrink-0 text-primary-orange'>{type.name}:</div>
                  <div className='font-inter text-sm font-normal text-primary-light-grey truncate w-full'>
                    {Number(
                      participationDetails?.configuration?.rateCard?.[type.key]
                    ).toLocaleString() || '-'}{' '}
                    {selectedDomain?.unit}
                  </div>
                </div>
              ))}
            </div>
            <div className='flex items-center py-6 '>
              <div className='w-32 self-start min-w-[128px] shrink-0 text-primary-orange'>
                Note:
              </div>
              <div className='font-inter text-sm font-normal text-primary-light-grey truncate w-full'>
                {participationDetails?.note || '-'}
              </div>
            </div>
          </>
        );
      }
    }
    if (checkWithoutParticipation()) {
      //add Participation
      if (selectedDomain.type === 'equity') {
        return (
          <div className='w-full max-w-md h-40'>
            <textarea
              placeholder={translate('handlesScreen.tellUsWhyYouAreParticipating')}
              value={newDomainParticipation.note || ''}
              onChange={e => {
                setNewDomainParticipation({ ...newDomainParticipation, note: e.target.value });
              }}
              className='bg-primary-dark-grey placeholder:bg-primary-dark-grey block text-left py-3 px-4 border-2 border-white/60 rounded-md placeholder-primary-light-grey placeholder:text-left text-white disabled:opacity-[.35] appearance-none outline-none focus:outline-none font-inter focus:ring-transparent focus:border-white sm:text-base font-medium w-full min-h-full placeholder:font-inter placeholder:text-sm'
            />
          </div>
        );
      } else {
        return (
          <>
            <div>
              {convoTypes?.map(type => (
                <div key={type.key} className='flex items-center py-2 '>
                  <div className='w-40 text-primary-orange'>
                    {type.name === 'Instagram Post' ? 'Post' : type.name}:
                  </div>
                  <div className='font-inter text-sm font-normal w-full text-primary-light-grey'>
                    <div className='relative w-full'>
                      <input
                        type={'text'}
                        placeholder={'Price'}
                        maxLength={15}
                        value={
                          isNaN(newDomainParticipation?.configuration?.rateCard?.[type.key])
                            ? ''
                            : Number(
                                newDomainParticipation?.configuration?.rateCard?.[type.key]
                              ).toLocaleString()
                        }
                        onChange={e => {
                          const value = e.target.value.replace(/,/g, '');
                          if (/^\d*$/.test(value) || !value) {
                            setNewDomainParticipation({
                              ...newDomainParticipation,
                              configuration: {
                                ...newDomainParticipation?.configuration,
                                rateCard: {
                                  ...newDomainParticipation?.configuration?.rateCard,
                                  [type.key]: +value,
                                },
                              },
                            });
                          }
                        }}
                        className='tw-input-secondary h-9 sm:!text-base placeholder:text-left text-white'
                        style={{ textAlign: 'left' }}
                      />
                      <div className='absolute right-0 top-0 flex h-full items-center justify-center pr-3'>
                        <IoLogoUsd className='text-lg ' />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <div className='w-full max-w-md h-40 mt-2'>
                <textarea
                  placeholder={translate('handlesScreen.tellUsWhyYouAreParticipating')}
                  value={newDomainParticipation?.note || ''}
                  onChange={e => {
                    setNewDomainParticipation({ ...newDomainParticipation, note: e.target.value });
                  }}
                  className='bg-primary-dark-grey placeholder:bg-primary-dark-grey block text-left py-3 px-4 border-2 border-white/60 rounded-md placeholder-primary-light-grey placeholder:text-left text-white disabled:opacity-[.35] appearance-none outline-none focus:outline-none font-inter focus:ring-transparent focus:border-white sm:text-base font-medium w-full min-h-full placeholder:font-inter placeholder:text-sm'
                />
              </div>
            </div>
          </>
        );
      }
    }
    return null;
  };

  const participationModalFooter = (): JSX.Element | null => {
    if (checkWithParticipation()) {
      //viewDetail
      return null;
    }
    if (checkWithoutParticipation()) {
      //add Participation
      if (selectedDomain.type === 'equity') {
        return (
          <div className='flex items-center justify-center gap-2 md:gap-5'>
            <button
              type='button'
              className='tw-button-blue-outline !border-primary-orange w-[85px] md:w-[150px]'
              onClick={resetData}
            >
              {translate('common.no')}
            </button>
            <button
              type='button'
              disabled={!canAddDomainParticipation}
              className='tw-button-blue-outline w-[85px] md:w-[150px]'
              onClick={onAddDomainParticipation}
            >
              {translate('common.yes')}
            </button>
          </div>
        );
      } else {
        return (
          <div className='flex flex-col items-center justify-center gap-4 md:gap-5 mt-4'>
            {haveSimilarParticipation && (
              <div className='text-sm mb-4 text-center normal-case font-bold'>
                <span className=''>
                  {translate(
                    'handlesScreen.itLooksLikeYouAlreadyHaveSimilarParticipationToThisTypeOfDomain'
                  )}{' '}
                </span>
                <span
                  onClick={() => getSimilar()}
                  className='text-primary-orange underline cursor-pointer'
                >
                  {translate('handlesScreen.importPreviousParticipation')}
                </span>
              </div>
            )}
            {getParticipation(selectedDomain._id) === 'new' && canDeleteDomainParticipation && (
              <button
                type='button'
                disabled={!canDeleteDomainParticipation}
                className='inline-flex items-center justify-center px-0 py-0 text-base font-bold text-primary-orange capitalize underline'
                onClick={onDeleteParticipation}
              >
                {translate('handlesScreen.deleteParticipations')}
              </button>
            )}
            <button
              type='button'
              disabled={
                hasParticipation() &&
                (getParticipation(selectedDomain._id) === 'new' ||
                  getParticipation(selectedDomain._id) === 'rejected')
                  ? !canUpdateDomainParticipation
                  : !canAddDomainParticipation
              }
              className='tw-button-blue-outline !text-primary-blue !p-0 w-full md:w-[250px]'
              onClick={onAddNotequityParticipation}
            >
              {translate('handlesScreen.saveParticipationsAsDraft')}
            </button>
            <button
              type='button'
              disabled={
                hasParticipation() &&
                (getParticipation(selectedDomain._id) === 'new' ||
                  getParticipation(selectedDomain._id) === 'rejected')
                  ? !canUpdateDomainParticipation
                  : !canAddDomainParticipation
              }
              className='tw-button-light-blue !p-0 w-full md:w-[250px]'
              onClick={onSubmitParticipation}
            >
              {translate('handlesScreen.saveAndSubmitForApproval')}
            </button>
          </div>
        );
      }
    }
    return null;
  };

  const termsAndConditionsModal = (): JSX.Element | null => {
    return (
      <div className=' flex flex-col gap-4 px-2 pt-2'>
        <h1 className='font-Condensed text-[28px] font-bold text-center'>
          {translate('handlesScreen.iAgreeToJoin', {
            domainName: selectedDomain?.name ?? '',
          })}
        </h1>
        <div className='max-h-[400px] overflow-auto border border-[#6F7577] rounded-[4px] p-6'>
          <span
            className='p-6 h-full text-primary-light-grey text-sm'
            dangerouslySetInnerHTML={{ __html: termsAndConditions?.data?.content || '' }}
          />
          <span ref={termsRef} />
        </div>
        <span className='text-[10px] italic font-normal'>
          <HiOutlineInformationCircle className='inline text-[12px]' />{' '}
          {translate('handlesScreen.approvedTAndCDescription', {
            domainName: selectedDomain?.name ?? '',
          })}
        </span>
        <div className='flex items-center justify-center gap-2 md:gap-5'>
          <button
            type='button'
            className='tw-button-blue-outline !border-primary-orange w-[85px] md:w-[150px]'
            onClick={resetData}
          >
            {translate('common.no')}
          </button>
          <button
            type='button'
            className='tw-button-blue-outline w-[85px] md:w-[150px]'
            onClick={onAcceptTermsAndConditions}
            disabled={!inReadTerms || !canAddNewClassTerms}
          >
            {translate('common.yes')}
          </button>
        </div>
      </div>
    );
  };

  const _renderParticipationModal = () => {
    return (
      <Modal visible={isAddParticipationVisible} onClose={resetData}>
        {canGetHandleDomainParticipation && canGetSimilar && (
          <>
            {termsAndConditions?.isUpToDate && (
              <div className='flex flex-col gap-4 px-4'>
                {participationModalHeader()}
                <div className='font-Condensed text-2xl divide-y-2 divide-primary-light-grey font-bold capitalize'>
                  {participationModalBody()}
                </div>
                {participationModalFooter()}
              </div>
            )}
            {!termsAndConditions?.isUpToDate && <>{termsAndConditionsModal()}</>}
          </>
        )}

        {!canGetHandleDomainParticipation ||
          (!canGetSimilar && (
            <div className='font-Condensed text-primary-light-grey text-4xl md:text-[40px] m-6'>
              <div className='flex items-center justify-center text-center h-full'>
                <div className='max-w-xl px-4 sm:px-0'>
                  {translate('handlesScreen.itLooksLikeYouDontHaveAccessToThe')}{' '}
                  <span className='text-primary-orange'>{selectedDomain.name}</span>
                </div>
              </div>
            </div>
          ))}
      </Modal>
    );
  };

  const _renderContenTable = () => {
    const columns: Array<Column> = [
      {
        dataIndex: '',
        align: 'left' as AlignTypes,
        render: (_, item: any) => {
          return (
            <>
              <div className='text-2xl max-w-[200px] sm:max-w-full whitespace-normal'>
                <div className='capitalize'>
                  {item?.name} - <span className='whitespace-nowrap'>{item?.classInfo?.name}</span>{' '}
                  ({item?.type})
                </div>
              </div>
              <div className='text-xl'>
                <div
                  className={`${getStatusClass(
                    getParticipation(item?._id)
                  )} text-xl flex xjustify-end items-start align-top capitalize`}
                >
                  {getParticipation(item?._id) || 'Not Participating'}
                </div>
              </div>
            </>
          );
        },
      },
      {
        dataIndex: '',
        align: 'right' as AlignTypes,
        veticalAlign: 'middle' as VeticalAlignTypes,
        render: (_p1: any, item: any) => {
          return (
            <>
              {(handle.status === 'accepted' || canGetDomainSummary) && (
                <Menu as='div' className={`relative inline-block text-right`}>
                  <div>
                    <Menu.Button className='flex items-center justify-end rounded-full text-white hover:text-gray-600 xfocus:outline-none xfocus:ring-2 xfocus:ring-indigo-500 xfocus:ring-offset-2 xfocus:ring-offset-gray-100 bg-'>
                      <span className='sr-only'>Open options</span>
                      <BsThreeDotsVertical
                        className='text-3xl cursor-pointer'
                        // aria-hidden='true'
                        onClick={() => {
                          setSelectedDomain(item);
                        }}
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter='transition ease-out duration-100'
                    enterFrom='transform opacity-0 scale-95'
                    enterTo='transform opacity-100 scale-100'
                    leave='transition ease-in duration-75'
                    leaveFrom='transform opacity-100 scale-100'
                    leaveTo='transform opacity-0 scale-95'
                  >
                    <Menu.Items
                      className={`font-inter text-base text-right absolute right-4 xtop-6 z-10 xmt-2 border-2 border-primary-blue origin-top-right rounded-md bg-primary-dark-grey shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden focus:outline-none ${
                        handleLastitem() ? 'top-[-53px]' : 'top-6'
                      }`}
                    >
                      <div className=''>
                        {handle.status === 'accepted' && (
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                className={classNames(
                                  active
                                    ? 'bg-primary-medium-grey text-white'
                                    : 'text-primary-light-grey',
                                  'block px-2 py-2 disabled:cursor-not-allowed w-full'
                                )}
                                disabled={
                                  handle.status !== 'accepted' || !canCheckIsClassLastTermsUpdated
                                }
                                onClick={ParticipationHandler}
                              >
                                {translate('handlesScreen.participation')}
                              </button>
                            )}
                          </Menu.Item>
                        )}
                        {canGetDomainSummary && (
                          <Menu.Item>
                            {({ active }) => (
                              <Link
                                to={`/home/mission-control/${membership}/${id}/${handleId}/${item._id}`}
                                className={classNames(
                                  active
                                    ? 'bg-primary-medium-grey text-white'
                                    : 'text-primary-light-grey',
                                  'block px-2 py-2'
                                )}
                              >
                                {translate('handlesScreen.domainDetails')}
                              </Link>
                            )}
                          </Menu.Item>
                        )}
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              )}
            </>
          );
        },
      },
    ];

    const { limit, skip } = calcPagination(currentPage);

    return (
      <>
        <div className='' style={{ boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15266)' }}>
          <div className='pt-6'>
            <div className='relative shadow-sm flex gap-5'>
              <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
                <SearchIcon className='h-5 w-5 text-primary-light-grey' aria-hidden='true' />
              </div>
              <input
                type='search'
                name='search'
                className='tw-input-blue-dark !pl-10 py-4 h-8 w-full sm:w-52'
                placeholder={translate('common.search')}
                value={filter?.keywords || ''}
                onChange={e => {
                  setFilter({ ...filter, keywords: e.target.value });
                }}
              />
            </div>
          </div>
          <Table columns={columns} dataSource={domainsList} hover loading={loading} />
          <Pagination
            count={countUsersPages}
            limit={limit}
            skip={skip}
            onChange={pageParams => {
              fetchHandlesDomains(pageParams);
            }}
            className='mt-5 pr-4 pb-3'
          />
        </div>
      </>
    );
  };

  return (
    <div className='bg-primary-dark-grey border-2 border-t-0 border-primary-blue px-4 relative'>
      <>
        {domainsList.length === 0 && firstLoad === 1 ? (
          <div className='flex justify-center items-center font-Condensed font-bold text-4xl h-32 md:h-full px-6 py-3 text-center'>
            {translate('handlesScreen.itLooksYouDontHaveAnyDomainsToParticipateIn')}
          </div>
        ) : (
          <>
            {canGetHandleDomains && _renderContenTable()}
            {!canGetHandleDomains && <GeneralNoAccess border={false} />}
          </>
        )}
      </>

      {_renderParticipationModal()}
      {_renderTerminatedDomainModal()}
    </div>
  );
};
