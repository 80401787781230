import { useEffect, useState } from 'react';
import { AlignTypes, Column, Modal, Spiner, Table } from 'components/shared';
import { OnboardingServices } from 'services/apis/Onboarding';
import {
  addIndefiniteArticle,
  clearInviteCode,
  getInviteCode,
  getMembership,
  getPercentClass,
  translate,
} from 'utils/helperFunctions';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { UseAbility, useStore } from 'services/context';
import { toggleSplash } from 'services/context/Auth/actions';
import { getPathTitle } from './helpers';
import {
  clearMembershipStatus,
  getMembershipStatuses,
  setMembershipsStatus,
} from 'utils/StorageServices';
import useRefetchAbilities from 'utils/hooks/useRefetchAbilities';
import { Splash } from 'Pages';
import { GeneralNoAccess } from 'components/shared/GeneralNoAccess';

export const Home = () => {
  const navigate = useNavigate();
  const route = useLocation();
  const [, dispatch] = useStore();
  const canGetInvitationCode = UseAbility('invitation', 'get');
  const canCreateMembership = UseAbility('membership', 'create');
  const canGetMemberships = UseAbility('membershipsPercent', 'get');
  const [loading, setLoading] = useState(true);
  const [inviteRecord, setInviteRecord] = useState<{ [key: string]: any }>({});
  const [mempershipPercents, setMempershipPercents] = useState<any[]>([]);
  const [haveMembershipVisible, setHaveMembershipVisible] = useState(false);
  const [thereIsProblemWithInvitation, setThereIsProblemWithInvitation] = useState(false);
  const [addMembershipVisible, setAddMembershipVisible] = useState(false);
  const [inviteCode, setInviteCode] = useState<string>('');
  const code = getInviteCode();
  const refetchAbilities = useRefetchAbilities();

  const checkStatuses = (percent: { items: any[] }) => {
    const oldStatuses = getMembershipStatuses();
    const statuses = percent.items.map((item: any) => ({ id: item._id, status: item.status }));
    if (oldStatuses) {
      const statusesChanged = statuses.some(
        (item: any) => oldStatuses[item.id] !== item.status && item.status === 'accepted'
      );
      if (statusesChanged) {
        refetchAbilities();
      }
    }
    clearMembershipStatus();
    setMembershipsStatus(statuses);
  };

  const fetchMembershipPercent = async () => {
    try {
      const percent = await OnboardingServices.fetchMembershipPercent();
      checkStatuses(percent);
      setMempershipPercents(percent.items);
      setLoading(false);
    } catch (error: any) {
      toast.error(error?.message);
    } finally {
      setLoading(false);
    }
  };

  const onHaveCode = (state: any) => {
    if (state) {
      setLoading(true);
      setInviteRecord(state?.record);
      setHaveMembershipVisible(true);
      clearInviteCode();
    }
  };

  useEffect(() => {
    fetchMembershipPercent();
    if (route?.state !== null) {
      onHaveCode(route?.state);
    }
    dispatch(toggleSplash(false));
  }, [route?.state]);

  const onEnterCode = async () => {
    if (inviteCode) {
      setLoading(true);
      try {
        const invitationStatus = await OnboardingServices.onEnterInvitationCode(inviteCode);
        if (invitationStatus === true) {
          const record = await OnboardingServices.fetchInviteRecord(inviteCode);
          setInviteRecord(record);
          if (record.status === 'accepted') {
            setAddMembershipVisible(false);
            setHaveMembershipVisible(true);
          } else {
            navigate(`/home/onboarding-wizard/${record._id}`, { replace: true });
          }
        } else {
          localStorage.removeItem('inviteCode');

          setHaveMembershipVisible(false);
          setAddMembershipVisible(false);
          setThereIsProblemWithInvitation(true);
        }
      } catch (error: any) {
        toast.error(error?.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const _renderProblemWithInvitation = () => {
    return (
      <Modal
        visible={thereIsProblemWithInvitation}
        onClose={() => {
          setThereIsProblemWithInvitation(false);
          setInviteCode('');
        }}
        className='!border-primary-blue'
      >
        <div className='flex flex-col justify-center space-y-4 mx-2'>
          <h1 className='font-Condensed text-primary-orange font-black text-[32px] text-center sm:text-left'>
            {translate('join.itLooksLikeThereIsProblemWithYourInvitationCode')}
          </h1>
          <div className='w-full'>
            <button
              type='button'
              className='tw-button-light-blue !w-full'
              onClick={() => {
                setInviteCode('');
                setThereIsProblemWithInvitation(false);
              }}
            >
              {translate('common.dismiss')}
            </button>
          </div>
        </div>
      </Modal>
    );
  };

  const _renderHaveMembership = () => {
    return (
      <Modal
        visible={haveMembershipVisible}
        onClose={() => {
          setHaveMembershipVisible(false);
          setThereIsProblemWithInvitation(false);
          setAddMembershipVisible(false);
        }}
        className='!border-primary-blue'
      >
        <div className='flex flex-col justify-center space-y-4 mx-2'>
          <h1 className='font-Condensed text-primary-orange font-black text-[32px] mb-2 text-center sm:text-left'>
            {translate('join.itLooksLikeYoureAlready')}{' '}
            {addIndefiniteArticle(getMembership(inviteRecord?.membership))}
          </h1>
          <div className='w-full'>
            <button
              type='button'
              disabled={loading}
              className='tw-button-light-blue !w-full'
              onClick={() => {
                navigate(`/home/mission-control/${inviteRecord?.membership}/${inviteRecord?._id}`);
              }}
            >
              {loading && <Spiner className='ml-28' />}
              {translate('join.goToMissionControl')}
            </button>
          </div>
        </div>
      </Modal>
    );
  };

  const _renderAddMembership = () => {
    const inputTypes = [
      {
        name: 'enterInvitationCode',
        label: translate('join.invitationCode'),
        placeholder: translate('join.enterInvitationCode'),
      },
    ];
    return (
      <Modal
        visible={addMembershipVisible}
        onClose={() => {
          setAddMembershipVisible(false);
          setInviteCode('');
        }}
        className='!border-primary-blue'
      >
        <div className='flex flex-col justify-center space-y-4 mx-2'>
          <h1 className='font-Condensed font-black text-4xl text-center capitalize mb-4'>
            {translate('join.addMembership')}
          </h1>
          <div className='flex items-center justify-center'>
            {inputTypes.map(({ name, label, placeholder }, index) => (
              <div className='input-wrapper relative' key={index}>
                <label htmlFor={name} className='tw-label'>
                  *{label}:
                </label>
                <input
                  key={index}
                  type={'text'}
                  name={name}
                  id={name}
                  maxLength={6}
                  placeholder={placeholder}
                  value={inviteCode || ''}
                  onChange={e => {
                    if (/^[0-9]+$/g.test(e.target.value) || !e.target.value) {
                      setInviteCode(e.target.value);
                    }
                  }}
                  onKeyUp={event => event.key === 'Enter' && onEnterCode()}
                  className='tw-input-primary !w-full !text-lg'
                />
              </div>
            ))}
          </div>
          <div className='self-center !w-full'>
            <button
              type='button'
              disabled={loading || inviteCode === ''}
              className='tw-button-light-blue !w-full'
              onClick={onEnterCode}
            >
              {loading && <Spiner className='ml-28' />}
              {translate('common.confirm')}
            </button>
          </div>
        </div>
      </Modal>
    );
  };

  const _renderMembershipPercents = () => {
    const columns: Array<Column> = [
      {
        dataIndex: 'membership',
        render: (membership: string) => {
          return <div className='text-3xl sm:text-[32px]'>{getMembership(membership)}</div>;
        },
      },
      {
        dataIndex: 'percentage',
        align: 'right' as AlignTypes,
        render: (percentage: number) => {
          return (
            <div className={`${getPercentClass(percentage)} text-3xl sm:text-[40px]`}>
              {percentage}%
            </div>
          );
        },
      },
    ];

    return (
      <>
        <div className='mt-2 flex flex-col gap-4'>
          <p className='text-primary-light-grey text-4xl font-bold capitalize font-Condensed'>
            {getPathTitle(route.pathname)}
          </p>
          {loading ? (
            <div className='top-0 left-0 z-50 absolute w-full bg-primary-medium-grey'>
              <Splash />
            </div>
          ) : (
            <div
              className='bg-primary-dark-grey border rounded border-primary-blue  px-4'
              style={{ boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15266)' }}
            >
              {canGetInvitationCode && canCreateMembership && (
                <div className='flex justify-end items-center pt-5'>
                  <div>
                    <button
                      type={'button'}
                      disabled={!canGetInvitationCode || !canCreateMembership}
                      className={'tw-button-light-blue !px-3'}
                      onClick={() => setAddMembershipVisible(true)}
                    >
                      {translate('join.addMembership')}
                    </button>
                  </div>
                </div>
              )}
              {canGetMemberships && (
                <div className='relative '>
                  <Table
                    columns={columns}
                    dataSource={mempershipPercents}
                    hover
                    onClickRow={(_index, item) =>
                      item.status === 'accepted'
                        ? navigate(`/home/mission-control/${item.membership}/${item._id}`, {
                            replace: true,
                          })
                        : navigate(`/home/onboarding-wizard/${item._id}`, { replace: true })
                    }
                  />
                </div>
              )}
              {!canGetMemberships && <GeneralNoAccess border={false} />}
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <>
      {!!code ? (
        <div className='top-0 left-0 z-50 absolute w-full bg-primary-medium-grey'>
          <Splash />
        </div>
      ) : (
        <div>
          <div>{_renderMembershipPercents()}</div>
          <div>{addMembershipVisible && _renderAddMembership()}</div>
          <div>{haveMembershipVisible && _renderHaveMembership()}</div>
          <div>{thereIsProblemWithInvitation && _renderProblemWithInvitation()}</div>
        </div>
      )}
    </>
  );
};
