import './App.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'utils/axiosInterceptor';
import './i18n';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { StoreProvider } from './services/context';
import { AddToHomeScreen } from 'react-pwa-add-to-homescreen';
import AuthLoading from 'AuthLoading';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <StoreProvider>
        <AuthLoading />
        <ToastContainer />
        <Outlet />
        <AddToHomeScreen delayNotify={300} />
      </StoreProvider>
    </QueryClientProvider>
  );
}

export default App;
