import { useEffect } from 'react';
import Logo from 'assets/images/Logo.svg';
import { XIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { useStore } from 'services/context';
import { TopbarMenu } from './TopbarMenu';
import { Splash } from 'Pages';
import { toggleSplash } from 'services/context/Auth/actions';
import { getInviteCode } from 'utils/helperFunctions';
import useRefetchAbilities from 'utils/hooks/useRefetchAbilities';
import { Image } from 'components/shared/Binary/Binary';
import { LegalState } from './LegalState';

export const DashboardLayout = () => {
  const { pathname } = useLocation();
  const [splash, dispatch] = useStore();
  const route = useLocation();
  const { state } = route;
  const {
    authStore: { isSplashVisible, isAclLoading },
  } = splash;
  const code = getInviteCode();
  const { i18n } = useTranslation();
  const refetchAbilities = useRefetchAbilities();

  useEffect(() => {
    refetchAbilities();
  }, []);

  useEffect(() => {
    if (code) {
      dispatch(toggleSplash(true));
    } else {
      dispatch(toggleSplash(false));
    }
  }, []);

  return (
    <div className='h-screen flex flex-col'>
      <span className='sr-only'>{i18n.language}</span>
      {isSplashVisible || isAclLoading ? (
        <div className='top-0 left-0 z-50 absolute w-full bg-primary-medium-grey'>
          <Splash />
        </div>
      ) : (
        <>
          <div className='sticky bg-primary-medium-grey top-0 z-40 p-4'>
            <div className='flex items-start'>
              <div
                className={`flex items-center justify-between w-full transition-all duration-500`}
              >
                <div className='flex items-center gap-5 flex-shrink-0'>
                  <Link
                    to='/home'
                    className='hover:shadow-sm rounded-sm py-2 h-[60px]  transition-all duration-300'
                  >
                    <img
                      className='w-auto h-10 hover:filter hover:brightness-125 hover:contrast-125 transition-all duration-300 hover:scale-[1.01] cursor-pointer'
                      src={Logo}
                      alt='INFLUENCE'
                    />
                  </Link>
                </div>
                <div className=''>
                  <TopbarMenu />
                </div>
              </div>
            </div>

            {pathname.includes('onboarding-wizard') && !!state && state?.hasOwnProperty('logo') && (
              <div className='flex items-center gap-4'>
                <XIcon className='h-10 w-10 text-primary-light-grey' aria-hidden='true' />
                <Image
                  src={state?.logo}
                  alt='SponsorLogo'
                  containerClassName='max-w-[188px] max-h-[52px]'
                />
              </div>
            )}
          </div>
        </>
      )}

      <div
        className={`main pl-0 flex flex-col flex-1 transition-all duration-500 ${
          pathname.includes('onboarding-wizard') ? 'justify-center' : ''
        }`}
      >
        <main className='bg-primary-dark'>
          <div className=''>
            <div
              className={`${
                pathname.includes('onboarding-wizard') || pathname.includes('new-handle')
                  ? 'px-0 py-0'
                  : 'px-4 py-6'
              } mx-auto text-white`}
            >
              {/* Replace with your content */}
              <LegalState />
              <Outlet />
              {/* /End replace */}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
