import { useLocation } from 'react-router-dom';
import { AiFillCaretLeft } from 'react-icons/ai';
import { translate } from 'utils/helperFunctions';
import { Image } from 'components/shared/Binary/Binary';

export const SuspensionDetails = () => {
  const { state } = useLocation();

  const _renderSuspensionDetails = () => {
    return (
      <div
        className='bg-primary-dark-grey border-2 xborder-t-0 border-primary-blue p-4 capitalize w-full'
        style={{ boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15266)' }}
      >
        <div className='divide-y-2 divide-primary-light-grey text-2xl font-bold capitalize w-full'>
          <div className='flex items-start pb-6 w-full'>
            <div className='w-32 shrink-0 text-primary-orange'>
              {translate('handlesScreen.reason')}:
            </div>
            <div>{state?.note || '-'}</div>
          </div>
          <div className='flex flex-col items-start pt-6 pb-2 gap-y-6 w-full'>
            <div className='w-full bg-primary-medium-grey p-4'>
              <h1 className='text-[32px] text-primary-light-grey font-bold text-center pb-4'>
                {translate('handlesScreen.supportingImages')}
              </h1>
              {state?.images?.length > 0 && (
                <div className='flex gap-4 overflow-x-auto w-full'>
                  <div className='flex gap-4'>
                    {state?.images?.map((image: any) => {
                      return (
                        <div key={image} className='flex items-start h-[250px] !w-[150px]'>
                          <Image
                            src={image}
                            containerClassName='inline-block object-cover h-[250px] w-[1500px]'
                            alt='img'
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className='relative font-Condensed'>
      <>
        <div className='font-Condensed capitalize flex justify-start items-center text-primary-light-grey text-3xl mb-5'>
          <div className=''>
            <AiFillCaretLeft
              className='text-5xl cursor-pointer hover:filter hover:brightness-125 hover:contrast-125 transition-all duration-300 hover:scale-[1.1]'
              onClick={() => {
                history.back();
              }}
            />
          </div>
          <div className='normal-case'>{translate('handlesScreen.suspensionDetails')}</div>
        </div>
        <div className='flex flex-col gap-4 w-full '>{!!state && _renderSuspensionDetails()}</div>
      </>
    </div>
  );
};
