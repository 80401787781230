import { Modal } from 'components/shared';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { translate } from 'utils/helperFunctions';

type Props = {
  isHandleModalVisible: boolean;
  setIsHandleModalVisible: (key: boolean) => void;
  actionName: string | undefined;
};

export const ModalAddEditHandle = ({
  isHandleModalVisible,
  setIsHandleModalVisible,
  actionName,
}: Props) => {
  const navigate = useNavigate();
  const { membership, id } = useParams();

  return (
    <Modal visible={isHandleModalVisible} onClose={() => setIsHandleModalVisible(false)}>
      <div className='flex flex-col gap-4'>
        <h1 className='font-Condensed font-bold text-3xl text-center capitalize'>
          {translate('handlesScreen.isYourIdentityKnownForThisHandle')}
        </h1>
        <p className='text-sm text-center text-primary-orange'>
          {translate('handlesScreen.doYourFollowersKnowWhoYouReallyAre')}
        </p>

        <div className='flex items-center justify-center gap-5'>
          <div>
            <button
              type='button'
              className='tw-button-blue-outline !border-primary-orange w-[85px] md:w-[150px]'
              onClick={() => {
                navigate(`/home/mission-control/${membership}/${id}/action/${actionName}`, {
                  state: { state: false },
                });
              }}
            >
              {translate('common.no')}
            </button>
          </div>
          <div>
            <button
              type='button'
              className='tw-button-blue-outline w-[85px] md:w-[150px]'
              onClick={() => {
                navigate(`/home/mission-control/${membership}/${id}/action/${actionName}`, {
                  state: { state: true },
                });
              }}
            >
              {translate('common.yes')}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
