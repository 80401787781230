/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useMemo } from 'react';
import { IHandleDetails } from 'services/apis/Handle/handle.types';
import { translate } from 'utils/helperFunctions';
import HandleChangeDiff from './HandleChangeDiff';
import useMediaQuery from 'utils/hooks/useMediaQuery';

type Insight = NonNullable<IHandleDetails['metadata']['stats']>[0]['values'][0] | null;
type CombinedInsightValue = {
  new?: Insight;
  old?: Insight;
};

const combineInsightValues = ({
  handle,
  category,
}: {
  handle?: IHandleDetails | null;
  category: string;
}): CombinedInsightValue[] => {
  const insightValues = handle?.metadata?.stats?.find(ele => ele.category === category)?.values;
  const newInsightValues = handle?.handle_metadata?.holder?.metadata?.stats?.find(
    ele => ele.category === category
  )?.values;
  const filteredInsightValues = insightValues?.filter(
    ele => ele.value !== newInsightValues?.find(ele2 => ele2.key === ele.key)?.value
  );

  const filteredNewInsightValues = newInsightValues?.filter(
    ele => ele.value !== insightValues?.find(ele2 => ele2.key === ele.key)?.value
  );

  const allCombined: CombinedInsightValue[] = [];

  filteredInsightValues?.forEach(oldInsightValue => {
    const newInsightValue = filteredNewInsightValues?.find(ele => ele.key === oldInsightValue.key);
    allCombined.push({
      old: oldInsightValue,
      new: newInsightValue || null,
    });
  });

  filteredNewInsightValues?.forEach(newInsightValue => {
    const oldInsightValue = filteredInsightValues?.find(ele => ele.key === newInsightValue.key);
    if (!oldInsightValue) {
      allCombined.push({
        old: oldInsightValue || null,
        new: newInsightValue,
      });
    }
  });

  return allCombined.sort(a => (a?.new && a?.old ? -1 : 1));
};

const HandleDiffInsights = ({ handle }: { handle: IHandleDetails | null }) => {
  const { mobile: isMobileLayout } = useMediaQuery({ mobile: true });

  const renderStat = useCallback(
    (
      insightValue?: Insight,
      options: {
        renderNewTitle?: boolean;
        renderTitles?: boolean;
        old?: boolean;
        renderSecondaryTitle?: boolean;
      } = {
        renderNewTitle: false,
        renderTitles: true,
        old: true,
        renderSecondaryTitle: true,
      }
    ) => {
      return (
        <HandleChangeDiff
          sections={[
            {
              value: String(insightValue?.value),
              title: options?.renderSecondaryTitle ? insightValue?.key : '',
            },
          ]}
          options={{ ...options, includeTitleWithBackground: false }}
        />
      );
    },
    []
  );

  const newStats = handle?.handle_metadata?.holder?.metadata?.stats;
  const oldStats = handle?.metadata?.stats;

  const changedStats = newStats?.filter(s => {
    const oldStat = oldStats?.find(ele => ele.category === s.category);
    const newStatKeys = s.values.map(ele => ele.key);
    return !oldStat?.values?.every(
      ele =>
        newStatKeys.includes(ele.key) &&
        ele.value === s.values.find(st => st.key === ele.key)?.value
    );
  });
  const noChange = !changedStats?.length && newStats?.length === oldStats?.length;

  if (!newStats?.length || noChange) {
    return <></>;
  }

  return (
    <div className='flex flex-col gap-4'>
      <div
        className='bg-primary-dark-grey border-2 xborder-t-0 border-primary-blue p-4 capitalize'
        style={{ boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15266)' }}
      >
        <div className='flex flex-col gap-8'>
          {changedStats?.map(({ category }, index) => {
            const allCombined = combineInsightValues({ handle, category });
            return (
              <div key={index} className='flex flex-col gap-4'>
                <div className='font-bold text-3xl'>
                  {translate(`handlesScreen.insights.${category}`)}
                </div>
                <div className={`flex flex-col gap-8`}>
                  {allCombined.map((combined, itemIndex) => {
                    return (
                      <div
                        key={itemIndex}
                        className={`flex ${isMobileLayout ? 'flex-col-reverse' : ''} gap-4`}
                      >
                        {combined.new &&
                          renderStat(combined.new, {
                            old: false,
                            renderTitles: true,
                            renderNewTitle: index === 0 && itemIndex === 0,
                            renderSecondaryTitle: isMobileLayout ? !combined.old : true,
                          })}
                        {combined.old &&
                          renderStat(combined.old, {
                            old: true,
                            renderTitles: isMobileLayout || !combined.new,
                            renderNewTitle: index === 0 && itemIndex === 0,
                            renderSecondaryTitle: true,
                          })}
                      </div>
                    );
                  })}
                </div>
                {index !== changedStats.length - 1 && (
                  <hr className='border rounded border-white w-full' />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default HandleDiffInsights;
