import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { AiFillCaretLeft } from 'react-icons/ai';
import { toast } from 'react-toastify';
import { HandleServices } from 'services/apis/Handle';
import { translate } from 'utils/helperFunctions';
import { NoAccess, Tabs } from 'components/shared';
import { Domains } from './Domains';
import { Conversations } from './Conversations';
import { UseAbility } from 'services/context';
import { IHandleDetails } from 'services/apis/Handle/handle.types';
import HandleDetailsList from './HandleDetailsList';
import { Stats } from './stats';

export const HandleDetails = () => {
  const navigate = useNavigate();
  const { membership, id, handleId } = useParams();
  const canGetHandleDetails = UseAbility('handleDetails', 'get');
  const canUpdateHandle = UseAbility('handle', 'update');
  const canRequestSuspend = UseAbility('suspendRequest', 'create');

  const [handle, setHandle] = useState<IHandleDetails | null>(null);
  const [searchParams, setSearchParams] = useSearchParams({
    tab: 'domains',
  });
  const [activeKeyTab, setActiveKeyTab] = useState<string>('1');
  const tabParam = searchParams.get('tab');
  const [loading, setLoading] = useState<boolean>(true);
  const [_expanded, _setExpanded] = useState(false);

  const fetchHandledetails = async () => {
    if (handleId) {
      setLoading(true);
      try {
        const details = await HandleServices.handleDetails(handleId);
        setHandle(details);
      } catch (error: any) {
        toast.error(error?.message);
      }
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchHandledetails();
  }, []);

  useEffect(() => {
    if (!tabParam || tabParam === 'domains') {
      setActiveKeyTab('1');
    } else {
      setActiveKeyTab('2');
    }
  }, [tabParam]);

  const _renderTabs = () => {
    switch (activeKeyTab) {
      case '1':
        return <Domains handle={handle && handle} updateHandle={fetchHandledetails} />;
      case '2':
        return <Conversations />;
      default:
        return null;
    }
  };

  const _renderHandleDetails = () => {
    if (!handle) {
      return null;
    }
    return <HandleDetailsList handle={handle} />;
  };

  const _renderDomainConversation = () => {
    return (
      <div
        className='bg-primary-medium-grey xborder-2 xborder-primary-blue xpx-4 font-inter capitalize'
        style={{ boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15266)' }}
      >
        <Tabs
          activeKey={activeKeyTab}
          onChange={key => {
            setActiveKeyTab(key);
            setSearchParams({ tab: key === '1' ? 'domains' : 'conversations' });
          }}
          tabPanes={[
            { name: translate('common.domains'), key: '1' },
            { name: translate('common.conversations'), key: '2' },
          ]}
          className=''
        />
        {_renderTabs()}
      </div>
    );
  };

  return (
    <div className='relative font-Condensed'>
      {!loading && (
        <>
          <div className='font-Condensed capitalize flex justify-start items-center text-primary-light-grey text-3xl mb-5'>
            <div className=''>
              <AiFillCaretLeft
                className='text-5xl cursor-pointer hover:filter hover:brightness-125 hover:contrast-125 transition-all duration-300 hover:scale-[1.1]'
                onClick={() => {
                  navigate(`/home/mission-control/${membership}/${id}?tab=handles`);
                }}
              />
            </div>
            <div className='normal-case'>
              {handle?.handle} {translate('common.details')}
            </div>
          </div>
          {canGetHandleDetails && handle?.handle && (
            <div className='flex flex-col gap-4'>
              <div
                className='bg-primary-dark-grey border-2 xborder-t-0 border-primary-blue p-4 capitalize'
                style={{ boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15266)' }}
              >
                <div className='w-full flex justify-end'>
                  <div className='flex gap-4'>
                    {handle?.handle_metadata?._id && handle.status !== 'suspended' && (
                      <button
                        className='tw-input-blue-dark !rounded-[4px] !bg-transparent !pr-[8px] !pl-[8px] !h-[48px] leading-[18px] !pt-1 sm:!pt-2 !w-[85px] sm:!w-[160px] !text-center !text-[16px] font-inter'
                        onClick={() => {
                          navigate(
                            `/home/mission-control/${membership}/${id}/${handleId}/view-changes`
                          );
                        }}
                      >
                        {translate('handlesScreen.viewChanges')}
                      </button>
                    )}
                    {handle && handle.status === 'accepted' && (
                      <div className='flex justify-end gap-4'>
                        {canRequestSuspend && !handle.hasOwnProperty('suspendRequest') && (
                          <button
                            className='tw-input-orange-dark !bg-transparent !w-[105px] !text-[16px] !leading-[17px] !text-center font-inter'
                            onClick={() => {
                              navigate(
                                `/home/mission-control/${membership}/${id}/${handleId}/suspend`
                              );
                            }}
                          >
                            {translate(`handlesScreen.suspendHandle`)}
                          </button>
                        )}
                        {!!handle.hasOwnProperty('suspendRequest') && (
                          <button
                            className='tw-input-orange-dark !bg-transparent !w-[105px] !text-[16px] !leading-[17px] !text-center font-inter'
                            onClick={() => {
                              navigate(
                                `/home/mission-control/${membership}/${id}/${handleId}/suspension`,
                                { state: handle?.suspendRequest ?? null }
                              );
                            }}
                          >
                            {translate(`handlesScreen.viewSuspension`)}
                          </button>
                        )}
                        {canUpdateHandle && (
                          <button
                            className='tw-button-light-blue !text-center font-inter !w-[85px]'
                            onClick={() => {
                              navigate(
                                `/home/mission-control/${membership}/${id}/action/${handleId}`,
                                {
                                  state: { state: handle?.metadata?.lightsYourFire ? false : true },
                                }
                              );
                            }}
                          >
                            {translate('common.edit')}
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                {_renderHandleDetails()}
              </div>
              <Stats
                stats={
                  handle?.metadata?.stats || handle?.handle_metadata?.holder?.metadata?.stats || []
                }
                lightsYourFire={handle?.metadata?.lightsYourFire || []}
              />
              {_renderDomainConversation()}
            </div>
          )}

          {!handle?.handle && (
            <div className='text-3xl text-primary-light-grey font-bold text-center'>
              {translate('handlesScreen.noHandleFound')}
            </div>
          )}
          {!canGetHandleDetails && <NoAccess membership={membership as string} />}
        </>
      )}
    </div>
  );
};
