const APIs: any = {
  abilities: {
    oauth: {
      post: {
        '/token/phone': {
          I: 'phone',
          A: 'verification',
          M: 'Create a verification code for that phone',
        },
        '/token/phone/code': {
          I: 'accessToken',
          A: 'create',
          M: 'Create an access token for that phone',
        },
      },
      delete: {
        '/accessToken/:token': {
          I: 'accessToken',
          A: 'delete',
          M: 'Delete access token',
        },
        '/refreshToken/:token': {
          I: 'refreshToken',
          A: 'delete',
          M: 'Delete refresh token',
        },
      },
    },
    urac: {
      get: {
        '/user/me': {
          I: 'me',
          A: 'get',
          M: 'Get logged in user account information',
        },
        '/validate/changeEmail': {
          I: 'changeEmail',
          A: 'get',
          M: 'validate change email',
        },
        '/validate/changePhone': {
          I: 'changePhone',
          A: 'get',
          M: 'validate change phone',
        },
      },
      put: {
        '/account': {
          I: 'account',
          A: 'edit',
          M: 'Edit account information by id',
        },
        '/account/email/code': {
          I: 'email',
          A: 'edit',
          M: 'Change account email by id - a code will be emailed',
        },
        '/account/phone': {
          I: 'phone',
          A: 'edit',
          M: 'Change account phone by id',
        },
      },
    },
    onboarding: {
      get: {
        '/resend/code': {
          I: 'resend',
          A: 'get',
          M: 'Resend phone or email code',
        },
        '/user/memberships': {
          I: 'membershipsPercent',
          A: 'get',
          M: 'Get a logged in user memberships',
        },
        '/new/invitation/:code': {
          I: 'invitation',
          A: 'get',
          M: 'Check the status of an invitation code for the logged in user',
        },
        '/membership/:id': {
          I: 'membership',
          A: 'get',
          M: 'Get a membership by providing a membership id for the logged in user',
        },
        '/membership/:id/contract/state': {
          I: 'contractState',
          A: 'get',
          M: 'Not Added',
        },
        '/:id/summary': {
          I: 'membershipSummary',
          A: 'get',
          M: 'Get a membership summary by providing a membership id',
        },
      },
      post: {
        '/join/consumer/invited': {
          I: 'invite',
          A: 'join',
          M: 'Join as a consumer by code, email and phone',
        },
        '/contract/status': {
          I: 'contractStatus',
          A: 'edit',
          M: 'Update the status of a membership contract',
        },
      },
      put: {
        '/validate/codes': {
          I: 'join',
          A: 'validate',
          M: 'Validate join by providing codes',
        },
        '/membership/:inviteCode': {
          I: 'membership',
          A: 'create',
          M: 'Create a membership by providing an invite code for the logged in user',
        },
        '/membership/:id/metadata': {
          I: 'membership',
          A: 'edit',
          M: 'Edit a membership metadata by providing the membership id for the logged in user',
        },
        '/membership/:id/contract': {
          I: 'membershipContract',
          A: 'create',
          M: 'Create an invitation',
        },
        '/membership/:id/contract/resend': {
          I: 'membershipContract',
          A: 'update',
          M: 'Update a membership contract by providing a membership id for the logged in user',
        },
      },
    },
    organization: {
      get: {
        '/connected/companies/membership/type/:type': {
          I: 'connectedCompanies',
          A: 'get',
          M: 'Not Added',
        },
        '/user/search/connect': {
          I: 'companies',
          A: 'get',
          M: 'Get the companies the user has connection to',
        },
        '/company/:companyId/summary': {
          I: 'companySummary',
          A: 'get',
          M: 'Get a company summary details by providing companyId',
        },
      },
    },
    convo: {
      get: {
        '/social/media/convo/types': {
          I: 'convoTypes',
          A: 'get',
          M: 'get all types of convos',
        },
        '/search/handle/:handleId': {
          I: '`conversations`',
          A: 'get',
          M: 'get all types of convos',
        },
      },
    },
    domain: {
      get: {
        '/:domainId/user/summary': {
          I: 'domainSummary',
          A: 'get',
          M: 'Get the domain summary for the user by providing a domainId',
        },
      },
    },
    notification: {
      post: {
        '/help': {
          I: 'help',
          A: 'ask',
          M: 'Ask for help',
        },
      },
    },
    handle: {
      get: {
        '/user/search': {
          I: 'handles',
          A: 'get',
          M: 'Get list of handles for the loggedIn user',
        },
        '/user/:handleId': {
          I: 'handleDetails',
          A: 'get',
          M: 'Get a handle details for the logged in user',
        },
        '/user/:handleId/domains': {
          I: 'handleDomains',
          A: 'get',
          M: 'Get a handle domains for the logged in user',
        },
        '/schema/:socialMedia': {
          I: 'statsSchema',
          A: 'get',
          M: 'Get the a stats schema by providing its type (socialMedia)',
        },
        '/user/:handleId/domain/participation/:participationId': {
          I: 'handleDomainParticipation',
          A: 'get',
          M: 'Get a handle domainParticipation by providing a handleId and a participationId',
        },
        '/user/:handleId/domain/participation/:domainId/similar': {
          I: 'similar',
          A: 'get',
          M: 'Get the rateCard of a domain participation on a similar domain',
        },
        '/search/connected/membership/type/:type': {
          I: 'notInfluencer',
          A: 'get',
          M: 'Not Added',
        },
        '/user/participations/switched/class': {
          I: 'classSwitchParticipations',
          A: 'check',
          M: 'Get domain participations where the handle is requested to be switched from one class to another',
        },
        '/user/handle/:handleId/participation/:participationId/switched/class': {
          I: 'classSwitchRequestDetails',
          A: 'get',
          M: 'Get domain participations where the handle is requested to be switched from one class to another',
        },
      },
      put: {
        '/:handleId/metadata': {
          I: 'handle',
          A: 'update',
          M: 'Update handles metadata for the logged in user',
        },
        '/user/:handleId/suspend/request': {
          I: 'suspendRequest',
          A: 'create',
          M: 'Create a request to suspend a handle providing its id and a reason',
        },
        '/user/:handleId/stats': {
          I: 'handleStats',
          A: 'update',
          M: 'Update a handle stats',
        },
        '/user/:handleId/domain/participation/:participationId': {
          I: 'participation',
          A: 'update',
          M: 'Update a participation in a handle',
        },
        '/user/:handleId/domain/participation/:participationId/status': {
          I: 'participationStatus',
          A: 'update',
          M: 'Update participation status in a handle',
        },
        '/user/handle/:handleId/participation/:participationId/switch/class/action/:action': {
          I: 'classSwitchRequest',
          A: 'accept',
          M: 'Accept or reject the class switch request',
        },
      },
      post: {
        '/user': {
          I: 'handle',
          A: 'create',
          M: 'Create a handle',
        },
        '/user/:handleId/domain/participation': {
          I: 'domainParticipation',
          A: 'add',
          M: 'Add a domainParticipation to a handle by providing the handleId',
        },
      },
      delete: {
        '/user/:handleId/domain/participation/:participationId': {
          I: 'domainParticipation',
          A: 'delete',
          M: '"Delete a domainParticipation in a handle only if this domainparticipation status is new by providing the handleId and participationId',
        },
      },
    },
    referral: {
      get: {
        '/user/search': {
          I: 'allReferrals',
          A: 'get',
          M: 'Get all referrals under this user',
        },
        '/user/:referralId': {
          I: 'referralDetails',
          A: 'get',
          M: 'Get a referral details by the referralId provided',
        },
      },
      put: {
        '/user/:referralId': {
          I: 'referral',
          A: 'edit',
          M: 'Edit a user referral by providing a referralId',
        },
        '/user/:referralId/status': {
          I: 'referralStatus',
          A: 'update',
          M: 'Change a user referral status to pending by providing a referralId',
        },
      },
      post: {
        '/user/company': {
          I: 'companyReferral',
          A: 'add',
          M: 'Add a company referral',
        },
        '/user/influencer': {
          I: 'influencerReferral',
          A: 'add',
          M: 'Add an influencer referral',
        },
        '/user/agency': {
          I: 'agencyReferral',
          A: 'add',
          M: 'Add an agency referral',
        },
        '/user/ambassador': {
          I: 'ambassadorReferral',
          A: 'add',
          M: 'Add an ambassador referral',
        },
      },
    },
    legal: {
      get: {
        '/user/state': {
          I: 'userLegalState',
          A: 'check',
          M: 'Check the legal state for a logged in user',
        },
        '/user/outdated/user/terms': {
          I: 'userOutdatedTerms',
          A: 'check',
          M: 'Checks the user outdated terms and conditions for the general influence platform',
        },
        '/user/outdated/privacy/policies': {
          I: 'OutdatedPrivacyPolicies',
          A: 'check',
          M: 'Checks the logged in users outdated privacy policy for the general influence platform',
        },
        '/terms/:legalId/summary': {
          I: 'userTermsOrPrivacyPolicySummary',
          A: 'get',
          M: 'Get terms summary by legalId',
        },
        '/user/domain/class/:classId/terms/state': {
          I: 'isClassLastTermsUpdated',
          A: 'check',
          M: 'Check if the last terms for class is up to date for the logged in user',
        },
      },
      post: {
        '/user/terms/user': {
          I: 'userTermsAndCondition',
          A: 'accept',
          M: 'User accepts the general influence platform terms and conditions',
        },
        '/user/privacy/policy': {
          I: 'userPrivacyPolicy',
          A: 'accept',
          M: 'User accepts the general influence platform privacy policy',
        },
        '/user/domain/class/:classId/terms': {
          I: 'newClassTerms',
          A: 'add',
          M: 'add new terms for class for the logged in user',
        },
      },
    },
    aws: {
      get: {
        '/s3/private/object': {
          I: 'privateS3',
          A: 'get',
          M: 'Get the object behind a private S3',
        },
        '/s3/signed/url': {
          I: 'signedUrl',
          A: 'get',
          M: 'Get a signed url for S3 putObject',
        },
      },
      put: {
        '/s3/signed/url': {
          I: 'signedUrl',
          A: 'add',
          M: 'Get a signed url for S3 getObject',
        },
      },
      delete: {
        '/s3': {
          I: 's3Object',
          A: 'add',
          M: 'delete an object from S3 bucket',
        },
      },
    },
  },
  versions: {
    urac: '3',
    oauth: '1',
    organization: '2',
    onboarding: '2',
    convo: '1',
    domain: '1',
    notification: '1',
    handle: '1',
    referral: '1',
    legal: '1',
    aws: '1',
  },
};

export default APIs;
