import axios from 'axios';
import { CompanySummary } from './Organization.types';

export const OrganizationServices = {
  fetchCompanySummary: async (id: string): Promise<CompanySummary> => {
    const endPoint = `organization/company/${id}/summary`;
    try {
      const response = await axios.get<object, CompanySummary>(endPoint);
      return response;
    } catch (error) {
      throw error;
    }
  },
};
