import React, { useState } from 'react';
import '../componentStyle/Style.css';
export type OptionType = {
  title: string;
  detail?: string;
};

type Props = {
  options: Array<OptionType>;
  selectedPackages: any;
  onChange: (value: any) => void;
  className?: string;
  borderColor?: string;
  onSelectColor?: string;
};

export const CustomCheckbox: React.FC<Props> = React.memo(
  ({ options, className, onChange, selectedPackages, borderColor, onSelectColor }) => {
    const [other, setOther] = useState<string>(
      selectedPackages
        .filter((pkj: any) => pkj.title === 'other' && pkj.detail)
        .map((pkj: any) => pkj.detail)
        .join('')
    );

    const handleOnchange = (e: any) => {
      if (e?.key === 'other') {
        setOther(e.v);
        if (!selectedPackages.some((obj: object) => Object.values(obj).includes(e.key))) {
          onChange?.([...selectedPackages, { title: e.key, detail: e.v }]);
        } else {
          if (!e.v) {
            const removeOther = selectedPackages.filter((opt: any) => opt.title !== e.key);
            onChange?.(removeOther);
          } else {
            onChange?.([
              ...selectedPackages.map((pkg: any) => {
                if (pkg.title === e.key) {
                  return { ...pkg, detail: e.v };
                }
                return pkg;
              }),
            ]);
          }
        }
      } else {
        const { value } = e.target;
        if (!selectedPackages.some((obj: object) => Object.values(obj).includes(value))) {
          const checked = options.filter(option => option.title === value);
          onChange?.([...selectedPackages, checked[0]]);
        } else {
          const removeUnchecked = selectedPackages.filter((option: any) => option.title !== value);
          onChange?.(removeUnchecked);
        }
      }
    };

    return (
      <div className={`${className}`}>
        {options?.map(option => {
          return (
            <div
              key={option.title}
              className={`flex justify-center items-center gap-7  xmax-w-[340px] rounded-xl border-2 ${
                selectedPackages.some((obj: object) => Object.values(obj).includes(option.detail))
                  ? `${borderColor} ${onSelectColor}`
                  : 'border-primary-light-grey bg-primary-medium-grey'
              } ${
                option.title === 'other'
                  ? `col-span-2 ${other ? onSelectColor : ''} ${
                      options.length % 3 === 0 ? 'md:col-span-1' : 'md:col-span-3'
                    }`
                  : 'max-w-[340px]'
              } ${option.title === 'other' && other ? ` border-2 ${borderColor}` : ''}`}
            >
              <input
                type='checkbox'
                id={option.title}
                value={option.title}
                className='hidden'
                disabled={option.title === 'other'}
                onChange={handleOnchange}
              />
              <label
                htmlFor={option.title}
                className='h-[140px] sm:h-[170px] cursor-pointer  w-full self-start rounded-xl overflow-hidden pt-1 pb-4 px-1'
              >
                <div className='font-Condensed text-[32px] sm:text-[40px] sm:mb-2 font-bold capitalize'>
                  {option.title}
                </div>
                {option.title === 'other' ? (
                  <div className='w-full px-3'>
                    <textarea
                      id='other'
                      className='text-base bg-primary-medium-grey w-full sm:h-[80px] border-2 border-primary-light-grey rounded-xl placeholder:text-primary-light-grey focus:border-primary-light-grey focus:ring-primary-light-grey '
                      placeholder={option.detail}
                      value={other}
                      onChange={e => handleOnchange({ key: 'other', v: e.target.value })}
                    />
                  </div>
                ) : (
                  <p className='text-base sm:text-xl font-medium max-w-sm multiline-ellipsis'>
                    {option.detail}
                  </p>
                )}
              </label>
            </div>
          );
        })}
      </div>
    );
  }
);
