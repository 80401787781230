import React, { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { IoMdClose } from 'react-icons/io';

type ModalProps = {
  visible: boolean;
  onClose: () => void;
  children: React.ReactNode;
  width?: number | string;
  hideXIcon?: boolean;
  className?: string;
};
export const Modal: React.FC<ModalProps> = props => {
  const RefDiv = useRef(null);

  return (
    <Transition.Root show={props.visible} as={Fragment}>
      <Dialog
        as='div'
        initialFocus={RefDiv}
        className='fixed z-[1000] inset-0 overflow-y-auto'
        onClose={() => {}}
      >
        <div className='flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-primary-dark-grey bg-opacity-[.95] transition-opacity' />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className='hidden sm:inline-block sm:align-middle sm:h-screen' aria-hidden='true'>
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            <div
              ref={RefDiv}
              style={{ boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15266)', width: props.width }}
              className={`bg-primary-dark-grey inline-block border-primary-orange border-2 rounded-[4px] align-bottom px-2 pt-2 pb-4 text-left transform transition-all sm:my-8 sm:align-middle ${
                !!props.width ? '' : 'sm:max-w-xl w-[300px] md:w-[500px]'
              } xsm:w-full xsm:p-6 ${!!props.className ? props.className : ''}`}
            >
              <div className={`${!!props.hideXIcon ? 'hidden' : ''} text-right`}>
                <button type='button' onClick={() => props.onClose()}>
                  {/* <XIcon className='w-6 h-6 stroke-3'/> */}
                  <IoMdClose className='text-3xl stroke-[25px] ' />
                </button>
              </div>

              {props.children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
