import { CustomCheckbox, OptionType } from 'components/shared';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { whatTurnsYouOffOptions } from 'utils/helperFunctions';
type Props = {
  onChange: (keys: any) => void;
  addHandle?: any;
};

export const AddWhatTurnsYouOff = (props: Props) => {
  const { onChange, addHandle } = props;
  const { t } = useTranslation();
  const onChangeInfo = (data = {}) => {
    onChange(data);
  };

  const options: OptionType[] = whatTurnsYouOffOptions.map(({ detail, title }) => ({
    title: t(title),
    detail: t(detail) || '',
  }));

  return (
    <div className='flex flex-col justify-center items-center gap-8 mt-4 mb-6 mx-1 xl:mx-40 text-center'>
      <div className='font-Condensed text-white text-[36px] font-bold'>
        {t('handlesScreen.forThisAccount')}
      </div>
      <div className='text-[20px] -ml-2 sm:ml-3 xl:ml-0 sm:mb-0 w-[100%] text-primary-light-grey font-normal self-start text-left'>
        {t('handlesScreen.selectTheTopicsThatYouWontCover')}
      </div>
      <CustomCheckbox
        className={'grid grid-cols-2 md:grid-cols-3 gap-4'}
        borderColor={'border-primary-blue'}
        onSelectColor='bg-[#074257]'
        options={options}
        selectedPackages={addHandle.metadata.turnsYouOff || []}
        onChange={e => {
          onChangeInfo({ ...addHandle, metadata: { ...addHandle.metadata, turnsYouOff: e } });
        }}
      />
    </div>
  );
};
