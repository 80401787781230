import { CustomCheckbox, OptionType } from 'components/shared';
import { useTranslation } from 'react-i18next';
import { whatLightsYourFireOptions } from 'utils/helperFunctions';

type Props = {
  onChange: (keys: any) => void;
  addHandle?: any;
};

export const AddWhatLightsYourFire = (props: Props) => {
  const { onChange, addHandle } = props;
  const { t } = useTranslation();
  const onChangeInfo = (data = {}) => {
    onChange(data);
  };

  const options: OptionType[] = whatLightsYourFireOptions.map(({ detail, title }) => ({
    title: t(title),
    detail: t(detail) || '',
  }));

  return (
    <div className='flex flex-col justify-center items-center gap-8 mt-4 mb-6 mx-1 xl:mx-40 text-center'>
      <div className='font-Condensed text-white text-[36px] font-bold'>
        {t('handlesScreen.forThisAccountTellUsMore')}
      </div>
      <div className='text-[20px] text-primary-light-grey font-normal self-start text-left -ml-2 sm:ml-3 xl:ml-0'>
        {t('handlesScreen.whichTopicsWouldYouPromote')}?
      </div>
      <CustomCheckbox
        className={'grid grid-cols-2 md:grid-cols-3 gap-4'}
        borderColor={'border-primary-orange'}
        onSelectColor='bg-[#3D1E19]'
        options={options}
        selectedPackages={addHandle.metadata.lightsYourFire || []}
        onChange={e => {
          onChangeInfo({ ...addHandle, metadata: { ...addHandle.metadata, lightsYourFire: e } });
        }}
      />
    </div>
  );
};
