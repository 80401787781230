import { useCallback, useState } from 'react';

import { replaceAndUploadBinary } from './utils';
import { BinaryState, FileList, UseUploadBinaryProps } from '../type';

const useUploadBinary = ({ binariesDataArray, ...rest }: UseUploadBinaryProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const defaultFilesList: FileList[] = (binariesDataArray || [])?.map(
    ({ id, binariesData, ...restBinaryDataArray }) => ({
      id,
      files: binariesData?.map(binary => ({
        type: 'old',
        src: typeof binary === 'object' ? binary?.uri : binary || '',
        preview: binary || '',
      })) as BinaryState[],
      ...restBinaryDataArray,
    })
  );

  const [filesList, setFilesList] = useState<FileList[]>(defaultFilesList);

  const setFiles = (
    id: string,
    files: BinaryState[] | ((prev: BinaryState[]) => BinaryState[])
  ) => {
    setFilesList(prev => {
      const index = prev.findIndex(list => list.id === id);
      if (index === -1) {
        return prev;
      }
      const newFiles = typeof files === 'function' ? files(prev[index].files) : files;
      return [
        ...prev.slice(0, index),
        { ...prev[index], files: newFiles },
        ...prev.slice(index + 1),
      ];
    });
  };

  const clearImage = useCallback(
    (id: string, index: number) => {
      const file = filesList.find(list => list.id === id)?.files[index];
      if (file) {
        if (file.preview) {
          URL.revokeObjectURL(file.preview);
        }
        setFiles(
          id,
          filesList.find(list => list.id === id)?.files.filter((_, i) => i !== index) || []
        );
      }
    },
    [filesList]
  );

  const onUpload = async () => {
    try {
      setIsLoading(true);
      const paths = await replaceAndUploadBinary({
        filesList,
        binariesDataArray,
        bucketPath: rest.bucketPath,
      });
      setIsLoading(false);

      return paths;
    } catch (error) {
      setIsError(true);
      setIsLoading(false);
      throw error;
    }
  };

  const checkIfIdIsValid = (id: string) => {
    const list = filesList.find(files => files.id === id);
    if (!list?.required) {
      return true;
    }

    return !!list?.files?.length;
  };

  const options = {
    clearImage,
    onUpload,
    filesList,
    setFilesList,
    checkIfIdIsValid,
    isLoading,
    isError,
  };

  return [
    filesList.map(({ id, ...restFilesOptions }) => ({
      id,
      ...restFilesOptions,
      setFiles: (files: BinaryState[] | ((prev: BinaryState[]) => BinaryState[])) =>
        setFiles(id, files),
      ...rest,
      ...options,
      clearImage: (index: number) => clearImage(id, index),
    })),
    options,
  ] as const;
};

export default useUploadBinary;
