import { Spiner } from 'components/shared';
import { Splash } from 'Pages';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { OnboardingServices } from 'services/apis/Onboarding';
import { translate } from 'utils/helperFunctions';
import usePrompt from 'utils/hooks/usePrompt';
import { flushSync } from 'react-dom';
import { setMembershipsStatus } from 'utils/StorageServices';

export const WizardWaitingForSignature = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const route = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [remainingTime, setRemainingTime] = useState(0);

  const { _renderModalDiscardChanges, setIsBlocking } = usePrompt({
    message: translate('discardModal.areYouSureYouWantToExitTheOnboardingProcess'),
    okText: translate('common.yes'),
    cancelText: translate('common.no'),
  });

  useEffect(() => {
    let timer: any;
    if (remainingTime > 0) {
      timer = setInterval(() => {
        setRemainingTime(prevTime => prevTime - 1);
      }, 1000);
    } else {
      setButtonDisabled(false);
      clearInterval(timer);
    }
    return () => {
      clearInterval(timer);
    };
  }, [remainingTime]);

  useEffect(() => {
    setIsBlocking(true);
  }, []);

  const onRefresh = async () => {
    if (id) {
      setIsBlocking(false);
      try {
        setRefresh(true);
        setTimeout(async () => {
          await OnboardingServices.fetchMembershipStatus(id)
            .then(res => {
              setRefresh(false);
              setMembershipsStatus([{ id, status: res.status }]);

              if (res.status === 'waitingForSignature') {
                setIsBlocking(true);
              }
              switch (res.status) {
                case 'signed':
                case 'notSigned':
                case 'rejected':
                case 'waitingForSignature':
                  return navigate(`/home/onboarding-wizard/${id}/${res.status}`, {
                    replace: true,
                    state: { logo: res?.sponsorInfo?.logo, membership: res.membership },
                  });
              }
              return;
            })
            .catch(error => {
              setRefresh(false);

              toast.error(error?.message);
            });
        }, 2000);
      } catch (error: any) {
        setRefresh(false);
        toast.error(error?.message);
      }
    }
  };

  const onResign = async () => {
    if (id) {
      setIsBlocking(false);
      try {
        setLoading(true);
        const membership = await OnboardingServices.fetchMembershipStatus(id);
        if (membership?.status !== 'waitingForSignature') {
          toast.error('please click on refresh');
          setButtonDisabled(true);
          setRemainingTime(3600);
          return;
        }
        const response = await OnboardingServices.onResign(id).then(res => {
          if (res.wait) {
            setButtonDisabled(true);
            setRemainingTime(3600);
            toast.error(res?.message);
            setIsBlocking(true);
          } else {
            window.open(res.url, '_self');
          }
          return res;
        });
        if (response.url && !response.wait) {
          navigate(`/home/onboarding-wizard/${id}`);
        }
        setLoading(false);
      } catch (error: any) {
        toast.error(error?.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const _renderWizardWaitingForSignature = () => {
    return (
      <div className='flex flex-col items-center justify-center gap-8 xl:w-[70%] xl:mx-auto border border-primary-orange w-full m-4 bg-primary-dark-grey'>
        <div className='font-Condensed text-[48px] mt-5 font-bold'>
          {translate('waitingForSignatureScreen.almostThere')}
        </div>
        <div
          className='text-[20px] lg:text-[24px] font-normal  text-left self-start mx-6'
          style={{ minHeight: '420px' }}
        >
          {translate('waitingForSignatureScreen.wereWaitingForConfirmationFromDocuSign')}
          <br className='sm:hidden' /> {translate('waitingForSignatureScreen.takingALittleTooLong')}
          <br />
          {translate('waitingForSignatureScreen.getInTouchWithYourContactOr')}{' '}
          <span
            className='text-primary-blue font-bold cursor-pointer hover:text-primary-blue/80'
            onClick={() => {
              flushSync(() => {
                setIsBlocking(false);
              });
              navigate(`/home/onboarding-wizard/${id}/wizardSendNote`, { state: route.state });
            }}
          >
            {translate('common.sendUsANote')}
          </span>
        </div>
        <div className='flex justify-center items-center gap-4 w-full mb-4 '>
          <button
            className='tw-button-secondary disabled:!bg-primary-light-grey disabled:!border-primary-secondary-light-grey !px-9 !text-base !w-[150px] sm:!w-[175px] !h-[48px] ml-2'
            onClick={onResign}
            disabled={isButtonDisabled}
          >
            {loading && <Spiner className='ml-[106px]' />}
            {translate('common.resign')}
          </button>
          <button
            className='tw-button-light-blue !text-base !w-[150px]  sm:!w-[175px] !h-[48px] mr-2'
            onClick={onRefresh}
          >
            {translate('common.refresh')}
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className='xoverflow-y-auto xoverflow-x-hidden'>
      {_renderModalDiscardChanges()}
      {refresh ? (
        <div className='top-0 left-0 z-50 absolute w-full bg-primary-medium-grey'>
          <Splash />
        </div>
      ) : (
        <div>
          <div
            className={`flex justify-start items-center`}
            style={{ minHeight: 'calc(100vh - 200px)' }}
          >
            {_renderWizardWaitingForSignature()}
          </div>
        </div>
      )}
    </div>
  );
};
