import { Select } from 'components/shared';
import React from 'react';
import { getSocilaIcon, SocialMediaList, translate } from 'utils/helperFunctions';
import { FiCamera } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import { HandleInfo } from './HandleInfo';
import { BinaryUpload } from 'components/shared/Binary/Binary';
import { BinaryUploadProps } from 'components/shared/Binary/type';
type Props = {
  onChange: (keys: any) => void;
  addHandle?: any;
  binaryData: BinaryUploadProps;
};

export const AddHandleInfo = (props: Props) => {
  const { onChange, addHandle, binaryData } = props;
  const { handleId } = useParams();

  const onChangeInfo = (data = {}) => {
    onChange(data);
  };

  return (
    <div className='flex flex-col justify-center items-center gap-6 mt-4 mb-6 text-center w-full '>
      <div className='flex flex-col gap-6 w-full'>
        <h1 className='font-Condensed text-[36px] font-bold not-italic'>
          {translate('handlesScreen.howBigIsYourEmpire')}?
        </h1>
        {handleId === 'new' ? (
          <>
            <p className='text-xl font-normal text-primary-light-grey text-left'>
              {translate('handlesScreen.enterYourSocialMediaHandleAndNumberOfFollowers')}
            </p>
            <div className='input-wrapper relative text-left'>
              <label htmlFor='relation' className='tw-label !text-[24px] !font-Condensed'>
                *{translate('common.handle')}:
              </label>
              <Select
                options={SocialMediaList}
                withIcon={true}
                focusOnOpen={true}
                placeholderStyle='!text-[16px]'
                className='bg-primary-medium-grey  relative w-full !border-2 shadow-sm pl-3 text-left cursor-pointer focus:outline-none sm:text-sm h-12 rounded border-primary-light-grey'
                menuBg='bg-primary-medium-grey mt-[10px] border-2 !text-base rounded  border-primary-orange'
                getIcon={getSocilaIcon}
                placeholder={translate('handlesScreen.selectSocialMedia')}
                value={addHandle.socialMedia}
                onChange={e => {
                  onChangeInfo({ ...addHandle, socialMedia: e?.value });
                }}
              />
              <input
                className='tw-input-primary relative !mt-3'
                id='website'
                type='text'
                autoComplete={'off'}
                value={addHandle.handle}
                placeholder={translate('handlesScreen.enterHandle')}
                onChange={e => {
                  onChangeInfo({ ...addHandle, handle: e.target.value });
                }}
              />
            </div>

            <div className='input-wrapper relative text-left'>
              <label htmlFor='relation' className='tw-label !text-[24px] !font-Condensed'>
                *{translate('handlesScreen.handleLink')}:
              </label>
              <input
                placeholder={translate('handlesScreen.enterHandleLink')}
                className='tw-input-primary relative'
                value={addHandle?.url}
                // maxLength={15}
                // TODO validate link
                type='text'
                onChange={e => {
                  // const filtered = filterAndFormatNumeric(e.target.value);
                  onChangeInfo({ ...addHandle, url: e.target.value });
                }}
              />
            </div>
          </>
        ) : (
          <>
            <HandleInfo details={addHandle} />
          </>
        )}
      </div>
      <hr className='border rounded xborder-primary-light-grey w-full' />
      <div className='flex flex-col gap-4 w-full'>
        <h1 className='font-Condensed text-[40px] font-bold'>
          {translate('handlesScreen.aboutYourPeople')}
        </h1>
        <p className='text-xl font-normal text-primary-light-grey text-left'>
          {translate('handlesScreen.provideCapturesShowingAccountStats')}
        </p>
        <div className='flex flex-col items-center justify-center'>
          <label htmlFor='pic' className='cursor-pointer'>
            <div className='flex gap-2 justify-center items-center text-base font-bold uppercase text-center hover:opacity-80'>
              <FiCamera className='text-2xl' />
              <span>
                {translate('handlesScreen.addCaptures')} (
                {addHandle?.metadata?.aboutYourPeople?.length ?? 0})
              </span>
            </div>
          </label>
        </div>
        <div className='w-full flex justify-center bg-primary-medium-grey items-center border-dashed border-2 rounded-md px-4 sm:px-6 py-6'>
          <BinaryUpload
            binaryData={binaryData?.binaryData}
            id={binaryData?.id}
            onChange={v =>
              onChangeInfo({
                ...addHandle,
                metadata: { ...addHandle.metadata, aboutYourPeople: v },
              })
            }
          />
        </div>
      </div>
    </div>
  );
};
