import { Spiner } from 'components/shared';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { translate } from 'utils/helperFunctions';
import { FiCamera } from 'react-icons/fi';
import { NotificationServices } from 'services/apis/Notification';
import { BucketPaths } from 'config';
import useUploadBinary from 'components/shared/Binary/hooks/useUploadBinary';
import BinaryUpload from 'components/shared/Binary/BinaryUpload';

export const WizardSendNote = () => {
  const navigate = useNavigate();
  const [noteDetails, setNoteDetails] = useState<any>({
    title: '',
    message: '',
    images: [],
  });
  const [loading, setLoading] = useState<boolean>(false);

  const [binaryData, { onUpload }] = useUploadBinary({
    binariesDataArray: [
      {
        binariesData: noteDetails?.images ? noteDetails?.images : [],
        id: 'images',
        accept: ['image/*'],
        maxNumberOfFiles: 10,
        maxSize: 100000,
        required: true,
        quality: 0.8,
      },
    ],
    bucketPath: BucketPaths.Handle,
    label: '',
  });

  const onSubmit = async () => {
    try {
      setLoading(true);

      const paths = await onUpload();
      const newUploadedImages = paths?.find((item: any) => item.id === 'images')?.paths;

      await NotificationServices.help({ ...noteDetails, images: newUploadedImages });
      setLoading(false);
      navigate('/home');
    } catch (error: any) {
      setLoading(false);
      toast.error(error?.message);
    }
  };

  return (
    <div className='border border-primary-orange bg-primary-dark-grey lg:w-[70vw] lg:mx-auto m-4 p-4'>
      <div className='flex flex-col justify-center items-center gap-8  text-center'>
        <h1 className='font-Condensed text-[48px]'>{translate('helpScreen.howCanWeHelp')}</h1>
        <div className='input-wrapper !items-start'>
          <label htmlFor='whatAbout' className='tw-label'>
            {translate('helpScreen.whatIsThisAbout')}
          </label>
          <input
            id='whatAbout'
            type='text'
            placeholder={translate('helpScreen.whatIsThisAbout')}
            value={noteDetails.about}
            onChange={e => setNoteDetails({ ...noteDetails, title: e.target.value })}
            className='tw-input-primary'
          />
        </div>
        <div className='w-full h-40'>
          <textarea
            placeholder={translate('helpScreen.describeWhatsHappeningInMoreDetail')}
            value={noteDetails.message}
            onChange={e => setNoteDetails({ ...noteDetails, message: e.target.value })}
            className='bg-primary-dark-grey placeholder:bg-primary-dark-grey block text-left py-3 px-4 border-2  border-primary-light-grey rounded-md placeholder-primary-light-grey placeholder:text-left text-white disabled:opacity-[.35] appearance-none outline-none focus:outline-none focus:ring-transparent focus:border-primary-orange sm:text-lg font-medium w-full min-h-full'
          />
        </div>
        <div className='flex flex-col justify-center items-center gap-2 w-full'>
          <label htmlFor='pic' className='cursor-pointer self-start'>
            <div className='flex gap-2 justify-center items-center text-base font-bold uppercase text-center hover:opacity-80'>
              <FiCamera className='text-2xl' />
              <span>
                {translate('helpScreen.addImages')} ({noteDetails?.images?.length})
              </span>
            </div>
          </label>
          <BinaryUpload binaryData={binaryData} id='images' />
        </div>
        <div className='flex justify-center items-center w-full'>
          <button
            className='tw-button-light-blue !w-[366px] !h-[48px] !text-base !uppercase'
            onClick={onSubmit}
          >
            {loading && <Spiner className='ml-28' />}
            {translate('common.submit')}
          </button>
        </div>
      </div>
    </div>
  );
};
