import React from 'react';
import { getFileTypeFromUrl } from './hooks/utils';
import { File, Image, Video } from './Binary';

const BufferedFilePreview = ({ src }: { src: string }) => {
  if (!src) return <span>-</span>;
  const type = getFileTypeFromUrl(src);
  if (type === 'image') {
    return (
      <Image containerClassName='max-w-[100px] max-h-[300px] object-contain' src={src ?? ''} />
    );
  }
  if (type === 'video') {
    return (
      <Video
        containerClassName='max-w-[400px] max-h-[200px] object-contain'
        src={src ?? ''}
        controls
      />
    );
  }
  return <File src={src} containerClassName='tw-link mr-2' />;
};

export default BufferedFilePreview;
