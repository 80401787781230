import React, { useCallback, useMemo } from 'react';
import { IHandleDetails } from 'services/apis/Handle/handle.types';
import { getKeys, translate } from 'utils/helperFunctions';
import HandleChangeDiff from './HandleChangeDiff';
import useMediaQuery from 'utils/hooks/useMediaQuery';

type Partnership = NonNullable<IHandleDetails['metadata']['partnerships']>[0] | null;
type CombinedPartnership = {
  new?: Partnership;
  old?: Partnership;
};

const combinePartnerships = (handle?: IHandleDetails | null): CombinedPartnership[] => {
  const partnerships = handle?.metadata?.partnerships;
  const newPartnerships = handle?.handle_metadata?.holder?.metadata?.partnerships;
  const allCombined: CombinedPartnership[] = [];

  partnerships?.forEach(oldPartnership => {
    const newPartnership = newPartnerships?.find(ele => ele.name === oldPartnership.name);
    allCombined.push({
      old: oldPartnership,
      new: newPartnership || null,
    });
  });

  newPartnerships?.forEach(newPartnership => {
    const oldPartnership = partnerships?.find(ele => ele.name === newPartnership.name);
    if (!oldPartnership) {
      allCombined.push({
        old: oldPartnership || null,
        new: newPartnership,
      });
    }
  });

  const allCombinedFiltered = allCombined.filter(
    ele =>
      ele.new?.name !== ele.old?.name ||
      getKeys(ele.new?.relation || {}).some(
        key => ele.new?.relation?.[key] !== ele.old?.relation?.[key]
      )
  );

  return allCombinedFiltered;
};

const HandleDiffPartnerships = ({ handle }: { handle: IHandleDetails | null }) => {
  const { mobile: isMobileLayout } = useMediaQuery({ mobile: true });
  const allCombined = useMemo(() => combinePartnerships(handle), [handle]);

  const renderPartnership = useCallback(
    (
      partnership?: Partnership,
      options: {
        renderNewTitle?: boolean;
        renderTitles?: boolean;
        old?: boolean;
      } = {
        renderNewTitle: false,
        renderTitles: true,
        old: true,
      }
    ) => {
      const sections = [
        {
          title: translate('handlesScreen.sponser'),
          value: partnership?.name || '-',
        },
        {
          title: translate('handlesScreen.exclusive'),
          value: partnership?.relation?.exclusive
            ? translate('common.yes')
            : translate('common.no'),
        },
        {
          title: translate('common.details'),
          value: partnership?.relation?.description || '-',
        },
        {
          title: translate('handlesScreen.legalClause'),
          value: partnership?.relation?.clause || '-',
        },
      ];
      return (
        <HandleChangeDiff
          sections={sections}
          itemClass='p-4 h-50px'
          options={{ ...options, includeTitleWithBackground: true }}
        />
      );
    },
    []
  );

  if (!handle?.handle_metadata?.holder?.metadata?.partnerships || !allCombined.length) {
    return <></>;
  }

  return (
    <div
      className='bg-primary-dark-grey  xborder-t-0  p-4 capitalize flex flex-col gap-8 border-2 border-primary-blue'
      style={{ boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15266)' }}
    >
      <div className='flex flex-col gap-8'>
        <div className='flex'>
          <label className='font-bold text-3xl'>{translate('handlesScreen.sponsers')}</label>
        </div>
        {allCombined.map((partnership, index) => {
          return (
            <div key={index} className='flex flex-col gap-4'>
              {isMobileLayout && (
                <div className='font-bold text-2xl'>
                  {translate('handlesScreen.sponser')} {index + 1}
                </div>
              )}
              <div className={`flex ${isMobileLayout ? 'flex-col-reverse gap-5' : 'flex-row'}`}>
                {partnership.new &&
                  renderPartnership(partnership.new, {
                    renderTitles: true,
                    old: false,
                    renderNewTitle: !partnership.old || index === 0,
                  })}
                {partnership.old &&
                  renderPartnership(partnership.old, {
                    old: true,
                    renderTitles: !partnership.new || isMobileLayout,
                    renderNewTitle: !partnership.new || index === 0,
                  })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default HandleDiffPartnerships;
