import { FC } from 'react';

import clsx from 'clsx';

import useBinary from './hooks/useBinary';
import { Spiner } from '../Spiner';
import { ImageProps } from './type';
import { downloadFile } from './hooks/utils';
import fallbackImage from '../../../assets/images/defaultImage.png';

const BufferedImage: FC<ImageProps> = ({
  src,
  fallback = fallbackImage,
  Fallback,
  style,
  className,
  containerClassName = '',
  alt,
  enableDownload = '',
}) => {
  const { signedSrc, isLoading, isError, onError, onLoad } = useBinary({ src });

  return (
    <>
      {!!enableDownload && signedSrc && (
        <button className='tw-link block mb-2' onClick={() => downloadFile(signedSrc, 'download')}>
          {enableDownload}
        </button>
      )}
      {(signedSrc || fallback) && (!isError || !Fallback) && (
        <div className={clsx('relative overflow-hidden', containerClassName)}>
          <img
            decoding='async'
            loading='lazy'
            className={clsx('h-full w-full object-cover', className)}
            onError={onError}
            onLoad={onLoad}
            style={style}
            src={isError ? fallback : signedSrc || fallback}
            alt={alt || 'fallBack'}
          />
          {isLoading && (
            <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
              <Spiner />
            </div>
          )}
        </div>
      )}
      {!!Fallback && ((!signedSrc && !fallback) || isError) && (
        <Fallback
          className={`${className}`}
          style={style}
          src={signedSrc}
          alt={alt || 'fallBack'}
        />
      )}
    </>
  );
};

export default BufferedImage;
