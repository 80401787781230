import React, { useEffect, useState } from 'react';
import {
  calcPagination,
  getMembership,
  getSocilaIcon,
  getStatus,
  getStatusClass,
  translate,
} from 'utils/helperFunctions';
import {
  AlignTypes,
  Column,
  ComingSoon,
  NoAccess,
  Pagination,
  Select,
  Spiner,
  Table,
  VeticalAlignTypes,
} from 'components/shared';
import { SearchIcon } from '@heroicons/react/outline';
import { useNavigate, useParams } from 'react-router-dom';
import { HandleServices } from 'services/apis/Handle';
import { toast } from 'react-toastify';
import { UseAbility } from 'services/context';
import { ModalAddEditHandle } from './ModalAddHandle';
import MobileCards, { MobileView } from 'components/shared/MobileCards';
import { IHandleDetails } from 'services/apis/Handle/handle.types';

let debounce: any = null;

export const Handles = () => {
  const navigate = useNavigate();
  const { membership, id } = useParams();
  const canGetHandlesList = UseAbility('handles', 'get');
  const canGetNotInfluencerHandles = UseAbility('notInfluencer', 'get');

  const canCreateHandle = UseAbility('handle', 'create');
  const canGetHandleDetails = UseAbility('handleDetails', 'get');

  const [loading, setLoading] = useState<boolean>(false);
  const [isAddHandleModalVisible, setIsAddHandleModalVisible] = useState<boolean>(false);
  const [countUsersPages, setCountUsersPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [handles, sethandles] = useState<IHandleDetails[]>([]);
  const [filter, setFilter] = useState<{ [key: string]: any }>({
    keywords: '',
    socialMedia: '',
    status: '',
  });

  const fetchHandleList = async (params: any = {}) => {
    const finalParams = { ...calcPagination(), ...filter, ...params };
    if (membership === 'venturePartner') {
      return;
    }
    setLoading(true);
    try {
      if (membership === 'influencer') {
        const list = await HandleServices.handleList(finalParams);
        sethandles(list.items);
        setCountUsersPages(list.count);
        setCurrentPage(finalParams.skip / finalParams.limit + 1);
        if (list.items.length === 0 && !filter?.keywords && !filter?.socialMedia && !filter?.status)
          setIsAddHandleModalVisible(true);
      } else {
        if (membership) {
          const list = await HandleServices.notInfluencerlhandle(membership, finalParams);
          sethandles(list.items);
          setCountUsersPages(list.count);
          setCurrentPage(finalParams.skip / finalParams.limit + 1);
        }
      }
      setLoading(false);
    } catch (error: any) {
      toast.error(error?.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      (membership === 'influencer' && canGetHandlesList) ||
      (membership !== 'influencer' && canGetNotInfluencerHandles)
    ) {
      fetchHandleList();
    }
  }, [filter?.socialMedia, filter?.status]);

  const _renderModalAddHandles = () => {
    return (
      <ModalAddEditHandle
        isHandleModalVisible={isAddHandleModalVisible}
        setIsHandleModalVisible={setIsAddHandleModalVisible}
        actionName='new'
      />
    );
  };

  const _renderContenTable = () => {
    const columns: Array<Column> = [
      {
        dataIndex: '',
        align: 'left' as AlignTypes,
        render: (_, item: any) => {
          return (
            <div className='flex flex-col gap-2'>
              <div className='flex items-start text-2xl'>
                <div className='flex-shrink-0 mr-4 items-start justify-start'>
                  <img className='h-7 w-7' src={getSocilaIcon(item.socialMedia)} alt={'icon'} />
                </div>
                <div className='sm:flex gap-5'>
                  <div>{item.handle}</div>
                  {membership !== 'influencer' && (
                    <div className='text-primary-blue capitalize'>
                      {`(${item?.userInfo?.firstName} ${item?.userInfo?.lastName})` || '-'}
                    </div>
                  )}
                </div>
              </div>
              <div className='text-xl'>
                <div>
                  <span className='text-primary-orange'>{item.numberOfConvos || 0}</span>{' '}
                  {translate('common.conversations')} -{'  '}
                  <span className='text-primary-orange'>
                    {' '}
                    {(membership === 'influencer'
                      ? item.numberOfDomains
                      : item.numberOfCampaigns) || 0}
                  </span>{' '}
                  {membership === 'influencer'
                    ? translate('handlesScreen.domains')
                    : translate('handlesScreen.campaigns')}
                </div>
              </div>
            </div>
          );
        },
      },
      {
        dataIndex: 'status',
        align: 'right' as AlignTypes,
        veticalAlign: 'bottom' as VeticalAlignTypes,
        render: (status: string) => {
          return (
            <div
              className={`${getStatusClass(
                status
              )} text-2xl flex justify-end items-start align-top capitalize`}
            >
              {getStatus(status)}
            </div>
          );
        },
      },
    ];

    const mobileColumns: Array<MobileView> = [
      {
        dataIndex: 'membership',
        render: (_membership: string, _item) => (
          <div className='text-base font-bold'>{getMembership(membership || '')}</div>
        ),
      },
      {
        dataIndex: 'handle',
        title: translate('common.handle'),
        render: (_, item) => {
          return (
            <div className='flex items-center justify-center'>
              <img
                className='h-6 w-6 flex-shrink-0 mr-4'
                src={getSocilaIcon(item?.socialMedia)}
                alt={'icon'}
              />
              <div>{item?.handle}</div>
            </div>
          );
        },
      },
      {
        dataIndex: 'numberOfConvos',
        title: translate('common.conversations'),
        render: numberOfConvos => numberOfConvos || 0,
      },
      {
        dataIndex: 'campaigns',
        title:
          membership === 'influencer'
            ? translate('handlesScreen.domains')
            : translate('handlesScreen.campaigns'),
        render: (_, item) =>
          (membership === 'influencer' ? item?.numberOfDomains : item?.numberOfCampaigns) || 0,
      },
      {
        dataIndex: 'status',
        title: translate('common.status'),
        render: (status: string) => {
          return (
            <div
              className={`${getStatusClass(
                status
              )} flex justify-end items-start align-top capitalize`}
            >
              {getStatus(status)}
            </div>
          );
        },
      },
    ];

    const { limit, skip } = calcPagination(currentPage);

    return (
      <>
        <div
          className='bg-primary-dark-grey border-2 border-t-0 border-primary-blue px-4 relative'
          style={{ boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15266)' }}
        >
          {membership === 'venturePartner' && (
            <div className='absolute z-10 bg-primary-medium-grey bg-opacity-90 w-full h-full -ml-4 flex overflow-hidden justify-center'>
              <ComingSoon />
            </div>
          )}
          <div className='pt-6 flex gap-5 items-center justify-center'>
            <div className='relative shadow-sm grow w-full'>
              <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
                <SearchIcon className='h-5 w-5 text-primary-light-grey' aria-hidden='true' />
              </div>
              <input
                type='search'
                name='search'
                className='tw-input-blue-dark !border-[3px]  !bg-primary-medium-grey placeholder:!bg-primary-medium-grey placeholder:!text-lg font-bold !pl-10 h-12 w-full '
                placeholder={translate('common.search')}
                value={filter?.keywords || ''}
                onChange={e => {
                  setFilter({ ...filter, keywords: e.target.value });
                  clearTimeout(debounce);
                  debounce = setTimeout(() => {
                    fetchHandleList({ keywords: e?.target?.value });
                  }, 300);
                }}
              />
            </div>
            {membership === 'influencer' && canCreateHandle && (
              <div className='flex justify-end'>
                <button
                  type='button'
                  className='tw-button-light-blue !h-12 p-4 w-[85px] md:w-[175px]'
                  onClick={() => {
                    setIsAddHandleModalVisible(true);
                  }}
                >
                  {translate('handlesScreen.addHandle')}
                </button>
                {isAddHandleModalVisible && _renderModalAddHandles()}
              </div>
            )}
          </div>
          <div className='flex gap-5 mt-4'>
            <Select
              options={[
                { label: 'All', value: 'all' },
                { label: 'Facebook', value: 'facebook' },
                { label: 'Instagram', value: 'instagram' },
                { label: 'Tiktok', value: 'tiktok' },
                { label: 'Youtube', value: 'youtube' },
                { label: 'Twitter', value: 'twitter' },
                { label: 'Twitch', value: 'twitch' },
                { label: 'Snapchat', value: 'snapchat' },
                { label: 'Onlyfans', value: 'onlyfans' },
                { label: 'Pinterest', value: 'pinterest' },
                { label: 'Linkedin', value: 'linkedin' },
              ]}
              className='bg-primary-medium-grey relative w-40 shadow-sm pl-3 pr-10 text-left cursor-default focus:outline-none sm:text-sm border-2  !border-primary-blue h-8'
              value={filter?.socialMedia || 'all'}
              preValue={filter?.socialMedia ? '' : translate('common.type')}
              menuBg='bg-primary-medium-grey'
              onChange={e => {
                setFilter({ ...filter, socialMedia: e?.value === 'all' ? undefined : e?.value });
              }}
            />
            <Select
              options={[
                { label: 'All', value: 'all' },
                { label: 'New', value: 'new' },
                { label: 'Accepted', value: 'accepted' },
                { label: 'Pending', value: 'pending' },
                { label: 'Rejected', value: 'rejected' },
                { label: 'Suspended', value: 'suspended' },
              ]}
              className='bg-primary-medium-grey relative sm:w-36 shadow-sm pl-3 pr-10 text-left cursor-default focus:outline-none sm:text-sm !border-2  !border-primary-blue h-8'
              value={filter?.status || 'all'}
              preValue={filter?.status ? '' : translate('common.status')}
              menuBg='bg-primary-medium-grey'
              onChange={e => {
                setFilter({ ...filter, status: e?.value === 'all' ? undefined : e?.value });
              }}
            />
          </div>
          {loading ? (
            <div className='flex items-center justify-center' style={{ minHeight: '208px' }}>
              <Spiner />
            </div>
          ) : (
            <>
              <div className='hidden sm:block'>
                <Table
                  columns={columns}
                  dataSource={handles}
                  hover
                  onClickRow={(_index, item) =>
                    membership === 'influencer' &&
                    canGetHandleDetails &&
                    navigate(`/home/mission-control/${membership}/${id}/${item._id}`)
                  }
                  loading={loading}
                />
              </div>
              <div className='sm:hidden mt-4'>
                <MobileCards
                  dataSource={handles}
                  dataView={mobileColumns}
                  onClickCard={(item, _index) =>
                    membership === 'influencer' &&
                    canGetHandleDetails &&
                    navigate(`/home/mission-control/${membership}/${id}/${item._id}`)
                  }
                />
              </div>
            </>
          )}
          <Pagination
            count={countUsersPages}
            limit={limit}
            skip={skip}
            onChange={pageParams => {
              fetchHandleList(pageParams);
            }}
            className='mt-5 pr-4 pb-3'
          />
        </div>
      </>
    );
  };

  return (
    <div className=''>
      {(membership === 'influencer' && canGetHandlesList) ||
      (membership !== 'influencer' && canGetNotInfluencerHandles) ? (
        <>{_renderContenTable()}</>
      ) : (
        <NoAccess membership={membership as string} top={'top-56'} />
      )}
    </div>
  );
};
