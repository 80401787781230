export const actions: Record<string, string> = {
  SET_LOGIN_DATA: 'SET_LOGIN_DATA',
  SET_IS_LOGGED_IN: 'SET_IS_LOGGED_IN',
  UPDATE_USER_ACCOUNT: 'UPDATE_USER_ACCOUNT',
  TOGGLE_SPLASH: 'TOGGLE_SPLASH',
  SWITCH_LANGUAGE: 'SWITCH_LANGUAGE',
  ACL_HITTED: 'ACL_HITTED',
  SET_IS_ACL_LOADING: 'SET_IS_ACL_LOADING',
};

export const setLoginData = (payload: any) => ({
  type: actions.SET_LOGIN_DATA,
  payload,
});

export const setIsLoggedIn = (payload: any) => ({
  type: actions.SET_IS_LOGGED_IN,
  payload,
});

export const updateUserAccount = (payload: any) => ({
  type: actions.UPDATE_USER_ACCOUNT,
  payload,
});
export const AclHitted = (payload: any) => ({
  type: actions.ACL_HITTED,
  payload,
});
export const toggleSplash = (payload: any) => ({
  type: actions.TOGGLE_SPLASH,
  payload,
});

export const setIsAclLoading = (payload: boolean) => ({
  type: actions.SET_IS_ACL_LOADING,
  payload,
});

export const switchLanguage = (language: 'en' | 'fr') => ({
  type: actions.SWITCH_LANGUAGE,
  language: language,
});
