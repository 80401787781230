import Upload from './BinaryUpload';
import BufferedFile from './BufferedFile';
import BufferedImage from './BufferedImage';
import BufferedVideo from './BufferedVideo';
import BufferedFilePreview from './BufferedFilePreview';

export const Image = BufferedImage;
export const File = BufferedFile;
export const Video = BufferedVideo;
export const BinaryUpload = Upload;
export const FilePreview = BufferedFilePreview;
