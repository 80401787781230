import axios from 'axios';
import { BucketPaths } from 'config';

export const AwsServices = {
  getS3SignedUrl: async (params: { fileKey: string }) => {
    const endPoint = 'aws/s3/signed/url';
    try {
      const data = await axios.put<object, { signedUrl: string }>(endPoint, params);
      return data.signedUrl;
    } catch (error) {
      throw error;
    }
  },
  getS3SignedUrls: async (params: { payload: { fileKey: string; download: boolean }[] }) => {
    const endPoint = 'aws/s3/signed/urls';
    try {
      const data = await axios.put<
        { fileKey: string; download: boolean }[],
        { fileKey: string; signedUrl: string }[]
      >(endPoint, params);
      return data;
    } catch (error) {
      throw error;
    }
  },
  uploadFilesToS3: async ({
    files,
  }: {
    files: {
      blob: Blob;
      path: BucketPaths;
      type: string;
    }[];
  }) => {
    const endPoint = 'aws/s3/signed/urls';
    try {
      const response = await axios.get<
        object,
        { signedUrl: string; fileKey: string; url: string }[]
      >(endPoint, {
        params: {
          payload: files.map(file => ({
            path: file.path,
            contentType: file.type,
          })),
        },
      });
      await Promise.all(
        response.map(async (signedUrl, index) => {
          await fetch(signedUrl.signedUrl, {
            method: 'PUT',
            headers: {
              'Content-Type': files[index].type,
            },
            body: files[index].blob,
          });
        })
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  deleteFromS3: async ({ fileKeys }: { fileKeys: string[] }) => {
    try {
      const endPoint = 'aws/s3/objects';
      const config = {
        params: { fileKeys },
      };
      const result = await axios.delete(endPoint, config);
      return result;
    } catch (error) {
      throw error;
    }
  },
};
