import React, { Fragment, useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
import { classNames } from 'utils/helperFunctions';

export type Option = {
  value: React.Key;
  label: string;
};
type SelectProps = {
  options: Array<Option>;
  label?: JSX.Element;
  floatingLabel?: string;
  value?: React.Key | null;
  preValue?: string;
  disabled?: boolean;
  onChange: (value: Option | undefined) => void;
  className?: string;
  labelClassName?: string;
  openlabelClassName?: string;
  inputClassName?: string;
  placeholder?: string;
  placeholderStyle?: string;
  id?: string;
  textStyle?: React.CSSProperties;
  menuBg?: string;
  withIcon?: boolean;
  getIcon?: any;
  focusOnOpen?: boolean;
};
export const Select: React.FC<SelectProps> = React.memo(
  ({
    options,
    label,
    floatingLabel,
    disabled = false,
    value,
    preValue,
    className,
    placeholder,
    onChange,
    textStyle,
    id,
    withIcon,
    getIcon,
    menuBg,
    placeholderStyle,
    focusOnOpen = false,
    labelClassName = '',
    inputClassName = '',
    openlabelClassName = '',
  }) => {
    const [selectedValue, setSelectedValue] = useState<Option | undefined>({
      value: '',
      label: '',
    });
    useEffect(() => {
      const newOption = options.find(option => option.value === value);
      if (value !== undefined && newOption) {
        setSelectedValue(newOption);
      }
    }, [value]);

    return (
      <Listbox
        value={selectedValue?.value || ''}
        disabled={disabled}
        onChange={selected => {
          const valueAfterSelect =
            selected === selectedValue?.value
              ? undefined
              : options?.find(option => option?.value === selected);
          if (valueAfterSelect !== null) {
            setSelectedValue(valueAfterSelect);
            onChange(valueAfterSelect);
          }
        }}
      >
        {({ open }) => (
          <div className='relative'>
            {label && (
              <Listbox.Label className={classNames('block text-sm font-medium ', labelClassName)}>
                {label}
              </Listbox.Label>
            )}
            {floatingLabel && (
              <h1
                className={classNames(
                  `absolute pointer-events-none text-sm  text-primary-light-grey left-4 transform translate-x-[0px] translate-y-[8px] scale-100 origin-top-left transition duration-200 ease-out z-10 ${
                    (open || selectedValue?.value) &&
                    `transform translate-x-[-15px] translate-y-[-12px]  ${openlabelClassName} scale-[0.8] sm:scale-[0.8] text-primary-light-grey uppercase`
                  }
                
                `,
                  labelClassName
                )}
              >
                {floatingLabel}
              </h1>
            )}
            <div className='relative'>
              <Listbox.Button
                className={
                  className
                    ? `${className} ${
                        open && focusOnOpen ? 'border-primary-orange' : ' border-b-white/50'
                      }`
                    : `${
                        open ? 'rounded-b-[0px]' : 'rounded-b-[0px]'
                      } bg-primary-medium-grey relative w-full shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none sm:text-sm border-b-white/50 border-b `
                }
              >
                <span
                  className={classNames(
                    'block align-middle truncate text-primary-mint text-sm min-h-[20px]',
                    inputClassName
                  )}
                  style={textStyle}
                >
                  {preValue ? `${preValue}: ` : ''}
                  {selectedValue?.label ? (
                    <>
                      {withIcon ? (
                        <span className='flex items-center gap-4'>
                          <img className='w-6 h-6' src={getIcon(selectedValue?.value) || ''} />{' '}
                          <span>{selectedValue?.label}</span>
                        </span>
                      ) : (
                        <>{selectedValue?.label}</>
                      )}
                    </>
                  ) : (
                    <span
                      className={`text-primary-light-grey text-sm truncate ${placeholderStyle}`}
                    >
                      {placeholder
                        ? placeholder
                        : floatingLabel && open
                        ? 'Select'
                        : !floatingLabel
                        ? 'Select'
                        : ''}
                    </span>
                  )}
                </span>
                <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                  {open ? (
                    <ChevronUpIcon
                      style={textStyle}
                      className='h-5 w-5 text-white'
                      aria-hidden='true'
                    />
                  ) : (
                    <ChevronDownIcon
                      style={textStyle}
                      className='h-5 w-5 text-white'
                      aria-hidden='true'
                    />
                  )}
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave='transition ease-in duration-100'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <Listbox.Options
                  id={id}
                  className={`absolute z-50 w-full ${
                    menuBg ? menuBg : 'bg-primary-dark-grey'
                  } bg-primary-dark-grey shadow-lg max-h-60 rounded-b mt-0 z-mt-9 py-2 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm`}
                >
                  {options?.map((option, index) => (
                    <Listbox.Option
                      key={index}
                      className={({ active }) =>
                        classNames(
                          active ? 'text-white bg-primary-medium-grey/20' : 'text-white',
                          'cursor-pointer select-none relative py-2 pl-3 pr-9'
                        )
                      }
                      value={option?.value}
                    >
                      {({ active }) => (
                        <>
                          <span
                            className={classNames(
                              option?.value === selectedValue?.value
                                ? 'font-semibold text-white'
                                : 'font-normal',
                              'block truncate text-left'
                            )}
                          >
                            {withIcon ? (
                              <span className='flex items-center gap-4'>
                                <img className='w-6 h-6' src={getIcon(option?.value)} />{' '}
                                <span>{option?.label}</span>
                              </span>
                            ) : (
                              <>{option?.label}</>
                            )}
                          </span>

                          {option?.value === selectedValue?.value ? (
                            <span
                              className={classNames(
                                active ? 'text-white' : 'text-white',
                                'absolute inset-y-0 right-0 flex items-center pr-4'
                              )}
                            >
                              <CheckIcon className='h-5 w-5' aria-hidden='true' />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </div>
        )}
      </Listbox>
    );
  }
);
