import Logo from 'assets/images/Logo.svg';

export const ComingSoon = () => {
  return (
    <div className='flex flex-col justify-center items-center'>
      <div className='flex-shrink-0'>
        <img src={Logo} />
      </div>
      <h1 className='font-Condensed font-black text-primary-orange text-3xl sm:text-[42px] mt-4'>
        Coming Soon...
      </h1>
    </div>
  );
};
