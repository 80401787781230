import axios from 'axios';
import store from 'services/context/store';
import { setLoginData } from 'services/context/Auth/actions';
import { setStorageLoginData } from 'utils/StorageServices';
import { ReAuthData } from '../Auth';
import { OnBoardingSummary } from './Onboarding.types';

export const OnboardingServices = {
  //used APIs
  onJoin: async (payload: any): Promise<any> => {
    const endPoint = `onboarding/join/consumer/invited`;
    try {
      const invitedUser = await axios.post(endPoint, payload);
      return invitedUser;
    } catch (error) {
      throw error;
    }
  },
  onValidateCodes: async (payload: any): Promise<any> => {
    const endPoint = `onboarding/validate/codes`;
    try {
      const join = await axios.put<object, ReAuthData>(endPoint, payload);
      setStorageLoginData(join);
      store.dispatch(setLoginData({ ...join }));
      return join;
    } catch (error) {
      throw error;
    }
  },
  onResendValidateCode: async (code: string, type: string): Promise<any> => {
    const endPoint = `onboarding/resend/code/?code=${code}&type=${type}`;
    try {
      const resendCode = await axios.get(endPoint);
      return resendCode;
    } catch (err) {
      throw err;
    }
  },
  onEnterInvitationCode: async (inviteCode: string): Promise<any> => {
    const endPoint = `onboarding/new/invitation/${inviteCode}`;
    try {
      const invitationStatus = await axios.get(endPoint);
      return invitationStatus;
    } catch (err) {
      throw err;
    }
  },
  fetchInviteRecord: async (inviteCode: string): Promise<any> => {
    const endPoint = `onboarding/membership/${inviteCode}`;
    try {
      const record = await axios.put(endPoint);
      return record;
    } catch (err) {
      throw err;
    }
  },
  fetchMembershipStatus: async (id: string): Promise<any> => {
    const endPoint = `onboarding/membership/${id}`;
    try {
      const membership = await axios.get(endPoint);
      return membership;
    } catch (err) {
      throw err;
    }
  },
  fetchMembershipPercent: async (): Promise<any> => {
    const endPoint = `onboarding/user/memberships`;
    try {
      const mempershipPercent = await axios.get(endPoint);
      return mempershipPercent;
    } catch (err) {
      throw err;
    }
  },
  addNewMedadata: async (id: string, payload: any): Promise<any> => {
    const endPoint = `onboarding/membership/${id}/metadata`;
    try {
      const metadata = await axios.put(endPoint, payload);
      return metadata;
    } catch (err) {
      throw err;
    }
  },
  addLegalName: async (id: string, payload: any): Promise<any> => {
    const endPoint = `onboarding/membership/${id}/contract`;
    try {
      const res = await axios.put(endPoint, payload);
      return res;
    } catch (err) {
      throw err;
    }
  },
  onResign: async (id: string): Promise<any> => {
    const endPoint = `onboarding/membership/${id}/contract/resend`;
    try {
      const res = await axios.put(endPoint);
      return res;
    } catch (err) {
      throw err;
    }
  },
  validateChangePhoneCode: async (code: string): Promise<any> => {
    const endPoint = `onboarding/validate/changePhone/autoLogin?token=${code}`;
    try {
      const response = await axios.get<object, ReAuthData>(endPoint);
      setStorageLoginData(response);
      store.dispatch(setLoginData({ ...response }));
      return response;
    } catch (error) {
      throw error;
    }
  },
  fetchOnboardingSummary: async (id: string): Promise<OnBoardingSummary> => {
    const endPoint = `onboarding/${id}/summary`;
    try {
      const response = await axios.get<object, OnBoardingSummary>(endPoint);
      return response;
    } catch (error) {
      throw error;
    }
  },
};
