import Logo from 'assets/images/Logo.svg';
import React from 'react';

export const Splash = () => {
  return (
    <div className='flex flex-col justify-center items-center h-screen p-8'>
      <div className='flex-shrink-0'>
        <img src={Logo} className='w-80' />
      </div>
      <h1 className='font-Condensed font-black text-primary-orange text-2xl sm:text-[42px] mt-13'>
        Loading...
      </h1>
    </div>
  );
};
