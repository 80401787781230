import { getSocilaIcon, translate } from 'utils/helperFunctions';

export const HandleInfo = ({ details }: any) => {
  const rows = [
    {
      value: <p className='capitalize'>@{details?.handle} </p>,
      label: translate('common.handle'),
      p: 'pt-0 pb-6',
      b: 'border-0',
    },
    {
      value: details?.socialMedia && (
        <div className='flex gap-2 items-center justify-center capitalize'>
          <img className='h-7 w-7' src={getSocilaIcon(details?.socialMedia)} alt={'icon'} />{' '}
          {details.socialMedia}
        </div>
      ),
      label: translate('common.type'),
    },
    {
      value: details?.url,
      label: translate('handlesScreen.handleLink'),
    },
  ];

  return (
    <div className=''>
      {rows &&
        rows.map((row, index) => {
          return (
            <div
              key={index}
              className={`${row.p ? row.p : `py-6`} ${
                row.b ? row.b : 'border-t-2 border-primary-light-grey'
              } flex flex-row gap-4`}
            >
              <div className='flex items-start justify-start w-[128px] sm:w-auto break-words'>
                <div className='flex items-start justify-start font-Condensed text-[24px] text-primary-orange font-bold w-[128px] whitespace-normal break-words'>
                  {row.label}:
                </div>
              </div>
              <div className='flex items-center justify-start '>
                <div
                  className={`font-Condensed capitalize text-[24px] text-left font-bold sm:whitespace-normal break-words max-w-[180px] sm:max-w-none truncate`}
                >
                  {row?.value ? row.value : '-'}
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};
