import { Spiner } from 'components/shared';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { OnboardingServices } from 'services/apis/Onboarding';
import { MetadataType } from 'services/apis/Onboarding/Onboarding.types';
import { useStore } from 'services/context';
import { translate } from 'utils/helperFunctions';
import usePrompt from 'utils/hooks/usePrompt';
import { WizardNewContact } from './WizardNewContact';
import { WizardNewLightsYourFire } from './WizardNewLightsYourFire';
import { WizardNewTurnsYouOff } from './WizardNewTurnsYouOff';

export const WizardNew = () => {
  const { id } = useParams();
  const [state] = useStore();
  const navigate = useNavigate();
  const route = useLocation();
  const membership = route?.state?.membership ?? '';
  const { userData } = state?.authStore;
  const [screen, setScreen] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [metadata, setMetadata] = useState<MetadataType>({
    contact: userData?.phone,
    lightsYourFire: [],
    turnsYouOff: [],
  });

  const { _renderModalDiscardChanges, setIsBlocking } = usePrompt({
    message: translate('discardModal.areYouSureYouWantToExitTheOnboardingProcess'),
    okText: translate('common.yes'),
    cancelText: translate('common.no'),
  });

  useEffect(() => {
    setIsBlocking(true);
  }, []);

  const crumbsArray =
    membership === 'influencer'
      ? [
          {
            name: 'Contact',
            screen: 1,
          },
          {
            name: 'Likes',
            screen: 2,
          },
          {
            name: 'Dislikes',
            screen: 3,
          },
        ]
      : [];

  const _renderCrumbs = () => {
    return (
      <div className={`flex w-full items-center justify-center gap-2 sm:gap-3 mt-4 `}>
        {crumbsArray.map(crumb => (
          <div className='relative' key={crumb?.screen}>
            <p className='absolute w-full text-[16px] font-bold text-center justify-center h-full flex items-center'>
              {crumb.name}
            </p>
            <svg
              width='222'
              height='51'
              viewBox='0 0 222 51'
              className=' hidden md:block '
              fill={crumb.screen < screen ? '#E66752' : crumb.screen > screen ? '#6F7577' : ''}
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M211.989 50H2.293L11.1136 25.843L11.2389 25.5L11.1136 25.157L2.293 1H211.989L220.935 25.5L211.989 50Z'
                fill=''
                stroke={crumb.screen === screen ? '#E66752' : ''}
                strokeWidth='2'
              />
            </svg>
            <svg
              width='105'
              height='36'
              viewBox='0 0 112 36'
              className=' block md:hidden '
              fill={crumb.screen < screen ? '#E66752' : crumb.screen > screen ? '#6F7577' : ''}
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M0 0H107.284L112 18L107.284 36H0L4.71579 18L0 0Z'
                fill=''
                stroke={crumb.screen === screen ? '#E66752' : ''}
                strokeWidth='2'
              />
            </svg>
          </div>
        ))}
      </div>
    );
  };

  const _renderWizardNew = () => {
    switch (screen) {
      case 1:
        return <WizardNewContact onChange={setMetadata} metadata={metadata} />;
      case 2:
        return <WizardNewLightsYourFire onChange={setMetadata} metadata={metadata} />;
      case 3:
        return <WizardNewTurnsYouOff onChange={setMetadata} metadata={metadata} />;
      default:
        return null;
    }
  };

  const onBack = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    setScreen(prev => prev - 1);
  };

  const onNext = async () => {
    if (id) {
      if (membership === 'influencer') {
        if (screen === 3) {
          setIsBlocking(false);
          try {
            setLoading(true);
            await OnboardingServices.addNewMedadata(id, {
              metadata,
            });
            setLoading(false);
            navigate(`/home/onboarding-wizard/${id}`);
          } catch (error: any) {
            setLoading(false);
            toast.error(error?.message);
          }
        }
        if (screen < 3) {
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
          setScreen(prev => prev + 1);
        }
      } else {
        setIsBlocking(false);
        try {
          setLoading(true);
          const newMetadata = { ...metadata };
          delete newMetadata.lightsYourFire;
          delete newMetadata.turnsYouOff;
          await OnboardingServices.addNewMedadata(id, {
            metadata: { ...newMetadata },
          });
          setLoading(false);
          navigate(`/home/onboarding-wizard/${id}`);
        } catch (error: any) {
          setLoading(false);
          toast.error(error?.message);
        }
      }
    }
  };

  return (
    <>
      <div className='border xl:w-[80%] xl:mx-auto border-primary-orange mx-4 my-4 bg-primary-dark-grey overflow-hidden'>
        {_renderCrumbs()}
        <div>
          {_renderModalDiscardChanges()}
          <div className={`flex justify-center items-center`}>{_renderWizardNew()}</div>
          <div className={`w-full`}>
            <div className='flex justify-center items-center gap-5 sm:gap-7 pb-4 w-full px-3'>
              {membership === 'influencer' && screen !== 1 && (
                <button
                  className='tw-button-secondary !px-6 !text-base !w-[175px] !h-[48px]'
                  disabled={screen === 1}
                  onClick={onBack}
                  hidden
                >
                  {translate('common.back')}
                </button>
              )}
              <button
                className={`${
                  screen === 1 ? '!w-[366px] mx-4' : '!w-[175px]'
                }  tw-button-light-blue !h-[48px] !px-6 !text-base`}
                disabled={
                  (screen === 2 && metadata?.lightsYourFire?.length === 0) ||
                  (screen === 3 && metadata?.turnsYouOff?.length === 0)
                }
                onClick={onNext}
              >
                {loading && (
                  <Spiner className={membership === 'influencer' ? 'ml-20' : 'ml-[100px]'} />
                )}
                {screen === 3
                  ? translate('common.next')
                  : membership === 'influencer'
                  ? translate('common.next')
                  : translate('common.submit')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
